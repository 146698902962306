<template>
  <el-collapse class="editor-wrapper" value="editor-input-label">
    <el-collapse-item :title="$t('interface_editor.component_editor.editor_gantt_bryntum.calendar_source')" name="editor-tasks-source">
      <el-form>
        <!-- источник -->
        <el-collapse-item
          :title="$t('interface_editor.component_editor.editor_gantt_bryntum.source')"
          name="editor-source">
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.table.source_type') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.entityType"
              @change="$set(value.editorProperties.taskSource, 'entityType', $event)"
              :options="{
              multiple: false,
               options: dataSourceTypes
            }"
            ></editor-select>
          </el-form-item>
          <el-form-item v-if="value.editorProperties.taskSource.entityType === 'registry'">
            <registry-select
              :append-to-body="true"
              :label="$t('interface_editor.component_editor.table.registry')"
              :value="value.editorProperties.taskSource.entityId"
              @change="$set(value.editorProperties.taskSource, 'entityId', $event)"
            ></registry-select>
          </el-form-item>
          <el-form-item v-if="value.editorProperties.taskSource.entityType === 'query'">
            <span class="property_label">{{ $t('interface_editor.component_editor.table.request') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.entityId"
              @change="$set(value.editorProperties.taskSource ,'entityId', $event)"
              :options="{
                multiple: false,
                options: entityTypeData
              }"
            ></editor-select>
          </el-form-item>
        </el-collapse-item>
        <!-- свойства сопоставления -->
        <el-collapse-item
          :title="$t('interface_editor.component_editor.editor_gantt_bryntum.comparison')"
          name="comparison_task"
        >
          <!--Ключевое поле-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.key_field') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.keyField"
              @change="$set(value.editorProperties.taskSource ,'keyField', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!--id потомка -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.parent_field') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.parentIdField"
              @change="$set(value.editorProperties.taskSource ,'parentIdField', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- название -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.name') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.nameField"
              @change="$set(value.editorProperties.taskSource ,'nameField', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- процент выполнения -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.percent_done') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.percentDoneField"
              @change="$set(value.editorProperties.taskSource ,'percentDoneField', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- длительность -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.duration') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.duration"
              @change="$set(value.editorProperties.taskSource ,'duration', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- трудозатраты -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.effort') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.effort"
              @change="$set(value.editorProperties.taskSource ,'effort', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- тип ограничения -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.constraint_type') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.constraintType"
              @change="$set(value.editorProperties.taskSource ,'constraintType', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- дата ограничения -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.constraint_date') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.constraintDate"
              @change="$set(value.editorProperties.taskSource ,'constraintDate', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- ручное планирование -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.manually_scheduled') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.isManuallyScheduledField"
              @change="$set(value.editorProperties.taskSource ,'isManuallyScheduledField', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- не активна -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.inactive') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.inactive"
              @change="$set(value.editorProperties.taskSource ,'inactive', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- порядковый номер -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.index_number') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.indexNumber"
              @change="$set(value.editorProperties.taskSource ,'indexNumber', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
          <!-- иерархический номер -->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.hierarchy_number') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.hierarchyNumber"
              @change="$set(value.editorProperties.taskSource ,'hierarchyNumber', $event)"
              :options="{
            multiple: false,
            options: attrs
          }"
            ></editor-select>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item
          :title="$t('interface_editor.component_editor.editor_gantt_bryntum.dates')"
          name="dates"
        >
          <!--Плановая дата начала-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.plan_start_date') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.planStartDate"
              @change="$set(value.editorProperties.taskSource, 'planStartDate', $event)"
              :options="{
                multiple: false,
                options: attrs
              }"
            ></editor-select>
          </el-form-item>
          <!--Плановая дата окончания-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.plan_end_date') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.planEndDate"
              @change="$set(value.editorProperties.taskSource, 'planEndDate', $event)"
              :options="{
                multiple: false,
                options: attrs
              }"
            ></editor-select>
          </el-form-item>
          <!--Фактическая дата начала-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.fact_start_date') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.factStartDate"
              @change="$set(value.editorProperties.taskSource, 'factStartDate', $event)"
              :options="{
                multiple: false,
                options: attrs
              }"
            ></editor-select>
          </el-form-item>
          <!--Фактическая дата окончания-->
          <el-form-item>
            <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.fact_end_date') }}</span>
            <editor-select
              :value="value.editorProperties.taskSource.factEndDate"
              @change="$set(value.editorProperties.taskSource, 'factEndDate', $event)"
              :options="{
                multiple: false,
                options: attrs
              }"
            ></editor-select>
          </el-form-item>
        </el-collapse-item>
        <!-- фильтры календарной части -->
        <el-collapse-item
          :title="$t('interface_editor.component_editor.editor_gantt_bryntum.filters')"
          name="editor_filters"
        >
          <editor-filters
            :value="value.editorProperties.taskSource.filters"
            @change="$set(value.editorProperties.taskSource ,'filters', $event)"
          ></editor-filters>
        </el-collapse-item>
      </el-form>
    </el-collapse-item>
    <!-- табличная часть -->
    <el-collapse-item
      :title="$t('interface_editor.component_editor.editor_gantt_bryntum.table_source')"
      name="editor-table-source">
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.table.column') }}</span>
        <editor-registry-headers
          :is-gantt="true"
          :value="value.editorProperties.tableSource.columns"
          :otherProperties="{list: {}}" :options="{ objectId: value.editorProperties.taskSource.entityId }"
          @change="$set(value.editorProperties.tableSource, 'columns', $event)"
        ></editor-registry-headers>
      </el-form-item>
    </el-collapse-item>
    <!-- Таблица связей -->
    <el-collapse-item
      :title="$t('interface_editor.component_editor.editor_gantt_bryntum.tasks_relation')"
      name="editor_relations"
    >
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.table.source_type') }}</span>
        <editor-select
          :value="value.editorProperties.tasksRelation.entityType"
          @change="$set(value.editorProperties.tasksRelation, 'entityType', $event)"
          :options="{
              multiple: false,
               options: dataSourceTypes
            }"
        ></editor-select>
      </el-form-item>
      <el-form-item v-if="value.editorProperties.tasksRelation.entityType === 'registry'">
        <registry-select
          :append-to-body="true"
          :label="$t('interface_editor.component_editor.table.registry')"
          :value="value.editorProperties.tasksRelation.entityId"
          @change="$set(value.editorProperties.tasksRelation, 'entityId', $event)"
        ></registry-select>
      </el-form-item>
      <!-- ключевое поле -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.key_field') }}</span>
        <editor-select
          :value="value.editorProperties.tasksRelation.keyField"
          @change="$set(value.editorProperties.tasksRelation ,'keyField', $event)"
          :options="{
            multiple: false,
            options: relationAttrs
          }"
        ></editor-select>
      </el-form-item>
      <!-- id откуда -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.parent_field') }}</span>
        <editor-select
          :value="value.editorProperties.tasksRelation.fromAttr"
          @change="$set(value.editorProperties.tasksRelation ,'fromAttr', $event)"
          :options="{
            multiple: false,
            options: relationAttrs
          }"
        ></editor-select>
      </el-form-item>
      <!-- id куда -->
      <el-form-item>
        <span class="property_label">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.child') }}</span>
        <editor-select
          :value="value.editorProperties.tasksRelation.toAttr"
          @change="$set(value.editorProperties.tasksRelation ,'toAttr', $event)"
          :options="{
            multiple: false,
            options: relationAttrs
          }"
        ></editor-select>
      </el-form-item>
      <!-- тип связи -->
      <el-form-item>
        <span>{{ $t('interface_editor.component_editor.editor_gantt_bryntum.relation_type') }}</span>
        <el-tooltip class="item" effect="dark" :content="tooltips.relationType">
          <i class="el-icon-question"></i>
        </el-tooltip>
        <editor-select
          :value="value.editorProperties.tasksRelation.type"
          @change="$set(value.editorProperties.tasksRelation ,'type', $event)"
          :options="{
            multiple: false,
            options: relationAttrs
          }"
        ></editor-select>
      </el-form-item>
    </el-collapse-item>
    <!-- Базовые настройки -->
    <el-collapse-item
      :title="$t('interface_editor.component_editor.editor_gantt_bryntum.base_settings')"
      name="editor_gantt_settings"
    >
      <!-- редактирование диаграммы -->
      <el-form-item>
        <el-checkbox v-model="value.editorProperties.isDiagramEditing"/>
        <span class="property_label" style="display: inline-block;">{{ $t('interface_editor.component_editor.editor_gantt_bryntum.is_diagram_editing') }}</span>
      </el-form-item>
      <el-form-item>
        <editor-filters
          :value="value.editorProperties.defaultValuesForTask"
          @change="$set(value.editorProperties ,'defaultValuesForTask', $event)"
          :title="$t('main.fields.default')"
          :show-xref-option="false"/>
      </el-form-item>
      <!-- Действия при клике внутри настроек -->
      <el-form-item>
        <editor-button-action
          :value='value.editorProperties.chartItemAction'
          :label="$t('interface_editor.component_editor.editor_gantt_new.chart_item_action')"
          :source-id="value.editorProperties.chartItemAction.card.registryId"
          source-type="registry"
          :is-headers="true"
          @change="$set(value.editorProperties, 'chartItemAction', $event)">
        </editor-button-action>
      </el-form-item>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'
import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch'
import EditorFilters from './editor-filters'
import EditorRegistryHeaders from './editor-registry-headers'
import EditorButtonAction from './editor-button-action'
import { buildFilters } from '@/components/InterfaceEditor/components/utils'

export default {
  name: 'editor-gantt-bryntum',
  components: {
    RegistrySelect,
    EditorSelect,
    EditorFilters,
    EditorRegistryHeaders,
    EditorButtonAction
  },
  props: {
    value: {
      type: Object
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.editorProperties.taskSource.entityType': {
      async handler (type) {
        this.$set(this.value.editorProperties.taskSource, 'planStartDate', null)
        this.$set(this.value.editorProperties.taskSource, 'planEndDate', null)
        this.$set(this.value.editorProperties.taskSource, 'factStartDate', null)
        this.$set(this.value.editorProperties.taskSource, 'factEndDate', null)
        this.$set(this.value.editorProperties.taskSource, 'keyField', null)
        this.$set(this.value.editorProperties.taskSource, 'nameField', null)
        this.$set(this.value.editorProperties.taskSource, 'parentIdField', null)
        this.$set(this.value.editorProperties.taskSource, 'percentDoneField', null)
        this.$set(this.value.editorProperties.taskSource, 'filters', [])
        this.$set(this.value.editorProperties.taskSource, 'isManuallyScheduledField', null)
        this.$set(this.value.editorProperties.taskSource, 'duration', null)
        this.$set(this.value.editorProperties.taskSource, 'effort', null)
        this.$set(this.value.editorProperties.taskSource, 'constraintType', null)
        this.$set(this.value.editorProperties.taskSource, 'constraintDate', null)
        this.$set(this.value.editorProperties.taskSource, 'inactive', null)

        this.$set(this.value.editorProperties.tableSource, 'columns', [])

        this.$set(this, 'entityTypeData', [])
        this.$set(this, 'attrs', [])
        this.$set(this, 'relationAttrs', [])

        if (type === 'query' || type === 'extended_object') {
          this.entityTypeData = await this.getDataByEntityType(type)
        }
      }
    },
    'value.editorProperties.taskSource.entityId': {
      async handler (entityId) {
        let type = this.value.editorProperties.taskSource.entityType
        let attrs = await this.getAttrs(entityId, type)
        if (attrs) {
          this.$set(this, 'attrs', Object.entries(attrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
        }
      }
    },
    'value.editorProperties.tasksRelation.entityId': {
      async handler (entityId) {
        let type = this.value.editorProperties.tasksRelation.entityType
        let attrs = await this.getAttrs(entityId, type)
        if (attrs) {
          this.$set(this, 'relationAttrs', Object.entries(attrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
        }
      }
    }
  },
  data () {
    return {
      dataSourceTypes: [
        { id: 'extended_object', name: 'Расширенная таблица' },
        { id: 'query', name: 'Запросы' },
        { id: 'registry', name: 'Реестр' }
      ],
      entityTypeData: [],
      attrs: [],
      relationAttrs: [],
      tooltips: {
        relationType: 'Значения: 1 - начало к началу; 2 - начало к концу; 3 - конец к началу; 4 - конец к концу',
        constraintType: 'Значения: 1 - окончание не раньше, 2 - окончание не позднее, 3 - фиксированное окончание, 4 - фиксированное начало, 5 - начало не раньше, 6 - начало не позднее'
      }
    }
  },
  async mounted () {
    let type = this.value.editorProperties.taskSource.entityType
    let relationType = this.value.editorProperties.tasksRelation.entityType

    if (type) {
      let taskEntityId = this.value.editorProperties.taskSource.entityId
      this.entityTypeData = await this.getDataByEntityType(type)

      let attrs = await this.getAttrs(taskEntityId, type)
      if (attrs) {
        this.$set(this, 'attrs', Object.entries(attrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
      }
    }

    if (relationType) {
      let relationEntityId = this.value.editorProperties.tasksRelation.entityId

      let relationAttrs = await this.getAttrs(relationEntityId, relationType)
      if (relationAttrs) {
        this.$set(this, 'relationAttrs', Object.entries(relationAttrs[0]).map(function (item) { return { id: item[0], name: item[0] } }))
      }
    }
  },
  methods: {
    async getDataByEntityType (type) {
      let data = []
      switch (type) {
        case 'extended_object':
          data = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
          break
        case 'query':
          data = await this.$http.get(`${this.$config.api}/bieditor/queries`)
          break
      }

      return data.data
    },
    async getAttrs (entityId, sourceType) {
      let result
      let response

      switch (sourceType) {
        case 'query':
          response = await this.$http.post(
            `${this.$config.api}/datawarehouseservice/query/${entityId}`,
            { limit: 1 },
            { hideNotification: true }
          )
          result = response.data
          break
        case 'registry':
          response = await this.$http.post(
            `${this.$config.api}/registryservice/registry/${entityId}`,
            { limit: 1 },
            { hideNotification: true }
          )
          result = response.data.data
          break
      }
      return result
    }
  }
}
</script>

<style scoped>

</style>
