import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class ConstraintDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: string
  description: Nullable<string>
  constraint_type_id: string
  is_auto: boolean
  is_allow: boolean
  is_init_allow: boolean
  is_notify: boolean
  message: Nullable<string>
  view_fields: Array<number>
  hide_fields: Array<number>
  disable_fields: Array<number>
  constraint_cards: Array<number>
  constraint_roles: Array<number>
  constraint_states: Array<number>
  state_match_type: string
  constraint_init_states: Array<number>
  init_state_match_type: string
  alias: Nullable<string>
  logic_id?: number
  parent_tree_element_id?: Nullable<number>

  constructor ({ id, guid, name, description, constraint_type_id, is_auto, is_allow, is_init_allow, is_notify, message, view_fields, hide_fields, disable_fields, constraint_cards, constraint_roles, constraint_states, state_match_type, constraint_init_states, init_state_match_type, alias, logic_id, parent_tree_element_id }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.constraint_type_id = constraint_type_id
    this.is_auto = is_auto
    this.is_allow = is_allow
    this.is_init_allow = is_init_allow
    this.is_notify = is_notify
    this.message = message
    this.view_fields = view_fields
    this.hide_fields = hide_fields
    this.disable_fields = disable_fields
    this.constraint_cards = constraint_cards
    this.constraint_roles = constraint_roles
    this.constraint_states = constraint_states
    this.state_match_type = state_match_type
    this.constraint_init_states = constraint_init_states
    this.init_state_match_type = init_state_match_type
    this.alias = alias
    this.logic_id = logic_id
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): ConstraintDTO {
    return new ConstraintDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      constraint_type_id: null,
      is_auto: true,
      is_allow: false,
      is_init_allow: false,
      is_notify: false,
      message: null,
      view_fields: [],
      hide_fields: [],
      disable_fields: [],
      constraint_cards: [],
      constraint_roles: [],
      constraint_states: [],
      state_match_type: 'all',
      constraint_init_states: [],
      init_state_match_type: 'all',
      alias: null,
      logic_id: null,
      parent_tree_element_id: null
    })
  }
}

export default class Constraint extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private description: Nullable<string>
  private constraintTypeId: string
  private isAuto: boolean
  private isAllow: boolean
  private isInitAllow: boolean
  private isNotify: boolean
  private stateMatchType: string
  private initStateMatchType: string
  private message: Nullable<string>
  private viewFields: Array<number>
  private hideFields: Array<number>
  private disableFields: Array<number>
  private constraintCards: Array<number>
  private constraintRoles: Array<number>
  private constraintStates: Array<number>
  private constraintInitStates: Array<number>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    constraintTypeId: string,
    isAuto: boolean,
    isAllow: boolean,
    isInitAllow: boolean,
    isNotify: boolean,
    stateMatchType: string,
    initStateMatchType: string,
    message: Nullable<string>,
    viewFields: Array<number>,
    hideFields: Array<number>,
    disableFields: Array<number>,
    constraintCards: Array<number>,
    constraintRoles: Array<number>,
    constraintStates: Array<number>,
    constraintInitStates: Array<number>,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId?: Nullable<number>,
    id?: Nullable<number>,
    guid?: Nullable<string>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.constraintTypeId = constraintTypeId
    this.isAuto = isAuto
    this.isAllow = isAllow
    this.isInitAllow = isInitAllow
    this.isNotify = isNotify
    this.stateMatchType = stateMatchType
    this.initStateMatchType = initStateMatchType
    this.message = message
    this.viewFields = viewFields
    this.hideFields = hideFields
    this.disableFields = disableFields
    this.constraintCards = constraintCards
    this.constraintRoles = constraintRoles
    this.constraintStates = constraintStates
    this.constraintInitStates = constraintInitStates
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: ConstraintDTO): Constraint {
    return new Constraint(
      dto.name,
      dto.description,
      dto.constraint_type_id,
      dto.is_auto,
      dto.is_allow,
      dto.is_init_allow,
      dto.is_notify,
      dto.state_match_type,
      dto.init_state_match_type,
      dto.message,
      dto.view_fields,
      dto.hide_fields,
      dto.disable_fields,
      dto.constraint_cards,
      dto.constraint_roles,
      dto.constraint_states,
      dto.constraint_init_states,
      dto.logic_id,
      dto.alias,
      dto.parent_tree_element_id,
      dto.id,
      dto.guid
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  setName (name: string): void {
    this.name = name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  getConstraintTypeId (): string {
    return this.constraintTypeId
  }

  setConstraintTypeId (constraintTypeId: string): void {
    this.constraintTypeId = constraintTypeId
  }

  getIsAuto (): boolean {
    return this.isAuto
  }

  setIsAuto (isAuto: boolean): void {
    this.isAuto = isAuto
  }

  getIsAllow (): boolean {
    return this.isAllow
  }

  setIsAllow (isAllow: boolean): void {
    this.isAllow = isAllow
  }

  getIsInitAllow (): boolean {
    return this.isInitAllow
  }

  setInitAllow (isInitAllow: boolean): void {
    this.isInitAllow = isInitAllow
  }

  getIsNotify (): boolean {
    return this.isNotify
  }

  setIsNotify (isNotify: boolean): void {
    this.isNotify = isNotify
  }

  getStateMatchType (): string {
    return this.stateMatchType
  }

  setStateMatchType (stateMatchType: string): void {
    this.stateMatchType = stateMatchType
  }

  getInitStateMatchType (): string {
    return this.initStateMatchType
  }

  setInitStateMatchType (initStateMatchType: string): void {
    this.initStateMatchType = initStateMatchType
  }

  getMessage (): Nullable<string> {
    return this.message
  }

  setMessage (message: Nullable<string>): void {
    this.message = message
  }

  getViewFields (): Array<number> {
    return this.viewFields
  }

  setViewFields (viewFields: Array<number>): void {
    this.viewFields = viewFields
  }

  getHideFields (): Array<number> {
    return this.hideFields
  }

  setHideFields (hideFields: Array<number>): void {
    this.hideFields = hideFields
  }

  getDisableFields (): Array<number> {
    return this.disableFields
  }

  setDisableFields (disableFields: Array<number>): void {
    this.disableFields = disableFields
  }

  getConstraintCards (): Array<number> {
    return this.constraintCards
  }

  setConstraintCards (constraintCards: Array<number>): void {
    this.constraintCards = constraintCards
  }

  getConstraintRoles (): Array<number> {
    return this.constraintRoles
  }

  setConstraintRoles (constraintRoles: Array<number>): void {
    this.constraintRoles = constraintRoles
  }

  getConstraintStates (): Array<number> {
    return this.constraintStates
  }

  setConstraintStates (constraintStates: Array<number>): void {
    this.constraintStates = constraintStates
  }

  getConstraintInitStates (): Array<number> {
    return this.constraintInitStates
  }

  setConstraintInitStates (constraintInitStates: Array<number>): void {
    this.constraintInitStates = constraintInitStates
  }

  getLogicId (): number {
    return this.logicId
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }
}
