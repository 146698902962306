




































































































































































































import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select.vue'

import UsersQuery from '@/services/AccessEditor/application/query/UsersQuery'
import RolesQuery from '@/services/AccessEditor/application/query/RolesQuery'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilter, faTrash, faWindowMaximize, faWindowMinimize } from '@fortawesome/free-solid-svg-icons'
import EventsQuery from '@/services/ActivityService/application/query/EventsQuery'

library.add(faWindowMinimize, faFilter, faTrash, faWindowMaximize)

export default {
  name: 'SnapshotPanel',
  components: {
    FontAwesomeIcon,
    EditorSelect
  },
  data () {
    return {
      loading: false,
      informationPanelHeight: 0,
      eventsFilterOptions: {
        users: {
          data: [],
          value: []
        },
        roles: {
          data: [],
          value: []
        },
        dateRange: {
          value: null
        },
        event_types: {
          data: [
            { id: 'login', name: this.$t('activity_service.activity_panel.events.event_types.login') },
            { id: 'logout', name: this.$t('activity_service.activity_panel.events.event_types.logout') },
            { id: 'save_card', name: this.$t('activity_service.activity_panel.events.event_types.save_card') },
            { id: 'new_save_card', name: this.$t('activity_service.activity_panel.events.event_types.new_save_card') },
            { id: 'open_menu', name: this.$t('activity_service.activity_panel.events.event_types.open_menu') },
            { id: 'open_tab', name: this.$t('activity_service.activity_panel.events.event_types.open_tab') },
            { id: 'close_tab', name: this.$t('activity_service.activity_panel.events.event_types.close_tab') },
            { id: 'custom_button', name: this.$t('activity_service.activity_panel.events.event_types.custom_button') },
            { id: 'open_record', name: this.$t('activity_service.activity_panel.events.event_types.open_record') }
          ],
          value: []
        }
      },
      events: {
        events: [],
        defaultEventsPageSize: 100,
        eventsCurrentPage: 0,
        eventsPageLimit: 100,
        eventsCount: 0,
        allEventsCount: 0,
        event: null,
        eventsPageSize: 0
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus'],
  methods: {
    async loadEventsFilterData () {
      await this.getQueryBus().execute(
        new UsersQuery()
      ).then(data => {
        this.eventsFilterOptions.users.data = data.map((user) => {
          return { id: user.id, name: user.full_name }
        })
      })

      await this.getQueryBus().execute(
        new RolesQuery()
      ).then(data => {
        this.eventsFilterOptions.roles.data = data
      })
    },
    async loadAllEvents () {
      this.loading = true
      await this.getQueryBus().execute(
        new EventsQuery({
          limit: this.events.eventsPageLimit,
          offset: this.events.eventsCurrentPage
        })
      ).then(data => {
        this.events.eventsPageSize = this.events.eventsPageLimit
        this.events.events = data.data
        this.events.eventsCount = data.count
        this.loading = false
      })
    },
    handleEventsPageChange (val) {
      val--
      this.events.eventsCurrentPage = (val * this.events.eventsPageLimit)
      if (this.eventsFilterOptions.event_types.value.length > 0 ||
        this.eventsFilterOptions.users.value.length > 0 ||
        this.eventsFilterOptions.roles.value.length > 0 ||
        this.eventsFilterOptions.dateRange.value
      ) {
        this.filterEvents()
      } else {
        this.loadAllEvents()
      }
    },
    parseDump (record, service, resource) {
      let res = []
      if (typeof record.id !== 'undefined') {
        res.push('<strong>id</strong>: ' + record.id)
      }
      if (typeof record.name !== 'undefined') {
        res.push('<strong>' + this.$locale.main.fields.name + '</strong>: ' + record.name)
      }

      if (service == 'reporteditor') {
        res.push('<strong>' + this.$locale.main.fields.type + '</strong>: ' + this.$locale.report_editor.types[record.report_type])
      }

      if (service == 'menueditor') {
        if (resource == 'menu') {
          res.push('<strong>' + this.$locale.main.fields.type + '</strong>: ' + this.$locale.menu_editor.label[record.menu_type_id])

          for (const [key, value] of Object.entries(record)) {
            if (key !== 'name' && this.$locale.menu_editor.label.hasOwnProperty(key)) {
              res.push('<strong>' + this.$locale.menu_editor.label[key] + '</strong>: ' + (value ?? ''))
            }
          }

          if (record.properties) {
            for (const [key, value] of Object.entries(record.properties)) {
              if (this.$locale.menu_editor.label.hasOwnProperty(key)) {
                res.push('<strong>' + this.$locale.menu_editor.label[key] + '</strong>: ' + (value ?? ''))
              }
            }
          }
        }
      }

      if (service == 'objecteditor') {
        if (resource == 'entities') {
          let props = record.properties
          if (props != null) {
            props.forEach(prop => {
              if (this.$locale.object_editor.settings.properties.hasOwnProperty(prop.id)) {
                let val = '<strong>' + this.$locale.object_editor.settings.properties[prop.id] + '</strong>: '
                if (prop.value == true) {
                  val += this.$locale.main.fields.true
                } else if (prop.value == false) {
                  val += this.$locale.main.fields.false
                } else {
                  val += (prop.value ?? '')
                }

                res.push(val)
              }
            })
          }
        }
      }

      return res.join(', ')
    },
    async filterEvents () {
      this.loading = true
      let payload = {
        limit: JSON.stringify(this.events.eventsPageLimit),
        offset: JSON.stringify(this.events.eventsCurrentPage)
      }
      if (this.eventsFilterOptions.users.value.length > 0) {
        Object.assign(payload, {
          user_id: JSON.stringify(this.eventsFilterOptions.users.value)
        })
      }
      if (this.eventsFilterOptions.roles.value.length > 0) {
        Object.assign(payload, {
          role_id: JSON.stringify(this.eventsFilterOptions.roles.value)
        })
      }
      if (this.eventsFilterOptions.event_types.value.length > 0) {
        Object.assign(payload, {
          event_type: JSON.stringify(this.eventsFilterOptions.event_types.value)
        })
      }
      if (this.eventsFilterOptions.dateRange.value) {
        Object.assign(payload, {
          time_local: JSON.stringify(this.eventsFilterOptions.dateRange.value)
        })
      }
      await this.getQueryBus().execute(
        new EventsQuery(payload)
      ).then(data => {
        this.events.eventsPageSize = this.events.eventsPageLimit
        this.events.events = data.data
        this.events.eventsCount = data.count
        this.loading = false
      })
    },
    clearEventFilters () {
      this.$refs.event_users_select.clearValue()
      this.$refs.event_types_select.clearValue()
      this.$set(this.eventsFilterOptions.users, 'value', [])
      this.$set(this.eventsFilterOptions.roles, 'value', [])
      this.$set(this.eventsFilterOptions.event_types, 'value', [])
      this.$set(this.eventsFilterOptions.dateRange, 'value', null)

      this.loadAllEvents()
    },
    parseEventType (type) {
      return this.eventsFilterOptions.event_types.data.find(item => item.id === type)?.name ?? ''
    }
  },
  mounted () {
    this.loadEventsFilterData()
    this.loadAllEvents()
  }
}
