import ApprovalStage from '@/services/BPMNEditor/domain/model/ApprovalStage'
import GatewayParser from '@/services/BPMNEditor/domain/service/GatewayParser'

export default class ApprovalStageBPMNAdapter {
  public static adapt (element) {
    const businessObject = element.businessObject
    let approvalStage = new ApprovalStage(businessObject.name)
    approvalStage.guid = businessObject.accentGuid
    approvalStage.element = element

    element.attachers.forEach((attached) => {
      if (attached.businessObject.accentType === 'cancelGateway') {
        approvalStage.cancelGateway = attached
        approvalStage.cancelCommandElements = GatewayParser.findAndStop(attached, 'bpmn:ServiceTask')
      } else if (attached.businessObject.accentType === 'approveGateway') {
        approvalStage.approveGateway = attached
        approvalStage.approveCommandElements = GatewayParser.findAndStop(attached, 'bpmn:ServiceTask')
      }
    })

    const blockedStagesElements = GatewayParser.findDeep(approvalStage.approveGateway, 'bpmn:UserTask')

    blockedStagesElements.forEach((item) => {
      if (item.businessObject.accentType === 'approval_stage') {
        approvalStage.blockedStages.push(item.businessObject.accentGuid)
      }
    })

    const returnStage = GatewayParser.findDeep(approvalStage.cancelGateway, 'bpmn:UserTask')
    if (returnStage.length > 0 && returnStage[0].businessObject?.accentGuid) {
      approvalStage.returnStageGuid = returnStage[0].businessObject.accentGuid
    }

    approvalStage.cancelStageElement = GatewayParser.findAndStop(approvalStage.cancelGateway, 'bpmn:UserTask').pop()

    return approvalStage
  }
}
