<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.source_type }}</span>
      <editor-select :value="value.type" @change="$set(value, 'type', $event)" :options="{multiple: false, options: data_source_types}"></editor-select>
    </el-form-item>
    <el-form-item  v-if="value.type === 'extend_object'">
      <editor-select :value="value.extendObject" @change="$set(value, 'extendObject', $event)" :options="{multiple: false, options: listExtendObject}"></editor-select>
    </el-form-item>
    <el-form-item v-if="['registry', 'xref'].includes(value.type)">
      <!-- <span class="property_label">{{ $locale.interface_editor.component_editor.table.registry }}</span> -->
      <!-- <editor-registry-select :value="value.registryId" @change="$set(value, 'registryId', $event)"></editor-registry-select> -->
      <registry-select
        :label="$locale.interface_editor.component_editor.editor_open_card.select_registry"
        :value="value.registryId"
        @change="$set(value ,'registryId', $event)"
      ></registry-select>
    </el-form-item>
    <el-form-item v-if="false">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.xref }}</span>
      <editor-xref-select :value="value.xrefId" @change="$set(value, 'xrefId', $event)" @changeRegistryId="$set(value, 'registryId', $event)"></editor-xref-select>
    </el-form-item>
    <el-form-item v-if="(['registry', 'xref'].includes(value.type) && value.registryId) || value.type === 'plugin'">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.column }}</span>
      <editor-registry-headers
        :value="value.columns"
        :otherProperties="{list: {}}" :options="{ objectId: value.registryId }"
        @change="$set(value, 'columns', $event); addOptimizeAttribute(true)"
        ></editor-registry-headers>
    </el-form-item>
    <!-- Оптимизация загрузки данных -->
    <el-form-item v-if="['registry', 'xref'].includes(value.type)">
      <el-checkbox v-model="value.showOptimizeOptions" @change="addOptimizeAttribute($event)"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.table.optimize_data_loading }}</span>
      <el-input v-if="value.showOptimizeOptions" v-model="value.optimizeOptions"></el-input>
    </el-form-item>
    <!-- / Оптимизация загрузки данных / -->
    <el-form-item v-if="['registry', 'xref'].includes(value.type) && value.registryId">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.search }}</span>
      <el-input v-model="value.searchAttributes"></el-input>
    </el-form-item>
    <el-form-item v-if="['registry', 'xref'].includes(value.type) && value.registryId">
      <el-checkbox v-model="value.showSearch"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.table.search_show }}</span>
    </el-form-item>
    <el-form-item v-if="['registry', 'xref'].includes(value.type) && value.registryId">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.condition }}</span>
      <editor-states
        v-model="value.stateId"
        :options="{ registryId: value.registryId }"
      ></editor-states>
      <editor-filters
        title="Параметры"
        v-model="value.stateParams"
        :show-xref-option="false"
      >
      </editor-filters>
    </el-form-item>
    <el-form-item v-if="value.type === 'plugin'">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.plugin }}</span>
      <editor-plugin :value="value.plugin" @change="$set(value, 'plugin', $event)"></editor-plugin>
    </el-form-item>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.cell_click_plugin }}</span>
      <el-input v-model="value.cellClickPlugin"></el-input>
    </el-form-item>
    <el-form-item v-if="value.type === 'plugin'">
      <el-checkbox v-model="value.is_selectable"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.table.choice }}</span>
    </el-form-item>
    <el-form-item v-if="value.type === 'plugin'">
      <el-checkbox v-model="value.fast_add"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.table.fast_add }}</span>
    </el-form-item>
    <el-form-item v-if="value.type === 'plugin'">
      <el-checkbox v-model="value.show_pagination"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.table.pagination }}</span>
    </el-form-item>
    <el-form-item v-if="value.type === 'plugin'">
      <el-checkbox v-model="value.show_summary"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.table.summary }}</span>
    </el-form-item>
    <el-form-item v-if="value.type === 'plugin'">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.filter }}</span>
    </el-form-item>
    <el-row v-if="value.type === 'plugin'" style="margin-bottom: 5px">
        <el-row>
          <el-button @click="addFilter">{{$locale.interface_editor.component_editor.table.add}}</el-button>
        </el-row>
        <el-row v-for="(condition, index) in value.filters" :key="index">
          <el-col :span="8">
            <el-select
              v-model="condition.attribute"
            >
              <el-option
                v-for="(item, index) in components.filter((cmp) => cmp.properties.name)"
                :key="index"
                :label="item.name"
                :value="item.properties.name">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="14">
            <el-input v-model="condition.alias"></el-input>
          </el-col>
          <el-col :span="2" style="float: right;">
            <el-button
              @click="deleteFilter(index)"
              size="mini"
              type="danger"><i class="el-icon-delete"></i></el-button>
          </el-col>
        </el-row>
      </el-row>
    <el-form-item v-if="value.type === 'plugin'">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.card }}</span>
      <editor-dashboards :value="value.editor" @change="$set(value, 'editor', $event)"></editor-dashboards>
    </el-form-item>
  </el-form>
</template>

<script>
import EditorPlugin from './editor-plugins'
import EditorSelect from './editor-select'
// import EditorRegistrySelect from './editor-registry-select'
import EditorXrefSelect from './editor-xref-select'
import EditorRegistryHeaders from './editor-registry-headers'
import EditorDashboards from './editor-dashboards'
import EditorStates from './editor-states.vue'
import EditorFilters from '@/components/InterfaceEditor/components/editor/editor-filters'

import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'

export default {
  name: 'editor-table',
  components: {
    EditorFilters,
    EditorDashboards,
    EditorSelect,
    // EditorRegistrySelect,
    EditorXrefSelect,
    EditorRegistryHeaders,
    EditorPlugin,
    EditorStates,
    RegistrySelect
  },
  inject: ['getComponents'],
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      components: this.getComponents(),
      data_source_types: [
        { id: 'registry', name: 'Реестр' },
        { id: 'plugin', name: 'Плагин' },
        // { id: 'extend_object', name: 'Расширенная таблица' },
        { id: 'xref', name: 'Множественная ссылка' }
      ],
      listExtendObject: [],
      states: []
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  async mounted () {
    if (this.value.stateId && typeof this.value.stateId === 'string') {
      this.value.stateId = Number(this.value.stateId)
    }

    if (typeof this.value.stateParams === 'undefined') {
      this.$set(this.value, 'stateParams', [])
    }
    let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
    this.listExtendObject = response.data
  },
  methods: {
    addOptimizeAttribute (event) {
      if (this.value.showOptimizeOptions === true) {
        if (event === true) {
          let optimizeAttrs = ''
          if (typeof this.value.columns !== 'undefined') {
            this.value.columns.forEach((item) => {
              switch (item.type) {
                case 'htmlField':
                  if (item.hasOwnProperty('htmlTemplate')) {
                    optimizeAttrs += item.htmlTemplate.match(/attr_[0-9]+_/gi) + ','
                  }
                  break
                case 'fieldGroup':
                  item.children.forEach((children) => {
                    optimizeAttrs += this.fieldGroupAttrsParse(children)
                  })
                  break
                default:
                  if (item.field.match(/attr_[0-9]+_/gi) !== null) {
                    optimizeAttrs += item.value + ','
                  }
              }
              if (item.clickType === 'open_card') {
                optimizeAttrs += `attr_${item.card.fieldId}_` + ','
              }
            })
            this.$set(this.value, 'optimizeOptions', [...new Set(optimizeAttrs.slice(0, -1).split(','))].toString())
          }
        }
      } else {
        this.$set(this.value, 'optimizeOptions', null)
      }
    },
    fieldGroupAttrsParse (item) {
      let optimizeAttrs = ''
      if (item.type === 'fieldGroup') {
        item.children.forEach((children) => {
          optimizeAttrs += this.fieldGroupAttrsParse(children)
        })
      } else {
        optimizeAttrs += item.value + ','
      }

      return optimizeAttrs
    },
    addFilter () {
      if (typeof this.value.filters === 'undefined') {
        this.$set(this.value, 'filters', [])
      }
      this.value.filters.push({
        attribute: null,
        alias: null
      })
    },
    deleteFilter (index) {
      this.value.filters.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
