import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { TaskDTO } from '@/services/Databus/domain/model/Route/Task'

export namespace TaskAPI {

    export class GetTasks implements APIRequest<TaskDTO> {
        response: TaskDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/etleditor/tasks?' +  (new URLSearchParams(payload).toString())
        }
    }
}