import QueryBus from '@/core/application/query/service/QueryBus'
import DataQuery from '@/services/RegistryTable/application/query/DataQuery'
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { RequestBuilder } from '@/core/infrastructure/components/Grid/infrastructure/service/RequestBuilder'
import PivotColumnsBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/PivotColumnsBuilder'
import { Nullable } from '@/core/domain/type/types'

export class DataSource implements IServerSideDatasource {
  private queryBus: QueryBus
  private readonly registryId: number
  private readonly showCount: boolean
  private readonly stateId: number
  private externalFilters: Nullable<object[]>
  private readonly optimizeOptions: string
  private success: Function
  private failure: Function
  private lastPayload: object
  private initClass: boolean
  private contex: any

  constructor (registryId: number,
    queryBus: QueryBus,
    externalFilters: Nullable<object[]> = null,
    showCount: boolean = false,
    stateId: number = null,
    optimizeOptions: string = null,
    initClass: boolean = false,
    contex: any
  ) {
    this.registryId = registryId
    this.queryBus = queryBus
    this.externalFilters = externalFilters
    this.showCount = showCount
    this.stateId = stateId
    this.optimizeOptions = optimizeOptions
    this.initClass = initClass
    this.contex = contex
  }

  setExternalFilters (externalFilters: Nullable<object[]>) {
    this.externalFilters = externalFilters
  }
  getExternalFilters (): Nullable<object[]> {
    return this.externalFilters || []
  }

  getRows (params: IServerSideGetRowsParams) {
    const pivotColumnIsXref = params.request.pivotCols.length > 0
      ? params.columnApi.getColumn(params.request.pivotCols[0].id).getUserProvidedColDef().type.includes('xrefField')
      : false
    let payload
    if (this.showCount) {
      payload = RequestBuilder.build(params.request, pivotColumnIsXref, this.externalFilters, this.registryId, this.stateId, this.optimizeOptions)
    } else {
      payload = RequestBuilder.build(params.request, pivotColumnIsXref, this.externalFilters, null, this.stateId, this.optimizeOptions)
    }
    this.lastPayload = payload
    // Есть фильтры в таблице, не делать первую загрузку при иницилизации компонента, а дождаться фильтров
    if (this.initClass) {
      return
    }
    this.contex.loading = true
    console.log('%c%s', 'color: pink;', 'registryTable ЗАГРУЗКА', this.registryId)

    this.queryBus.execute(new DataQuery(this.registryId, payload)).then((response) => {
      // eslint-disable-next-line camelcase
      const { data, count, pivot_fields } = response
      if (params.request.groupKeys.length === 0) {
        PivotColumnsBuilder.build(params.request, pivot_fields, params.columnApi)
      } else {
        params.columnApi.setPivotResultColumns([])
      }
      // если есть группировка столбцов - rowGroupCols
      if (count && !params.request.rowGroupCols.length) {
        params.success({ rowData: data, rowCount: count })
      } else {
        params.success({ rowData: data, rowCount: this.getLastRow(params.request, data.length) })
      }
      this.contex.loading = false
    }).catch((error) => {
      params.fail()
      this.contex.loading = false
      throw error
    })
  }
  getLastPayload (): object {
    return this.lastPayload
  }
  private getLastRow (request: IServerSideGetRowsRequest, resultLength: number) {
    if (resultLength === 0) {
      return request.startRow
    }

    const currentLastRow = request.startRow + resultLength + 1

    return currentLastRow <= request.endRow ? (currentLastRow - 1) : -1
  }
  isFirstRequest (): boolean {
    return this.initClass
  }
  setIsFirstRequest (value:boolean) {
    this.initClass = value
  }
}
