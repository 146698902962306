








import Vue from 'vue'
import FieldArray from '@/services/LogicEditor/infrastructure/components/elements/FieldArray.vue'

export default Vue.extend({
  name: 'ConvertToPdfEventForm',

  props: ['value', 'objectId'],

  components: {
    FieldArray
  },

  data () {
    return {
      model: this.value,
      rules: {
        file_fields: [
          { required: true, message: 'Выберите одно или несколько файловых полей!', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    }
  }
})
