import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import SmevSettingQueryRepositoryInterface from '@/services/AccessEditor/domain/repository/SmevSettingQueryRepositoryInterface'
import store from '@/store'
import { GetSmevSettings, GetSmevSettingByGuid } from '@/services/AccessEditor/infrastructure/store/modules/SmevSetting/action-types'

export default class SmevSettingQueryRepository extends AbstractQueryRepository implements SmevSettingQueryRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetSmevSettings(payload))
  }

  getByGuid (guid: string): Promise<any> {
    return store.dispatch(new GetSmevSettingByGuid(guid))
  }
}
