import Modeler from 'bpmn-js/lib/Modeler'
import ApprovalStageJSONAdapter from '@/services/BPMNEditor/domain/service/ApprovalStageJSONAdapter'
import ApprovalBPMNBuilder from '@/services/BPMNEditor/domain/service/ApprovalBPMNBuilder'
import ApprovalStageBPMNAdapter from '@/services/BPMNEditor/domain/service/ApprovalStageBPMNAdapter'
import GatewayParser from '@/services/BPMNEditor/domain/service/GatewayParser'

export default class BPMNService {
  private readonly modeler

  constructor (modeler: Modeler) {
    this.modeler = modeler
  }

  public getModeler (): Modeler {
    return this.modeler
  }

  public export () {
    this.modeler.saveXML({ format: true }).then((xml) => {
      const blob = new Blob([xml.xml], { type: 'application/xml' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'bpmn.bpmn'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  public import () {
    const input = document.createElement('input')
    input.type = 'file'

    input.onchange = (e) => {
      // @ts-ignore
      const files = e.target.files
      if (files[0] !== undefined) {
        const fr = new FileReader()
        fr.readAsText(files[0])
        fr.addEventListener('load', () => {
          this.modeler.importXML(fr.result).then(() => {
            this.modeler.get('canvas').zoom('fit-viewport')
          })
        })
      }
    }

    document.body.appendChild(input)
    input.click()
    document.body.removeChild(input)
  }

  public add ({
    // eslint-disable-next-line camelcase
    type, name, bpmn_properties, event, additionalProperties
  }) {
    const shape = this.createElement({
      type: type,
      // eslint-disable-next-line camelcase
      properties: bpmn_properties,
      name: name,
      additionalProperties: additionalProperties
    })
    this.modeler.get('create').start(event, shape)
  }

  public createElement ({ type, properties, name, position, additionalProperties = {} }: { type: string, properties?: object, name?: string, position?: {X, Y}, additionalProperties?: object }) {
    if (typeof this[`create${type}`] === 'function') {
      return this[`create${type}`]({ type, properties, name, position })
    }
    const elementFactory = this.modeler.get('elementFactory')
    const bpmnFactory = this.modeler.get('bpmnFactory')
    const taskBusinessObject = bpmnFactory.create(`bpmn:${type}`, { id: this.generateGuid(), name: name })

    const task = elementFactory.createShape(Object.assign({ type: `bpmn:${type}`, businessObject: taskBusinessObject }, additionalProperties))
    if (properties && Object.keys(properties).length > 0) {
      this.modeler.get('modeling').updateProperties(task, properties)
    }
    return task
  }

  private createApprovalStage ({ type, properties, name, position }: { type: string, properties?: object, name?: string, position?: {X, Y} }) {
    const elementFactory = this.modeler.get('elementFactory')
    const bpmnFactory = this.modeler.get('bpmnFactory')
    const taskBusinessObject = bpmnFactory.create(`bpmn:UserTask`, { id: this.generateGuid(), name: name || 'Task' })

    const task = elementFactory.createShape({ type: `bpmn:UserTask`, businessObject: taskBusinessObject })
    if (properties && Object.keys(properties).length > 0) {
      this.modeler.get('modeling').updateProperties(task, properties)
    }

    if (position) {
      task.x = position.X
      task.y = position.Y
    } else {
      task.x = 0
      task.y = 0
    }

    const approveBoundaryEvent = elementFactory.createShape({
      id: this.generateGuid(),
      type: 'bpmn:BoundaryEvent',
      eventDefinitionType: 'bpmn:SignalEventDefinition'
    })
    approveBoundaryEvent.businessObject.name = 'Да'
    approveBoundaryEvent.businessObject.attachedToRef = taskBusinessObject
    approveBoundaryEvent.host = task
    approveBoundaryEvent.y = task.y + 65
    approveBoundaryEvent.x = task.x + 82
    this.modeler.get('modeling').updateProperties(approveBoundaryEvent, { accentType: 'approveGateway' })

    const cancelBoundaryEvent = elementFactory.createShape({
      id: this.generateGuid(),
      type: 'bpmn:BoundaryEvent',
      eventDefinitionType: 'bpmn:SignalEventDefinition'
    })
    cancelBoundaryEvent.businessObject.name = 'Нет'
    cancelBoundaryEvent.businessObject.attachedToRef = taskBusinessObject
    cancelBoundaryEvent.host = task
    cancelBoundaryEvent.y = task.y + 65
    cancelBoundaryEvent.x = task.x - 15
    this.modeler.get('modeling').updateProperties(cancelBoundaryEvent, { accentType: 'cancelGateway' })

    return [task, approveBoundaryEvent, cancelBoundaryEvent]
  }

  public importApproval (element, data, commandMap) {
    const approvalStages = data.approval_stages
    const modeling = this.modeler.get('modeling')
    const process = this.modeler.get('elementRegistry').get('Process_1')

    let Y = element.y
    let X = element.x

    const approvalStageElements = approvalStages
      .map((item) => {
        const approvalStage = ApprovalStageJSONAdapter.adapt(item, commandMap, this)
        if (item.return_stage_id) {
          const returnStage = approvalStages.find((_) => _.id === item.return_stage_id)
          if (returnStage) {
            approvalStage.returnStageGuid = returnStage.guid
          }
        }
        return approvalStage
      })

    const approvalBuilder = new ApprovalBPMNBuilder(approvalStageElements)

    const { elements, connections, lastPosition } = approvalBuilder.build(this, { X, Y })
    if (elements) {
      modeling.createElements(elements, { x: X + 1000, y: Y }, process)
      modeling.connect(element, elements[0])
    }
    if (connections) {
      connections.forEach((connection) => {
        modeling.connect(connection.from, connection.to)
      })
    }

    const shape = this.createElement({
      type: 'EndEvent',
      name: `Конец процесса "${data.name}"`
    })
    modeling.appendShape([element, ...elements].pop(), shape, { x: lastPosition.X + 100, y: Y }, process)

    this.modeler.get('modeling').updateProperties(element, {
      name: `Начало процесса "${data.name}"`
    })
  }

  public buildApproval (element) {
    const approval = element.businessObject

    let approvalStages = GatewayParser.findDeepWithAttachers(
      element,
      'bpmn:UserTask'
    )
    approvalStages = approvalStages.map((stage) => ApprovalStageBPMNAdapter.adapt(stage)).map((item) => {
      return {
        guid: item.guid,
        name: item.name,
        approve_commands: item.getApproveCommandsGuid(),
        cancel_commands: item.getCancelCommandsGuid(),
        blocked_stages: item.blockedStages,
        cancel_text: item.cancelGateway?.businessObject?.name,
        approve_text: item.approveGateway?.businessObject?.name,
        cancel_stage: item.cancelStageElement?.businessObject?.accentGuid,
        return_stage: item.returnStageGuid
      }
    })

    return {
      guid: approval.accentGuid,
      stages: approvalStages
    }
  }

  private generateGuid () {
    let d = new Date().getTime()
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now()
    }
    return 'c_' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  }
}
