<template>
  <div style="width: 100%; height: 100%; position: relative;">
    <grid
      ref="grid"
      :columns="resultColumns"
      :data-source-service="dataSourceService"
      :pagination="true"
      :page-size="pageSize"
      :cache-block-size="cacheBlockSize"
      :multi-selection="true"
      :check-box-selection="checkBoxSelection"
      :sideBar="sideBar"
      :closeToolPanel="closeToolPanel"
      :floatingFilter="floatingFilter"
      :row-double-clicked="rowDoubleClicked"
      :groupUseEntireRow="groupUseEntireRow"
      :is-pivot-mode="isPivotMode"
      :show-count="showCount"
      :disabled-column-header="disabledColumnHeader"
      :hide-header="hideHeader"
      :wrap-header="wrapHeader"
      :readonly="readonly"
      :theme="theme"
      :context-menu="contextMenu"
      :CSS="CSS"
      :CSSClasses="CSSClasses"
      :rowClassRulesProps="rowClassRules"
      @edit-record="$emit('edit-record', $event)"
      @grid-ready="$emit('grid-ready', $event)"
    ></grid>
    <div v-if="showCount" style="position: absolute;bottom: 15px;left: 10px;">
      <pagination
        :pagination-data="paginationData"
        :registry-id="registryId"
      />
    </div>
  </div>
</template>

<script>
import Grid from '@/core/infrastructure/components/Grid/index.vue'
import StructureQuery from '@/services/RegistryTable/application/query/StructureQuery'
import Pagination from '@/core/infrastructure/components/Grid/infrastructure/components/Pagination/index.vue'
import { ColumnsFactory } from '@/services/RegistryTable/domain/service/ColumnsFactory'
import { DataSource } from '@/services/RegistryTable/infrastructure/service/DataSource'
import { arraysEqualByObjects } from '@/helpers'

import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RecordAPI } from '@/services/RegistryTable/infrastructure/api/RecordAPI'

export default {
  name: 'table-body',
  components: {
    Grid,
    Pagination
  },
  inject: {
    getEventBus: {
      default: () => false
    },
    getQueryBus: {
      default: () => {}
    },
    getModel: {
      default: () => {}
    },
    isEditor: {
      default: () => false
    },
    getComponents: {
      default: () => () => []
    }
  },
  props: {
    contextMenu: {
      type: Array,
      default () {
        return [
          'copy',
          'copyWithHeaders',
          'paste',
          'separator',
          'chartRange',
          'export'
        ]
      }
    },
    registryId: {
      type: Number
    },
    customColumns: {
      type: Array
    },
    isCustomColumnsSort: {
      type: Boolean,
      default: false
    },
    isCustomColumnsWidth: {
      type: Boolean,
      default: false
    },
    externalFilters: {
      type: Array
    },
    filtersForFilterCmp: {
      type: Array
    },
    isPivotMode: {
      type: Boolean
    },
    sideBar: {
      type: Boolean
    },
    closeToolPanel: {
      type: Boolean
    },
    floatingFilter: {
      type: Boolean
    },
    rowDoubleClicked: {
      type: Boolean
    },
    groupUseEntireRow: {
      type: Boolean
    },
    searchModel: {
      type: String
    },
    showCount: {
      type: Boolean
    },
    disabledColumnHeader: {
      type: Boolean
    },
    hideHeader: {
      type: Boolean
    },
    wrapHeader: {
      type: Boolean
    },
    state: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String
    },
    checkBoxSelection: {
      type: Boolean,
      description: 'Чек-бокс выделение',
      default: false
    },
    optimizeOptions: {
      type: String
    },
    pageSize: {
      type: Number
    },
    cacheBlockSize: {
      type: Number
    },
    readonly: {
      type: Boolean
    },
    theme: {
      type: String
    },
    CSS: {
      type: String
    },
    CSSClasses: {
      type: String
    },
    rowClassRules: {
      type: Array
    }
  },
  data () {
    return {
      columns: [],
      handledCustomColumns: undefined,
      dataSourceService: undefined,
      filterDebounce: undefined,
      promise: undefined,
      paginationData: {
        pageSize: '-',
        currentPage: 1,
        totalPage: ''
      },
      countFilters: 0,
      isFirstInitFilter: true,
      loading: false,
      firstInitWatch: false
    }
  },
  computed: {
    resultColumns () {
      // console.log('%c%s', 'color: green;', 'tableBody resultColumns')
      if (this.handledCustomColumns != null) {
        return this.handledCustomColumns
      }
      if (this.customColumns != null) {
        return this.customColumns
      }
      return this.columns
    }
  },
  watch: {
    loading (val) {
      if (val) {
        this.$refs.grid.gridApi?.showLoadingOverlay()
      } else {
        this.$refs.grid.gridApi?.hideOverlay()
      }
    },
    externalFilters (val) {
      if (this.isFirstInitFilter && this.countFilters === val.length) {
        this.dataSourceService.setIsFirstRequest(false)
        this.dataSourceService.setExternalFilters(val)
        this.$refs.grid.load()
        this.isFirstInitFilter = false

        return
      }

      if (!this.firstInitWatch || (!this.isFirstInitFilter && !arraysEqualByObjects(this.dataSourceService.getExternalFilters(), val))) {
        this.firstInitWatch = true
        this.dataSourceService.setExternalFilters(val)
        this.dataSourceService.setIsFirstRequest(false)
        this.$refs.grid.load()
      }
    }
  },
  beforeMount () {
    if (this.registryId) {
      // получить компоненты у которых указаны дефолтное значения
      const componentsWithDefaultValue = this.getComponents().filter(item => {
        return item.properties?.defaultValue || item.group === 'registry'
      })
      let initFilter = true

      if (this.filtersForFilterCmp) {
        // подсчитать количество фильтров, которые имеют дефолтное значение (countFilters)
        let asyncFilters = componentsWithDefaultValue.filter(component =>
          this.filtersForFilterCmp.some(filterItem => filterItem.attribute === component.properties.name)
        ).length || 0

        let sumFilters = this.filtersForFilterCmp.reduce((acc, filterItem) => {
          if (filterItem.type === 'constant') {
            acc['constant'] += 1
          }
          if (filterItem.type === 'current_user') {
            acc['user'] += 1
          }
          if (filterItem.type === 'registry_field') {
            acc['field'] += 1
          }
          return acc
        }, { constant: 0, user: 0, field: 0, asyncFilters })

        this.countFilters = asyncFilters + sumFilters.constant + sumFilters.user + sumFilters.field

        this.isFirstInitFilter = false
        initFilter = false
      }

      // Если фильтров нет, то при иницилизации компонента грузим данные
      if (!this.countFilters) {
        initFilter = false
        this.isFirstInitFilter = false
        this.firstInitWatch = true
      }

      this.dataSourceService = new DataSource(this.registryId, this.getQueryBus(), this.externalFilters, this.showCount, this.state.stateId, this.optimizeOptions, initFilter, this)
      this.loadStructure()
    }
  },
  mounted () {
    // console.warn('%c%s', 'color: green;', 'tableBody mounted')
    this.registerEvents()
  },
  methods: {
    getDataSourceService () {
      return this.dataSourceService
    },
    getColumns () {
      return this.$refs.grid.getResultColumns()
    },
    getColumnApi () {
      return this.$refs.grid.getColumnApi()
    },
    registerEvents () {
      // console.log('%c%s', 'color: green;', 'tableBody registerEvents')
      this.getEventBus().$on('load', () => {
        this.$refs.grid.gridApi.deselectAll()
        this.$refs.grid.load()
      })
      this.getEventBus().$on('add', () => {
        // console.log('%c%s', 'color: green;', 'getEventBus add')
        // ломается вид таблицы при загрузки
        this.$refs.grid.load()
      })
      this.getEventBus().$on('edit', (dataCard) => {
        let idsToUpdate = this.$refs.grid.gridApi.getSelectedNodes().map(function (node) {
          return node.data.id
        })
        this.$refs.grid.gridApi.forEachNode(function (rowNode) {
          if (idsToUpdate.indexOf(rowNode.data.id) >= 0) {
            let updated = JSON.parse(JSON.stringify(rowNode.data))
            let newData = { ...updated, ...dataCard }
            rowNode.setData(newData)
          }
        })
        // this.$refs.grid.load()
      })
      this.getEventBus().$on('delete', (selectedRows) => {
        this.deleteRecord(selectedRows)
      })
      this.getEventBus().$on('deselectAll', (selectedRows) => {
        this.$refs.grid.gridApi.deselectAll()
      })
    },
    deleteRecord (selectedRows) {
      let me = this
      this.$confirm(this.$locale.registry.message_delete.message, this.$locale.registry.message_delete.title, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        await Promise.all(selectedRows.map(async (selectedRecord) => {
          try {
            await APIClient.shared.request(new RecordAPI.DeleteRecord(me.registryId, selectedRecord.id))
          } catch (error) {
            console.log(error)
            if (error.raw.response.data.error === 'not_allowed') {
              me.$notify.error({
                title: me.$locale.main.message.error,
                message: me.$locale.main.message.not_allowed
              })
            }
          }
        }))
        this.$refs.grid.load()
        this.$refs.grid.delete()
      })
    },
    async loadStructure () {
      // console.log('%c%s', 'color: green;', 'tableBody loadStructure registryId', this.registryId)
      // Загрузить столбцы реестра по registryId в реестровом порядке
      const { columns } = await this.getQueryBus().execute(new StructureQuery(this.registryId))
      this.columns = ColumnsFactory.build(columns)

      if (this.customColumns != null) {
        if (this.isCustomColumnsSort) {
          // Столбцы существующие в загруженных столбцах реестра (columns) уложить в порядке this.customColumns
          const customColumns = []

          // Отсортировать столбцы реестра в порядке кастомных
          this.customColumns.forEach((customColumn, customColumnIndex) => {
            // Найти реестровый столбец соответствующий кастомному столбцу
            const column = this.findColumnByCustomColumn(customColumn)

            // Ширина реестрового столбца от кастомного столбца
            if (this.isCustomColumnsWidth) {
              if (customColumn.width) {
                column.width = customColumn.width
              } else {
                column.isFlex = true
              }
            }
            column.isExtended = true

            // Уложить реестровый столбец в порядке кастомного
            customColumns[customColumnIndex] = column
          })

          // Столбцы customColumns, которых нет в columns (Загруженных от реестра) не будут в новом порядке столбцов
          this.handledCustomColumns = customColumns
        }
      }
    },
    // Найти реестровый столбец соответствующий кастомному столбцу
    findColumnByCustomColumn (customColumn) {
      let column = null
      this.columns.some(function traverse (currentColumn) {
        if (currentColumn.field === customColumn.value) {
          column = currentColumn
          return true
        }

        if (Array.isArray(currentColumn.children)) {
          // Обойти дочерние столбцы (currentColumn - группа столбцов)
          return currentColumn.children.some(childrenColumn => traverse(childrenColumn))
        }
      })
      return column
    }
  },
  beforeDestroy () {
    // console.log('%c%s', 'color: green;', 'tableBody beforeDestroy')
    this.getEventBus().$off('load')
    this.getEventBus().$off('edit')
    this.getEventBus().$off('add')
    this.getEventBus().$off('delete')
    this.getEventBus().$off('deselectAll')
  }
}
</script>

<style scoped>

</style>
