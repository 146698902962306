<template>
  <div>
    <div class="setting-label">Источник / <string-select
        v-model="additionalData.sourceType"
        :options="sourceTypes"
      ></string-select>
    </div>

    <registry-select-tree
      v-if="additionalData.sourceType === 'Registry'"
      v-model="additionalData.sourceId"
      type="registry"
      value-as="number"
    ></registry-select-tree>

    <el-form-item v-if="additionalData.sourceType === 'extended_object'">
      <span class="property_label">{{ $t('interface_editor.component_editor.table.extended_object') }}</span>
      <el-select
        :value="additionalData.sourceId"
        filterable
        @change="$set(additionalData ,'sourceId', $event)"
      >
        <el-option
          v-for="(item, index) in extended_objects"
          :key="index"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <br>

    <div class="setting-label">Столбцы <el-input-number
        v-model="settings.columns"
        :min="0"
        controls-position="right"
        size="mini"
        style="float: right"
      ></el-input-number>
    </div>

    <div class="setting-label" style="display: flex; align-items: center">
      <span style="display: inline-block;flex: 1">Число записей на странице</span>
      <el-input-number
        v-model="additionalData.limit"
        :min="0"
        controls-position="right"
        size="mini"
        style="float: right"
      ></el-input-number>
    </div>
    <el-button :disabled="!additionalData.limit" @click="addPagination" type="text">Добавить пагинацию</el-button>

    <div class="setting-label">Отступы</div>
    <div class="sizes">
      <div v-for="(item, index) in margins" :key="index">
        <span class="name">{{ item.name }}</span>
        <span class="data">
          <span class="value" v-if="editSizeType !== item.id" @click="editMargin(item.id)">
            {{ settings[item.id].value || 0 }}
          </span>
          <el-input size="mini"
                    v-model="settings[item.id].value"
                    @blur="editSizeType = undefined"
                    @change="editSizeType = undefined"
                    v-else
                    class="editor"
                    :ref="`editor_${item.id}`"
          ></el-input>
          <span class="type" @click="changeMarginType(item.id)">{{settings[item.id].type || 'px'}}</span>
        </span>
      </div>
    </div>

    <div class="setting-label">Фильтры</div>
    <EditorFilters
      v-model="additionalData.filters"
      :options="{ showEqualsTypes: true, showXrefOption: true, isShowLabel: false, tooltip: 'Фильтры работают по логике <b>И</b><br><br>Как сделать логику <b>ИЛИ</b> (поиск по нескольким полям в целевом реестре)?<br><b>Чекбокс</b> - сделать один с нескольким вариантами<br><b>Строка</b> - создать формульное поле с объединением значений нужных полей и выбрать тип сравнения полей <b>Поиск</b> для поиска LIKE' }"
      :show-equals-types="true"
      :show-xref-option="true"
    ></EditorFilters>

    <div class="setting-label">Состояние</div>
    <EditorStates
      v-model="additionalData.stateId"
      :options="{ registryId: additionalData.sourceId }"
    ></EditorStates>
  </div>
</template>

<script>
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import StringSelect from '@/services/InterfaceEditor/infrastructure/components/SettingsPanel/StringSelect'
import EditorFilters from '@/components/InterfaceEditor/components/editor/editor-filters.vue'
import EditorStates from '@/components/InterfaceEditor/components/editor/editor-states.vue'
import EditorAnalyticTableSource from '@/components/InterfaceEditor/components/editor/editor-analytic-table-source.vue'

const SOURCE_TYPES = [
  { id: 'None', name: 'Отсутствует' },
  { id: 'Registry', name: 'Реестр' },
  { id: 'extended_object', name: 'Расширенная таблица' },
  { id: 'Query', name: 'Запрос' }
]
export default {
  name: 'ReplicationSettings',
  components: { EditorAnalyticTableSource, StringSelect, RegistrySelectTree, EditorFilters, EditorStates },
  inject: {
    getEventBus: {
      default: () => {}
    }
  },
  props: {
    settings: {
      type: Object,
      default: () => {
        return {
          columns: 1,
          horizontalMargin: {
            type: 'px',
            value: 0
          },
          verticalMargin: {
            type: 'px',
            value: 0
          }
        }
      }
    }
  },
  data () {
    return {
      margins: [
        { id: 'horizontalMargin', name: 'Left' },
        { id: 'verticalMargin', name: 'Top' }
      ],
      editSizeType: undefined,
      sourceTypes: SOURCE_TYPES,
      additionalData: this.settings.additionalData || {
        sourceType: 'None',
        sourceId: null,
        filters: [],
        limit: 10,
        stateId: null
      },
      extended_objects: []
    }
  },
  watch: {
    settings: {
      deep: true,
      handler (value) {
        this.$emit('change', value)
      }
    },
    additionalData: {
      deep: true,
      handler (value) {
        this.$set(this.settings, 'additionalData', value)
      }
    },
    'additionalData.sourceType': {
      async handler () {
        this.additionalData.sourceId = null
      }
    }
  },
  created () {
    this.restoreInvalidDataStructure()
  },
  async mounted () {
    let data = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
    this.extended_objects = data.data
  },
  methods: {
    restoreInvalidDataStructure () {
      if (this.settings.additionalData) {
        if (!('limit' in this.settings.additionalData)) {
          this.settings.additionalData.limit = 10
        }
      }
    },
    addPagination () {
      this.getEventBus().$emit('addPaginationBlock', { limit: this.additionalData.limit, settings: this.additionalData })
    },
    editMargin (type) {
      this.editSizeType = type
      this.$nextTick(() => {
        if (this.$refs[`editor_${type}`]) {
          this.$refs[`editor_${type}`][0].focus()
        }
      })
    },
    changeMarginType (type) {
      if (this.settings[type].type) {
        this.settings[type].type = this.settings[type].type === 'px' ? '%' : 'px'
      } else {
        this.settings[type].type = '%'
      }
    }
  }
}
</script>

<style scoped>

</style>
<style src="../../../main.scss" scoped lang="scss"></style>
