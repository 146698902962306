





























import Vue from 'vue'
import RuleForm from './forms/Rule/RuleForm.vue'
import RuleGroupForm from './forms/RuleGroupForm.vue'

import RuleTreeElementByElementGuidQuery from '@/services/TaskEditor/application/query/RuleTreeElementByElementGuidQuery'
import Template from '@/components/EtlEditor/Properties/Template.vue'

export default Vue.extend({
  name: 'EditorElementLayout',

  components: {
    Template,
    RuleForm,
    RuleGroupForm
  },

  props: {
    value: Object,

    form: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: ''
    },

    taskRegistryId: {
      type: Number
    },

    elementType: {
      type: String,
      required: true
    }
  },

  inject: ['getEventBus', 'getQueryBus', 'getCommandBus', 'getTreeElementLayout', 'getEditorNode'],

  computed: {
    isNew () {
      return typeof this.value.guid === 'undefined' || this.value.guid === null
    },

    ruleCreated () {
      return this.$store.getters['Rule/getRuleLocation']
    },

    ruleGroupCreated () {
      return this.$store.getters['RuleGroup/getRuleGroupLocation']
    }
  },

  watch: {
    ruleCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new RuleTreeElementByElementGuidQuery(
            location.replace('/rules/', '')
          )
        ).then(response => {
          this.addElementToTree(response)
        })
      }
    },

    ruleGroupCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new RuleTreeElementByElementGuidQuery(
            location.replace('/rule_groups/', '')
          )
        ).then(response => {
          this.addElementToTree(response)
        })
      }
    }
  },

  methods: {
    cancelEditor () {
      this.getTreeElementLayout().cancelEditor()
    },

    async addElementToTree (response) {
      if (
        typeof response.code !== 'undefined' &&
        ['invalid_guid', 'entity_not_found'].indexOf(response.code) !== -1
      ) {
        return
      }

      let model = this.value
      model.guid = response.element_guid
      this.$emit('input', model)
      this.$emit('update:is-new', false)

      this.getTreeElementLayout().addNode(response, this.getEditorNode())
    },

    submit () {
      this.$refs.form.submit(async () => {
        let tree = this.getTreeElementLayout()
        if (this.isNew === false) {
          let node = await tree.getNodeByElementGuid(this.value.guid)
          if (node) {
            node.data.name = this.value.name
            tree.updateNode(node)
          }
        }
      })
    }
  }
})
