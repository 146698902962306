














































import Vue from 'vue'
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default Vue.extend({
  components: {
    TreeSelect
  },

  props: {
    dataTable: {
      type: Array,
      default: () => []
    },

    selectAlias: {
      type: Function,
      default: () => {}
    },

    multiple: {
      type: Boolean,
      default: false
    },

    appendToBody: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    standartTree: {
      type: Boolean,
      default: true
    },

    clearable: {
      type: Boolean,
      default: true
    },

    typeField: {
      type: String,
      default: 'entity_type_id'
    },

    value: {}
  },

  data () {
    return {
      matchKeys: ['name', 'id'],
      valueConsistsOf: 'LEAF_PRIORITY',
      clearValueText: 'Очистить',
      noChildrenText: 'Нет данных',
      noResultsText: 'Не найдено',
      limitText: count => `+ ${count}`,
      normalizer (node) {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
          isLeaf: node.leaf
        }
      }
    }
  },

  methods: {
    deepSearch (arr, multi = false) {
      for (let el of arr) {
        if (el.leaf) delete el.children
        if (!el.leaf && multi) el.isDisabled = true
        if (el.children instanceof Array && el.children.length) this.deepSearch(el.children)
      }
      return arr
    }
  }
})
