import { ActionTree } from 'vuex'
import { SmevSettingState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, SmevSettingLoadingEvent, SmevSettingLoadedEvent, SmevSettingCreatedEvent, SmevSettingUpdatedEvent, SmevSettingDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { SmevSettingAPI } from '@/services/AccessEditor/infrastructure/api/SmevSettingAPI'

export const actions: ActionTree<SmevSettingState, any> = {
  [ActionTypes.ADD_SMEV_SETTING]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SmevSettingLoadingEvent())
      let location = await APIClient.shared.request(new SmevSettingAPI.AddSmevSetting(data.payload))
      commit(new SmevSettingCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_SMEV_SETTING]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SmevSettingLoadingEvent())
      let location = await APIClient.shared.request(new SmevSettingAPI.UpdateSmevSetting(data.payload))
      commit(new SmevSettingUpdatedEvent())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_SMEV_SETTING]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new SmevSettingAPI.DeleteSmevSetting(data.guid))
      commit(new SmevSettingDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_SMEV_SETTINGS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new SmevSettingLoadingEvent())
      let res = await APIClient.shared.request(new SmevSettingAPI.GetSmevSettings(data.payload))
      commit(new SmevSettingLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_SMEV_SETTING_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new SmevSettingAPI.GetSmevSettingByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
