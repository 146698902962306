import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'
import RuleTreeElement, { IAPIRuleTreeElement } from '@/services/TaskEditor/domain/model/RuleTreeElement'

export namespace RuleTreeElementAPI {
  export class GetRuleTreeElements implements APIRequest<Array<IAPIRuleTreeElement>> {
    response: Array<IAPIRuleTreeElement>;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse<Array<IAPIRuleTreeElement>>) => RuleTreeElement.loadMany(data.data)
    constructor (payload: string[][]) {
      this.path = `/taskeditor/rule_tree_elements?${new URLSearchParams(payload).toString()}`
    }
  }

  export class GetRuleTreeElementByGuid implements APIRequest<RuleTreeElement> {
    response: RuleTreeElement;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new RuleTreeElement(data.data)
    constructor (guid: string) {
      this.path = `/taskeditor/rule_tree_elements/${guid}`
    }
  }

  export class GetRuleTreeElementByElementGuid implements APIRequest<RuleTreeElement> {
    response: RuleTreeElement;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => new RuleTreeElement(data.data)
    constructor (elementGuid: string) {
      this.path = `/taskeditor/rule_tree_elements/${elementGuid}/element`
    }
  }
}
