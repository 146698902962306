
















import { CoordinateSystemDTO } from '@/services/MapEditor/domain/model/Geometry/CoordinateSystem'

export default {
  name: "CoordinateSystemInformationPanel",
  inject: ['getEventBus', 'getQueryBus'],
  props: {
    coordinateSystem: Object, 
    labelPosition: {
      type: String,
      default: 'left'
    }
  },
  watch: {
    coordinateSystem: {
      immediate: true, 
      handler () {
        this.dto = new CoordinateSystemDTO({
          srid: this.coordinateSystem.getSrid(),
          auth_name: this.coordinateSystem.getName(),
          proj4text: this.coordinateSystem.getProj4text()
        });
      }
    }
  },
  data() {
    return {
      dto: null
    }
  }
}
