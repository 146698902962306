<template>
  <div class="report_editor">
    <el-dialog
      :title="$t('report_editor.tooltip.add_report')"
      :visible.sync="isCreateDialogVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <span>
        <template v-if="createModel !== null">
          <el-form ref="createForm" :model="createModel" size="mini" :rules="formRules" labelPosition="top">
            <el-form-item :label="$t('report_editor.form.name')" prop="name">
              <el-input v-model="createModel.name" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item :label="$t('report_editor.form.type')" prop="type">
              <el-select style="width: 100%;" v-model="createModel.type">
                <el-option
                  v-for="item in reportTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
      </span>

      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-close" size="small" @click="isCreateDialogVisible = false">
          {{ $t('main.button.cancel') }}
        </el-button>

        <el-button icon="el-icon-success" size="small" type="primary" @click="addMenu">
          {{ $t('main.button.save') }}
        </el-button>
      </span>
    </el-dialog>

    <editor-layout>
      <template v-slot:header>
        {{ $t('report_editor.header.title') }}
      </template>

      <template v-slot:tree.side>
        <card :bordered="false" :rounded="false" type="grey">
          <template v-slot:header.toolbar>
            <el-tooltip
              slot="reference"
              class="item"
              effect="dark"
              :content="$t('report_editor.tooltip.add_report')"
              placement="bottom"
            >
              <el-button style="display: block;" size="small" icon="el-icon-plus" @click="onCreate()" :disabled="isSearch">
                {{ $t('main.button.add') }}
              </el-button>
            </el-tooltip>

            <el-input
              v-model="searchText"
              placeholder="Поиск"
              size="small"
              style="margin-right: 10px;"
              v-on:keyup.enter.native="search"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                size="small"
                :disabled="!isSearchText"
                :loading="isSearchLoading"
                @click="search"
              ></el-button>
            </el-input>

            <el-select v-model="filters" multiple collapse-tags size="small" style="width: 325px;" @change="filterChange">
              <el-option
                v-for="item in reportTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </template>

          <el-scrollbar class="tree-scroll">
            <el-tree
              v-loading="isSearchLoading"
              v-bind="treeProp"
              @node-click="openEditor"
              @node-drop="nodeDrop"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span class="node-label" :class="{ 'selected-node': editorModel !== null && editorModel.id === data.id }">
                  <span class="node-label__icon" :class="getTreeIcon(data, node.expanded)"></span>
                  <span class="node-label__name" :class="{ 'is-search': !!data.is_search }">{{ node.label }}</span>
                  <span class="node-label__info">(id: {{ data.id }}, {{ $t('report_editor.types.' + data.report_type) }})</span>
                </span>

                <span v-if="editorModel !== null && editorModel.id === data.id">
                  <el-tooltip
                    v-if="data.report_type === 'report_group'"
                    slot="reference"
                    class="item"
                    effect="dark"
                    :content="$t('report_editor.tooltip.add_report')"
                    placement="bottom"
                  >
                    <el-button
                      v-if="!isSearch"
                      class="btn_visible"
                      size="mini"
                      icon="el-icon-plus"
                      circle
                      @click="onCreate(node)"
                    ></el-button>
                  </el-tooltip>

                  <el-tooltip :content="$t('report_editor.tooltip.download')" placement="bottom">
                    <el-button
                      v-show="['interface_template'].includes(data.report_type)"
                      class="btn_visible"
                      size="mini"
                      icon="el-icon-download"
                      circle
                      @click="downloadTemplate(data)"
                    ></el-button>
                  </el-tooltip>

                  <el-tooltip :content="$t('report_editor.tooltip.upload')" placement="bottom">
                    <el-button
                      v-show="['report', 'document', 'xml', 'interface_template'].includes(data.report_type)"
                      class="btn_visible"
                      size="mini"
                      icon="el-icon-upload2"
                      circle
                      @click="openUpload(data.report_type)"
                    ></el-button>
                  </el-tooltip>

                  <el-tooltip :content="$t('report_editor.tooltip.edit')" placement="bottom">
                    <el-button
                      v-show="['report', 'document', 'xml', 'interface_template'].includes(data.report_type)"
                      class="btn_visible"
                      size="mini"
                      icon="el-icon-s-tools"
                      circle
                      @click="editReport(data)"
                    ></el-button>
                  </el-tooltip>

                  <el-tooltip :content="$t('main.button.delete')" placement="bottom">
                    <el-button
                      v-if="!isSearch"
                      class="btn_visible"
                      size="mini"
                      type="danger"
                      icon="el-icon-minus"
                      circle
                      @click="remove(node, data)"
                    ></el-button>
                  </el-tooltip>
                </span>
              </span>
            </el-tree>
          </el-scrollbar>
        </card>
      </template>

      <template v-slot:editor.side>
        <card v-if="editorModel !== null" :bordered="false" :rounded="false" type="grey">
          <template v-slot:header.title>
            <b>{{ $t('report_editor.types.' + editorModel.report_type) }}</b>: {{ titleForm }}
          </template>

          <el-scrollbar>
            <el-main>
              <component
                ref="form"
                v-if="editorModel !== null"
                v-model="editorModel"
                :key="generateGuid()"
                :is="typeComponent"
                :activeNode="activeNode">
              </component>
            </el-main>
          </el-scrollbar>

          <template v-slot:footer>
            <el-button
              @click="cancel"
              type="danger"
              size="small"
            >
              <span class="el-icon-close"></span> {{ $t('main.button.close') }}
            </el-button>

            <el-button
              type="primary"
              @click="save"
              size="small"
            >
              <span class="el-icon-success"></span> {{ $t('main.button.save') }}
            </el-button>
          </template>
        </card>
      </template>
    </editor-layout>

    <el-dialog
      :title="$t('report_editor.form.select_file')"
      :visible.sync="isUploadDialogVisible"
      :close-on-click-modal="false"
      width="20%"
    >
      <template v-if="editorModel !== null">
        <el-upload
          :headers="getHeaders()"
          class="upload-demo"
          ref="upload"
          :action="`${$config.api}/reporteditor/reports/${editorModel.id}/templates`"
          :on-change="numberFiles"
          :on-success="onSuccess"
          :file-list="fileList"
          :auto-upload="false"
          :accept="acceptFile"
        >
          <el-button
            size="small"
            type="primary"
            icon="el-icon-upload2"
          >
            {{ $t('report_editor.form.download') }}
          </el-button>
        </el-upload>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button
          icon="el-icon-close"
          size="small"
          @click="isUploadDialogVisible = false"
        >
          {{ $t('main.button.cancel') }}
        </el-button>

        <el-button
          icon="el-icon-success"
          size="small"
          type="primary"
          @click="saveFile"
        >
          {{ $t('main.button.save') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import GroupForm from './components/GroupForm'
import ReportForm from './components/ReportForm'
import Report from './Models/Reports'
// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { interfaceTemplateAPI } from '@//services/InterfaceEditor/infrastructure/api/interfaceTemplateAPI'
import Node from 'element-ui/packages/tree/src/model/node'

export default {
  components: {
    GroupForm,
    ReportForm
  },

  inject: ['addMainTab'],

  data () {
    return {
      searchText: null,
      isSearch: false,
      searchData: [],
      isSearchLoading: false,
      filters: ['report_group', 'report', 'document', 'xml', 'interface_template'],

      formRules: {
        name: [{ required: true, message: 'Введите название', trigger: 'blur' }],
        type: [{ required: true, message: 'Выберите тип', trigger: 'change' }]
      },
      fileList: [],
      reportTypes: [
        {
          value: 'report_group',
          label: this.$t('report_editor.form.report_group')
        },
        {
          value: 'report',
          label: this.$t('report_editor.form.report')
        },
        {
          value: 'document',
          label: this.$t('report_editor.form.document')
        },
        {
          value: 'xml',
          label: this.$t('report_editor.form.xml')
        },
        {
          value: 'interface_template',
          label: this.$t('report_editor.form.interface_template')
        }
      ],
      activeNode: null,
      typeComponent: null,
      isCreateDialogVisible: false,
      isUploadDialogVisible: false,
      acceptFile: '.docx',
      selectParent: null,
      selectNode: null,
      editorModel: null,
      createModel: null,
      titleForm: null
    }
  },

  watch: {
    isCreateDialogVisible: function (val) {
      if (val === false) {
        this.selectParent = null
        this.createModel = null
      }
    },

    searchText () {
      if (!this.isSearchText) {
        this.isSearch = false
        this.searchData = []
      }
    }
  },

  computed: {
    treeProp () {
      /*
        ref="tree"
        lazy
        draggable
        :props="props"
        :filter-node-method="filterNode"
        :load="loadNode"
        node-key="id"
        :expand-on-click-node="false"
        :allowDrop="allowDrop"
        :allowDrag="allowDrag"
      */
      return {
        ref: 'tree', // Vue ID компонента
        nodeKey: 'id', // Основной уникальный ключ узла
        key: this.isSearch ? 'searchTree' : 'defaultTree', // Меняем ключ, что бы перерендерить компонент с новыми свойствами
        lazy: !this.isSearch, // Для поиска отключаем ленивую загрузку
        data: this.isSearch ? this.searchData : [], // Для поиска передаём найденные данные в дерево
        draggable: !this.isSearch, // Блокируем drag & drop если сейчас активен поиск
        props: {
          isLeaf: 'is_leaf',
          label: 'name',
          children: 'children'
        },
        filterNodeMethod: this.filterNode,
        expandOnClickNode: false,
        load: this.loadNode,
        allowDrop: this.allowDrop,
        allowDrag: this.allowDrag,
        defaultExpandAll: this.isSearch
      }
    },

    isSearchText () {
      return !!this.searchText
    }
  },

  methods: {
    async search () {
      if (!this.searchText.length) {
        return
      }

      this.isSearch = true
      this.searchData = await this.searchElements()
    },

    async filterChange () {
      this.$refs.tree.filter(this.filters)
    },

    filterNode (value, data) {
      if (!value) return true
      return value.includes(data.report_type)
    },

    async searchElements () {
      const expressions = []
      const elementId = parseInt(this.searchText)

      if (!isNaN(elementId)) {
        // Либо ищем по ID сущности
        expressions.push({
          eq: {
            id: elementId
          }
        })
      }

      // Ищем по имени сущности
      expressions.push({
        like: {
          name: `%${this.searchText}%`
        }
      })

      try {
        this.isSearchLoading = true

        const response = await this.$http.post(`${this.$config.api}/reporteditor/reports/search`, {
          where: {
            or: expressions
          },
          order: 'report_type:asc,name:asc'
        }, {
          hideNotification: true
        })

        this.isSearchLoading = false

        return response.data || []
      } catch (e) {
        console.error(e)
      }

      this.isSearchLoading = false
    },

    // Разрешено перетаскивать только в группы
    allowDrop (draggingNode, dropNode, type) {
      if (type === 'inner') {
        // inner drop
        if (draggingNode.data.id === dropNode.data.id || dropNode.data.report_type !== 'report_group') {
          return false
        }

        const nodePath = this.$refs.tree
          .getNodePath(dropNode.data.id)
          .map((nodeData) => nodeData.id)

        return !nodePath.includes(draggingNode.data.id)
      }

      return true
    },

    allowDrag (draggingNode) {
      return true
    },

    // Супер мега функция... Запрещает родительскому узлу перемещаться в дочерний узел, на любой уровень вложенности
    getDropNodeParentKeys (node) {
      const nodeKeys = []
      if (node !== null && typeof node.data !== 'undefined') {
        nodeKeys.push(...[node.data.parent_id, ...this.getDropNodeParentKeys(node.parent)])
      }
      return nodeKeys
    },

    async nodeDrop (draggingNode, dropNode, dropType, ev) {
      let model = {}
      if (dropType !== 'inner') {
        model = {
          target_id: draggingNode.data.parent_id === dropNode.data.parent_id
            ? draggingNode.data.parent_id
            : dropNode.data.parent_id,
          row_order: this.mediumRowOrder(draggingNode, dropNode)
        }
      } else {
        model = {
          target_id: dropNode
            ? dropNode.data.id
            : draggingNode.data.parent_id,
          row_order: draggingNode.data.row_order
        }
      }

      await this.$http.post(`${this.$config.api}/reporteditor/reports/${draggingNode.data.id}/draggable`, model)
    },

    mediumRowOrder (draggingNode, node) {
      // children into parent
      const a = node.parent.childNodes
      let rowOrder
      for (let i = 0; i < a.length; i++) {
        if (draggingNode.data.id === a[i].data.id) {
          // console.log('совпадение', a[i].data.name, a[i].data.id)
          if (!a[i - 1]) {
            // console.log('сосед сверху не найден')
            rowOrder = Math.round((a[i + 1].data.row_order - 330))
          }
          if (!a[i + 1]) {
            // console.log('сосед снизу не найден')
            rowOrder = Math.round((100 + a[i - 1].data.row_order))
          }
          if (a[i + 1] && a[i - 1]) {
            rowOrder = Math.round((a[i + 1].data.row_order + a[i - 1].data.row_order) / 2)
          }
        }
      }

      return rowOrder
    },

    cancel () {
      this.editorModel = null
      this.typeComponent = null
    },

    save () {
      this.$refs.form.save()
    },

    getTreeIcon (data, expanded) {
      if (data.report_type === 'report_group' && !expanded) {
        return 'el-icon-folder'
      } else if (data.report_type === 'report_group' && expanded) {
        return 'el-icon-folder-opened'
      }

      let accessIcon = {
        report: 'el-icon-document-copy text_success',
        document: 'el-icon-document text_primary',
        xml: 'el-icon-tickets text_warning',
        interface_template: 'el-icon-postcard text_info'
      }

      return accessIcon[data.report_type]
    },

    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },

    // Tree
    async loadNode (node, resolve) {
      resolve(await this.loadEntities(node.level === 0 ? null : node.data.id))
    },

    async loadEntities (entityId = null) {
      const params =
        !entityId ? {
          spec: 'is_null',
          parent_id: entityId,
          order: 'report_type:asc,name:asc'
        } : {
          parent_id: entityId,
          order: 'report_type:asc,name:asc'
        }

      const data = await new Report().params(params).$get()

      data.forEach(d => {
        d.is_leaf = !d.has_children
      })

      return data
    },

    // Open edit entity
    async openEditor (data) {
      let node = this.$refs.tree.getNode(data)
      let report = await Report.find(data.id)
      if (report) {
        this.activeNode = node
        this.editorModel = report
        this.titleForm = this.editorModel.name
        data.report_type === 'report_group'
          ? (this.typeComponent = GroupForm)
          : (this.typeComponent = ReportForm)
      }
    },

    // Open create dialog
    async onCreate (parentNode = null) {
      this.selectParent = parentNode
      this.isCreateDialogVisible = true
      this.createModel = new Report({
        name: null,
        parent_id: parentNode
          ? parentNode.data.id
          : null,
        type: null
      })

      this.editorModel = null
      this.activeNode = null
      this.typeComponent = null
    },

    // добавить сущность
    addMenu () {
      this.$refs.createForm.validate(async (valid) => {
        if (valid) {
          const response = await this.createModel.save()

          response.is_leaf = response.report_type !== 'report_group'
          response.has_children = response.report_type === 'report_group'

          this.$refs.tree.append(response, this.selectParent)

          this.isCreateDialogVisible = false
        } else {
          return false
        }
      })
    },

    // удалить сущность
    remove (node, data) {
      if (!data.id) {
        return false
      }
      this.$confirm(this.$t('main.message.confirm'), this.$t('main.message.attention'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(async () => {
        await this.editorModel.delete()
        this.$refs.tree.remove(data.id)
        this.activeNode = null
        this.typeComponent = null
        this.editorModel = null
      }).catch(error => { console.log(error) })
    },

    downloadTemplate (report) {
      const extension = {
        document: 'docx',
        xml: 'xml',
        report: 'mrt',
        interface_template: 'zip'
      }
      const mimeType = {
        document: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        xml: 'application/xml',
        report: 'application/json',
        interface_template: 'application/zip'
      }
      this.$http
        .get(`${this.$config.api}/reporteditor/templates/${report.id}/export`, {
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType[report.report_type] }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${report.name}.${extension[report.report_type]}`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => console.log('error occured'))
    },

    openStimulsoft (report) {
      this.addMainTab({ name: report.name,
        componentType: 'StimulsoftReport',
        payload: {
          filename: `${report.guid}.mrt`,
          reportId: report.id
        } })
    },

    openDocumentEditor (report) {
      this.addMainTab({ name: report.name,
        componentType: 'DocumentEditor',
        payload: {
          guid: this.generateGuid(),
          filename: `${report.guid}.docx`,
          reportId: report.id
        } })
    },

    openXmlEditor (report) {
      let me = this
      this.addMainTab({ name: report.name,
        componentType: 'XmlEditor',
        payload: {
          guid: this.generateGuid(),
          reportId: report.id
        },
        beforeClose: (component) => new Promise((resolve, reject) => {
          if (component.isEditXml()) {
            me.$confirm(me.$t('main.message.close_xml_editor'), me.$t('main.message_title.warning'), {
              confirmButtonText: me.$t('main.button.yes'),
              cancelButtonText: me.$t('main.button.no'),
              type: 'warning'
            }).then(() => {
              resolve()
            }).catch(() => {
              reject(new Error('canceled'))
            })
          } else {
            resolve()
          }
        }) })
    },

    async openInterfaceEditor (template) {
      try {
        let { content } = await APIClient.shared.request(new interfaceTemplateAPI.GetTemplateContent(template.id))
        // убрать костыль с name: "Simple Interface Template"
        let structureFromTemplate = {}
        if (content?.blocks && content?.components) {
          structureFromTemplate = {
            blocks: [ ...content.blocks ],
            components: { ...content.components },
            template: {
              id: template.id,
              name: template.name
            }
          }
        } else {
          structureFromTemplate = {
            template: {
              id: template.id,
              name: template.name
            }
          }
        }

        this.addMainTab({ name: template.name,
          componentType: 'InterfaceEditor',
          payload: {
            guid: this.generateGuid(),
            structureFromTemplate,
            type: 'dashboard'
          }
        })
      } catch (error) {
        console.warn('openInterfaceEditor: шаблон пустой')
      }
    },
    editReport (data) {
      if (data.report_type === 'report') {
        this.openStimulsoft(data)
      } else if (data.report_type === 'document') {
        this.openDocumentEditor(data)
      } else if (data.report_type === 'xml') {
        this.openXmlEditor(data)
      } else if (data.report_type === 'interface_template') {
        this.openInterfaceEditor(data)
      }
    },
    openUpload (type) {
      let types = {
        report: '.mrt',
        document: '.docx',
        xml: '.xml',
        interface_template: '.zip'
      }
      this.acceptFile = types[type]
      this.fileList = []
      this.isUploadDialogVisible = true
    },
    saveFile () {
      this.$refs.upload.submit()
      this.isUploadDialogVisible = false
    },
    onSuccess (response, file, fileList) {
      this.$notify({
        title: this.$t('main.message.success'),
        message: this.$t('main.message.saved'),
        type: 'success'
      })
    },
    numberFiles (file, fileList) {
      console.log(file)

      if (this.acceptFile === '.docx' && !['application/octet-stream', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.raw.type)) {
        this.fileList = []
        this.$message.error(this.$t('report_editor.form.error_format_docx'))
        return false
      }

      if (this.acceptFile === '.xml' && !['application/xml', 'text/xml'].includes(file.raw.type)) {
        this.fileList = []
        this.$message.error(this.$t('report_editor.form.error_format_xml'))
        return false
      }

      if (this.acceptFile === '.mrt' && file.raw.type !== '') {
        this.fileList = []
        this.$message.error(this.$t('report_editor.form.error_format_mrt'))
        return false
      }

      if (this.acceptFile === '.zip' && !['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip'].includes(file.raw.type)) {
        this.fileList = []
        this.$message.error(this.$t('report_editor.form.error_format_zip'))
        return false
      }

      this.fileList = fileList.slice(-1)
    }
  }
}
</script>
<style src="./ReportEditor.css"></style>
