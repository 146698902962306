<template>
  <span>
  <el-table-column
    :prop="prop"
    v-if="!hidden"
    :label="label"
    :width="width"
    :class-name="className"
    :fixed="fixed"
    header-align="center"
  >
    <template slot="header" slot-scope="scope">
      <div :style="cssHeader">
        <span v-if="underLabel"><b>{{ scope.column.label }}</b><br>{{underLabel}}</span>
      <span v-else>{{ scope.column.label }} </span>
      <i style="cursor: pointer"
         class="el-icon-edit"
         v-if="fastEdit"
      @click="$emit('init-fast-edit', {column: scope.column, children: children})"></i>
      <i style="cursor: pointer" @click="expandHorizontal"
                                  :class="{'el-icon-plus' : !showHorizontalItems, 'el-icon-minus' : showHorizontalItems}"
                                  v-if="horizontalItems.length > 0"></i>
      </div>
    </template>
    <template slot-scope="scope">
      <component
        v-if="(!scope.row.isEdit || !editable) && !scope.row.isGrouped"
        :is="typeCol"
        :css="css"
        @click.native="onClick(scope, openCard, action, $event)"
        :fixedNumber="fixedNumber"
        :htmlTemplate="htmlTemplate"
        :scopeRow="scope.row"
        :open-card="openCard"
        :value="scope.row[prop]"
        :record-id="scope.row.id"
        :extended="extended"
        :action="action"
      ></component>
      <span
        v-else-if="!scope.row.isEdit && scope.row.isGrouped"
      >
        <b>{{ scope.row[prop] }}</b>
      </span>
      <component
        :key="scope.row.isEdit"
        v-if="scope.row.isEdit && children.length === 0 && editable"
        :is="'input' + UcFirst(typeCol)"
        :value="scope.row[prop]"
        @input="$emit('update-editable-model', {name: prop, value: $event})"
        :record-id="scope.row.id"
        :attr-name="prop"
      ></component>
    </template>
    <template
      v-if="children.length > 0"
    >
      <el-table-column
        class-name="collapsed"
        align="center"
        :width="1"
      />
      <table-header
        v-for="child in children.filter(item => !item.horizontalParent)"
        :key="child.value"
        :prop="child.value"
        :label="child.text"
        :fast-edit="child.fastEdit"
        @init-fast-edit="$emit('init-fast-edit', $event)"
        :under-label="child.underLabel"
        :children="child.children"
        :hidden="child.hidden"
        :width="child.width"
        :columns="children"
        :fixed="child.fixed"
        :css="child.css"
        :fixedNumber="child.fixedNumber"
        :htmlTemplate="child.htmlTemplate"
        :extended="child.extended"
        :editable="child.isEdit"
        :type-col="child.type"
        :input-type="child.inputType"
        :horizontal-items="children.filter(item => (item.horizontalParent || {}).value === child.value)"
        @update-layout="$emit('update-layout')"
        @update-editable-model="$emit('update-editable-model', $event)"
      ></table-header>
    </template>
  </el-table-column>
    <span v-show="showHorizontalItems" v-if="!hidden">
      <table-header
        v-for="child in horizontalItems.filter(item => !item.hidden)"
        :key="child.value"
        :prop="child.value"
        :fast-edit="child.fastEdit"
        @init-fast-edit="$emit('init-fast-edit', $event)"
        :label="showHorizontalItems ? child.text : ''"
        :children="[]"
        :under-label="showHorizontalItems ? child.underLabel: ''"
        :hidden="child.hidden"
        :class-name="showHorizontalItems ? '' : 'collapsed'"
        :columns="columns"
        :width="showHorizontalItems ? child.width : 1"
        :fixed="false"
        :extended="child.extended"
        :editable="child.isEdit"
        :type-col="child.type"
        :input-type="child.inputType"
        :horizontal-items="columns.filter(item => (item.horizontalParent || {}).value === child.value)"
        @update-layout="$emit('update-layout')"
        @update-editable-model="$emit('update-editable-model', $event)"
      ></table-header>
    </span>
  </span>
</template>

<script>
import stringField from './Columns/string_field'
import booleanField from './Columns/boolean_field.vue'
import dateField from './Columns/date_field'
import datetimeField from './Columns/datetime_field'
import floatField from './Columns/float_field'
import integerField from './Columns/integer_field'
import textField from './Columns/text_field'
import xrefField from './Columns/xref_field'
import xrefMultiField from './Columns/xref_multi_field'
import xrefOuterField from './Columns/xref_outer_field'
import fileField from './Columns/file_field'
import timeField from './Columns/time_field'
import indicatorField from './Columns/indicator_field'
import progressField from './Columns/progress_field'
import monthField from './Columns/month_field'
import htmlField from './Columns/html_field'

import inputStringField from './Inputs/input_string_field'
import inputTextField from './Inputs/input_text_field'
import inputIntegerField from './Inputs/input_integer_field'
import inputFloatField from './Inputs/input_float_field'
import inputBooleanField from './Inputs/input_boolean_field'
import inputTimeField from './Inputs/input_time_field'
import inputDateField from './Inputs/input_date_field'
import inputDatetimeField from './Inputs/input_datetime_field'
import inputXrefField from './Inputs/input_xref_field'
import inputXrefMultiField from './Inputs/input_xref_multi_field'
import inputMonthField from './Inputs/input_month_field'

import Dashboard from '@/components/Dashboard'
import RegistryCard from '@/components/RegistryCard'
import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'

export default {
  name: 'table-header',
  props: {
    className: {
      type: String
    },
    columns: {
      type: Array,
      default () {
        return []
      }
    },
    horizontalItems: {
      type: Array,
      default () {
        return []
      }
    },
    grouped: {
      type: Boolean,
      default: false
    },
    prop: [String, Number],
    label: {
      type: String
    },
    children: {
      type: [Object, Array],
      default () {
        return []
      }
    },
    underLabel: {
      type: String
    },
    fastEdit: {
      type: Boolean,
      default: false
    },
    typeCol: {
      type: String
    },
    inputType: {
      type: String
    },
    width: {
      type: Number
    },
    fixed: {
      type: [Boolean, String]
    },
    extended: {
      type: [Boolean, String]
    },
    editable: {
      type: [Boolean, String]
    },
    hidden: {
      type: [Boolean, String]
    },
    css: {
      type: String,
      default () {
        return ''
      }
    },
    fixedNumber: {
      type: String,
      default () {
        return '2'
      }
    },
    htmlTemplate: {
      type: String
    },
    cssHeader: {
      type: String,
      default () {
        return ''
      }
    },
    clickType: {
      type: [String, Object, Array],
      default () {
        return ''
      }
    },
    openCard: {
      type: Object,
      default () {
        return {}
      }
    },
    action: {
      type: [Object, Array]
    },
    oldDashboardAction: {
      type: [Object, Array]
    },
    oldActionCard: {
      type: [Object, Array]
    }
  },
  components: {
    stringField,
    booleanField,
    dateField,
    datetimeField,
    floatField,
    integerField,
    textField,
    xrefField,
    xrefMultiField,
    xrefOuterField,
    fileField,
    timeField,
    indicatorField,
    progressField,
    monthField,
    htmlField,
    inputStringField,
    inputTextField,
    inputIntegerField,
    inputFloatField,
    inputBooleanField,
    inputTimeField,
    inputDateField,
    inputDatetimeField,
    inputXrefField,
    inputXrefMultiField,
    inputMonthField,
    Dashboard,
    RegistryCard
  },
  inject: {
    openRegistryCard: {
      default: () => {}
    },
    getDashboardComponents: {
      default: () => {}
    },
    xrefOuterFieldScope: {
      default: () => {}
    },
    aTableScope: {
      default: () => {}
    },
    getRawData: {
      default: () => () => {}
    }
  },
  data () {
    return {
      showHorizontalItems: false
    }
  },
  methods: {
    /** @var context - this используемого в данный момент компонента (a-table или xref-outer-field)
     *  Они провайдятся напрямую из соответствующих компонентов
     */
    async onClick (scope, oldAction, action, event) {
      /** Старый тип действий */
      let isOldAction = typeof action === 'undefined' || !action.hasOwnProperty('type') || action instanceof Array
      let context = this.xrefOuterFieldScope ?? this.aTableScope
      if (this.oldActionCard?.hasOwnProperty('type') && (isOldAction)) {
        /** Старый тип действия - карточка */
        await this.oldActionAOpenCard(this.oldActionCard, context, scope)
      } else if (this.oldDashboardAction?.id && (isOldAction)) {
        /** Старый тип действия - дашборд */
        await this.oldActionOpenDashboard(this.oldDashboardAction, context, scope)
      } else if (typeof action !== 'undefined' && action.hasOwnProperty('type')) {
        /** Новый тип действий */
        let recordId = null
        if (action?.card?.type === 'update' || action?.card?.type === 'read') {
          try {
            recordId = JSON.parse(scope.row[action.card.fieldId])[0].id
          } catch (error) {
            recordId = scope.row[action.card.fieldId]
          }
          if (!recordId) {
            console.warn(`recordId doesn't set = ${action.card.fieldId}`)
            return false
          }
        }
        action.card.recordId = recordId
        //context.getModel()[action.card.fieldId] = recordId
        try {
          await ActionExecutor.execute(
            context,
            {
              readonly: context.readonly,
              pluginName: context.pluginName,
              action: action,
              event: event
            })
        } catch (error) {
          console.error('Ошибка действия кнопки', error)
        }
      }
    },
    async oldActionAOpenCard (action, context, scope) {
      if (this.clickType === 'open_card') {
        if (
          !action.registryId ||
          !action.type ||
          !action.fieldId ||
          !action.cardId
        ) {
          console.warn('wrong parameters', action)
          return false
        }
        const readOnly = action.type === 'read'
        let recordId = null
        if (action.type === 'update' || action.type === 'read') {
          try {
            recordId = JSON.parse(scope.row[`attr_${action.fieldId}_`])[0].id
          } catch (error) {
            recordId = scope.row[`attr_${action.fieldId}_`]
          }
          if (!recordId) {
            console.warn(`recordId doesn't set = ${action.fieldId}`)
            return false
          }
        }
        context.getModel()[`attr_${action.fieldId}_`] = recordId
        if (action.frameGuid) {
          let frame = (context.getDashboardComponents()[`component_${action.frameGuid}`] || [])[0]
          if (!frame) {
            console.warn('frame not found', action.frameGuid)
            return false
          }

          frame.openCard({
            cardId: action.cardId,
            registryId: action.registryId,
            readOnly: readOnly,
            recordId: recordId
          })
        } else {
          if (action.isWindow) {
            const h = context.$createElement
            let customClass = 'custom_scrollbar '
            if (action.windowWidth) {
              customClass += `dashboard_window_width_${action.windowWidth}`
            }
            context.$msgbox({
              title: action.windowTitle,
              customClass: customClass,
              message: h('registry-card', { props: {
                cardId: action.cardId,
                registryId: action.registryId,
                readonly: readOnly,
                recordId: recordId,
                initialData: {}
              },
              on: {
                cancelChanges: () => {
                  context.$msgbox.close()
                }
              },
              key: context.generateGuid() }),
              showCancelButton: false,
              showConfirmButton: false,
              closeOnClickModal: false
            })
          } else {
            context.openRegistryCard({
              registryId: action.registryId,
              cardId: action.cardId,
              cardName: '',
              recordId: recordId,
              initialData: {},
              registry: { readonly: readOnly, addRecord: () => {}, updateRecord: () => {} }
            })
          }
        }
      }
    },
    async oldActionOpenDashboard (action, context, scope) {
      if (action.isFullscreen) {
        context.openDashboardCard(action.id, action.window_title || '')
      } else {
        if (action.frameGuid) {
          let frame = (context.getDashboardComponents()[`component_${action.frameGuid}`] || [])[0]
          if (!frame) {
            console.warn('frame not found', action)
            return false
          }

          frame.openDashboard({
            dashboardId: action.id,
            title: action.window_title
          })
          return
        }
        const h = this.$createElement
        let customClass = 'custom_scrollbar '
        if (action.window_width) {
          customClass += `dashboard_window_width_${parseInt(action.window_width)}`
        }
        this.$msgbox({
          title: action.window_title,
          customClass: customClass,
          message: h('dashboard', {
            props: {
              id: action.id,
              parentContext: context,
              model: JSON.parse(JSON.stringify(context.getModel())),
              msgbox: 'msgbox'
            },
            key: context.generateGuid()
          }),
          showCancelButton: false,
          showConfirmButton: false,
          closeOnClickModal: false
        })
      }
    },
    UcFirst (s) {
      return s.replace(/(^[a-z])/ig, ($1) => {
        return $1.toUpperCase()
      })
    },
    expandHorizontal () {
      this.showHorizontalItems = !this.showHorizontalItems
      this.$emit('update-layout')
    }
  }
}
</script>
