<template>
  <el-dialog
    title="Добавить источник"
    :visible.sync="show"
    width="30%"
    :before-close="_close"
    :close-on-click-modal="false"
  >
    <span>
      <el-form :model="value" :rules="formRules" ref="form" size="mini">
        <!-- Тип источника -->
        <el-form-item
          :label="$t('report_editor.form.source_type')"
          prop="report_source_type_id"
        >
          <el-select
            v-model="localValue.report_source_type_id"
            :placeholder="$t('report_editor.form.source_type')"
            @change="_sourceTypeChange"
          >
            <el-option
              v-for="item in localReportSourceTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- Источник/Имя источника -->
        <el-form-item
          v-if="localValue.report_source_type_id !== null && localValue.report_source_type_id !== 'xml'"
          :label="$t('report_editor.form.source')"
          prop="properties.id"
        >
          <template v-if="localValue.report_source_type_id === 'extended_object'">
            <el-select
              v-model="localValue.properties.id"
              :placeholder="$t('report_editor.object_types.extended_object')"
              @change="_selectedExtendedObject"
            >
              <el-option
                v-for="item in extendedObjectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>

          <template v-if="localValue.report_source_type_id === 'query'">
            <el-select
              v-model="localValue.properties.id"
              :placeholder="$t('report_editor.object_types.query')"
              @change="_selectedQuery"
            >
              <el-option
                v-for="item in queryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>

          <template v-if="localValue.report_source_type_id === 'registry'">
            <registry-select-tree
              style="width: 100%;"
              type="registry"
              v-model="localValue.properties"
              :placeholder="$t('report_editor.object_types.registry')"
            />
          </template>
        </el-form-item>

        <el-form-item
          v-else-if="localValue.report_source_type_id !== null && localValue.report_source_type_id === 'xml'"
          :label="$t('report_editor.form.source')"
          prop="properties.name"
        >
          <el-input
            v-model="localValue.properties.name"
            :placeholder="$t('report_editor.form.name')"
          ></el-input>
        </el-form-item>

        <!-- Псевдоним источника -->
        <el-form-item
          v-if="['document', 'xml', 'report'].includes(reportType)"
          :label="$t('report_editor.form.alias')"
          prop="properties.alias"
        >
          <el-input
            :placeholder="$t('report_editor.form.alias')"
            v-model="localValue.properties.alias"
            @input="aliasChange"
          ></el-input>
        </el-form-item>

        <!-- XSD схема XML источника -->
        <template v-if="localValue.report_source_type_id === 'xml'">
          <el-form-item
            :label="$t('report_editor.form.xsd_schema')"
            prop="properties.xsd"
          >
            <xsd-upload
              v-model="zipXsdData"
              @on-remove="zipXsdData = null"
            ></xsd-upload>

            <xsd-parser
              v-if="zipXsdData"
              v-model="localValue.properties.xsd"
              :data="zipXsdData"
            ></xsd-parser>
          </el-form-item>
        </template>
      </el-form>
    </span>

    <span slot="footer" class="dialog-footer">
      <el-button type="default" @click="_close">
        {{ $t('main.button.cancel') }}
      </el-button>

      <el-button type="primary" @click="_save">
        {{ $t('main.button.save') }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import XsdUpload from '@/components/ReportEditor/components/typeComponents/XsdUpload'
import XsdParser from '@/components/ReportEditor/components/typeComponents/XsdParser'

export default {
  name: 'SourceAppendForm',

  components: {
    RegistrySelectTree,
    XsdUpload,
    XsdParser
  },

  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },

    reportType: {
      type: String,
      default () {
        return 'report'
      }
    },

    reportSourceTypes: {
      type: Array,
      default () {
        return []
      }
    },

    extendedObjectList: {
      type: Array,
      default () {
        return []
      }
    },

    queryList: {
      type: Array,
      default () {
        return []
      }
    },

    save: {
      type: Function,
      default () {
        return function () {
        }
      }
    },

    close: {
      type: Function,
      default () {
        return function () {
        }
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.localValue = value
      },
      deep: true
    },
    localValue: {
      handler: function (value, oldValue) {
        if (value.properties.id !== oldValue.properties.id) {
          value.properties.alias = typeof value.properties.name === 'string'
            ? this.aliasReplace(value.properties.name)
            : null

          value.properties.relation = []
        }

        this.$emit('input', value)
      },
      deep: true
    }
  },

  computed: {
    localReportSourceTypes () {
      if (['document', 'xml'].includes(this.reportType) && this.localValue.parent_id === null) {
        return this.reportSourceTypes
      }

      return this.reportSourceTypes.filter(el => el.id !== 'xml')
    },

    formRules () {
      const rules = {
        report_source_type_id: [
          {
            required: true,
            message: this.$t('report_editor.errors.report_source_type_id'),
            trigger: 'change'
          }
        ],
        'properties.alias': [
          {
            required: true,
            message: this.$t('report_editor.errors.properties_alias'),
            trigger: 'blur'
          }
        ]
      }

      if (this.localValue.report_source_type_id !== 'xml') {
        rules['properties.id'] = [
          {
            required: true,
            message: this.$t('report_editor.errors.properties_id'),
            trigger: 'change'
          }
        ]
      } else {
        rules['properties.name'] = [
          {
            required: true,
            message: 'Задайте имя источнику',
            trigger: 'input'
          }
        ]

        rules['properties.xsd'] = [
          {
            required: true,
            message: 'Необходимо импортировать XSD файл',
            trigger: 'input'
          }
        ]
      }

      return rules
    }
  },

  data () {
    return {
      show: false,

      zipXsdData: null,

      localValue: this.value
    }
  },

  methods: {
    open () {
      this.show = true
    },

    _save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.save()
          this.show = false
        }
      })
    },

    _close (done) {
      this.close()

      this.show = false
      this.zipXsdData = null
    },

    _sourceTypeChange (value) {
      this.localValue.properties = {
        id: null,
        name: null,
        alias: null,
        xsd: null,
        structure: null
      }

      if (this.localValue.parent_id) {
        this.localValue.properties.relation = []
      }

      this.$refs.form.clearValidate()
    },

    _selectedExtendedObject (value) {
      let extendedObject = this.extendedObjectList.find(item => value === item.id)

      this.localValue.properties.name = extendedObject.name
      this.localValue.properties.alias = this.aliasReplace(extendedObject.name)
    },

    _selectedQuery (value) {
      let query = this.queryList.find(item => value === item.id)

      this.localValue.properties.name = query.name
      this.localValue.properties.alias = this.aliasReplace(query.name)
    },

    aliasReplace (alias) {
      return alias
        .replace(/(\s)+/g, '_')
        .replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')
    },

    aliasChange (value) {
      this.localValue.properties.alias = value = this.aliasReplace(value)
    }
  }
}
</script>
