





































































































































import Treeselect from '@bingosoftnn/vue-treeselect'
import MapManager from '@bingo_soft/mapmanager'
import SourceType from '@bingo_soft/mapmanager/src/Domain/Model/Source/SourceType'
import CursorType from '@bingo_soft/mapmanager/src/Domain/Model/Map/CursorType'
import FeatureCollection from '@bingo_soft/mapmanager/src/Domain/Model/Feature/FeatureCollection'
import FeatureUpdateCommand from '@/services/MapEditor/application/command/FeatureUpdateCommand'
import ExportType from '@bingo_soft/mapmanager/src/Domain/Model/Map/ExportType'
import InteractionType from '@bingo_soft/mapmanager/src/Domain/Model/Interaction/InteractionType'

export default {
  name: 'MainToolbar',
  components: {
    Treeselect
  },
  props: ['mapInstance'],
  inject: ['getEventBus', 'getCommandBus'],
  watch: {
    onActiveLayer: {
      handler: function () {
        this.getEventBus().$emit('change-on-active-layer', this.onActiveLayer)
        // MapManager.clearSelectInteraction(this.mapInstance);
      },
      immediate: true
    }
  },
  data () {
    return {
      onActiveLayer: true,
      editEnabled: false,
      mode: {
        zoomIn: false,
        zoomOut: false,
        attrBySingleClick: false,
        attrByRectangle: false,
        selectBySingleClick: false,
        selectByRectangle: false,
        draw: false,
        snap: false
      },
      cutFeatures: null,
      copyFeatures: null,
      linkingFeatures: null
    }
  },
  computed: {
    selectionType: function () {
      return this.onActiveLayer ? this.$locale.map_editor.selection_types.active_layer : this.$locale.map_editor.selection_types.all_layers
    },
    editEnabledText: function () {
      return this.editEnabled ? this.$locale.map_editor.messages.edit_enabled : this.$locale.map_editor.messages.edit_disabled
    }
  },
  mounted () {
    this.getEventBus().$on('linkToRegistryFormReturn', this.linkToRegistryFormReturn)
  },
  methods: {
    linkToRegistryFormReturn (record_id) {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      let changes = {}
      changes[layer.getProperties()['layer_id']] = this.layerChanges(layer, record_id)
      this.getCommandBus().execute(
        new FeatureUpdateCommand(JSON.stringify(changes))
      )
      this.getEventBus().$emit('linkToRegistryFormClose')
    },
    clearModes(except) {
      Object.keys(this.mode).forEach((key) => {
        if (key != except) {
          this.mode[key] = false
        }
      })
    },
    zoomIn () {
      this.clearModes("zoomIn")
      if (this.mode.zoomIn) {
        this.mode.zoomIn = false
        MapManager.setCursor(this.mapInstance, 'default')
        MapManager.clearInteractions(this.mapInstance, [InteractionType.Zoom])
      } else {
        this.mode.zoomIn = true
        MapManager.setCursor(this.mapInstance, 'url(/icon/differentIcons/map-zoom-in.svg),auto')
        MapManager.setZoomInteraction(this.mapInstance, {
          'zoom_type': 'in'
        })
      }
    },
    zoomOut () {
      this.clearModes("zoomOut")
      if (this.mode.zoomOut) {
        this.mode.zoomOut = false
        MapManager.setCursor(this.mapInstance, 'default')
        MapManager.clearInteractions(this.mapInstance, [InteractionType.Zoom])
      } else {
        this.mode.zoomOut = true
        MapManager.setCursor(this.mapInstance, 'url(/icon/differentIcons/map-zoom-out.svg),auto')
        MapManager.setZoomInteraction(this.mapInstance, {
          'zoom_type': 'out'
        })
      }
    },
    fitLayer () {
      const activeLayer = MapManager.getActiveLayer(this.mapInstance)
      if (activeLayer) {
        MapManager.fitLayer(this.mapInstance, activeLayer)
      }
    },
    fitSelectedFeatures () {
      MapManager.fitFeatures(
        this.mapInstance,
        MapManager.getSelectedFeatures(this.mapInstance)
      )
    },
    attributeBy (what) {
      if (what == 'singleclick') {
        MapManager.setCursor(this.mapInstance, 'url(/icon/differentIcons/cursor-attribute-by-click.svg),auto')
        this.mode.attrBySingleClick = true
        this.mode.attrByRectangle = false
      } else if (what == 'rectangle') {
        MapManager.setCursor(this.mapInstance, 'url(/icon/differentIcons/cursor-attribute-by-area.svg),auto')
        this.mode.attrBySingleClick = false
        this.mode.attrByRectangle = true
      } else {
        return
      }
      MapManager.clearInteractions(this.mapInstance, [InteractionType.Select])
      MapManager.setSelectInteraction(this.mapInstance, {
        selection_type: what,
        select_callback: (features: FeatureCollection) => {
          this.getEventBus().$emit('showFeatureAttributes', features)
          MapManager.setCursor(this.mapInstance, 'default')
          this.mode.attrBySingleClick = false
          this.mode.attrByRectangle = false
        }
      })
    },
    getActiveLayers () {
      let layers = []
      if (this.onActiveLayer) {
        if (MapManager.getActiveLayer(this.mapInstance)) {
          layers = [MapManager.getActiveLayer(this.mapInstance)]
        }
      } else {
        layers = MapManager.getLayers(this.mapInstance, SourceType.Vector)
      }
      return layers
    },
    selectBy (what, multiple = false) {
      let activeLayers = this.getActiveLayers()
      if (activeLayers.length == 0) {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
        return
      }
      if (what == 'singleclick') {
        const cursor = multiple ? 'url(/icon/differentIcons/cursor-select-multiple-features.svg),auto' : 'url(/icon/differentIcons/cursor-select-by-click.svg),auto'
        MapManager.setCursor(this.mapInstance, cursor)
        this.mode.selectBySingleClick = true
        this.mode.selectByRectangle = false
      } else if (what == 'rectangle') {
        MapManager.setCursor(this.mapInstance, 'url(/icon/differentIcons/cursor-select-by-area.svg),auto')
        this.mode.selectBySingleClick = false
        this.mode.selectByRectangle = true
      } else {
        return
      }
      MapManager.clearInteractions(this.mapInstance, [InteractionType.Select])
      MapManager.setSelectInteraction(this.mapInstance, {
        selection_type: what,
        layers: activeLayers,
        multiple: multiple,
        select_callback: (feature, select) => {
          MapManager.setCursor(this.mapInstance, 'default')
          this.mode.selectBySingleClick = false
          this.mode.selectByRectangle = false
          this.$store.commit('Selection/setGlobalSelection', select)
        }
      })
    },
    /* selectByClick() {
      let activeLayers = this.getActiveLayers();
      if (activeLayers.length == 0) {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        });
      } else {
        MapManager.setCursor(this.mapInstance, "url(/icon/differentIcons/cursor-select-by-click.svg),auto");
        MapManager.setSelectInteraction(this.mapInstance, {
          selection_type: "singleclick",
          layers: activeLayers
        });
      }
    },
    selectByArea() {
      let activeLayers = this.getActiveLayers();
      if (activeLayers.length == 0) {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        });
      } else {
        MapManager.setCursor(this.mapInstance, "url(/icon/differentIcons/cursor-select-by-area.svg),auto");
        MapManager.setSelectInteraction(this.mapInstance, {
          selection_type: "rectangle",
          layers: activeLayers
        });
      }
    }, */
    /* selectMultipleFeatures() {
      let activeLayers = this.getActiveLayers();
      if (activeLayers.length == 0) {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        });
      } else {
        MapManager.setCursor(this.mapInstance, "url(/icon/differentIcons/cursor-select-multiple-features.svg),auto");
        MapManager.setSelectInteraction(this.mapInstance, {
          selection_type: "singleclick",
          multiple: true,
          layers: activeLayers,
          select_callback: (features) => { console.log(features);
            this.selectedFeatures = features;
            MapManager.setCursor(this.mapInstance, "default");
            this.mode.selectBySingleClick = false;
            this.mode.selectByRectangle = false;
          }
        });
      }
    },
    selectOnMultipleLayers() {
      MapManager.setCursor(this.mapInstance, "url(/icon/differentIcons/cursor-select-on-multiple-layers.svg),auto");
    }, */
    clearSelection () {
      MapManager.setCursor(this.mapInstance, 'default')
      MapManager.clearSelection(this.mapInstance)
    },
    measureLength () {
      MapManager.setMeasureInteraction(this.mapInstance,
        {
          measure_type: 'distance',
          measure_popup_settings: {
            'distance_units': this.$locale.map_editor.units.m,
            'rotation_caption': this.$locale.map_editor.units.rotation,
            'angle_caption': this.$locale.map_editor.units.angle
          }
        })
    },
    measureArea () {
      MapManager.setMeasureInteraction(this.mapInstance,
        {
          measure_type: 'area',
          measure_popup_settings: {
            'area_units': this.$locale.map_editor.units.sqm
          }
        })
    },
    clearMeasureResults () {
      MapManager.clearMeasureResult(this.mapInstance)
      MapManager.setCursor(this.mapInstance, 'default')
    },
    drawGeometry (geometryType) {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      if (layer) {
        MapManager.clearInteractions(this.mapInstance, [InteractionType.Draw])
        // TODO: отмечать визуально кнопку нажатой/отжатой
        if (this.mode.draw) {
          this.mode.draw = false
        } else {
          this.mode.draw = true
          MapManager.setDrawInteraction(this.mapInstance, layer, {
            'geometry_type': geometryType,
            'style': layer.getProperties()['style']
          })
        }
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    },
    drawPoint () {
      this.drawGeometry('Point')
    },
    drawLinestring () {
      this.drawGeometry('LineString')
    },
    drawPolygon () {
      this.drawGeometry('Polygon')
    },
    snapMode () {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      if (layer) {
        // не выключается, проверить
        MapManager.clearInteractions(this.mapInstance, [InteractionType.Snap])
        // TODO: отмечать визуально кнопку нажатой/отжатой
        if (this.mode.snap) {
          this.mode.snap = false
        } else {
          this.mode.snap = true
          MapManager.setSnapInteraction(this.mapInstance, {
            layers: [layer],
            pixelTolerance: 10
          })
        }
      }
    },
    modifyFeature () {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      if (layer) {
        // MapManager.setNormalInteraction(this.mapInstance);
        MapManager.clearInteractions(this.mapInstance, [InteractionType.Modify])
        MapManager.setModifyInteraction(this.mapInstance, {
          source: layer
        })
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    },
    transformGeometry () {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      if (layer) {
        // MapManager.setNormalInteraction(this.mapInstance);
        MapManager.clearInteractions(this.mapInstance, [InteractionType.Transform])
        MapManager.setTransformInteraction(this.mapInstance, {
          source: layer
        })
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    },
    modifyFeatureCoordinates () {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      const features = MapManager.getSelectedFeatures(this.mapInstance)
      if (layer) {
        if (features.getLength() == 0) {
          this.getEventBus().$emit('vertexEditPanel', 'add', this.mapInstance, layer, null, layer.getSRSId(), [])
        } else if (features.getLength() == 1) {
          this.getEventBus().$emit('vertexEditPanel', 'edit', this.mapInstance, layer, features.getFeatures()[0], layer.getSRSId(), [])
        } else {
          this.$message({
            message: this.$locale.map_editor.messages.select_single_feature,
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    },
    /* modifyFeatureCoordinates1() {
      const layer = MapManager.getActiveLayer(this.mapInstance);
      const features = MapManager.getSelectedFeatures(this.mapInstance);
      if (layer) {
        if (features.getLength() == 0) {
          this.getEventBus().$emit('vertexEditPanel', 'add', this.mapInstance, layer, null, layer.getSRSId(), []);
        } else if (features.getLength() == 1) {
          this.getEventBus().$emit('vertexEditPanel', 'view', this.mapInstance, layer, features.getFeatures()[0], layer.getSRSId(), []);
        } else {
          this.$message({
            message: this.$locale.map_editor.messages.select_single_feature,
            type: 'warning'
          });
        }
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        });
      }
    }, */
    linkToRegistry () {
      let activeLayers = this.getActiveLayers()
      if (activeLayers.length == 1 && this.onActiveLayer) {
        const activeLayerRegistryId = activeLayers[0].getProperties()['geometry_object_id']
        if (!activeLayerRegistryId) {
          this.$message({
            message: this.$locale.map_editor.messages.layer_is_not_registry,
            type: 'warning'
          })
          return
        }
        let activeLayerGeometryField
        try {
          activeLayerGeometryField = JSON.parse(activeLayers[0].getProperties()['geometry_field'])
        } catch (e) {
          this.$message({
            message: this.$locale.main.message.error + ' (' + e.message + ')',
            type: 'warning'
          })
          return
        }
        if (!activeLayerGeometryField.is_external) {
          this.$message({
            message: this.$locale.map_editor.messages.layer_has_no_external_geom,
            type: 'warning'
          })
          return
        }
        MapManager.setCursor(this.mapInstance, 'url(/icon/differentIcons/cursor-select-by-area.svg),auto')
        MapManager.setSelectInteraction(this.mapInstance, {
          selection_type: 'rectangle',
          layers: activeLayers,
          select_callback: (features) => {
            if (features.getLength()) {
              this.linkingFeatures = features
              this.getEventBus().$emit('linkToRegistryForm', activeLayerRegistryId)
              MapManager.setCursor(this.mapInstance, 'default')
              // MapManager.setNormalInteraction(this.mapInstance);
              MapManager.clearInteractions(this.mapInstance, [InteractionType.Select])
            }
          }
        })
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    },
    importLayers () {
      this.getEventBus().$emit('importForm')
    },
    exportLayers () {
      this.getEventBus().$emit('exportForm')
    },
    async exportGeoTiff () {
      const exportInfo = await MapManager.export(this.mapInstance, ExportType.GeoTIFF)
    },
    saveChanges (type) {
      if (type == 'active') {
        const layer = MapManager.getActiveLayer(this.mapInstance)
        const dirtyFeatures = layer.getDirtyFeatures()
        const removedFeatures = layer.getRemovedFeatures()
        if (dirtyFeatures.getLength() > 0 || removedFeatures.getLength() > 0) {
          let changes = {}
          changes[layer.getProperties()['layer_id']] = this.layerChanges(layer)
          this.getCommandBus().execute(
            new FeatureUpdateCommand(JSON.stringify(changes))
          ).then((response) => {
            if (response.length) {
              let i = 0
              dirtyFeatures.forEach(feature => {
                const props = feature.getProperties()
                if (!Object.prototype.hasOwnProperty.call(props, 'guid')) {
                  feature.setProperties({ 'guid': response[i] })
                  i += 1
                }
              })
            }
            MapManager.clearDirtyFeatures(layer)
          })
        } else {
          this.$message({
            message: this.$locale.map_editor.main_toolbar.no_changes,
            type: 'warning'
          })
        }
      } else {
        const layers = MapManager.getDirtyLayers(this.mapInstance)
        if (layers.length) {
          let changes = {}
          layers.forEach(layer => {
            changes[layer.getProperties()['layer_id']] = this.layerChanges(layer)
          })
          this.getCommandBus().execute(
            new FeatureUpdateCommand(JSON.stringify(changes))
          ).then((response) => {
            if (response.length) {
              let j = 0
              for (let i = 0; i < layers.length; i += 1) {
                const dirtyFeatures = layers[i].getDirtyFeatures()
                dirtyFeatures.forEach(feature => {
                  const props = feature.getProperties()
                  if (!Object.prototype.hasOwnProperty.call(props, 'guid')) {
                    feature.setProperties({ 'guid': response[j] })
                    j += 1
                  }
                })
              }
            }
            for (let i = 0; i < layers.length; i += 1) {
              MapManager.clearDirtyFeatures(layers[i])
            }
          })
        } else {
          this.$message({
            message: this.$locale.map_editor.main_toolbar.no_changes,
            type: 'warning'
          })
        }
      }
    },
    layerChanges (layer, recordId?) {
      const dirtyFeatures = layer.getDirtyFeatures()
      const removedFeatures = layer.getRemovedFeatures()
      let res = {}
      if (dirtyFeatures.getLength() > 0 || removedFeatures.getLength() > 0) {
        res = {}
        res['upsert'] = this.getFeaturesDump(layer, dirtyFeatures)
        res['delete'] = this.getFeaturesDump(layer, removedFeatures)
      } else {
        res['link'] = this.getFeaturesDump(layer, this.linkingFeatures, recordId)
      }
      return res
    },
    getFeaturesDump (layer, features, recordId?) {
      let res = { 'srs_id': layer.getSRSId(), 'features': [] }
      features.forEach(feature => {
        let props = feature.getFeature().getProperties()
        let featureData = {
          properties: Object.keys(props).filter((key) => {
            return key == 'id' || key == 'guid' || /^attr_\d+_$/.test(key)
          }).reduce((obj, key) => {
            obj[key] = props[key]
            return obj
          }, {}),
          geometry: recordId ? null : MapManager.getFeaturesAsSingleGeometry(new FeatureCollection([feature]), layer.getSRSId()),
          record_id: recordId
        }
        res['features'].push(featureData)
      })
      return res
    },
    cutFeature () {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      if (layer) {
        const features = MapManager.getSelectedFeatures(this.mapInstance)
        if (features.getLength()) {
          MapManager.setStyle(features, {
            'point': {
              'color': '#FF0000',
              'opacity': 70
            },
            'linestring': {
              'color': '#FF0000',
              'opacity': 70
            },
            'polygon': {
              'color': '#FF0000',
              'opacity': 70,
              'background_color': '#FF0000'
            }
          })
          MapManager.cutFeatures(this.mapInstance, features)
        }
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    },
    copyFeature () {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      if (layer) {
        const features = MapManager.getSelectedFeatures(this.mapInstance)
        if (features.getLength()) {
          MapManager.setStyle(features, {
            'point': {
              'color': '#9AA894',
              'opacity': 70
            },
            'linestring': {
              'color': '#9AA894',
              'opacity': 70
            },
            'polygon': {
              'color': '#9AA894',
              'opacity': 70,
              'background_color': '#CCCCCC'
            }
          })
          MapManager.cutFeatures(this.mapInstance, features)
        }
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    },
    pasteFeature () {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      if (layer) {
        MapManager.pasteFeatures(this.mapInstance, layer)
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    },
    deleteFeature () {
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        const features = MapManager.getSelectedFeatures(this.mapInstance)
        MapManager.removeFeatures(
          this.mapInstance,
          features
        )
      })
    },
    coordinateSystems () {
      this.getEventBus().$emit('selectCoordinateSystemsFromContextMenu')
      // this.getEventBus().$emit('selectCoordinateSystem');
    },
    combineGeometry () {

    },
    splitGeometry () {

    },
    createBuffer () {

    },
    lineToPolygon () {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      const features = MapManager.getSelectedFeatures(this.mapInstance)
      if (layer) {
        if (features.getLength() == 1) {
          const feature = features.getFeatures()[0]
          if (feature.getType() == 'LineString') {
            MapManager.lineToPolygon(feature)
          } else {
            this.$message({
              message: this.$locale.map_editor.messages.select_linestring,
              type: 'warning'
            })
          }
        } else {
          this.$message({
            message: this.$locale.map_editor.messages.select_single_feature,
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    },
    polygonToLine () {
      const layer = MapManager.getActiveLayer(this.mapInstance)
      const features = MapManager.getSelectedFeatures(this.mapInstance)
      if (layer) {
        if (features.getLength() == 1) {
          const feature = features.getFeatures()[0]
          if (feature.getType() == 'Polygon') {
            MapManager.polygonToLine(feature)
          } else {
            this.$message({
              message: this.$locale.map_editor.messages.select_polygon,
              type: 'warning'
            })
          }
        } else {
          this.$message({
            message: this.$locale.map_editor.messages.select_single_feature,
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: this.$locale.map_editor.messages.select_active_layers,
          type: 'warning'
        })
      }
    }

  }
}
