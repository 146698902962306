import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class ConstraintUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private description: Nullable<string>
  private constraintTypeId: string
  private isAllow: boolean
  private isAuto: boolean
  private isInitAllow: boolean
  private isNotify: boolean
  private stateMatchType: string
  private initStateMatchType: string
  private message: Nullable<string>
  private viewFields: Array<number>
  private hideFields: Array<number>
  private disableFields: Array<number>
  private constraintCards: Array<number>
  private constraintRoles: Array<number>
  private constraintStates: Array<number>
  private constraintInitStates: Array<number>
  private alias: Nullable<string>

  constructor (
    guid: string,
    name: string,
    description: Nullable<string>,
    constraintTypeId: string,
    isAuto: boolean,
    isAllow: boolean,
    isInitAllow: boolean,
    isNotify: boolean,
    stateMatchType: string,
    initStateMatchType: string,
    message: Nullable<string>,
    viewFields: Array<number>,
    hideFields: Array<number>,
    disableFields: Array<number>,
    constraintCards: Array<number>,
    constraintRoles: Array<number>,
    constraintStates: Array<number>,
    constraintInitStates: Array<number>,
    alias: Nullable<string>
  ) {
    this.guid = guid
    this.name = name
    this.description = description
    this.constraintTypeId = constraintTypeId
    this.isAuto = isAuto
    this.isAllow = isAllow
    this.isInitAllow = isInitAllow
    this.isNotify = isNotify
    this.stateMatchType = stateMatchType
    this.initStateMatchType = initStateMatchType
    this.message = message
    this.viewFields = viewFields
    this.hideFields = hideFields
    this.disableFields = disableFields
    this.constraintCards = constraintCards
    this.constraintRoles = constraintRoles
    this.constraintStates = constraintStates
    this.constraintInitStates = constraintInitStates
    this.alias = alias
  }

  getClassName (): string {
    return 'ConstraintUpdateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  getConstraintTypeId (): string {
    return this.constraintTypeId
  }

  getIsAuto (): boolean {
    return this.isAuto
  }

  getIsAllow (): boolean {
    return this.isAllow
  }

  getIsInitAllow (): boolean {
    return this.isInitAllow
  }

  getIsNotify (): boolean {
    return this.isNotify
  }

  getStateMatchType (): string {
    return this.stateMatchType
  }

  getInitStateMatchType (): string {
    return this.initStateMatchType
  }

  getMessage (): Nullable<string> {
    return this.message
  }

  getViewFields (): Array<number> {
    return this.viewFields
  }

  getHideFields (): Array<number> {
    return this.hideFields
  }

  getDisableFields (): Array<number> {
    return this.disableFields
  }

  getConstraintCards (): Array<number> {
    return this.constraintCards
  }

  getConstraintRoles (): Array<number> {
    return this.constraintRoles
  }

  getConstraintStates (): Array<number> {
    return this.constraintStates
  }

  getConstraintInitStates (): Array<number> {
    return this.constraintInitStates
  }

  getAlias (): Nullable<string> {
    return this.alias
  }
}
