






























































































































import Treeselect from '@bingosoftnn/vue-treeselect'
import Setting, { SettingDTO } from '@/services/Databus/domain/model/Setting/Setting'
import SettingsQuery from '@/services/Databus/application/query/SettingsQuery'
import SettingsCountQuery from '@/services/Databus/application/query/SettingsCountQuery'
import SettingUpdateCommand from '@/services/Databus/application/command/SettingUpdateCommand'


export default {
  name: 'SettingPanel',
  components: {
    Treeselect
  },
  data () {
    return {
      loading: false,
      settingsPageSize: 0,
      defaultSettingsPageSize: 100,
      settingsCurrentPage: 0,
      settingsPageLimit: 100,
      settingsCount: 0,
      allSettingsCount: 0,
      settings: [],
      setting: null,
      settingDto: null,
      settingDtoPrev: null,
      settingDtoParams: [],
      settingIsRouted: false,
      isDuplicate: false,
      informationPanelHeight: 0,
      isCreateSettingWindowVisible: false,
      settingTypes: [
        {
          id: "on",
          name: this.$locale.databus.setting_panel.on
        },
        {
          id: "off",
          name: this.$locale.databus.setting_panel.off
        },
        {
          id: "ok",
          name: this.$locale.databus.setting_panel.ok
        },
        {
          id: "error",
          name: this.$locale.databus.setting_panel.error
        }
      ],
      createSettingRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        value: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    isSettingLoading: (state) => state,
    getSaveSettingError: function (err) {
      if (err.code == 'duplicate_key_violation_exception') {
        this.isDuplicate = true;
        this.$message({
          message: this.$locale.databus.setting_panel.duplicate_setting_code,
          type: 'warning'
        });
      }
    }
  },
  computed: {
    isSettingLoading() {
      return this.$store.getters['Setting/isLoading'];
    },
    getSaveSettingError() {
      return this.$store.getters['Setting/getError'];
    }
  },
  methods: {
    loadSettings(callback) {
      if (this.allSettingsCount == 0) {
        this.countAndLoadSettings(callback);
      } else {
        this.loadAllSettings(callback);
      }
    },
    async countAndLoadSettings(callback) {
      await this.getQueryBus().execute(
        new SettingsCountQuery()
      ).then(data => {
        this.settingsCount = data[0].count;
        this.loadAllSettings(callback);
      });
    },
    async loadAllSettings(callback) {
      await this.getQueryBus().execute(
        new SettingsQuery({
          limit: this.settingsPageLimit,
          offset: this.settingsCurrentPage
        })
      ).then(data => {
        this.settingsPageSize = this.settingsPageLimit;
        this.settings = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    changeSetting(settingDto) {
      if (settingDto !== null) {
        this.setting = Setting.create(settingDto);
        this.settingDto = settingDto;
      } else {
        this.setting = null;
        this.settingDto = null;
      }
    },
    updateSetting() {
      if (this.setting !== null) {
        if (this.settingDtoPrev != null) {
          this.settingDto = this.settingDtoPrev;
          this.settingDtoPrev = null;
        }
        this.isCreateSettingWindowVisible = true;
      } else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    saveSetting() {
      let setting = Setting.create(this.settingDto);
      if (setting.getId() == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
      else {
        this.getCommandBus().execute(
            new SettingUpdateCommand(
              setting.getId(),
              setting.getName(),
              setting.getValue(),
              setting.getDescription()
            )
          ).then(() => {
            if (this.isDuplicate) {
              this.isDuplicate = false;
            }
            else {
              this.closeCreateSettingWindow();
            }
          });
      }
    },
    closeCreateSettingWindow() {
      this.isCreateSettingWindowVisible = false;
    },
    handleSettingsPageChange(val) {
      val--;
      this.settingsCurrentPage = (val * this.settingsPageLimit);
      this.loadSettings();
    },
    saveSettingDirectly(row) {
      this.settingDto = new SettingDTO({id: row.id, name: row.name, value: row.value, description: row.description});
      this.saveSetting();
    }
  },
  mounted() {
    this.loadSettings();
  }
}
