<template>
  <div class="tag-input">
    <el-tag
      v-for="(tag, index) in model"
      :key="index"
      closable
      :disable-transitions="false"
      @close="handleClose(tag)"
    >
      {{ tag }}
    </el-tag>

    <el-input
      v-model="inputValue"
      class="input-new-tag"
      ref="saveTagInput"
      size="small"
      :placeholder="placeholder"
      @keyup.enter.native="handleInputConfirm"
    >
      <template v-slot:append>
        <el-tooltip class="item" effect="dark" :content="tooltip" placement="top">
          <el-button
            size="small"
            icon="el-icon-plus"
            @click="handleInputConfirm"
          ></el-button>
        </el-tooltip>
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'TagInput',

  props: {
    value: [String, Array],
    tooltip: {
      type: String,
      default: 'Добавить'
    },
    placeholder: String
  },

  watch: {
    value () {
      if (this.isString) {
        if (this.value && this.value.length > 0) {
          this.model = this.value.split(', ')
        }
      } else if (this.isArray) {
        this.model = this.value
      } else {
        this.model = []
      }
    },

    model () {
      if (this.isString) {
        if (this.model.length > 0) {
          this.$emit('input', this.model.join(', '))
        } else {
          this.$emit('input', null)
        }
      } else if (this.isArray) {
        this.$emit('input', this.model)
      } else {
        this.$emit('input', [])
      }
    }
  },

  computed: {
    isString () {
      return typeof this.value === 'string'
    },

    isArray () {
      return Array.isArray(this.value)
    }
  },

  data () {
    return {
      model: [],
      inputValue: ''
    }
  },

  mounted () {
    if (this.isString) {
      this.model = this.value && this.value.length > 0 ? this.value.split(', ') : []
    } else if (this.isArray) {
      this.model = this.value
    }
  },

  methods: {
    handleClose (tag) {
      this.model.splice(this.model.indexOf(tag), 1)
    },

    handleInputConfirm () {
      if (this.inputValue.length > 0) {
        let inputValue = this.inputValue
        if (inputValue) {
          this.model.push(inputValue)
        }
        this.inputValue = ''
      }
    }
  }
}
</script>

<style lang="scss">
  .tag-input {
    .el-tag {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .input-new-tag {
      width: 150px;
      vertical-align: bottom;
      margin-bottom: 10px;
    }
  }
</style>
