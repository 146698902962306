<template>
  <el-form label-width="120px" label-position="top" size="mini" class="editor_tree">
    <el-button type="text" @click="dialogSettingsVisible = true">{{$locale.interface_editor.component_editor.editor_tree.button_visibility}}</el-button>
    <el-dialog
      :title="$locale.interface_editor.component_editor.editor_tree.button_visibility"
      :modal="false"
      :visible.sync="dialogSettingsVisible">
      <el-form-item>
        <editor-conditions
          v-model="value.addBtnHidden"
          :options="{
            label: $locale.interface_editor.component_editor.editor_tree.add_btn_hidden,
            sourceRegistry: true,
            fieldsRegistry: fieldsRegistry
            }"/>
        <editor-conditions
          v-model="value.deleteBtnHidden"
          :options="{
            label: $locale.interface_editor.component_editor.editor_tree.delete_btn_hidden,
            sourceRegistry: true,
            fieldsRegistry: fieldsRegistry
            }"/>
        <editor-conditions
          v-model="value.openBtnHidden"
          :options="{
            label: $locale.interface_editor.component_editor.editor_tree.open_btn_hidden,
            sourceRegistry: true,
            fieldsRegistry: fieldsRegistry
            }"/>
      </el-form-item>
    </el-dialog>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.source_type }}</span>
      <editor-select
        :value="value.type"
        @change="$set(value ,'type', $event)"
        :options="{
          multiple: false,
          options: [
            { id: 'registry', name: 'Реестр' },
            { id: 'extend_object', name: 'Расширенная таблица' },
            { id: 'requests', name: 'Запросы' }
          ]
        }"
      ></editor-select>
    </el-form-item>
    <el-form-item v-if="value.type === 'registry'">
      <!-- <span class="property_label">{{ $locale.interface_editor.component_editor.table.registry }}</span>
      <editor-registry-select :value="value.registryId" @change="$set(value ,'registryId', $event)"></editor-registry-select> -->
      <registry-select
        :label="$locale.interface_editor.component_editor.editor_open_card.select_registry"
        :value="value.registryId"
        @change="$set(value ,'registryId', $event)"
      ></registry-select>
    </el-form-item>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.registry_headers.html_template }}</span>
      <el-input
        class="htmlTemplate"
        type="textarea"
        :autosize="{ minRows: 3, maxRows: 6}"
        v-model="value.htmlTemplate">
      </el-input>
    </el-form-item>
    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.editor_tree.limit }}</span>
      <el-input-number
        controls-position="right"
        v-model="value.limit">
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import EditorSelect from './editor-select'
// import EditorRegistrySelect from './editor-registry-select'
import EditorConditions from './editor-conditions'

import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
export default {
  name: 'editor-tree',
  components: {
    EditorSelect,
    // EditorRegistrySelect,
    EditorConditions,
    RegistrySelect
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      fieldsRegistry: [],
      dialogSettingsVisible: false
    }
  },
  watch: {
    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'value.registryId': {
      async handler (val) {
        if (val) {
          this.fieldsRegistry = await this.getFieldsRegistry(val)
        }
      }
    }
  },
  async mounted () {
    if (this.value.type === 'registry' && this.value.registryId) {
      this.fieldsRegistry = await this.getFieldsRegistry(this.value.registryId)
    }
  },
  methods: {
    async getFieldsRegistry (registryId) {
      let data = await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${registryId}&show_children=true`)
      return data.data.data
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
