import { Nullable } from '@/core/domain/type/types'

export interface IApprovalStage {
  id?: Nullable<number>;
  guid?: Nullable<string>;
  name: string;
  description: string|null;
  approval_id?: number;
  close_commands: number[];
  approve_commands: number[];
  approve_button_id: number|null;
  approve_text: string|null;
  cancel_commands: number[];
  cancel_button_id: number|null;
  cancel_text: string|null;
  approval_cancel_stages: number[];
  approval_blocking_stages: number[];
  approval_stage_type_id: string|null;
  approval_stage_members: number[];
  member_field_id: number|null;
  member_state_id: number|null;
  return_stage_id: number|null;
  is_approvable_by_all: boolean;
  is_matched_inclusion_states: boolean;
  forced_approval_roles: number[];
  inclusion_states: number[];
  activity_additional_states: number[];
}

export class ApprovalStageDTO {
  id?: Nullable<number>;
  guid?: Nullable<string>;
  name: string;
  description: string|null;
  approval_id?: number;
  close_commands: number[];
  approve_commands: number[];
  approve_button_id: number|null;
  approve_text: string|null;
  cancel_commands: number[];
  cancel_button_id: number|null;
  cancel_text: string|null;
  approval_cancel_stages: number[];
  approval_blocking_stages: number[];
  approval_stage_type_id: string|null;
  approval_stage_members: number[];
  member_field_id: number|null;
  member_state_id: number|null;
  return_stage_id: number|null;
  is_approvable_by_all: boolean;
  is_matched_inclusion_states: boolean;
  forced_approval_roles: number[];
  inclusion_states: number[];
  activity_additional_states: number[];

  constructor (data: IApprovalStage) {
    this.id = data.id
    this.guid = data.guid
    this.name = data.name
    this.description = data.description
    this.approval_id = data.approval_id
    this.close_commands = data.close_commands
    this.approve_commands = data.approve_commands
    this.approve_button_id = data.approve_button_id
    this.approve_text = data.approve_text
    this.cancel_commands = data.cancel_commands
    this.cancel_button_id = data.cancel_button_id
    this.cancel_text = data.cancel_text
    this.approval_cancel_stages = data.approval_cancel_stages
    this.approval_blocking_stages = data.approval_blocking_stages
    this.approval_stage_type_id = data.approval_stage_type_id
    this.approval_stage_members = data.approval_stage_members
    this.member_field_id = data.member_field_id
    this.member_state_id = data.member_state_id
    this.return_stage_id = data.return_stage_id
    this.is_approvable_by_all = data.is_approvable_by_all
    this.is_matched_inclusion_states = data.is_matched_inclusion_states
    this.forced_approval_roles = data.forced_approval_roles
    this.inclusion_states = data.inclusion_states
    this.activity_additional_states = data.activity_additional_states
  }

  static create (name: Nullable<string> = null, approvalId: Nullable<number> = null) {
    return new ApprovalStageDTO({
      name: name,
      description: null,
      approval_id: approvalId,
      close_commands: [],
      approve_commands: [],
      approve_button_id: null,
      approve_text: null,
      cancel_commands: [],
      cancel_button_id: null,
      cancel_text: null,
      approval_cancel_stages: [],
      approval_blocking_stages: [],
      approval_stage_type_id: 'field',
      approval_stage_members: [],
      member_field_id: null,
      member_state_id: null,
      return_stage_id: null,
      is_approvable_by_all: false,
      is_matched_inclusion_states: true,
      forced_approval_roles: [],
      inclusion_states: [],
      activity_additional_states: []
    })
  }
}
