








































































































































































































































































































































import Vue from 'vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'
import Mapping from '@/components/EtlEditor/Models/Mapping'
import Node from 'element-ui/packages/tree/src/model/node'
import { ElMessageBoxOptions } from 'element-ui/types/message-box'

export default Vue.extend({
  name: 'TreeMapping',

  components: {
    TreeSelect,
    RegistrySelectTree
  },

  props: {
    taskId: Number,

    loaders: Array,

    transformers: Array,

    extractorType: {
      type: String,
      default: null
    }
  },

  computed: {
    rules () {
      return {
        source_column: [{ required: true, message: this.$t('etl_editor.form.rules.required'), trigger: 'blur' }]
      }
    }
  },

  data () {
    return {
      treeProps: {
        children: 'children',
        label: 'source_column',
        isLeaf: 'is_leaf'
      },

      fieldTreeProps: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return data.entity_type_id === 'field_group'
        }
      },

      importTreeProps: {
        children: 'children',
        label: 'name',
        disabled: function (data, node) {
          console.log('node', node, data)

          return node.level === 1
        }
      },

      editorModel: null,
      selectNode: null,

      selectTargetField: [],

      addRootDisabled: false,

      expanded: [],

      isFullScreen: false,

      importDialog: {
        show: false,
        selected: false,
        data: [],
        guid: null,
        fileList: [],
        checkedNode: null,
        fileType: '',
        loading: false
      },

      xrefObjectId: null
    }
  },

  methods: {
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },

    selectTargetFieldId (value) {
      this.xrefObjectId = null
      if (value !== null) {
        this.$http
          .get(`${this.$config.api}/registryservice/xref/${value}`)
          .then((response) => {
            if (response.data !== null) {
              this.xrefObjectId = response.data.xref_object_id
            }
          })
      }
    },

    async addEl (type = 'element', root = true) {
      let node = root ? null : this.selectNode
      let model = await new Mapping({
        task_id: this.taskId,
        element_type_id: type,
        source_column: `new-${type}`,
        parent_id: node ? node.data.id : null,
        is_leaf: ['element', 'array', 'constant'].indexOf(type) === -1
      }).save()

      let nodeModel = new Node({
        parent: node || this.$refs.tree.root,
        store: node ? node.store : this.$refs.tree.store,
        data: model,
        level: node ? node.level + 1 : 1
      })

      if (node) {
        // added child
        node.childNodes.push(nodeModel)

        // auto expanded node children
        this.expanded = [node.data.id]
      } else {
        // added root (only one)
        if (this.extractorType === 'xml') {
          if (this.$refs.tree.root.childNodes.length < 1) {
            this.$refs.tree.root.childNodes.push(nodeModel)

            this.addRootDisabled = true
          }
        } else {
          this.$refs.tree.root.childNodes.push(nodeModel)
        }
      }
    },

    editEl (node, data) {
      if (this.selectNode === node) {
        this.selectNode = null
        this.editorModel = null
        this.xrefObjectId = null
        return
      }

      this.selectNode = node
      this.editorModel = data

      this.selectTargetField = []
      if (this.editorModel.loader_object_id) {
        this.$http
          .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.editorModel.loader_object_id}&show_children=true`)
          .then((response) => {
            this.selectTargetField.push(...response.data.data)
          })
      }

      if (this.editorModel.target_field_id !== null) {
        this.selectTargetFieldId(this.editorModel.target_field_id)
      } else {
        this.xrefObjectId = null
      }
    },

    deleteEl (node, data) {
      const confirmOptions: ElMessageBoxOptions = {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }

      this.$confirm(
        this.$t('etl_editor.form.messages.delete'),
        this.$t('etl_editor.form.messages.warningTitle'),
        confirmOptions
      ).then(async () => {
        this.selectNode = null
        this.editorModel = null

        this.$refs.tree.remove(node.data.id)

        if (this.extractorType === 'xml') {
          this.addRootDisabled = this.$refs.tree.root.childNodes.length > 0
        }

        await data.delete()
      })
    },

    async save () {
      if (!this.editorModel.xref_field_id) {
        this.editorModel.xref_field_id = null
      }

      if (!this.editorModel.xref_condition) {
        this.editorModel.xref_condition = null
      }

      console.log(this.editorModel)

      let response = await this.editorModel.save()

      if (response.code === 'mapping_not_found') {
        this.$refs.tree.remove(this.editorModel.id)

        this.selectNode = null
        this.editorModel = null
      }
    },

    async loadNode (node, resolve) {
      resolve(await this.loadObjects(node.level === 0 ? null : node.data.id))
    },

    async loadObjects (parentId = null) {
      let params = { task_id: this.taskId, order: 'row_order:asc,id:desc' }
      if (parentId === null) {
        params = Object.assign({ is_null: 'parent_id' }, params)
      } else {
        params = Object.assign({ parent_id: parentId }, params)
      }

      let response = await new Mapping().params(params).get()

      if (!parentId && this.extractorType === 'xml') {
        this.addRootDisabled = response.length > 0
      }

      response.forEach(item => {
        item.is_leaf = ['element', 'array', 'constant'].indexOf(item.element_type_id) === -1
      })

      return response
    },

    importXSD () {
      if (this.$refs.tree.root.childNodes.length > 0) {
        this.$message.error(this.$t('etl_editor.xsd_import.import_root_error'))
        return
      }

      this.importDialog.show = true
    },

    handleChange (file, fileList) {
      if (['application/x-zip-compressed', 'application/zip'].indexOf(file.raw.type) === -1) {
        this.importDialog.fileList = []
        this.$message.error(this.$t('etl_editor.xsd_import.zip_file'))
        return false
      }

      this.importDialog.fileList = fileList
    },

    onSuccess (response, file, fileList) {
      this.importDialog.selected = true
      this.importDialog.guid = response.guid
      this.importDialog.data = response.file_list
    },

    onImportCancel () {
      this.importDialog = {
        show: false,
        selected: false,
        data: [],
        guid: null,
        fileList: [],
        checkedNode: null,
        fileType: '',
        loading: false
      }

      this.$refs.xsdUpload.clearFiles()
    },

    onImport () {
      if (this.importDialog.checkedNode !== null) {
        this.$http
          .post(
            `${this.$config.api}/xsd2jsonconverter/xsdservice/zip_parse`,
            {
              guid: this.importDialog.guid,
              filename: this.importDialog.checkedNode.name
            }
          )
          .then((response) => {
            this.importDialog.loading = true
            this.$http
              .post(`${this.$config.api}/etleditor/mappings/${this.taskId}/xsd_import`, { elements: response.data.json })
              .catch((error) => {
                this.$message.error('Во время импорта возникла ошибка!')

                console.log('xsd_import', error.message)
              })
              .finally(() => {
                this.onImportCancel()
              })
          })
          .catch((error) => {
            this.$message.error('Во время парсинга XSD/XML возникла ошибка!')
            this.onImportCancel()

            console.log('zip_parse', error.message)
          })
      }
    },

    checkNode (node, checked) {
      if (checked) {
        this.importDialog.checkedNode = node
        this.$refs.treeImport.setCheckedKeys([node.id])
      }
    },

    saveAllEditMapping (nodes = null) {
      if (!nodes) {
        nodes = this.$refs.tree.root.childNodes
      }

      nodes.forEach((node) => {
        if (node.data.isEdit()) {
          node.data.save()
        }

        if (node.childNodes.length > 0) {
          this.saveAllEditMapping(node.childNodes)
        }
      })
    },

    fullScreen () {
      this.isFullScreen = !this.isFullScreen
    },

    maskedElement (value) {
      value = value.replace(/(\s)+/g, '-').replace(/[^_:.\-0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')

      this.editorModel.source_column = value
    },

    renderOptionLabel (item) {
      return `${item.name} (id: ${item.id}, ${this.$t('object_editor.entity_types.' + item.entity_type_id)})`
    },

    renderContent (h, { node, data, store }) {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    }
  }
})
