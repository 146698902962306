



































































































































// Центр уведомлений
import Messages from '@/components/System/Models/Messages'
import Cards from '@/components/Registry/Cards.vue'
import { getCardId } from '@/helpers'
import mixin from '@/components/System/Notification/mixins/index'
import mixins from 'vue-typed-mixins'

export default mixins(mixin).extend({
  name: 'NotificationCenter',

  components: {
    Cards
  },
  data () {
    return {
      tableData: [],
      loading: false,
      pageLimit: 50,
      search: '',
      total: null,
      currentPage: 0,
      openedCards: [],
      showCard: false,
      users: [],
      filterUsers: [],
      radio: 'all',
      expanded: '',
      filter: {
        name: '',
        date: null,
        type: null
      },
      badge: false,
      visibleFilter: false,
      pickerOptions: {
        shortcuts: [{
          text: 'Прошлая неделя',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Прошлый месяц',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Последние 3 месяца',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  computed: {
    disabledBtn () {
      if (!this.filter.name && this.filter.date === null) {
        return true
      }
      return false
    },
    loadParams () {
      let params = {
        offset: this.currentPage,
        limit: this.pageLimit,
        order: 'create_date:desc',
        where: { and: [] }
      }
      if (this.radio === 'new') {
        params.where.and.push({
          is_null: 'read_date'
        })
      }
      if (this.filter.name) {
        params.where.and.push({
          eq: { 'm.author_id': this.filter.name }
        })
      }
      if (this.filter.date) {
        params.where.and.push({
          and: [{
            gte: { 'm.create_date': this.filter.date[0] },
            lte: { 'm.create_date': this.filter.date[1] }
          }]
        })
      }
      if (this.search) {
        params.where.and.push({
          or: [
            { like: { 'u.name': `%${this.search}%` } },
            { like: { 'u.midname': `%${this.search}%` } },
            { like: { 'u.surname': `%${this.search}%` } },
            { like: { 'm.title': `%${this.search}%` } },
            { like: { 'm.content': `%${this.search}%` } }
          ]
        })
      }
      return params
    }
  },
  inject: {
    addMainTab: {
      default: () => {}
    }
  },
  provide () {
    return {
      openRegistryCard: this.openRegistryCard,
      cancelChanges: this.cancelChanges
    }
  },
  methods: {
    openRegistryCard ({ registryId, cardId, cardName, recordId = null, initialData = {}, registry = null }) {
      if (!cardId || !registryId) {
        this.$notify.error({
          title: this.$locale.main.message.error,
          message: this.$locale.main.message.not_saved
        })

        return false
      }
      this.showCard = true
      this.openedCards.push({
        id: cardId,
        registryId: registryId,
        recordId: recordId,
        name: cardName,
        initialData: initialData,
        registry: registry,
        readonly: registry ? (registry.readonly || false) : false
      })
    },
    async openCard (data) {
      let dataRegistryByRecord = await this.getDataRegistryByRecord({ registryId: data.notification[0].object_id, recordId: data.record_id })
      if (dataRegistryByRecord.is_deleted) {
        this.$message.error(`Запись удалена из системы`)
        return
      }
      // Открыть карточку внешнего реестра
      if (data.notification[0].interaction_type === 'open_external_card') {
        this.openCardOuter(data, dataRegistryByRecord)
        return
      }
      this.loading = true
      let card: any = {}
      try {
        card = await getCardId(this, { registryId: data.notification[0].object_id, recordId: data.record_id })
      } catch (error) {
        console.log({ error })
        this.loading = false
        return
      }
      this.openedCards.push({
        id: card?.id || null,
        registryId: data.notification[0].object_id,
        recordId: data.record_id,
        name: card?.name || '',
        initialData: {}
        // registry: registry,
        // readonly: registry ? (registry.readonly || false) : false
      })
      this.showCard = true
      this.readMessage(data)
      this.loading = false
    },
    // Открыть карточку внешнего реестра
    async openCardOuter (data, dataRegistryByRecord) {
      let { openCard, readOnly, cardId, recordXref } = await this.validationData(data, dataRegistryByRecord)
      if (!openCard) return
      // получить id карточки внешнего реестра
      const card: any = (cardId) ? { id: cardId } : await getCardId(this, { registryId: openCard.external_object_id, recordId: recordXref })
      this.showCard = true
      this.openedCards.push({
        id: card?.id,
        registryId: openCard.external_object_id,
        recordId: recordXref,
        name: '',
        initialData: {},
        readonly: readOnly
      })
      this.showCard = true
      this.readMessage(data)
      this.loading = false
    },
    cancelChanges () {
      if (this.openedCards.length === 1) {
        this.showCard = false
        this.openedCards = []
      } else {
        this.openedCards = this.openedCards.slice(0, this.openedCards.length - 1)
      }
    },
    async updateTable () {
      this.radio = 'all'
      this.search = ''
      this.filter.name = this.filter.type = ''
      this.filter.date = null
      this.badge = false
      this.loadAvatarUser()
    },
    saveFilter () {
      this.badge = true
      this.visibleFilter = false
      this.loadAvatarUser(true)
    },
    clearFilter () {
      this.filter.name = this.filter.type = ''
      this.filter.date = null
      this.badge = false
      this.visibleFilter = false
      this.loadAvatarUser(true)
    },
    readMessage (row) {
      this.$refs.notifyTable.toggleRowExpansion(row)
      if (row.read_date !== null || this.expanded === row.id) return false
      this.$http({
        method: 'put',
        url: `${this.$config.api}/${this.$config.notification_publisher}/messages/read`,
        params: { message_id: row.id },
        hideNotification: true
      }).then(() => {
        this.$store.commit('Notify/readMsg', row.id)
        this.expanded = row.id
        this.tableData.find(el => { if (el.id === row.id) el.read_date = 1 })
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.read_date) {
        return 'read-row'
      } else {
        return 'noRead-row'
      }
    },
    updateLayout () {
      this.expanded = ''
      this.$nextTick(() => {
        this.$refs.notifyTable && this.$refs.notifyTable.doLayout()
        console.log('update layout notifyTable')
      })
    },
    async changePagination (val) {
      this.loading = true
      val--
      this.currentPage = val * this.pageLimit
      await this.loadAvatarUser()
      this.loading = false
    },
    fullName (user) {
      if (!user.midname && !user.surname) {
        return user.name
      }
      let name = user.name.substring(0, 1)
      let midname = user.midname !== null ? user.midname.substring(0, 1) : ''
      let surname = user.surname !== null ? user.surname : ''
      return `${surname} ${name}. ${midname}.`
    },
    async cashAvatar () {
      this.filterUsers.forEach(item => {
        item.fullName = this.fullName({ name: item.name, surname: item.surname, midname: item.midname })
        if (item.avatar_id) {
          this.$http.get(`${this.$config.api}/registryservice/files/${item.avatar_id}`)
            .then(res => { item.avatar = res.data })
            .then(() => {
              for (let i = 0; i < this.tableData.length; i++) {
                for (let j = 0; j < this.filterUsers.length; j++) {
                  if (this.tableData[i].author_id === this.filterUsers[j].id) {
                    this.$set(this.tableData[i], 'fullName', this.filterUsers[j].fullName)
                    this.$set(this.tableData[i], 'avatar', this.filterUsers[j].avatar)
                  }
                }
              }
            })
        }
      })
      // let count = await new Messages().params({ '*': { func: 'count' } }).get()
      let count = await this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, { '*': { func: 'count' } }, { hideNotification: true })
      this.total = count.data[0].count
      this.updateLayout()
      this.loading = false
    },
    async loadAvatarUser (filter = false) {
      this.loading = true
      try {
        if (filter) {
          this.$http.post(`${this.$config.api}/${this.$config.notification_publisher}/messages`, this.loadParams, { hideNotification: true })
            .then(response => {
              this.cashAvatar()
              this.tableData = response.data
            })
        } else {
          let notify = await new Messages().params(this.loadParams).$get()
          this.tableData = notify
          this.cashAvatar()
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    }
  },
  async mounted () {
    this.loading = true
    this.filterUsers = await new Messages().custom('messages/authors').get()
    await this.loadAvatarUser()
    if (this.$attrs.registryId) {
      this.openedCards.push({
        id: this.$attrs.cardId,
        registryId: this.$attrs.registryId,
        recordId: this.$attrs.recordId,
        name: '',
        initialData: {}
      })
      this.showCard = true
    }
    this.loading = false
  }
})
