<template>
  <div style="height: 100%" v-loading="loading">
    <user-main-edit-panel
      style="overflow-y: auto"
      class="access-editor"
      :card-params="cardParams"
      :roles-list="rolesList"
      :prevent-return="true"
      @cancelChanges="$emit('cancelChanges', $event)"
    ></user-main-edit-panel>
  </div>
</template>

<script>
import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import UserCreateHandler from '@/services/AccessEditor/application/handler/command/UserCreateHandler'
import UserCommandRepository from '@/services/AccessEditor/infrastructure/domain/repository/UserCommandRepository'
import UserUpdateHandler from '@/services/AccessEditor/application/handler/command/UserUpdateHandler'
import UserDeleteHandler from '@/services/AccessEditor/application/handler/command/UserDeleteHandler'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import UserQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/UserQueryRepository'
import UserCardHandler from '@/services/AccessEditor/application/handler/query/UserCardHandler'
import UserCardQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/UserCardQueryRepository'
import UserByGuidHandler from '@/services/AccessEditor/application/handler/query/UserByGuidHandler'
import UserPasswordHandler from '@/services/AccessEditor/application/handler/query/UserPasswordHandler'
import UserPasswordRulesHandler from '@/services/AccessEditor/application/handler/query/UserPasswordRulesHandler'
import UserPasswordCheckHandler from '@/services/AccessEditor/application/handler/query/UserPasswordCheckHandler'
import UserApiKeyHandler from '@/services/AccessEditor/application/handler/query/UserApiKeyHandler'
import UserAvatarHandler from '@/services/AccessEditor/application/handler/query/UserAvatarHandler'
import RolesQuery from '@/services/AccessEditor/application/query/RolesQuery'
import RolesHandler from '@/services/AccessEditor/application/handler/query/RolesHandler'
import RoleQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/RoleQueryRepository'
import UserCardQuery from '@/services/AccessEditor/application/query/UserCardQuery'
import UserMainEditPanel from '@/services/AccessEditor/infrastructure/components/UserPanel/UserMainEditPanel/index'
import UsersHandler from '@/services/AccessEditor/application/handler/query/UsersHandler'
import UsersQuery from '@/services/AccessEditor/application/query/UsersQuery'

export default {
  name: 'AdapterUserCard',
  components: { UserMainEditPanel },
  props: {
    userId: Number
  },
  async mounted () {
    this.eventBus.$on('userIsUpdated', () => {
      this.$emit('recordEdited', this.userId)
    })
    this.eventBus.$on('userIsCreated', () => {
      this.$emit('recordAdded', null)
    })
    this.loadRoles()
    await this.loadCard()
    await this.loadUser()
  },
  data () {
    return {
      loading: true,
      rolesList: [],
      user: {},
      cardParams: {},
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'UserCreateCommand': new UserCreateHandler(
            new UserCommandRepository()
          ),
          'UserUpdateCommand': new UserUpdateHandler(
            new UserCommandRepository()
          ),
          'UserDeleteCommand': new UserDeleteHandler(
            new UserCommandRepository()
          )
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'UsersQuery': new UsersHandler(
            new UserQueryRepository()
          ),
          'RolesQuery': new RolesHandler(
            new RoleQueryRepository()
          ),
          'UserCardQuery': new UserCardHandler(
            new UserCardQueryRepository()
          ),
          'UserByGuidQuery': new UserByGuidHandler(
            new UserQueryRepository()
          ),
          'UserPasswordQuery': new UserPasswordHandler(
            new UserQueryRepository()
          ),
          'UserPasswordRulesQuery': new UserPasswordRulesHandler(
            new UserQueryRepository()
          ),
          'UserPasswordCheckQuery': new UserPasswordCheckHandler(
            new UserQueryRepository()
          ),
          'UserApiKeyQuery': new UserApiKeyHandler(
            new UserQueryRepository()
          ),
          'UserAvatarQuery': new UserAvatarHandler(
            new UserQueryRepository()
          )
        })
      )
    }
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    loadRoles () {
      this.getQueryBus().execute(
        new RolesQuery({ only_available: true })
      ).then(data => {
        this.rolesList = data
      })
    },
    async loadCard () {
      let data = await this.getQueryBus().execute(
        new UserCardQuery({})
      )
      this.cardParams = data.card
    },
    async loadUser () {
      if (this.userId) {
        let user = await this.getQueryBus().execute(
          new UsersQuery({ id: this.userId })
        )
        this.user = user[0]
      }
      this.getEventBus().$emit('editUserEvent', this.user)
      this.loading = false
    },
    getEventBus () {
      return this.eventBus
    },
    getCommandBus () {
      return this.commandBus
    },
    getQueryBus () {
      return this.queryBus
    }
  }
}
</script>
<style src="../../../../AccessEditor.css"></style>
