import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class CommandEventTypeDTO {
  id: string;
  name: string;
}

export class CommandEventTypePropertyDTO {
  id?: Nullable<string>
  name?: Nullable<string>
  type?: Nullable<string>
  default_value?: Nullable<string>
  primitive_type?: Nullable<string>

  constructor ({ id, name, type, default_value, primitive_type }) {
    this.id = id
    this.name = name
    this.type = type
    this.default_value = default_value
    this.primitive_type = primitive_type
  }
}

export interface ICommand {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: string
  description: Nullable<string>
  target_state_id: Nullable<number>
  plugin_id: Nullable<string>
  event_id: Nullable<string>
  is_plugin: boolean
  is_complex: boolean
  properties: Nullable<Array<object>>
  before_command_id: Nullable<number>
  after_command_id: Nullable<number>
  cancel_command_id: Nullable<number>
  command_states: Array<number>
  command_external_states: Array<number>
  eds_sign_command_fields: Array<object>
  command_constraints: Array<number>
  child_commands: Array<ICommand>
  alias: Nullable<string>
  is_timer: false,
  period: null,
  logic_id: number
  parent_tree_element_id?: Nullable<number>
}

export class CommandDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: string
  description: Nullable<string>
  target_state_id: Nullable<number>
  plugin_id: Nullable<string>
  event_id: Nullable<string>
  is_plugin: boolean
  is_complex: boolean
  properties: Nullable<Array<object>>
  before_command_id: Nullable<number>
  after_command_id: Nullable<number>
  cancel_command_id: Nullable<number>
  command_states: Array<number>
  command_external_states: Array<number>
  eds_sign_command_fields: Array<object>
  command_constraints: Array<number>
  child_commands: Array<CommandDTO>
  alias: Nullable<string>
  is_timer: boolean
  period: Nullable<string>
  logic_id?: number
  parent_tree_element_id?: Nullable<number>

  constructor (
    { id, guid, description, name, target_state_id, plugin_id, event_id, is_plugin, is_complex, properties, before_command_id, after_command_id, cancel_command_id, command_states, command_external_states, eds_sign_command_fields, command_constraints, child_commands, alias, is_timer, period, logic_id, parent_tree_element_id }: ICommand
  ) {
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.target_state_id = target_state_id
    this.plugin_id = plugin_id
    this.event_id = event_id
    this.is_plugin = is_plugin
    this.is_complex = is_complex
    this.properties = properties
    this.before_command_id = before_command_id
    this.after_command_id = after_command_id
    this.cancel_command_id = cancel_command_id
    this.command_states = command_states
    this.command_external_states = command_external_states
    this.eds_sign_command_fields = eds_sign_command_fields
    this.command_constraints = command_constraints
    if (child_commands && Array.isArray(child_commands)) {
      this.child_commands = child_commands.map(item => new CommandDTO(item))
    } else {
      this.child_commands = []
    }
    this.logic_id = logic_id
    this.alias = alias
    this.is_timer = is_timer
    this.period = period
    this.parent_tree_element_id = parent_tree_element_id
  }

  static create (): CommandDTO {
    return new CommandDTO({
      id: null,
      guid: null,
      name: null,
      description: null,
      target_state_id: null,
      plugin_id: null,
      event_id: null,
      is_plugin: false,
      is_complex: false,
      properties: null,
      before_command_id: null,
      after_command_id: null,
      cancel_command_id: null,
      command_states: [],
      command_external_states: [],
      eds_sign_command_fields: [],
      command_constraints: [],
      child_commands: [],
      logic_id: null,
      alias: null,
      is_timer: false,
      period: null,
      parent_tree_element_id: null
    })
  }

  static createChildCommand (name: string, logicId: number): CommandDTO {
    return new CommandDTO({
      name: name,
      description: null,
      target_state_id: null,
      plugin_id: null,
      event_id: null,
      is_plugin: false,
      is_complex: false,
      properties: null,
      before_command_id: null,
      after_command_id: null,
      cancel_command_id: null,
      command_states: [],
      command_external_states: [],
      eds_sign_command_fields: [],
      command_constraints: [],
      child_commands: [],
      logic_id: logicId,
      alias: null,
      is_timer: false,
      period: null,
      parent_tree_element_id: null
    })
  }
}

export default class Command extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private description: Nullable<string>
  private targetStateId?: Nullable<number>
  private pluginId?: Nullable<string>
  private eventId?: Nullable<string>
  private isPlugin: boolean
  private isComplex: boolean
  private properties: Array<object>
  private beforeCommandId: Nullable<number>
  private afterCommandId: Nullable<number>
  private cancelCommandId: Nullable<number>
  private commandStates: Array<number>
  private commandExternalStates: Array<number>
  private edsSignCommandFields: Array<object>
  private commandConstraints: Array<number>
  private childCommands: Array<CommandDTO>
  private logicId: number
  private alias: Nullable<string>
  private isTimer: boolean
  private period: Nullable<string>
  private parentTreeElementId?: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    targetStateId: Nullable<number>,
    pluginId: Nullable<string>,
    eventId: Nullable<string>,
    isPlugin: boolean,
    isComplex: boolean,
    properties: Array<object>,
    beforeCommandId: Nullable<number>,
    afterCommandId: Nullable<number>,
    cancelCommandId: Nullable<number>,
    commandStates: Array<number>,
    commandExternalStates: Array<number>,
    edsSignCommandFields: Array<object>,
    commandConstraints: Array<number>,
    childCommands: Array<CommandDTO>,
    logicId: number,
    alias: Nullable<string>,
    isTimer: boolean,
    period: Nullable<string>,
    parentTreeElementId?: Nullable<number>,
    id?: Nullable<number>,
    guid?: Nullable<string>
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.description = description
    this.targetStateId = targetStateId
    this.pluginId = pluginId
    this.eventId = eventId
    this.isPlugin = isPlugin
    this.isComplex = isComplex
    this.properties = properties
    this.beforeCommandId = beforeCommandId
    this.afterCommandId = afterCommandId
    this.cancelCommandId = cancelCommandId
    this.commandStates = commandStates
    this.commandExternalStates = commandExternalStates
    this.edsSignCommandFields = edsSignCommandFields
    this.commandConstraints = commandConstraints
    this.childCommands = childCommands
    this.logicId = logicId
    this.alias = alias
    this.isTimer = isTimer
    this.period = period
    this.parentTreeElementId = parentTreeElementId
  }

  static create (dto: CommandDTO): Command {
    return new Command(
      dto.name,
      dto.description,
      dto.target_state_id,
      dto.plugin_id,
      dto.event_id,
      dto.is_plugin,
      dto.is_complex,
      dto.properties,
      dto.before_command_id,
      dto.after_command_id,
      dto.cancel_command_id,
      dto.command_states,
      dto.command_external_states,
      dto.eds_sign_command_fields,
      dto.command_constraints,
      dto.child_commands,
      dto.logic_id,
      dto.alias,
      dto.is_timer,
      dto.period,
      dto.parent_tree_element_id,
      dto.id,
      dto.guid
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId (): number {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  setName (name: string): void {
    this.name = name
  }

  getDescription (): string {
    return this.description
  }

  setDescription (description: string): void {
    this.description = description
  }

  getTargetStateId (): Nullable<number> {
    return this.targetStateId
  }

  setTargetStateId (targetStateId?: Nullable<number>): void {
    this.targetStateId = targetStateId
  }

  getPluginId (): Nullable<string> {
    return this.pluginId
  }

  setPluginId (pluginId?: Nullable<string>): void {
    this.pluginId = pluginId
  }

  getEventId (): Nullable<string> {
    return this.eventId
  }

  setEventId (eventId?: Nullable<string>): void {
    this.eventId = eventId
  }

  getIsPlugin (): boolean {
    return this.isPlugin
  }

  setIsPlugin (isPlugin: boolean): void {
    this.isPlugin = isPlugin
  }

  getIsComplex (): boolean {
    return this.isComplex
  }

  setIsComplex (isComplex: boolean): void {
    this.isComplex = isComplex
  }

  getProperties (): Array<object> {
    return this.properties
  }

  setProperties (properties: Array<object>): void {
    this.properties = properties
  }

  getBeforeCommandId (): Nullable<number> {
    return this.beforeCommandId
  }

  getAfterCommandId (): Nullable<number> {
    return this.afterCommandId
  }

  getCancelCommandId (): Nullable<number> {
    return this.cancelCommandId
  }

  getCommandStates (): Array<number> {
    return this.commandStates
  }

  setCommandStates (commandStates: Array<number>): void {
    this.commandStates = commandStates
  }

  getCommandExternalStates (): Array<number> {
    return this.commandExternalStates
  }

  setCommandExternalStates (commandExternalStates: Array<number>): void {
    this.commandExternalStates = commandExternalStates
  }

  getEdsSignCommandFields (): Array<object> {
    return this.edsSignCommandFields
  }

  setEdsSignCommandFields (edsSignCommandFields: Array<object>): void {
    this.edsSignCommandFields = edsSignCommandFields
  }

  getCommandConstraints (): Array<number> {
    return this.commandConstraints
  }

  setCommandConstraints (commandConstraints: Array<number>): void {
    this.commandConstraints = commandConstraints
  }

  getChildCommands (): Array<CommandDTO> {
    return this.childCommands
  }

  setChildCommands (childCommands: Array<CommandDTO>): void {
    this.childCommands = childCommands
  }

  getLogicId (): number {
    return this.logicId
  }

  setLogicId (logicId: number): void {
    this.logicId = logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  setAlias (alias: Nullable<string>): void {
    this.alias = alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }

  setParentTreeElementId (parentTreeElementId?: Nullable<number>): void {
    this.parentTreeElementId = parentTreeElementId
  }

  getIsTimer (): boolean {
    return this.isTimer
  }

  getPeriod (): Nullable<string> {
    return this.period
  }

  setIsTimer (isTimer: boolean): void {
    this.isTimer = isTimer
  }

  setPeriod (period?: Nullable<string>): void {
    this.period = period
  }
}
