var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Treeselect',{ref:"registryFieldSelect",staticClass:"custom_scrollbar",attrs:{"placeholder":"Выбрать","value":_vm.localValue,"options":_vm.registryFields,"normalizer":_vm.registryFieldsSettings.normalizer,"disable-branch-nodes":true,"clear-on-select":true,"clearValueText":_vm.registryFieldsSettings.clearValueText,"noChildrenText":_vm.registryFieldsSettings.noChildrenText,"noResultsText":_vm.registryFieldsSettings.noResultsText,"limitText":_vm.registryFieldsSettings.limitText,"matchKeys":_vm.registryFieldsSettings.matchKeys,"value-consists-of":_vm.registryFieldsSettings.valueConsistsOf,"appendToBody":true},on:{"select":function($event){return _vm.selectField($event)},"input":_vm.input},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_c('b',[_vm._v(_vm._s(node.id)+" ")]),_vm._v("("+_vm._s(node.label)+")")])}},{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName},[_c('b',[_vm._v(_vm._s(node.label))]),_c('br'),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(node.id))]),_c('span',{staticStyle:{"float":"right","font-size":"10px"}},[_vm._v(_vm._s(_vm.$locale.object_editor.entity_types[node.raw.entity_type_id] || node.raw.entity_type_id))])])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }