import { FilterBuilder } from '@/core/infrastructure/components/Grid/infrastructure/service/FilterBuilder'

export class ColumnsFactory {
  static build (columns: { row_order, id, name, children, entity_type_id, properties }[]): object[] {
    let result = []
    columns.sort((a, b) => b.row_order - a.row_order).forEach((column) => {
      const columnType = this.toCamelCase(column.entity_type_id)
      const types = [columnType]
      let object = {
        headerName: column.name,
        field: `attr_${column.id}_`,
        children: undefined,
        type: types,
        enableRowGroup: true,
        guid: this.generateGuid(),
        columnType: columnType
      }

      this.getProperty('is_extended', column.properties) &&
      (object = Object.assign(object, {
        autoHeight: true,
        wrapText: true
      }))

      this.getProperty('is_filtered', column.properties) && (object = Object.assign(object, FilterBuilder.build(columnType)))

      if (column.children) {
        object.children = ColumnsFactory.build(column.children)
      }

      result.push(object)
    })

    return result
  }
  private static toCamelCase (s:string): string {
    return s.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '')
    })
  }

  private static getProperty (propertyName: string, properties: {id, value}[]): any {
    let property: {value} = properties.find((item: {id}) => item.id === propertyName)
    if (property) {
      return property.value
    }
  }
  private static generateGuid () {
    let d = new Date().getTime()
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now()
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  }
}
