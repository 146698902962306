<template>
  <div class="editor_box object_editor">
    <el-container>
      <el-header height="">
        {{ $t('process_editor.title') }}
      </el-header>
      <el-container>
        <Split style="height: calc(100vh - 96px);">
          <SplitArea :size="50">
            <div class="tool_box">
              <el-tooltip
                slot="reference"
                class="item"
                effect="dark"
                :content="$locale.main.button.add"
                placement="top">
                <el-dropdown placement="bottom-start" trigger="click" @command="add($event)">
                  <el-button
                    icon="el-icon-plus"
                    size="small"
                    plain>
                    {{ $locale.main.button.add }}
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="type.id" v-for="(type, index) in types" :key="index">{{ type.title }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-tooltip>
              <el-input class="search_input"
                        @keyup.enter.native="onSearch"
                        clearable
                        placeholder="Поиск"
                        size="small"
                        style="width: calc(100% - 120px)"
                        v-model="searchText">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  :loading="isSearchButtonLoading"
                  @click="onSearch">
                </el-button>
              </el-input>
            </div>
            <el-scrollbar :style="{height:'calc(100vh - 153px)'}" >
              <el-tree
                ref="tree"
                :key="isSearch ? 1 : 2"
                :lazy="!isSearch"
                :data="searchData"
                :props="props"
                node-key="guid"
                :load="loadNode"
                :expand-on-click-node="false"
                :default-expand-all="isSearch">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span
                    :title="node.label.length > 33 ? node.label +' '+ data.id : ''"
                    :class="{ 'selected-node': editorModel.guid === data.element_guid }"
                    @click="openEditor(node, data)">
                    <i style="font-size: 16px;" :class="getTreeIcon(node, data)"></i>
                    <b :class="{'pl-5': data.element_type === 'group'}">{{ node.label.length > 33 ? `${node.label.substring(0, 33)}...` : node.label }}</b>
                    <span style="color: #7C838B; padding-left: 6px;">(id: {{data.element_id}})</span>
                  </span>
                  <span v-if="editorModel.guid === data.element_guid">
                    <el-dropdown @command="add($event, data)" v-if="data.element_type === 'group'" trigger="click">
                      <el-button type="text" size="small"><i class="el-icon-plus el-icon--left"></i>{{ $locale.main.button.add }}</el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="type.id" v-for="(type, index) in types" :key="index">{{ type.title }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-button type="text" size="small" style="color:red" @click="deleteNode(data.guid)"><i class="el-icon-delete el-icon--left"></i>{{ $locale.main.button.delete }}</el-button>
<!--                    <el-button v-show="data.entity_type_id === 'registry'" type="text" size="small" @click="addMainTab({name: data.name, componentType: 'Registry', payload: {id: data.id}})"><i class="el-icon-check el-icon&#45;&#45;left"></i>{{ $locale.main.button.preview }}</el-button>-->
                  </span>
                </span>
              </el-tree>
            </el-scrollbar>
          </SplitArea>
          <SplitArea :size="50">
            <template v-if="editorModel.id">
              <el-scrollbar style="height: calc(100% - 45px);">
                <el-form style="padding: 15px" ref="form" :model="editorModel" :rules="rules" size="mini" label-position="top">
                  <el-form-item :label="$locale.main.fields.name" prop="name">
                    <el-input v-model="editorModel.name"></el-input>
                  </el-form-item>
                  <el-form-item :label="$locale.main.fields.description" v-if="editorModel.element_type === 'process'">
                    <el-input type="textarea" v-model="editorModel.description"></el-input>
                  </el-form-item>
                  <el-form-item :label="$locale.main.fields.alias">
                    <el-input v-model="editorModel.alias"></el-input>
                  </el-form-item>
                  <el-form-item :label="$locale.process_editor.fields.schemas">
                    <el-table
                      v-if="editorModel.element_type === 'process'"
                      :data="schemas"
                      style="width: 100%">
                      <el-table-column
                        :label="$locale.main.fields.name"
                        prop="name">
                      </el-table-column>
                      <el-table-column
                        fixed="right"
                        width="120">
                        <template slot-scope="scope">
                          <el-button @click="editSchema(scope.row.guid)" style="float: right" size="small" icon="el-icon-edit"></el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-form-item>
                </el-form>
              </el-scrollbar>
              <el-button
                type="primary"
                size="small"
                @click="save"
                v-loading="isSaveButtonLoading"
                style="float: right;margin-right: 15px"
                icon="el-icon-success">
                {{ $locale.main.button.save }}
              </el-button>
            </template>
          </SplitArea>
        </Split>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Node from 'element-ui/packages/tree/src/model/node'

export default {
  name: 'ProcessEditor',
  inject: ['addMainTab'],
  data () {
    return {
      types: [
        { id: 'groups', title: 'Группа' },
        { id: 'processes', title: 'Процесс' }
      ],
      searchText: null,
      isSearchButtonLoading: false,
      isSearch: false,
      searchData: [],
      props: {
        isLeaf: 'is_leaf',
        label: 'name',
        children: 'child'
      },
      editorModel: {},
      activeNode: null,
      rules: {
        name: [{ required: true, message: this.$locale.object_editor.rules.name, trigger: 'blur' }]
      },
      isSaveButtonLoading: false,
      schemas: []
    }
  },
  methods: {
    async editSchema (guid) {
      const schema = await this.$http.get(`${this.$config.api}/processeditor/schemas/${guid}`)

      this.addMainTab({
        name: schema.data.name + ' (BPMN)',
        componentType: 'BPMNEditor',
        payload: {
          schema: schema.data.model,
          saveCallback: (xml) => {
            this.$http.put(`${this.$config.api}/processeditor/schemas/${guid}`, {
              model: xml.xml
            })
          }
        }
      })
    },
    async openEditor (node, data) {
      this.activeNode = node
      const type = data.element_type === 'group' ? 'groups' : 'processes'
      const response = await this.$http.get(`${this.$config.api}/processeditor/${type}?id=${data.element_id}`)
      this.editorModel = Object.assign(response.data, { element_type: data.element_type })
      if (data.element_type === 'process') {
        const schemas = await this.$http.get(`${this.$config.api}/processeditor/processes/${data.element_guid}/schemas`)
        this.schemas = schemas.data
      }
    },
    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!this.editorModel.guid) {
            return false
          }
          this.isSaveButtonLoading = true
          const type = this.editorModel.element_type === 'group' ? 'groups' : 'processes'
          this.$http.put(`${this.$config.api}/processeditor/${type}/${this.editorModel.guid}`, {
            name: this.editorModel.name,
            alias: this.editorModel.alias || null,
            description: this.editorModel.description || null
          }).then(() => {
            this.$set(this.activeNode.data, 'name', this.editorModel.name)
          }).finally(() => {
            this.isSaveButtonLoading = false
          })
        }
      })
    },
    add (type, parent = null) {
      this.$prompt(this.types.find(item => item.id === type).title, `${this.$locale.main.button.add}`, {
        confirmButtonText: this.$locale.main.button.add,
        cancelButtonText: this.$locale.main.button.cancel,
        closeOnClickModal: false,
        inputValidator (value) {
          return !!(value || '').trim()
        }
      }).then(async ({ value }) => {
        let response = await this.$http.post(`${this.$config.api}/processeditor/tree_elements/${type}`, {
          name: value,
          parent_id: parent?.id
        })
        let { data } = await this.getTreeByGuid(response.headers.location.split('/').pop(), type)
        if (parent) {
          this.$refs.tree.append(data, parent.guid)
        } else {
          let node = new Node({ parent: this.$refs.root, store: this.$refs.tree.store, data: data })
          node.level = 1
          this.$refs.tree.root.childNodes.push(node)
        }
      }).catch((error) => { console.log(error) })
    },
    onSearch () {

    },
    deleteNode (guid) {
      this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
        confirmButtonText: this.$locale.main.button.delete,
        cancelButtonText: this.$locale.main.button.cancel,
        type: 'warning'
      }).then(async () => {
        await this.$http.delete(`${this.$config.api}/processeditor/tree_elements/${guid}`)
        this.$refs.tree.remove(guid)
      }).catch((error) => { console.log(error) })
    },
    async loadNode (node, resolve) {
      let data = []
      if (node.level === 0) {
        data = await this.$http.get(`${this.$config.api}/processeditor/tree_elements`)
      } else {
        data = await this.$http.get(`${this.$config.api}/processeditor/tree_elements?parent_id=${node.data.id}`)
      }
      resolve(data.data)
    },
    async getTreeByGuid (guid) {
      return this.$http.get(`${this.$config.api}/processeditor/tree_elements/${guid}`)
    },
    getTreeIcon (node, data) {
      if (data.element_type === 'group' && !node.expanded) {
        return 'el-icon-folder'
      } else if (data.element_type === 'group' && node.expanded) {
        return 'el-icon-folder-opened'
      }
    }
  }
}
</script>

<style scoped>

</style>
