

































































































import BindServicesQuery from '@/services/ReleaseEditor/application/query/BindServicesQuery'


export default {
  name: 'BindServicesPanel',
  props: {
    release: {
      type: Object,
      required: true
    },
    servicesList: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      informationPanelHeight: 0,
      isCreateBindServiceWindowVisible: false,
      bindServices: [],
      bindService: null,
      bindServiceDto: null,
      bindServiceDtoPrev: null,
      releases: [],
      createBindServiceRules: {
        service_id: {
          required: true,
          message: this.$locale.release_editor.bind_service_panel.service,
          trigger: 'change'
        },
        service_version: {
          required: true,
          message: this.$locale.release_editor.bind_service_panel.service_version,
          trigger: 'change'
        },
        service_release_date: {
          required: true,
          message: this.$locale.release_editor.bind_service_panel.service_release_date,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    isBindServiceLoading: (state) => state,
  },
  computed: {
    isBindServiceLoading() {
      return this.$store.getters['BindService/isLoading'];
    }
  },
  methods: {
    async loadBindServices(callback) {
      await this.getQueryBus().execute(
        new BindServicesQuery({release_guid: this.release.guid})
      ).then(data => {
        this.bindServices = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    }
  },
  mounted() {
    this.loadBindServices();
    this.releases[0] = this.release;
  }
}
