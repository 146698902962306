import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import SmevSettingCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/SmevSettingCommandRepositoryInterface'
import SmevSettingCreateCommand from '@/services/AccessEditor/application/command/SmevSettingCreateCommand'
import { SmevSettingDTO } from '@/services/AccessEditor/domain/model/Smev/SmevSetting'

export default class SmevSettingCreateHandler implements CommandHandlerInterface {
    private repo: SmevSettingCommandRepositoryInterface;

    constructor (repo: SmevSettingCommandRepositoryInterface) {
      this.repo = repo
    }

    execute (command: SmevSettingCreateCommand): Promise<void> {
      let item: SmevSettingDTO = {
        name: command.getName(),
        value: command.getValue()
      }
      return this.repo.insert(item)
    }
}
