export class FilterBuilder {
  static MAP = {
    'textField': FilterBuilder.string,
    'stringField': FilterBuilder.string,
    'floatField': FilterBuilder.number,
    'integerField': FilterBuilder.number,
    'booleanField': FilterBuilder.booleanFilter,
    'dateField': FilterBuilder.date,
    'timeField': FilterBuilder.time,
    'datetimeField': FilterBuilder.dateTime,
    'xrefField': FilterBuilder.xref,
    'xrefMultiField': FilterBuilder.xref,
    'addressField': FilterBuilder.address,
    'addressMultiField': FilterBuilder.address
  }

  static build (columnType: string): object {
    const filterBuildFunction = this.MAP[columnType]
    return filterBuildFunction ? filterBuildFunction() : {}
  }

  static address (): object {
    return {
      filter: 'addressFilter'
    }
  }

  static string (): object {
    return {
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual']
      }
    }
  }

  static date (): object {
    return {
      filter: 'agDateColumnFilter'
    }
  }

  static dateTime (): object {
    return {
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'dateTimeFilter',
            floatingFilterComponent: 'disabledFitler'
          }
        ]
      }
    }
  }

  static time (): object {
    return {
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'timeFilter',
            floatingFilterComponent: 'disabledFitler'
          }
        ]
      }
    }
  }

  static number (): object {
    return {
      filter: 'agNumberColumnFilter'
    }
  }

  static booleanFilter (): object {
    return {
      filter: 'booleanFilter',
      floatingFilterComponent: 'booleanFloatingFilter'
    }
  }

  static xref (): object {
    return {
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'xrefFilter',
            floatingFilterComponent: 'disabledFitler'
          }
        ]
      }
    }
  }
}
