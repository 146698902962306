import { Nullable } from '@/core/domain/type/types'
import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import store from '@/store'
import { GetEvents } from '@/services/ActivityService/infrastructure/store/modules/Event/action-types'
import EventRepositoryInterface from '@/services/ActivityService/domain/repository/EventRepositoryInterface'

export default class EventRepository extends AbstractQueryRepository implements EventRepositoryInterface {
  get (payload: string[][] | object): Promise<any> {
    return store.dispatch(new GetEvents(payload))
  }
}
