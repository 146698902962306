<template>
  <div :id="`stimulsoft_designer_${componentId}`" :key="componentId"></div>
</template>

<script>
import StiHelper from './StiHelper'

export default {
  name: 'StimulsoftReport',
  props: {
    filename: {
      type: String,
      default () {
        return 'SimpleList.mrt'
      }
    },
    reportId: Number
  },
  data () {
    return {
      componentId: this.generateGuid()
    }
  },
  async mounted () {
    if (typeof window.Stimulsoft === 'undefined') {
      this.loadStyles()
      await this.loadScripts()
    }

    window.Stimulsoft.Base.StiLicense.loadFromFile(`${this.$config.api}/stimulsoftreports/stimulsoft/license.php`)
    window.Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile(
      `${this.$config.api}/stimulsoftreports/localization/ru.xml`, false, 'Russian'
    )

    window.Stimulsoft.Base.Localization.StiLocalization.cultureName = 'Russian'

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/arialbd.ttf`, 'Arial')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/arialbi.ttf`, 'Arial')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/ariali.ttf`, 'Arial')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/arial.ttf`, 'Arial')

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/ANTQUAB.TTF`, 'Book Antiqua')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/ANTQUABI.TTF`, 'Book Antiqua')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/ANTQUAI.TTF`, 'Book Antiqua')

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/calibri.ttf`, 'Calibri')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/calibrib.ttf`, 'Calibri')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/calibrii.ttf`, 'Calibri')

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/ARIALN.TTF`, 'Arial Unicode MS')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/ARIALNB.TTF`, 'Arial Unicode MS')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/ARIALNBI.TTF`, 'Arial Unicode MS')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/ARIALNI.TTF`, 'Arial Unicode MS')

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/CENTURY.TTF`, 'Century')

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/consola.ttf`, 'Consolas')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/consolab.ttf`, 'Consolas')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/consolai.ttf`, 'Consolas')

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/georgia.ttf`, 'Georgia')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/georgiab.ttf`, 'Georgia')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/georgiai.ttf`, 'Georgia')

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/impact.ttf`, 'Impact')

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/segoeui.ttf`, 'Segoe UI')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/segoeuib.ttf`, 'Segoe UI')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/segoeuii.ttf`, 'Segoe UI')

    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/timesbd.ttf`, 'Times New Roman')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/timesbi.ttf`, 'Times New Roman')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/timesi.ttf`, 'Times New Roman')
    window.Stimulsoft.Base.StiFontCollection.addOpentypeFontFile(`${this.$config.api}/stimulsoftreports/fonts/times.ttf`, 'Times New Roman')

    let options = new window.Stimulsoft.Designer.StiDesignerOptions()
    options.appearance.fullScreenMode = true
    options.toolbar.showSendEmailButton = true
    options.appearance.allowChangeWindowTitle = false

    let designer = new window.Stimulsoft.Designer.StiDesigner(options, 'StiDesigner_' + this.componentId, false)

    window.Stimulsoft.Helper = new StiHelper(`${this.$config.api}/stimulsoftreports/handler.php`, 300)
    designer.onBeginProcessData = function (event, callback) {
      window.Stimulsoft.Helper.process(arguments[0], arguments[1])
    }

    designer.onSaveReport = function (event) {
      window.Stimulsoft.Helper.process(arguments[0], arguments[1])
    }

    window.StiOptions.Export.Excel.AllowExportDateTime = true

    let report = new window.Stimulsoft.Report.StiReport()
    report.loadFile(`${this.$config.api}/reports/${this.filename}`)

    let databases = report.getDictionary().databases
    if (!databases.getByName('Акцент')) {
      let database = new window.Stimulsoft.Report.Dictionary.StiPostgreSQLDatabase('Акцент', 'Акцент')
      report.getDictionary().databases.add(database)
    }

    let response = await this.$http.get(`${this.$config.api}/reporteditor/reports/sources/${this.reportId}`)
    let sourceCollection = report.dataSources
    if (Array.isArray(response.data) && response.data.length > 0) {
      response.data.forEach((item) => {
        if (!sourceCollection.getByName(item.name)) {
          const source = new window.Stimulsoft.Report.Dictionary.StiPostgreSQLSource('Акцент', item.name, item.alias, item.query, true, false)
          source.dictionary = report.getDictionary()
          sourceCollection.add(source)
        }
      })
    }

    setTimeout(() => {
      designer.report = report
      designer.renderHtml(`stimulsoft_designer_${this.componentId}`)
    }, 1000)
  },
  methods: {
    async loadScripts () {
      await this.loadScript(`${this.$config.api}/stimulsoftreports/scripts/stimulsoft.reports.pack.js`)
      await this.loadScript(`${this.$config.api}/stimulsoftreports/scripts/stimulsoft.reports.maps.pack.js`)
      await this.loadScript(`${this.$config.api}/stimulsoftreports/scripts/stimulsoft.viewer.pack.js`)
      await this.loadScript(`${this.$config.api}/stimulsoftreports/scripts/stimulsoft.designer.pack.js`)
      await this.loadScript(`${this.$config.api}/stimulsoftreports/scripts/stimulsoft.dashboards.pack.js`)
    },

    loadScript (src) {
      return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.onload = () => {
          resolve()
        }
        script.src = src
        document.head.appendChild(script)
      })
    },

    loadStyles () {
      [
        `${this.$config.api}/stimulsoftreports/css/stimulsoft.designer.office2013.whiteblue.css`,
        `${this.$config.api}/stimulsoftreports/css/stimulsoft.viewer.office2013.whiteblue.css`
      ].forEach((src) => {
        let style = document.createElement('link')
        style.rel = 'stylesheet'
        style.href = src
        document.head.appendChild(style)
      })
    }
  }
}
</script>

<style type="text/css">
</style>
