import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { PointStyleImageDTO } from '@/core/domain/model/modules/PointStyleImage';

export namespace PointStyleImageAPI {

    export class AddPointStyleImage implements APIRequest<string> {
        response: string;
        path = '/mapeditor/point_style_images';
        method = HTTPMethod.POST;
        hasFiles = true;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: PointStyleImageDTO) {}
    }

    export class UpdatePointStyleImage implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.POST;
        hasFiles = true;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: PointStyleImageDTO) {
            this.path = `/mapeditor/point_style_images/${params.guid}`;
        }
    }

    export class DeletePointStyleImage implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/point_style_images/${guid}`;
        }
    }

    export class GetPointStyleImages implements APIRequest<PointStyleImageDTO> {
        response: PointStyleImageDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/mapeditor/point_style_images?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetPointStyleImageByGuid implements APIRequest<PointStyleImageDTO> {
        response: PointStyleImageDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/point_style_images/${guid}`;
        }
    }
}