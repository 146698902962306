






















































































import Vue from 'vue'
import ExtendedObject from '../../BIEditor/Models/ExtendedObject'
import Query from '../../BIEditor/Models/Query'
import SourceForm from './SourceForm.vue'
import FilterType from '../Models/FilterType'
import SourceTree from './SourceTree.vue'
import SourceAppendForm from './SourceAppendForm.vue'
import Source from '../Models/Source'
import TagInput from '@/core/infrastructure/components/TagInput.vue'
import ImageUpload from '@/core/infrastructure/components/ImageUpload.vue'
import Node from 'element-ui/packages/tree/src/model/node'

export default Vue.extend({
  name: 'ReportForm',

  props: {
    value: Object,
    activeNode: Object as Node
  },

  components: {
    SourceTree,
    SourceForm,
    SourceAppendForm,
    TagInput,
    ImageUpload
  },

  watch: {
    value: {
      handler: function (value) {
        this.localValue = value
      },
      deep: true
    },

    localValue: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  computed: {
    reportSourceTypes () {
      return [
        {
          id: 'query',
          name: this.$t('report_editor.object_types.query')
        },
        {
          id: 'extended_object',
          name: this.$t('report_editor.object_types.extended_object')
        },
        {
          id: 'registry',
          name: this.$t('report_editor.object_types.registry')
        },
        {
          id: 'xml',
          name: this.$t('report_editor.object_types.xml')
        }
      ]
    }
  },

  data () {
    return {
      localValue: this.value,
      extendedObjects: [],
      queries: [],
      registries: [],
      checked: true,
      listTag: [],
      listRole: [],
      images: [],
      miniatures: [],
      changeId: null,
      formRules: {
        name: [{ required: true, message: 'Введите название', trigger: 'blur' }]
      },
      filterTypeList: [],
      sourceEditor: null,
      appendSource: {
        report_id: null,
        parent_id: null,
        report_source_type_id: 'query',
        properties: {
          id: null,
          name: null,
          alias: null,
          relation: []
        },
        report_filters: [
          { report_filter_type_id: 'parameter', operation_type_id: 'eq', properties: { type: null, field: 'id', value: 'id' } }
        ]
      }
    }
  },

  async mounted () {
    this.extendedObjects = await new ExtendedObject().params({ fields: 'id,name', order: 'name:asc' }).$get()
    this.queries = await new Query().params({ fields: 'id,name', order: 'name:asc' }).$get()
    this.filterTypeList = await new FilterType().$get()
  },

  methods: {
    sourceEdit (data) {
      this.sourceEditor = data
    },

    addSource (parentId = null) {
      let properties: any = { id: null, name: null, alias: null, xsd: null, structure: null }
      if (parentId) {
        properties = {
          id: null,
          name: null,
          alias: null,
          xsd: null,
          structure: null,
          relation: []
        }
      }

      if (parentId === null) {
        this.appendSource = new Source({
          report_id: this.localValue.id,
          parent_id: parentId,
          report_source_type_id: 'query',
          properties: properties,
          report_filters: [
            { report_filter_type_id: 'parameter', operation_type_id: 'eq', properties: { type: null, field: 'id', value: 'rec_id' } }
          ]
        })
      } else {
        this.appendSource = new Source({
          report_id: this.localValue.id,
          parent_id: parentId,
          report_source_type_id: 'query',
          properties: properties
        })
      }

      this.$refs.sourceAppendForm.open()
    },

    async saveSource () {
      let response = await this.appendSource.save()

      this.localValue.report_sources.push(response)

      this.$refs.sourceTree.getTree().append(response, response.parent_id)
    },

    async deleteSource (sourceId, sourceIndex) {
      let source = await Source.find(sourceId)

      if (this.sourceEditor !== null && this.sourceEditor.id === sourceId) {
        this.sourceEditor = null
      }

      this.localValue.report_sources.splice(sourceIndex, 1)

      source.delete()
    },

    multipleValidate () {
      let invalidQuantity = 0

      this.$refs.reportForm.validate(async (valid) => {
        if (!valid) {
          invalidQuantity++
        }
      })

      if (typeof this.$refs.sourceForm !== 'undefined') {
        if (!this.$refs.sourceForm.isValid()) {
          invalidQuantity++
        }
      }

      if (typeof this.$refs.filterForm !== 'undefined') {
        this.$refs.filterForm.forEach((filterForm) => {
          if (!filterForm.isValid()) {
            invalidQuantity++
          }
        })
      }

      return !(invalidQuantity)
    },

    async save () {
      if (this.localValue.report_sources.length > 0) {
        for (let idx = 0; idx < this.localValue.report_sources.length; idx++) {
          const fIdx = this.localValue.report_sources[idx].report_filters.findIndex(
            filter => typeof filter.is_deleted !== 'undefined' && filter.is_deleted === true
          )

          if (fIdx !== -1) {
            this.localValue.report_sources[idx].report_filters.splice(fIdx, 1)
          }
        }
      }

      if (this.multipleValidate()) {
        this.localValue = await this.localValue.save()

        // eslint-disable-next-line vue/no-mutating-props
        this.activeNode.data.name = this.localValue.name
      }
    }
  }
})
