<template>
  <el-form-item :label="label">
    <el-select
      v-model="localModel"
      clearable
      :multiple="true"
      collapse-tags
      @clear="$emit('input', localModel)"
      @change="$emit('input', localModel)">
      <el-option
        v-for="(item, index) in geometryTypes"
        :key="index"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  name: 'geometryType',

  props: ['value', 'label'],

  data () {
    return {
      localModel: this.value || [],
      geometryTypes: []
    }
  },

  async mounted () {
    let types = await this.$http.get(`${this.$config.api}/mapeditor/geometry_types`)
    this.geometryTypes = types.data.map(el => ({ id: el.id, name: this.$locale.map_editor.geometry_types[el.name] || el.name }))
  }
}
</script>

<style scoped>

</style>
