import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { CommandDTO } from '@/services/LogicEditor/domain/model/Command'
import CommandUpdateCommand from '@/services/LogicEditor/application/command/CommandUpdateCommand'
import CommandCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/CommandCommandRepositoryInterface'

export default class CommandUpdateHandler implements CommandHandlerInterface {
    private repository: CommandCommandRepositoryInterface;

    constructor (repository: CommandCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: CommandUpdateCommand): Promise<void> {
      let item: CommandDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        is_timer: command.getIsTimer(),
        period: command.getPeriod(),
        is_plugin: command.getIsPlugin(),
        is_complex: command.getIsComplex(),
        event_id: command.getEventId(),
        plugin_id: command.getPluginId(),
        target_state_id: command.getTargetStateId(),
        properties: command.getProperties(),
        command_external_states: command.getCommandExternalStates(),
        eds_sign_command_fields: command.getEdsSignCommandFields(),
        command_constraints: command.getCommandConstraints(),
        before_command_id: command.getBeforeCommandId(),
        after_command_id: command.getAfterCommandId(),
        cancel_command_id: command.getCancelCommandId(),
        command_states: command.getCommandStates(),
        child_commands: command.getChildCommands()
      }

      return this.repository.update(item)
    }
}
