





















































import { RasterDTO } from '@/services/RasterLibrary/domain/model/Raster/Raster'
import 'moment/locale/ru'

const moment = require('moment');

export default {
  name: "CoordinateSystemInformationPanel",
  inject: ['getEventBus'],
  props: ['raster'],
  watch: {
    raster: {
      immediate: true, 
      handler () {
        this.dto = new RasterDTO({
          id: this.raster.getId(),
          guid: this.raster.getGuid(),
          name: this.raster.getName(),
          description: this.raster.getDescription(),
          file_path: this.raster.getFilePath(),
          create_date: moment(this.raster.getCreateDate()).format("DD.MM.Y H:mm:ss"),
          format: this.raster.getFormat(),
          scale: this.raster.getScale(),
          coordinate_system: this.raster.getCoordinateSystem(),
          width: this.raster.getWidth(),
          height: this.raster.getHeight(),
          extent_x0: this.raster.getExtentX0(),
          extent_y0: this.raster.getExtentY0(),
          extent_x1: this.raster.getExtentX1(),
          extent_y1: this.raster.getExtentY1(),
          data_type: this.raster.getDataType(),
          compression_method: this.raster.getCompressionMethod(),
          metadata: this.raster.getMetadata(),
          pixel_size: this.raster.getPixelSize(),
          is_valid: this.raster.getIsValid(),
          checking_result: this.raster.getCheckingResult(),
          edit_date: this.raster.getEditDate() == '0001-01-01T00:00:00' ? null: moment(this.raster.getEditDate()).format("DD.MM.Y H:mm:ss")
        });
      }
    }
  },
  computed: {
    extent: function() {
      return this.dto.extent_x0.toString() + ', ' + this.dto.extent_y0.toString() + ', ' + this.dto.extent_x1.toString() + ', ' + this.dto.extent_y1.toString();
    },
    size: function() {
      return this.dto.width.toString() + 'x' + this.dto.height.toString();
    }
  },
  data() {
    return {
      dto: null
    }
  },
  methods: {
    close() {
      this.getEventBus().$emit('showRasterInfoPanel', false); 
    }
  }

}
