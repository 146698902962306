<template>
  <div class="extended_object">
    <el-form ref="form" :rules="rules" label-position="top" label-width="100px" :model="localValue" size="mini" class="extended-form">
      <el-row>
        <el-tabs value="1" class="extended-tabs" type="border-card">
          <el-tab-pane :label="$t('bi_editor.form.tabs.objects')" name="1">
            <el-form-item :label="$t('bi_editor.form.label.name')" prop="name">
              <el-input v-model="localValue.name"></el-input>
            </el-form-item>

            <el-form-item :label="$t('bi_editor.form.label.alias')" prop="alias">
              <el-input v-model="localValue.alias" :placeholder="$t('bi_editor.form.label.alias')"></el-input>
            </el-form-item>

            <el-form-item :label="$t('bi_editor.form.label.description')" prop="alias">
              <el-input v-model="localValue.description" type="textarea" :rows="2" :placeholder="$t('bi_editor.form.label.description')"></el-input>
            </el-form-item>

            <el-form-item>
              <b>{{ entity.name }} (id: {{ entity.id }})</b>
            </el-form-item>

            <el-form-item>
              <el-checkbox :label="$t('bi_editor.form.label.is_materialized')" v-model="localValue.is_materialized"></el-checkbox>
            </el-form-item>

            <el-form-item>
              <el-checkbox :label="$t('bi_editor.form.label.is_group_by_record')" v-model="localValue.is_group_by_record"></el-checkbox>
            </el-form-item>

            <el-form-item>
              <el-checkbox :label="$t('bi_editor.form.label.is_based_on_table')" v-model="localValue.is_based_on_table"></el-checkbox>
            </el-form-item>
          </el-tab-pane>

          <el-tab-pane :label="$t('bi_editor.form.tabs.fields')" name="2">
            <el-row :gutter="10">
              <el-col :span="12">
                <!--<registry-tree :registry-id="localValue.object_id" :multiple-checked="true" type="all-objects" />-->
                <el-form-item label="Выбор полей">
                  <el-scrollbar class="scroll-tree-field">
                    <el-tree
                      class="tree-field tree-fix-button"
                      lazy
                      :load="loadNode"
                      :props="treeProps"
                    >
                      <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span class="node-label">
                          <span class="node-label__name">
                            {{ node.label.length > 32 ? node.label.substring(0, 32) + '...' : node.label }}
                          </span>
                          <span class="node-label__info">
                            (поле: {{ data.id }}, реестр: {{ data.object_id }}, <b>{{ $t('object_editor.entity_types.' + data.entity_type_id) }}</b>)
                          </span>
                        </span>

                        <span>
                          <el-button
                            v-if="!availableAddFieldType.includes(data.entity_type_id)"
                            type="text"
                            size="small"
                            @click="addField(node, data)"
                          >
                            {{ $t('main.button.add') }}
                          </el-button>
                        </span>
                      </span>
                    </el-tree>
                  </el-scrollbar>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <template v-slot:label>
                    <span>{{ $t('bi_editor.form.label.select_field') }}</span>

                    <el-tooltip class="item" effect="dark" content="Add formula" placement="top">
                      <el-button style="float: right;" type="success" icon="el-icon-plus" circle @click="addFormula" size="mini"></el-button>
                    </el-tooltip>
                  </template>

                  <el-scrollbar class="scroll-tree-field">
                    <el-tree
                      class="tree-field"
                      :data="localValue.extended_object_fields"
                      :props="treeProps"
                    >
                      <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span class="node-label">
                          <template v-if="data.field_id">
                            <span class="node-label__name">{{ data.name }}</span>
                            <span class="node-label__info">(id: {{ data.id }}, поле: {{ data.field_id }})</span>
                          </template>

                          <template v-else>
                            <span class="node-label__name">{{ data.name }}</span>
                            <span
                              v-if="data.formula_id"
                              class="node-label__info"
                            >
                              (id: {{ data.id }}, формула: {{ data.formula_id }})
                            </span>

                            <span
                              v-else
                              class="node-label__info"
                            >
                              (настройте формулу)
                            </span>
                          </template>
                        </span>

                        <span>
                          <span>
                            <el-button
                              type="text"
                              size="small"
                              class="text_danger"
                              @click="deleteField(node, data)"
                            >
                              {{ $t('main.button.delete') }}
                            </el-button>
                          </span>
                          |
                          <span>
                            <el-popover
                              placement="right"
                              width="400"
                              trigger="click">
                              <el-form-item label-width="200px" :label="$t('bi_editor.form.label.alias')">
                                <el-input v-model="data.name" @input="aliasInput(data)" @blur="aliasBlur(data)" @focus="aliasFocus(data)"></el-input>
                              </el-form-item>

                              <el-form-item :label="$t('bi_editor.form.label.description')" prop="alias">
                                <el-input v-model="data.description" type="textarea" :rows="2" :placeholder="$t('bi_editor.form.label.description')"></el-input>
                              </el-form-item>

                              <el-form-item label-width="200px" :label="$t('bi_editor.form.label.sort_order')">
                                <el-input-number v-model="data.sort_order" :min="0" :max="999"></el-input-number>
                              </el-form-item>

                              <el-form-item label-width="200px" :label="$t('bi_editor.form.label.function_type')">
                                <el-select v-model="data.function_type_id" clearable @clear="data.function_type_id = null">
                                  <el-option v-for="itemFun in functionTypes" :key="itemFun.id" :label="$t('bi_editor.function_types.' + itemFun.name)" :value="itemFun.id"></el-option>
                                </el-select>
                              </el-form-item>

                              <template v-if="data.function_type_id">
                                <el-form-item label-width="200px">
                                  <el-checkbox v-model="data.is_distinct">{{ $t('bi_editor.form.label.is_distinct') }}</el-checkbox>
                                </el-form-item>
                              </template>

                              <el-form-item label-width="200px">
                                <el-checkbox v-model="data.group_by">{{ $t('bi_editor.form.label.group_by') }}</el-checkbox>
                              </el-form-item>

                              <el-form-item label-width="200px" :label="$t('bi_editor.form.label.order_by')">
                                <el-select clearable @clear="data.order_by = null" v-model="data.order_by" :placeholder="$t('bi_editor.form.label.order_by')">
                                  <el-option
                                    v-for="(itemOrder, index) in orderTypes"
                                    :key="index"
                                    :label="$t('bi_editor.order_types.' + itemOrder.name)"
                                    :value="itemOrder.id">
                                  </el-option>
                                </el-select>
                              </el-form-item>

                              <el-form-item label-width="200px">
                                <el-checkbox v-model="data.is_group_filter">{{ $t('bi_editor.form.label.is_group_filter') }}</el-checkbox>
                              </el-form-item>

                              <template v-if="!data.field_id">
                                <div class="formula-box">
                                  <h4>Формула</h4>

                                  <template v-if="data.formula">
                                    <template v-if="data.formula.is_dsl">
                                      <div contenteditable="false" readonly class="calculator_editor" v-html="data.formula.dsl_statement"></div>
                                    </template>
                                    <template v-else>
                                      <div contenteditable="false" readonly class="calculator_editor" v-text="data.formula.sql_statements.select"></div>
                                    </template>
                                  </template>

                                  <el-button type="primary" @click="editFormula(data)" plain size="small">{{ $t('notify_editor.labels.calc') }}</el-button>
                                </div>
                              </template>

                              <el-button type="text" slot="reference" size="small">
                                {{ $t('main.button.edit') }}
                              </el-button>
                            </el-popover>
                          </span>
                        </span>
                      </span>
                    </el-tree>
                  </el-scrollbar>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>

          <el-tab-pane :label="$t('bi_editor.form.tabs.filters')" name="3">
            <el-scrollbar class="scroll-filter">
              <query-builder
                ref="qb"
                v-if="customRules.length > 0 && isValidFilter"
                v-model="localValue.filter"
                :key="localValue.guid || 'newValue'"
                :custom-rules="customRules"
                :field-type-to-operation-type="opers"
                :operation-types="operationTypes"
                :filter-types="filterTypes"
                type="extended_object"
              ></query-builder>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-form>

    <el-dialog
      :visible.sync="dialogNotify"
      title="Редактирование формулы"
      width="60%"
      :modal="true"
      :top='"15"'
      :close-on-click-modal="false"
    >
      <div class="di-card">
        <div class="di-card__body">
          <el-scrollbar style="height: 500px;">
            <formula-form
              ref="formulaForm"
              v-if="formula"
              v-model="formula"
              :object-id="localValue.object_id"
            ></formula-form>
          </el-scrollbar>
        </div>
        <div class="di-card__footer">
          <el-button type="danger" size="small" @click="dialogNotify = false">
            {{ $t('main.button.close') }}
          </el-button>
          <el-button type="primary" size="small" @click="saveFormula()">
            {{ $t('main.button.save') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script type="ts">
import Vue from 'vue'
import Entity from '../../ObjectEditor/Models/Entity'
import FunctionType from '../Models/FunctionType'
import OperationType from '../Models/OperationType'
import FilterType from '../Models/FilterType'
import Join from '../Models/ExtendedObjectJoin'
import Field from '../Models/ExtendedObjectField'
import QueryBuilder from '@/components/Common/QueryBuilder/index.vue'
import FormulaForm from '../../../services/LogicEditor/infrastructure/components/forms/FormulaForm.vue'
import { LogicEditorAPI } from '@/core/infrastructure/api/modules/LogicEditorAPI'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'
import { operatorsToTypes } from '@/components/BIEditor/mook'
import { parseFilters } from '@/components/BIEditor/Render/utils/utils'

export default Vue.extend({
  name: 'UpdateExtendedObject',

  components: {
    QueryBuilder,
    FormulaForm
  },

  props: {
    value: Object,
    rules: Object,
    dataWarehouse: Object
  },

  provide () {
    return {
      getEventBus: () => {},
      getCommandBus: () => {},
      getQueryBus: () => {}
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.localValue = value
      },
      deep: true
    },

    localValue: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    dialogNotify () {
      if (this.dialogNotify === false) {
        this.formula = null
        this.editField = null
      }
    }
  },

  computed: {
    isValidFilter () {
      return !!this.localValue.filter?.logical_operator
    }
  },

  data () {
    return {
      formula: null,
      editField: null,
      localValue: this.value,
      xrefEntityType: [
        'xref_field',
        'xref_outer_field',
        'xref_multi_field'
      ],
      customRules: [],
      filterTypes: [],
      availableAddFieldType: [
        'xref_field', 'xref_multi_field',
        'xref_outer_field', 'file_field',
        'button_field', 'field_group',
        'row_formula_field'
      ],
      entity: {},
      orderTypes: [
        {
          id: 'asc',
          name: 'asc'
        }, {
          id: 'desc',
          name: 'desc'
        }
      ],
      functionTypes: [],
      operationTypes: [],
      treeProps: {
        label: 'name',
        children: 'children',
        isLeaf: 'leaf'
      },
      opers: operatorsToTypes,
      fieldEdit: null,
      propslocal: {
        // новое действие
        isNewAction: true,
        // выбранная бизнес логика
        businesslogic: {},
        // список для построения subAction
        listOpenAction: null,
        // выбранное subAction
        selectSubAction: '',
        // значение полей от сервера
        getAnswer: '',
        getNotifyContent: null
      },
      objectFormula: {
        eof: null,
        isShow: true
      },
      dialogNotify: false
    }
  },

  async mounted () {
    await this.loadFilterTypes()
    this.entity = await Entity.$find(this.localValue.object_id)
    this.functionTypes = await new FunctionType().get()
    this.operationTypes = await new OperationType().get()
    this.customRules = this.buildRules(this.localValue.extended_object_fields)
    this.translateFilters()
  },

  methods: {
    async loadFilterTypes () {
      const filterTypes = await new FilterType().get()
      for (const { id, name } of filterTypes) {
        this.filterTypes.push({
          value: id,
          label: this.$t('main.query_builder.filter_types.' + name)
        })
      }
    },

    translateFilters () {
      if (!this.localValue.filter) {
        this.localValue.filter = {
          logical_operator: 'and',
          children: []
        }

        return
      }

      if (this.localValue.filter?.and || this.localValue.filter?.or) {
        this.localValue.filter = parseFilters(this.localValue.filter, this.localValue.extended_object_fields)
      }
    },

    async editFormula (data) {
      if (data.formula !== null) {
        this.formula = await APIClient.shared.request(new LogicEditorAPI.GetFormulaById(data.formula.id))
      } else {
        this.formula = FormulaDTO.create('extended_object_formula')
        this.formula.name = data.name
        this.formula.object_id = this.localValue.object_id
      }

      this.editField = data
      this.dialogNotify = true
    },

    saveFormula () {
      if (this.formula) {
        this.$refs.formulaForm.validate(async (valid) => {
          if (valid) {
            if (this.formula.guid) {
              await APIClient.shared.request(new LogicEditorAPI.UpdateFormula(this.formula))
              this.formula = await APIClient.shared.request(new LogicEditorAPI.GetFormulaByGuid(data.formula.guid))
              this.editField.formula = this.formula
            } else {
              const location = await APIClient.shared.request(new LogicEditorAPI.CreateFormula(this.formula))
              const formulaGuid = location.replace('/formulas/', '')
              this.formula = await APIClient.shared.request(new LogicEditorAPI.GetFormulaByGuid(formulaGuid))
              this.editField.formula = this.formula
              this.editField.formula_id = this.formula.id
              this.editField = await new Field(this.editField).save()
            }
          } else {
            return false
          }
        })
      }
    },

    addFormula () {
      this.$prompt('Задайте псевдоним новому полю:', 'Добавление формульного поля', {
        confirmButtonText: 'Добавить',
        cancelButtonText: 'Отмена',
        inputValue: null,
        inputPattern: /^[_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]+$/,
        inputErrorMessage: 'Некорректный псевдоним!'
      }).then(async ({ value }) => {
        let valid = true
        this.localValue.extended_object_fields.forEach(field => {
          if (value === field.name) {
            valid = false
            this.$alert('Введённый псевдоним совпадает с псеводнимом ранее добавленного поля!', 'Ошибка', {
              confirmButtonText: 'ОК',
              type: 'warning'
            })
          }
        })

        if (valid) {
          let newData = {
            name: value,
            extended_object_id: this.localValue.id,
            field_id: null,
            formula_id: null,
            function_type_id: null,
            group_by: false,
            order_by: null,
            extended_object_join_id: null,
            row_order: 0,
            sort_order: 0,
            is_group_filter: false,
            is_distinct: false
          }

          let response = await (new Field(newData)).save()

          this.localValue.extended_object_fields.push(response)
        }
      })
    },

    aliasFocus (field) {
      this.fieldEdit = field.name
    },

    aliasBlur (field) {
      if (field.name === null || field.name === '') {
        field.name = this.fieldEdit
      }
    },

    aliasInput (field) {
      field.name = field.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, '')

      this.customRules.forEach((rule) => {
        if (rule.id === field.id) {
          rule.label = `${field.name} (id: ${field.id}, field: ${field.field_id})`

          this.filterChangeName(this.localValue.filter, field)
        }
      })
    },

    filterChangeName (filter, field) {
      console.log('filterChangeName', filter)

      filter.children.forEach(item => {
        if (item.type === 'condition') {
          if (item.query.field_id === field.id) {
            item.query.field = field.name
          }
        }

        if (item.type === 'condition_group') {
          this.filterChangeName(item.query, field)
        }
      })
    },

    checkFilter (filter, ruleId) {
      let result = false

      filter.children.forEach(item => {
        if (item.type === 'condition') {
          if (ruleId === item.query.id) {
            result = true
          }
        } else if (item.type === 'condition_group') {
          result = this.checkFilter(item.query, ruleId)
        }
      })

      return result
    },

    buildRules (fields) {
      const rules = []

      for (const field of fields) {
        const fieldType = field.entity_type_id || 'formula_field'
        const label = fieldType === 'formula_field'
          ? `${field.name} (id: ${field.id}, формула: ${field.formula_id})`
          : `${field.name} (id: ${field.id}, поле: ${field.field_id})`

        rules.push({
          id: field.id, // unique int
          alias: field.name, // unique string
          objectId: null, // int | null
          fieldTypeId: fieldType, // string
          label: label, // string
          operators: this.buildOperators(fieldType) // object[]
        })
      }

      return rules
    },

    buildOperators (fieldTypeId) {
      const operators = []

      this.operationTypes.forEach((operatorType) => {
        if (this.opers[fieldTypeId]?.includes(operatorType.id)) {
          operators.push({
            id: operatorType.id,
            label: this.$t('bi_editor.operation_types.' + operatorType.name)
          })
        }
      })

      return operators
    },

    async addField (node, data) {
      this.$prompt('Задайте псевдоним выбранному полю:', 'Добавление поля', {
        confirmButtonText: 'Добавить',
        cancelButtonText: 'Отмена',
        inputValue: node.data.name.replace(/(\s)+/g, '_').replace(/[^_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]/ug, ''),
        inputPattern: /^[_0-9a-zA-Z\u0410-\u042F\u0430-\u044F]+$/,
        inputErrorMessage: 'Некорректный псевдоним!'
      }).then(async ({ value }) => {
        let valid = true
        this.localValue.extended_object_fields.forEach(field => {
          if (value === field.name) {
            valid = false
            this.$alert('Введённый псевдоним совпадает с псеводнимом ранее добавленного поля!', 'Ошибка', {
              confirmButtonText: 'ОК',
              type: 'warning'
            })
          }
        })

        if (valid) {
          let xrefNode = this.getXrefNode(node)
          let joinId = null
          let response

          if (xrefNode !== null) {
            let joinWithParent = await this.buildJoins(xrefNode)
            let join = this.findJoinByXref(joinWithParent.xref_path)

            if (join !== null) {
              joinId = join.id
            } else {
              response = await (new Join(joinWithParent)).save()
              this.localValue.extended_object_joins = await Join.params({ extended_object_id: this.localValue.id }).get()
              joinId = response.id
            }
          }

          let newData = {
            name: value,
            extended_object_id: this.localValue.id,
            field_id: node.data.id,
            function_type_id: null,
            group_by: false,
            order_by: null,
            extended_object_join_id: joinId,
            row_order: 0,
            sort_order: 0,
            is_group_filter: false,
            is_distinct: false
          }

          response = await (new Field(newData)).save()

          this.localValue.extended_object_fields.push(response)

          let operators = []
          this.operationTypes.forEach(item => {
            if (this.opers[response.entity_type_id].includes(item.id)) {
              operators.push({
                id: item.id,
                label: this.$t('bi_editor.operation_types.' + item.name)
              })
            }
          })

          const fieldType = response.entity_type_id || 'formula_field'
          const label = fieldType === 'formula_field'
            ? `${response.name} (id: ${response.id}, формула: ${response.formula_id})`
            : `${response.name} (id: ${response.id}, поле: ${response.field_id})`

          this.customRules.push({
            id: response.id, // unique int
            alias: response.name, // unique string
            objectId: null, // int | null
            fieldTypeId: fieldType, // string
            label: label, // string
            operators: this.buildOperators(fieldType) // object[]
          })
        }
      })
    },

    deleteField (node, data) {
      this.$confirm(
        'Вы действительно хотите удалить это поле из расширенной таблицы?',
        this.$t('main.message_title.warning'),
        {
          confirmButtonText: this.$t('main.button.delete'),
          cancelButtonText: this.$t('main.button.cancel'),
          type: 'warning'
        }
      ).then(async () => {
        if (this.checkFilter(this.localValue.filter, data.id)) {
          this.$alert('Удалите все фильтры связанные с этим полем!', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'error'
          })
        } else {
          for (const [index, rule] of this.customRules.entries()) {
            if (rule.id === data.id) {
              this.customRules.splice(index, 1)
            }
          }

          data.isDel = true

          await new Field(data).delete()

          this.localValue.extended_object_fields = this.localValue.extended_object_fields.filter(f => !f.isDel)
        }
      })
    },

    getXrefNode (curNode) {
      let node = null

      if (curNode.data !== undefined) {
        if (this.xrefEntityType.includes(curNode.data.entity_type_id)) {
          node = curNode
        } else {
          if (curNode.parent !== null) {
            node = this.getXrefNode(curNode.parent)
          }
        }
      }

      return node
    },

    findJoinByXref (xref) {
      let resJoin = this.localValue.extended_object_joins.find(join => join.xref_path === xref)

      return resJoin !== undefined ? resJoin : null
    },

    buildJoinXrefTest (node) {
      let xref = ''

      if (node !== null) {
        xref = '' + node.data.id

        let parent = this.getXrefNode(node.parent)

        if (parent !== null) {
          xref += '_' + this.buildJoinXrefTest(parent)
        }
      }

      return xref
    },

    async buildJoins (node) {
      let join = null

      if (node !== null) {
        if (node.data !== undefined) {
          let sourceFieldId = null
          let targetFieldId = null

          let xrefFieldId
          node.data.properties.forEach(property => {
            if (property.id === 'xref') {
              xrefFieldId = parseInt(property.value)
            }
          })

          if (['xref_field', 'xref_multi_field'].includes(node.data.entity_type_id)) {
            sourceFieldId = node.data.id
          } else {
            targetFieldId = xrefFieldId
          }

          let response = await Entity.$find(xrefFieldId)

          join = {
            extended_object_id: this.localValue.id,
            source_field_id: sourceFieldId,
            target_object_id: response.object_id,
            target_field_id: targetFieldId,
            xref_id: node.data.id,
            xref_path: this.buildJoinXrefTest(this.getXrefNode(node)),
            is_inner: false
          }

          let parentJoin = null
          let existJoin = null
          if (node.parent !== null) {
            parentJoin = await this.buildJoins(this.getXrefNode(node.parent))
            existJoin = parentJoin !== null ? this.findJoinByXref(parentJoin.xref_path) : null
          }

          if (existJoin !== null) {
            join.parent_id = existJoin.id
          } else {
            join.parent = parentJoin
          }
        }
      }

      return join
    },

    async loadNode (node, resolve) {
      if (node.level === 0) {
        resolve(await this.loadEntities(this.localValue.object_id, node))
      } else {
        if (this.xrefEntityType.includes(node.data.entity_type_id)) {
          let response = {}
          response = await Entity.$find(node.data.id)
          let propertyFieldId
          response.properties.forEach(item => {
            if (item.id === 'xref') {
              propertyFieldId = item.value !== null ? parseInt(item.value) : null
            }
          })
          if (propertyFieldId !== null) {
            response = await Entity.$find(propertyFieldId)

            resolve(await this.loadEntities(response.object_id, node))
          } else {
            console.log('Не проставлена ссылка!')
          }
        } else {
          resolve(await this.loadEntities(node.data.id, node))
        }
      }
    },

    async loadEntities (entityId, node) {
      const data = await new Entity({ id: entityId }).children().$get()

      data.forEach((entity) => {
        if (this.xrefEntityType.includes(entity.entity_type_id)) {
          entity.leaf = false
        }

        entity.properties.forEach(property => {
          if (property.id === 'xref' && property.value === null) {
            entity.leaf = true
          }
        })
      })

      return data
    },

    validate (callback) {
      this.$refs.form.validate(callback)
    }
  }
})
</script>

<style lang="scss">
  .tree-fix-button .custom-tree-node .el-button {
    position: absolute;
    right: 0;
    /* margin: 10px 10px 10px 10px; */
    margin-top: -12px;
    background-color: #fff;
    padding: 5px 10px 5px 10px;
  }

  .tree-fix-button .el-tree-node__content:hover > .custom-tree-node .el-button.el-button--text,
  .tree-fix-button .el-tree-node.is-current > .el-tree-node__content > .custom-tree-node .el-button.el-button--text {
    background-color: #F5F7FA !important;
  }

  .scroll-tree-field {
    border: 1px solid #e6e6e6;
    height: calc(100vh - 350px);
  }

  .scroll-filter {
    height: calc(100vh - 289px);
  }

  .tree-field {
    padding: 10px;
  }

  .el-form-item.is-error .tree-field {
    border-color: #F56C6C;
  }

  .bi_editor .el-select {
    display: block;
  }

  .bi_editor .extended-form {
    margin-top: -21px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;

    z-index: 0;
  }

  .bi_editor .extended-tabs .el-tabs__content {
    padding: 15px;
  }

  .bi_editor .el-tabs--border-card {
    box-shadow: none;
  }

  .formula-box {
    margin-bottom: 15px;
  }

  .formula-box > h4 {
    margin: 0;
    padding-bottom: 10px;
    line-height: 32px;
  }

  .formula-box > div {
    margin-bottom: 10px;
    border: 1px solid #DCDFE6;
    min-height: 70px;
    border-radius: 4px;
    padding: 10px;
    font-family: 'Courier New', sans-serif;
    color: #409EFF;
  }

  .extended_object {
    .el-dialog__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .el-dialog {
      margin: 0;

      .el-dialog__body {
        padding: 0;
      }
    }
  }

  .di-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .el-scrollbar__view {
      padding: 20px;
    }

    &__body, &__footer {
      border-top: 1px solid #efefef;
    }

    &__body {
      height: 100%;
    }

    &__footer {
      padding: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
</style>
