import { Nullable } from '@/core/domain/type/types'

export interface IAPIRule {
  id?: Nullable<number>
  guid?: Nullable<string>
  name: Nullable<string>
  object_id: Nullable<number>
  related_record_field_id: Nullable<number>
  rule_states: Nullable<Array<number>>
  rule_target_registry_states: Nullable<Array<number>>
  rule_task_registry_states: Nullable<Array<number>>
  rule_close_commands: Nullable<Array<number>>
  state_match_type: Nullable<string>
  close_match_type: Nullable<string>
  target_registry_state_match_type: Nullable<string>
  task_registry_state_match_type: Nullable<string>
  is_divided_by_executors: Nullable<boolean>
  rule_fields: Nullable<Array<object>>
  parent_tree_element_id?: Nullable<number>
}

export default class Rule {
  public id?: Nullable<number>
  public guid?: Nullable<string>
  public name: Nullable<string>
  public object_id: Nullable<number>
  public related_record_field_id: Nullable<number>
  public rule_states: Nullable<Array<number>>
  public rule_target_registry_states: Nullable<Array<number>>
  public rule_task_registry_states: Nullable<Array<number>>
  public rule_close_commands: Nullable<Array<number>>
  public state_match_type: Nullable<string>
  public close_match_type: Nullable<string>
  public target_registry_state_match_type: Nullable<string>
  public task_registry_state_match_type: Nullable<string>
  public is_divided_by_executors: Nullable<boolean>
  public rule_fields: Nullable<Array<object>>
  public parent_tree_element_id?: Nullable<number>

  constructor (rule: IAPIRule) {
    this.id = rule.id
    this.guid = rule.guid
    this.name = rule.name
    this.object_id = rule.object_id
    this.related_record_field_id = rule.related_record_field_id
    this.rule_states = rule.rule_states
    this.rule_target_registry_states = rule.rule_target_registry_states
    this.rule_task_registry_states = rule.rule_task_registry_states
    this.rule_close_commands = rule.rule_close_commands
    this.state_match_type = rule.state_match_type
    this.close_match_type = rule.close_match_type
    this.target_registry_state_match_type = rule.target_registry_state_match_type
    this.task_registry_state_match_type = rule.task_registry_state_match_type
    this.is_divided_by_executors = rule.is_divided_by_executors
    this.rule_fields = rule.rule_fields
    this.parent_tree_element_id = rule.parent_tree_element_id
  }

  static create (name: Nullable<string> = null): Rule {
    return new Rule({
      name: name,
      object_id: null,
      related_record_field_id: null,
      is_divided_by_executors: false,
      state_match_type: 'all',
      rule_states: [],
      close_match_type: 'all',
      rule_close_commands: [],
      target_registry_state_match_type: 'all',
      rule_target_registry_states: [],
      task_registry_state_match_type: 'all',
      rule_task_registry_states: [],
      rule_fields: [],
      parent_tree_element_id: null
    })
  }
}
