import Vue from 'vue'
import Vuex from 'vuex'

// Main modules
import Authorization from './modules/Authorization'
import Notify from './modules/Notify'
import Socket from './modules/Socket'
import Tabs from './modules/Tabs'
import ConstructorMode from './modules/ConstructorMode'

// AccessEditor modules
import Role from '../services/AccessEditor/infrastructure/store/modules/Role'
import SmevSetting from '../services/AccessEditor/infrastructure/store/modules/SmevSetting'
import User from '../services/AccessEditor/infrastructure/store/modules/User'

// ActivityService modules
import Activity from '../services/ActivityService/infrastructure/store/modules/Activity'
import Event from '../services/ActivityService/infrastructure/store/modules/Event'

// AddressService modules
import Address from '../services/AddressService/infrastructure/store/modules/Address'
import Region from '../services/AddressService/infrastructure/store/modules/Region'

// Core modules
import PointStyleImage from '../core/infrastructure/store/modules/PointStyleImage'
import StrokeStyle from '../core/infrastructure/store/modules/StrokeStyle'

// Databus modules
import Dag from '../services/Databus/infrastructure/store/modules/Dag'
import DatabusEvent from '../services/Databus/infrastructure/store/modules/DatabusEvent'
import DatabusTask from '../services/Databus/infrastructure/store/modules/DatabusTask'
import Package from '../services/Databus/infrastructure/store/modules/Package'
import Route from '../services/Databus/infrastructure/store/modules/Route'
import Topic from '../services/Databus/infrastructure/store/modules/Topic'
import Setting from '../services/Databus/infrastructure/store/modules/Setting'

// EtlEditor modules
import Extractor from '../services/EtlEditor/infrastructure/store/modules/Extractor'
import Group from '../services/EtlEditor/infrastructure/store/modules/Group'
import Loader from '../services/EtlEditor/infrastructure/store/modules/Loader'
import Mapping from '../services/EtlEditor/infrastructure/store/modules/Mapping'
import Task from '../services/EtlEditor/infrastructure/store/modules/Task'
import Template from '../services/EtlEditor/infrastructure/store/modules/Template'
import Transformer from '../services/EtlEditor/infrastructure/store/modules/Transformer'
import TreeElement from '../services/EtlEditor/infrastructure/store/modules/TreeElement'

// History modules
import History from '../services/History/infrastructure/store/modules/History'

// LogicEditor modules
import Approval from '../services/LogicEditor/infrastructure/store/modules/Approval'
import ApprovalStage from '../services/LogicEditor/infrastructure/store/modules/ApprovalStage'
import Command from '../services/LogicEditor/infrastructure/store/modules/Command'
import Constraint from '../services/LogicEditor/infrastructure/store/modules/Constraint'
import Formula from '../services/LogicEditor/infrastructure/store/modules/Formula'
import Listener from '../services/LogicEditor/infrastructure/store/modules/Listener'
import Logic from '../services/LogicEditor/infrastructure/store/modules/Logic'
import LogicGroup from '../services/LogicEditor/infrastructure/store/modules/LogicGroup'
import LogicTreeElement from '../services/LogicEditor/infrastructure/store/modules/LogicTreeElement'
import Plugin from '../services/LogicEditor/infrastructure/store/modules/Plugin'
import RelatedObject from '../services/LogicEditor/infrastructure/store/modules/RelatedObject'
import RowFormula from '../services/LogicEditor/infrastructure/store/modules/RowFormula'
import State from '../services/LogicEditor/infrastructure/store/modules/State'

// MapEditor modules
import AffineTransformation from '../services/MapEditor/infrastructure/store/modules/AffineTransformation'
import CoordinateSystem from '../services/MapEditor/infrastructure/store/modules/CoordinateSystem'
import Entity from '../services/MapEditor/infrastructure/store/modules/Entity'
import GeoJson from '../services/MapEditor/infrastructure/store/modules/GeoJson'
import Layer from '../services/MapEditor/infrastructure/store/modules/Layer'
import LayerGroup from '../services/MapEditor/infrastructure/store/modules/LayerGroup'
import LayerTreeElement from '../services/MapEditor/infrastructure/store/modules/LayerTreeElement'
import Project from '../services/MapEditor/infrastructure/store/modules/Project'
import ProjectGroup from '../services/MapEditor/infrastructure/store/modules/ProjectGroup'
import ProjectTreeElement from '../services/MapEditor/infrastructure/store/modules/ProjectTreeElement'
import Source from '../services/MapEditor/infrastructure/store/modules/Source'
import Style from '../services/MapEditor/infrastructure/store/modules/Style'
import Feature from '../services/MapEditor/infrastructure/store/modules/Feature'
import Selection from '../services/MapEditor/infrastructure/store/modules/Selection'

// RasterLibrary modules
import Raster from '../services/RasterLibrary/infrastructure/store/modules/Raster'

// SyncService modules
import Snapshot from '../services/SyncService/infrastructure/store/modules/Snapshot'
import Migration from '../services/SyncService/infrastructure/store/modules/Migration'
import ConfigurationElement from '../services/SyncService/infrastructure/store/modules/ConfigurationElement'

// TaskEditor modules
import Rule from '../services/TaskEditor/infrastructure/store/modules/Rule'
import RuleGroup from '../services/TaskEditor/infrastructure/store/modules/RuleGroup'
import RuleTreeElement from '../services/TaskEditor/infrastructure/store/modules/RuleTreeElement'
import TaskRegistryEntity from '../services/TaskEditor/infrastructure/store/modules/TaskRegistryEntity'

// ReleaseEditor modules
import Release from '../services/ReleaseEditor/infrastructure/store/modules/Release'
import Service from '../services/ReleaseEditor/infrastructure/store/modules/Service'
import BindService from '../services/ReleaseEditor/infrastructure/store/modules/BindService'
import Deployment from '../services/ReleaseEditor/infrastructure/store/modules/Deployment'

// NotificationEditor
import NotificationEditor from '../services/NotificationEditor/infrastructure/store/index'

// LogService
import Logs from '../services/LogService/infrastructure/store/modules/Logs/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // Main
    Authorization,
    Notify,
    Socket,
    Tabs,
    ConstructorMode,

    // AccessEditor
    Role,
    SmevSetting,
    User,

    // ActivityService
    Activity,
    Event,

    // AddressService
    Address,
    Region,

    // Core
    PointStyleImage,
    StrokeStyle,

    // Databus
    Dag,
    DatabusEvent,
    DatabusTask,
    Package,
    Route,
    Topic,
    Setting,

    // EtlEditor
    Extractor,
    Group,
    Loader,
    Mapping,
    Task,
    Template,
    Transformer,
    TreeElement,

    // History
    History,

    // LogicEditor
    Approval,
    ApprovalStage,
    Command,
    Constraint,
    Formula,
    Listener,
    Logic,
    LogicGroup,
    LogicTreeElement,
    Plugin,
    RelatedObject,
    RowFormula,
    State,

    // MapEditor
    AffineTransformation,
    CoordinateSystem,
    Entity,
    GeoJson,
    Layer,
    LayerGroup,
    LayerTreeElement,
    Project,
    ProjectGroup,
    ProjectTreeElement,
    Source,
    Style,
    Feature,
    Selection,

    // RasterLibrary
    Raster,

    // SyncService
    Snapshot,
    Migration,
    ConfigurationElement,

    // TaskEditor
    Rule,
    RuleGroup,
    RuleTreeElement,
    TaskRegistryEntity,

    //ReleaseEditor
    Release,
    Service,
    BindService,
    Deployment,

    // LogService
    Logs,

    ...NotificationEditor
  }
})
