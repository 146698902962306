<template>
  <el-form label-width="120px" label-position="top" size="mini" class="editor_calendar">
    <!-- {{value}} -->
    <el-form-item class="checkbox">
      <el-checkbox v-model="value.time"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_calendar.time }}</span>
    </el-form-item>

    <div v-show="value.time">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.time_from }}</span>
        <el-input type="text" size="small" v-model.trim="value.timeFrom" placeholder="8"></el-input>
      </el-form-item>
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.time_to }}</span>
        <el-input type="text" size="small" v-model.trim="value.timeTo" placeholder="23"></el-input>
      </el-form-item>
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.step_time }}</span>
        <el-input type="text" size="small" v-model.trim="value.stepTime" placeholder="30"></el-input>
      </el-form-item>
    </div>

    <el-form-item class="checkbox">
      <el-checkbox v-model="value.mini"></el-checkbox>
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_calendar.mini }}</span>
    </el-form-item>

    <el-button type="text" @click="dialogSettingsVisible = true">
      {{ $locale.interface_editor.component_editor.editor_calendar.disable_views }}
    </el-button>

    <el-dialog
      :title="$locale.interface_editor.component_editor.editor_calendar.disable_views"
      :modal="false"
      width="20%"
      :visible.sync="dialogSettingsVisible"
    >
      <el-checkbox-group v-model="value.disableViews">
        <el-checkbox style="display: block" v-for="item in controlsViews" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
      </el-checkbox-group>
    </el-dialog>

    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.active_view }}</span>
      <editor-select
        :value="value.activeView"
        @change="$set(value ,'activeView', $event)"
        :options="{
          multiple: false,
          options: controlsViews
        }"
      ></editor-select>
    </el-form-item>

    <el-form-item>
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.source_type }}</span>
      <editor-select
        :value="value.type"
        @change="$set(value ,'type', $event)"
        :options="{
          multiple: false,
          options: [
            { id: 'registry', name: 'Реестр' },
            { id: 'extend_object', name: 'Расширенная таблица' },
            { id: 'requests', name: 'Запросы' }
          ]
        }"
      ></editor-select>
    </el-form-item>

    <el-form-item v-if="value.type === 'registry'">
      <!-- <span class="property_label">{{ $locale.interface_editor.component_editor.table.registry }}</span> -->
      <!-- <editor-registry-select :value="value.registryId" @change="$set(value ,'registryId', $event)"></editor-registry-select> -->
      <registry-select
        :label="$locale.interface_editor.component_editor.editor_open_card.select_registry"
        :value="value.registryId"
        @change="$set(value ,'registryId', $event)"
      ></registry-select>
    </el-form-item>

    <el-form-item v-if="value.type === 'extend_object'">
      <span class="property_label">{{ $locale.interface_editor.component_editor.table.extended_object }}</span>
      <editor-select
        :value="value.extendedObjectId"
        @change="$set(value ,'extendedObjectId', $event)"
        :options="{multiple: false, options: extendObjects}"
      ></editor-select>
    </el-form-item>

    <template v-if="value.type === 'registry' || value.type === 'extend_object'">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.start }}</span>
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.events.start"/>
      </el-form-item>

      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.end }}</span>
        <selectTree class="extraLine_select" :clearable="true" :standartTree='false' :dataTable="listField" v-model="value.events.end"/>
      </el-form-item>

      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.title }}</span>
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.events.title"/>
      </el-form-item>

      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.content }}</span>
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="value.events.content"/>
      </el-form-item>

      <div style="position: relative">
        <el-tooltip  placement="top-start" effect="dark" style="position: absolute; left: 35px; top: 7px">
          <div slot="content" v-html="`blue<br>orange<br>yellow<br>red<br>`"></div>
          <i class="el-icon-question"></i>
        </el-tooltip>

        <el-form-item>
          <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.class }}</span>
          <selectTree class="extraLine_select" :clearable="true" :standartTree='false' :dataTable="listField" v-model="value.events.class"/>
        </el-form-item>
      </div>
    </template>

    <template v-if="value.type === 'requests'">
      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.table.request }}</span>
        <editor-select
          :value="value.requestsId"
          @change="$set(value ,'requestsId', $event)"
          :options="{
            multiple: false,
            options: requests
          }"
        ></editor-select>
      </el-form-item>

      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.start }}</span>
        <el-input type="text" size="small" v-model="value.events.start"></el-input>
      </el-form-item>

      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.end }}</span>
        <el-input type="text" size="small" v-model="value.events.end"></el-input>
      </el-form-item>

      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.title }}</span>
        <el-input type="text" size="small" v-model="value.events.title"></el-input>
      </el-form-item>

      <el-form-item>
        <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.content }}</span>
        <el-input type="text" size="small" v-model="value.events.content"></el-input>
      </el-form-item>

      <div style="position: relative">
        <el-tooltip placement="top-start" effect="dark" style="position: absolute; left: 40px; top: 75px: z-index: 100">
          <div slot="content" v-html="`red<br>orange<br>yellow<br>green<br>blue<br>purple<br>grey`"></div>
          <i class="el-icon-question"></i>
        </el-tooltip>

        <el-form-item>
          <span class="property_label">{{ $locale.interface_editor.component_editor.editor_calendar.class }}</span>
          <el-input type="text" size="small" v-model="value.events.class"></el-input>
        </el-form-item>
      </div>
    </template>

    <el-form-item>
      <editor-button-action
        :value='value.action'
        :source-id="sourceId"
        :source-type="sourceType"
        :is-headers="true"
        @change="$set(value, 'action', $event)">
      </editor-button-action>
    </el-form-item>
  </el-form>
</template>

<script>
import EditorSelect from './editor-select'
import EditorOpenCard from './editor-open-card'
import EditorDashboards from './editor-dashboards'
import EditorRegistrySelect from './editor-registry-select'
import Registry from '@/components/Models/EntityModel'
import SelectTree from '@/core/infrastructure/components/TreeSelect'

import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
import EditorButtonAction from '@/components/InterfaceEditor/components/editor/editor-button-action'

export default {
  name: 'editor-calendar',

  props: {
    value: {
      type: Object
    },
    filters: {
      type: Array,
      editor: 'Filters'
    }
  },

  components: {
    EditorButtonAction,
    SelectTree,
    EditorSelect,
    EditorOpenCard,
    EditorDashboards,
    // EditorRegistrySelect,
    RegistrySelect
  },

  data () {
    return {
      listField: [],
      requests: [],
      extendObjects: [],
      dialogSettingsVisible: false,
      controlsViews: [
        {
          id: 'years',
          name: this.$locale.interface_editor.component_editor.editor_calendar.years
        },
        {
          id: 'year',
          name: this.$locale.interface_editor.component_editor.editor_calendar.year
        },
        {
          id: 'month',
          name: this.$locale.interface_editor.component_editor.editor_calendar.month
        },
        {
          id: 'week',
          name: this.$locale.interface_editor.component_editor.editor_calendar.week
        },
        {
          id: 'day',
          name: this.$locale.interface_editor.component_editor.editor_calendar.day
        }
      ]
    }
  },

  computed: {
    sourceType () {
      if (this.value.extendedObjectId) {
        return 'extended_object'
      } else if (this.value.requestsId) {
        return 'query'
      }
      return 'registry'
    },
    sourceId () {
      if (this.value.extendedObjectId) {
        return this.value.extendedObjectId
      } else if (this.value.requestsId) {
        return this.value.requestsId
      }
      return this.value.registryId
    }
  },

  watch: {
    'value.registryId': {
      async handler (id) {
        if (id) {
          let registryTree = await Registry.params({ parent_id: id, show_children: true }).get()
          this.listField = registryTree.data
        }
      },
      immediate: true
    },

    'value.extendedObjectId': {
      async handler (id) {
        if (id) {
          this.listField = await this.getExtendObjects(id)
        }
      },
      immediate: true
    },

    'value.type': {
      async handler (val) {
        this.$set(this.value, 'filters', [])
        this.$set(this.value, 'extendedObjectId', null)
        this.$set(this.value, 'registryId', null)
        this.$set(this.value, 'requestsId', null)
        this.value.events.start = this.value.events.end = this.value.events.title = this.value.events.content = this.value.events.class = null
        this.listField = []
        this.requests = []

        if (val === 'requests') {
          this.requests = await this.getRequests()
        }

        if (val === 'extend_object') {
          this.extendObjects = await this.getExtendObjects()
        }
      }
    },

    value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },

  async mounted () {
    if (this.value.requestsId) {
      this.requests = await this.getRequests()
    }

    if (this.value.extendedObjectId) {
      this.extendObjects = await this.getExtendObjects()
    }
  },

  methods: {
    async getRequests () {
      const response = await this.$http.get(`${this.$config.api}/bieditor/queries`)

      return response.data
    },

    async getExtendObjects (id = null) {
      if (id) {
        const listField = await this.$http.get(`${this.$config.api}/bieditor/extended_object_fields?extended_object_id=${id}`)

        return listField.data.map((el) => ({ id: el.id, name: el.name }))
      }

      const extendObjects = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)

      return extendObjects.data
    }
  }
}
</script>

<style lang="scss">
.editor_calendar .el-form-item__label {
  padding: 0px;
}
.editor_calendar .el-form-item {
  margin-bottom: 10px;
}
.editor_calendar .checkbox .el-form-item__content {
  display: inline-block;
  margin-left: 10px;
}
</style>
