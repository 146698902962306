<template>
  <div class="document_editor">
    <iframe :src="url" width="100%" height="99%" style="border: none" title=""></iframe>

    <el-button
      class="cd-button"
      type="primary"
      :style="{ right: !collapsed ? '0px' : '400px' }"
      :icon="!collapsed ? 'el-icon-caret-left' : 'el-icon-caret-right'"
      @click="collapsePanel">
    </el-button>

    <div class="cd-panel from-right" v-bind:class="{ 'is-visible': collapsed }">
      <div class="cd-panel-container">
        <div class="cd-panel-content">
          <el-input
            v-show="!loading"
            v-model="filterText"
            placeholder="Фильтрация"
            size="mini"
          ></el-input>

          <el-scrollbar class="tree_scroll">
            <el-tree
              ref="tree"
              v-loading="loading"
              :data="fields"
              :props="{ children: 'children', label: 'name' }"
              :filter-node-method="filterNode"
              node-key="id"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <el-tooltip :open-delay="300" class="item" effect="dark" :content="$t('main.button.copy_clipboard')" placement="top">
                  <span class="node-label" v-clipboard:copy="data.id" @click.stop="() => {}">
                    <span class="node-label__name">
                      {{ node.label }}
                    </span>
                    <span class="node-label__info">
                      <template v-if="data.subType">
                        (тип: {{ getTypeName(data.type) }}, подтип: {{ getTypeName(data.subType) }})
                      </template>
                      <template v-else>
                        (тип: {{ getTypeName(data.type) }})
                      </template>
                    </span>
                  </span>
                </el-tooltip>
              </span>
            </el-tree>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentEditor',

  props: {
    reportId: Number,
    filename: String
  },

  computed: {
    url () {
      const filePath = `file:///data/reports/${this.filename}`

      return `${this.$config.api}/documenteditor?file_path=${filePath}`
    }
  },

  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },

  data () {
    return {
      fields: [],
      loading: true,
      collapsed: false,
      filterText: ''
    }
  },

  async mounted () {
    await this.$http
      .get(`${this.$config.api}/reporteditor/report_source_columns/${this.reportId}`)
      .then(response => {
        this.fields.push(...response.data)
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    collapsePanel () {
      this.collapsed = !this.collapsed
    },

    filterNode (value, data) {
      if (!value) {
        return true
      }

      return data.name.indexOf(value) !== -1
    },

    getTypeName (type) {
      switch (type) {
        case 'object':
          return 'объект'
        case 'array':
          return 'массив'
        case 'number':
          return 'число'
        case 'boolean':
          return 'логический'
        case 'string':
          return 'текст'
        default:
          return 'любой'
      }
    }
  }
}
</script>

<style scoped>
.document_editor {
  height: 99%;
  width: 100%;
}

.document_editor .node-label {
  cursor: pointer;
}

.document_editor .node-label:hover {
  color: #4FA9F3;
  text-decoration: underline;
}

.document_editor .el-tree-node__children {
  border-left: 2px solid #4FA9F3;
  margin-left: 5px;
}

.document_editor .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.cd-button {
  position: absolute;
  top: calc(50% - 35px);
  border-radius: 5px 0 0 5px;
  height: 70px;
  width: 10px;
  -webkit-transition: top 1s ease-out 0.5s;
  transition: top 1s ease-out 0.5s;
  padding: 10px;
  text-align: center;
}

.cd-panel {
  visibility: hidden;
  transition: visibility 0s 0.6s;
}

.cd-panel.is-visible {
  visibility: visible;
  transition: visibility 0s 0s;
}

.cd-panel-container {
  position: absolute;
  width: 400px;
  height: 100%;
  top: 0;
  right: 0;

  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0.3s;

  transform: translate3d(100%, 0, 0);

  background-color: #fff;
}

.is-visible .cd-panel-container {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

.document_editor .tree_scroll {
  height: calc(100vh - 70px);
  width: 100%;
}

.document_editor .tree_scroll .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
