






import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import ActivitiesHandler from '@/services/ActivityService/application/handler/query/ActivitiesHandler'
import ActivityRepository from '@/services/ActivityService/infrastructure/domain/repository/ActivityRepository'
import ActivityPanel from '@/services/ActivityService/infrastructure/components/ActivityPanel/index.vue'

import UsersHandler from '@/services/AccessEditor/application/handler/query/UsersHandler'
import UserQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/UserQueryRepository'
import RolesHandler from '@/services/AccessEditor/application/handler/query/RolesHandler'
import RoleQueryRepository from '@/services/AccessEditor/infrastructure/domain/repository/RoleQueryRepository'
import EventsHandler from '@/services/ActivityService/application/handler/query/EventsHandler'
import EventRepository from '@/services/ActivityService/infrastructure/domain/repository/EventRepository'

export default {
  name: 'ActivityService',
  components: {
    ActivityPanel
  },
  data () {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({})
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'ActivitiesQuery': new ActivitiesHandler(
            new ActivityRepository()
          ),
          'UsersQuery': new UsersHandler(
            new UserQueryRepository()
          ),
          'RolesQuery': new RolesHandler(
            new RoleQueryRepository()
          ),
          'EventsQuery': new EventsHandler(
            new EventRepository()
          )
        })
      )
    }
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus () {
      return this.eventBus
    },
    getCommandBus () {
      return this.commandBus
    },
    getQueryBus () {
      return this.queryBus
    }
  },
  mounted () {
  }
}
