<template>
  <div class="window" :style="{zIndex: zIndex}">
    <div class="overlay" @click="closeWindow(false)">
      <div class="content" @click="closeWindow(true)">
        <div class="header">Мастер импорта ЭК</div>
          <div class="settings">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="block">
                  <el-upload
                    ref="upload"
                    action=""
                    list-type="text"
                    :on-remove="handleRemove"
                    :auto-upload="false"
                    :limit=1
                  >
                    <i class="el-icon-upload el-icon-right">Загрузить архив</i>
                  </el-upload>
                </div>
              </el-col>
            </el-row>
          </div>
        <div class="button" @click="sendArchive">Отправить</div>
      </div>
    </div>
  </div>
</template>

<script>
import { PopupManager } from 'element-ui/src/utils/popup'

export default {
  name: 'ImportConfigurationElementsWindow',
  data () {
    return {
      archive: [],
      isClickedOnContent: false,
      zIndex: 0
    }
  },
  mounted () {
    this.zIndex = PopupManager.nextZIndex()
  },
  methods: {
    handleRemove (file, fileList) {
      this.archive = []
    },
    closeWindow (isClickedOnContent) {
      if (isClickedOnContent) {
        this.isClickedOnContent = true
      } else {
        if (this.isClickedOnContent) {
          this.isClickedOnContent = false
        } else {
          this.$emit('update:active', false)
        }
      }
    },
    async sendArchive () {
      let file = this.$refs.upload.uploadFiles[0].raw

      const fd = new FormData()
      fd.append('file', file)

      this.closeWindow(false)

      await this.$http({
        url: this.$config.api + '/syncservice/configuration_elements/import',
        data: fd,
        method: 'POST',
        hideNotification: true
      }).then(data => {
        this.$message({
          message: data,
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
<style scoped src="../../../importForm.scss" lang="scss"></style>
