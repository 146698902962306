<template>
  <el-form ref="form" :rules="rules" label-position="top" label-width="100px" :model="model" size="mini">
    <el-form-item :label="$t('bi_editor.form.label.name')" prop="name">
      <el-input v-model="model.name"></el-input>
    </el-form-item>

    <el-form-item :label="$t('bi_editor.form.label.alias')" prop="alias">
      <el-input v-model="model.alias" :placeholder="$t('bi_editor.form.label.alias')"></el-input>
    </el-form-item>

    <el-form-item :label="$t('bi_editor.form.label.description')" prop="description">
      <el-input v-model="model.description" type="textarea" :rows="2" :placeholder="$t('bi_editor.form.label.description')"></el-input>
    </el-form-item>

    <el-form-item class="code-input" :label="$t('bi_editor.form.label.sql_statement')" prop="sql_statement">
      <el-input :autosize="{ minRows: 6, maxRows: 20}" type="textarea" v-model="model.sql_statement"></el-input>
    </el-form-item>

    <el-form-item>
      <el-checkbox :label="$t('bi_editor.form.label.is_materialized')" v-model="model.is_materialized"></el-checkbox>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'UpdateQuery',

  props: {
    value: Object,

    rules: Object
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    validate (callback) {
      this.$refs.form.validate(callback)
    }
  }
}
</script>

<style>
.code-input .el-textarea textarea {
  color: #409EFF;
  font-family: 'Courier New', sans-serif;
  font-size: 14px;
}
</style>
