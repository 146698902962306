













































































import mixins from 'vue-typed-mixins'
import FilterForm from './FilterForm.vue'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import RelatedObject, { FilterDTO } from '@/services/LogicEditor/domain/model/RelatedObject'
import RelatedObjectByGuidQuery from '@/services/LogicEditor/application/query/RelatedObjectByGuidQuery'
import RelatedObjectCreateCommand from '@/services/LogicEditor/application/command/RelatedObjectCreateCommand'
import RelatedObjectUpdateCommand from '@/services/LogicEditor/application/command/RelatedObjectUpdateCommand'
import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'

export default mixins(FormMixin).extend({
  name: 'RelatedObjectForm',

  components: {
    FilterForm,
    RegistrySelectTree
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['RelatedObject/isRelatedObjectLoading']
    },

    relatedObjectCreated () {
      return this.$store.getters['RelatedObject/getRelatedObjectLocation']
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ],
        object_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.object'), trigger: 'change' }
        ],
        alias_field_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.alias_field'), trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    relatedObjectCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new RelatedObjectByGuidQuery(
            location.replace('/related_objects/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      accordionActive: null,

      isLoaded: false
    }
  },

  async mounted () {
    this.$nextTick(() => {
      this.isLoaded = true
    })
  },

  methods: {
    addFilter () {
      this.model.filters.push({
        name: this.$t('logic_editor_v2.default_values.new_filter'),
        filter_fields: []
      })

      this.model.filters.push(FilterDTO.create(this.$t('logic_editor_v2.default_values.new_filter')))
    },

    removeFilter (index) {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.model.filters.splice(index, 1)
      })
    },

    filterValid () {
      if (this.$refs.filterForm) {
        let cntx = 0

        this.$refs.filterForm.forEach((form) => {
          if (form.validate()) {
            cntx++
          }
        })

        return cntx === this.$refs.filterForm.length
      }

      return true
    },

    changeObject (value) {
      this.model.filters.forEach(filter => {
        filter.filter_fields.forEach(field => {
          field.related_object_field_id = null
        })
      })
    },

    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (this.filterValid() && valid) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let relatedObject = RelatedObject.create(this.value)

      if (this.isNew) {
        return new RelatedObjectCreateCommand(
          relatedObject.getName(),
          relatedObject.getDescription(),
          relatedObject.getObjectId(),
          relatedObject.getAliasFieldId(),
          relatedObject.getSqlStatements(),
          relatedObject.getFilters(),
          relatedObject.getLogicId(),
          relatedObject.getAlias(),
          relatedObject.getParentTreeElementId()
        )
      }

      return new RelatedObjectUpdateCommand(
        relatedObject.getGuid(),
        relatedObject.getName(),
        relatedObject.getDescription(),
        relatedObject.getObjectId(),
        relatedObject.getAliasFieldId(),
        relatedObject.getSqlStatements(),
        relatedObject.getFilters(),
        relatedObject.getAlias()
      )
    }
  }
})
