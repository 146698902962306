


























































































import Treeselect from '@bingosoftnn/vue-treeselect'
import BindServicesPanel from "@/services/ReleaseEditor/infrastructure/components/ReleasesPanel/BindServicesPanel/index.vue";
import ServicesQuery from '@/services/ReleaseEditor/application/query/ServicesQuery'
import ReleaseCreateCommand from '@/services/ReleaseEditor/application/command/ReleaseCreateCommand'
import ReleaseUpdateCommand from '@/services/ReleaseEditor/application/command/ReleaseUpdateCommand'
import ReleaseDeleteCommand from '@/services/ReleaseEditor/application/command/ReleaseDeleteCommand'
import ReleasesQuery from '@/services/ReleaseEditor/application/query/ReleasesQuery'
import Release, { ReleaseDTO } from '@/services/ReleaseEditor/domain/model/Release/Release'


export default {
  name: 'ReleasesPanel',
  components: {
    Treeselect,
    BindServicesPanel
  },
  data () {
    return {
      loading: false,
      servicesList: [],
      releases: [],
      release: null,
      releasesList: [],
      releaseFullName: '',
      releaseDto: null,
      releaseDtoPrev: null,
      releaseDtoParams: [],
      isDuplicate: false,
      informationPanelHeight: 0,
      isCreateReleaseWindowVisible: false,
      releaseTypes: [{
        id: "MAJOR",
        name: this.$locale.release_editor.release_panel.types.major
      }],
      createReleaseRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        type: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  watch: {
    releaseCreated: function (location) {
      this.loadReleases(() => {
        this.closeCreateReleaseWindow();
      });
    },
    isReleaseLoading: (state) => state,
    getSaveReleaseError: function (err) {
      if (err.code == 'duplicate_key_violation_exception') {
        this.isDuplicate = true;
        this.$message({
          message: this.$locale.databus.release_panel.duplicate_release_code,
          type: 'warning'
        });
      }
    }
  },
  computed: {
    isReleaseLoading() {
      return this.$store.getters['Release/isLoading'];
    },
    releaseCreated() {
      return this.$store.getters['Release/getLocation'];
    },
    getSaveReleaseError() {
      return this.$store.getters['Release/getError'];
    }
  },
  methods: {
    async loadServices(callback) {
      await this.getQueryBus().execute(
        new ServicesQuery({})
      ).then(data => {
        this.servicesList = data;
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    async loadReleases(callback) {
      await this.getQueryBus().execute(
        new ReleasesQuery({})
      ).then(data => {
        this.releases = data;
        this.releaseDto = this.releases.find(item => item.id == this.releases[0].id);
        this.releaseFullName = this.releases[0].name + ' (версия ' + this.releases[0].version + ')';
        this.releasesList = [];
        for (let i = 0; i < this.releases.length; i++) {
          this.releasesList.push({
            'id': this.releases[i].id,
            'name': this.releases[i].name + ' (версия ' + this.releases[i].version + ')'
          });
        }
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    createRelease() {
      this.releaseDtoPrev = this.releaseDto;
      this.releaseDto = new ReleaseDTO({});
      this.releaseDto.is_complete = false;
      this.releaseDto.type = this.releaseTypes[0].id;
      this.isCreateReleaseWindowVisible = true;
    },
    updateRelease() {
      if (this.releaseDto !== null) {
        if (this.releaseDtoPrev != null) {
          this.releaseDto = this.releaseDtoPrev;
          this.releaseDtoPrev = null;
        }
        this.isCreateReleaseWindowVisible = true;
      }
      else {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    saveRelease() {
      this.releaseDto.start_date = this.$moment(new Date()).format('YYYY-MM-DD H:mm:ss');
      if (this.releaseDto.is_complete) {
        this.releaseDto.complete_date = this.$moment(new Date()).format('YYYY-MM-DD H:mm:ss');
      }
      let release = Release.create(this.releaseDto);
      if (release.getId() == null) {
        this.getCommandBus().execute(
          new ReleaseCreateCommand(
            release.getName(),
            release.getDescription(),
            release.getVersion(),
            release.getType(),
            release.getStartDate(),
            release.getCompleteDate(),
            release.getIsComplete()
          )
        );
      }
      else {
        this.getCommandBus().execute(
            new ReleaseUpdateCommand(
              release.getGuid(),
              release.getName(),
              release.getDescription(),
              release.getVersion(),
              release.getType(),
              release.getStartDate(),
              release.getCompleteDate(),
              release.getIsComplete()
            )
          ).then(() => {
            if (this.isDuplicate) {
              this.isDuplicate = false;
            }
            else {
              this.closeCreateReleaseWindow();
            }
          });
      }
    },
    closeCreateReleaseWindow() {
      this.isCreateReleaseWindowVisible = false;
    },
    changeRelease(data) {
      let releaseDto = this.releases.find(item => item.id == data.id);
      if (typeof releaseDto != 'undefined') {
        this.releaseDto = releaseDto;
        this.releaseFullName = this.releaseDto.name + ' (версия ' + this.releaseDto.version + ')';
      }
      else {
        this.releaseDto = null;
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      }
    },
    deleteRelease() {
      if (this.releaseDto == null) {
        this.$message({
          message: this.$locale.main.message.select_record,
          type: 'warning'
        });
      } else {
        this.$confirm(this.$locale.main.message.confirm, this.$locale.main.message.attention, {
          confirmButtonText: this.$locale.main.button.delete,
          cancelButtonText: this.$locale.main.button.cancel,
          type: 'warning'
        }).then(async () => {
          this.getCommandBus().execute(
            new ReleaseDeleteCommand(
              this.releaseDto.guid
            )
          ).then((response) => {
            this.releaseDto = null;
            this.loadReleases();
          });
        }).catch((error) => { console.log(error); })
      }
    },
  },
  mounted() {
    this.loadServices();
    this.loadReleases();
  }
}
