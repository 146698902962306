<template>
  <div class="logic_tree_elements el-tree-select">
    <el-select
      v-model="checkedValue"
      ref="select"
      class="el-tree-select-input"
      popper-class="select-option"
      :style="{ width: '100%' }"
      :disabled="_disabled"
      :multiple="multiple"
      :size="size"
      :placeholder="placeholder"
      :popper-append-to-body="false"
      :filterable="false"
      :collapse-tags="collapseTags"
      v-popover:popover
      @clear="clear"
      @remove-tag="removeTag"
      @focus="_popoverShowFun"
    >
      <el-option
        v-for="item in options"
        :value="item.value"
        :label="item.label"
        :key="item.value"
      ></el-option>
    </el-select>
    <el-popover
      ref="popover"
      :placement="placement"
      :popper-class="popperClass"
      :width="width"
      v-model="visible"
      trigger="click"
    >
      <el-input v-model="filterText" placeholder="Filter"></el-input>

      <el-scrollbar
        tag="div"
        class="is-empty"
        wrap-class="el-select-dropdown__wrap"
        view-class="el-select-dropdown__list"
      >
        <el-tree
          v-if="isParsed"
          ref="tree"
          node-key="guid"
          :data="elements"
          :props="props"
          :accordion="false"
          :size="size"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          :default-checked-keys="defaultCheckedKeys"
          default-expand-all
          check-on-click-node
          check-strictly
          show-checkbox
          @check-change="checkNode"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span class="node-label" :title="node.label" v-html="getLabel(node, data)"></span>

            <span v-if="!hideActions">
              <el-dropdown
                v-if="['logic', 'group'].indexOf(data.element_type) !== -1"
                @command="addEntity($event, node)"
                trigger="click"
              >
                <el-button
                  class="text_success"
                  type="text"
                  size="mini"
                >
                  {{ $t('main.button.add') }}
                </el-button>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="group">
                    <i :class="icons.group"></i> {{ $t('logic_editor_v2.element_types.group') }}
                  </el-dropdown-item>

                  <el-dropdown-item :command="elementType">
                    <i :class="icons[elementType]"></i> {{ $t('logic_editor_v2.element_types.' + elementType) }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <el-button
                v-else-if="data.element_type === elementType"
                @click.stop.prevent="editEntity(node, data)"
                type="text"
                size="mini"
                class="text_primary"
              >
                {{ $t('main.button.edit') }}
              </el-button>
            </span>
          </span>
        </el-tree>
      </el-scrollbar>
    </el-popover>

    <el-dialog
      :title="modal.title"
      :visible.sync="modal.visible"
      @close="closeEditor()"
      width="50%"
    >
      <span>
        <component
          ref="form"
          v-show="modal.component !== null"
          :is="modal.component"
          v-model="modal.model"
          :logic-id="modal.logicId"
          :object-id="modal.objectId"
        ></component>
      </span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEditor()">
          {{ $t('main.button.close') }}
        </el-button>

        <el-button type="primary" @click="saveEditor()">
          {{ $t('main.button.save') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="ts">
import LogicTreeElementsTreeQuery from '@/services/LogicEditor/application/query/LogicTreeElementsTreeQuery'

import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'

import ApprovalDeleteCommand from '@/services/LogicEditor/application/command/ApprovalDeleteCommand'
import CommandDeleteCommand from '@/services/LogicEditor/application/command/CommandDeleteCommand'
import ConstraintDeleteCommand from '@/services/LogicEditor/application/command/ConstraintDeleteCommand'
import ListenerDeleteCommand from '@/services/LogicEditor/application/command/ListenerDeleteCommand'
import LogicDeleteCommand from '@/services/LogicEditor/application/command/LogicDeleteCommand'
import StateDeleteCommand from '@/services/LogicEditor/application/command/StateDeleteCommand'
import LogicGroupDeleteCommand from '@/services/LogicEditor/application/command/LogicGroupDeleteCommand'
import RelatedObjectDeleteCommand from '@/services/LogicEditor/application/command/RelatedObjectDeleteCommand'
import FormulaDeleteCommand from '@/services/LogicEditor/application/command/FormulaDeleteCommand'

import ApprovalCreateHandler from '@/services/LogicEditor/application/handler/command/ApprovalCreateHandler'
import ApprovalUpdateHandler from '@/services/LogicEditor/application/handler/command/ApprovalUpdateHandler'
import ApprovalDeleteHandler from '@/services/LogicEditor/application/handler/command/ApprovalDeleteHandler'
import RelatedObjectCreateHandler from '@/services/LogicEditor/application/handler/command/RelatedObjectCreateHandler'
import RelatedObjectUpdateHandler from '@/services/LogicEditor/application/handler/command/RelatedObjectUpdateHandler'
import RelatedObjectDeleteHandler from '@/services/LogicEditor/application/handler/command/RelatedObjectDeleteHandler'
import CommandCreateHandler from '@/services/LogicEditor/application/handler/command/CommandCreateHandler'
import CommandUpdateHandler from '@/services/LogicEditor/application/handler/command/CommandUpdateHandler'
import CommandDeleteHandler from '@/services/LogicEditor/application/handler/command/CommandDeleteHandler'
import ConstraintCreateHandler from '@/services/LogicEditor/application/handler/command/ConstraintCreateHandler'
import ConstraintUpdateHandler from '@/services/LogicEditor/application/handler/command/ConstraintUpdateHandler'
import ConstraintDeleteHandler from '@/services/LogicEditor/application/handler/command/ConstraintDeleteHandler'
import ListenerCreateHandler from '@/services/LogicEditor/application/handler/command/ListenerCreateHandler'
import ListenerUpdateHandler from '@/services/LogicEditor/application/handler/command/ListenerUpdateHandler'
import ListenerDeleteHandler from '@/services/LogicEditor/application/handler/command/ListenerDeleteHandler'
import LogicCreateHandler from '@/services/LogicEditor/application/handler/command/LogicCreateHandler'
import LogicUpdateHandler from '@/services/LogicEditor/application/handler/command/LogicUpdateHandler'
import LogicDeleteHandler from '@/services/LogicEditor/application/handler/command/LogicDeleteHandler'
import StateCreateHandler from '@/services/LogicEditor/application/handler/command/StateCreateHandler'
import StateUpdateHandler from '@/services/LogicEditor/application/handler/command/StateUpdateHandler'
import StateDeleteHandler from '@/services/LogicEditor/application/handler/command/StateDeleteHandler'
import LogicGroupCreateHandler from '@/services/LogicEditor/application/handler/command/LogicGroupCreateHandler'
import LogicGroupUpdateHandler from '@/services/LogicEditor/application/handler/command/LogicGroupUpdateHandler'
import LogicGroupDeleteHandler from '@/services/LogicEditor/application/handler/command/LogicGroupDeleteHandler'
import FormulaCreateHandler from '@/services/LogicEditor/application/handler/command/FormulaCreateHandler'
import FormulaUpdateHandler from '@/services/LogicEditor/application/handler/command/FormulaUpdateHandler'
import FormulaDeleteHandler from '@/services/LogicEditor/application/handler/command/FormulaDeleteHandler'

import RelatedObjectCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/RelatedObjectCommandRepository'
import LogicCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicCommandRepository'
import ListenerCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/ListenerCommandRepository'
import CommandCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/CommandCommandRepository'
import ConstraintCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/ConstraintCommandRepository'
import ApprovalCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/ApprovalCommandRepository'
import StateCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/StateCommandRepository'
import LogicGroupCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicGroupCommandRepository'
import FormulaCommandRepository from '@/services/LogicEditor/infrastructure/domain/repository/FormulaCommandRepository'

import ApprovalQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/ApprovalQueryRepository'
import RelatedObjectQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/RelatedObjectQueryRepository'
import LogicQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicQueryRepository'
import ListenerQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/ListenerQueryRepository'
import PluginQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/PluginQueryRepository'
import CommandQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/CommandQueryRepository'
import StateQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/StateQueryRepository'
import LogicGroupQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicGroupQueryRepository'
import ConstraintQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/ConstraintQueryRepository'
import LogicTreeElementQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/LogicTreeElementQueryRepository'
import FormulaQueryRepository from '@/services/LogicEditor/infrastructure/domain/repository/FormulaQueryRepository'

import ApprovalByGuidHandler from '@/services/LogicEditor/application/handler/query/ApprovalByGuidHandler'
import ApprovalStagesByApprovalIdHandler from '@/services/LogicEditor/application/handler/query/ApprovalStagesByApprovalIdHandler'
import ApprovalStageTypesHandler from '@/services/LogicEditor/application/handler/query/ApprovalStageTypesHandler'
import CommandByGuidHandler from '@/services/LogicEditor/application/handler/query/CommandByGuidHandler'
import CommandEventTypePropertiesHandler from '@/services/LogicEditor/application/handler/query/CommandEventTypePropertiesHandler'
import CommandsHandler from '@/services/LogicEditor/application/handler/query/CommandsHandler'
import ListenerByGuidHandler from '@/services/LogicEditor/application/handler/query/ListenerByGuidHandler'
import FormulaByGuidHandler from '@/services/LogicEditor/application/handler/query/FormulaByGuidHandler'
import RelatedObjectByGuidHandler from '@/services/LogicEditor/application/handler/query/RelatedObjectByGuidHandler'
import LogicByGuidHandler from '@/services/LogicEditor/application/handler/query/LogicByGuidHandler'
import LogicHandler from '@/services/LogicEditor/application/handler/query/LogicHandler'
import StateByGuidHandler from '@/services/LogicEditor/application/handler/query/StateByGuidHandler'
import StateOperationTypesHandler from '@/services/LogicEditor/application/handler/query/StateOperationTypesHandler'
import StateOperationTypeEntityTypesHandler from '@/services/LogicEditor/application/handler/query/StateOperationTypeEntityTypesHandler'
import StateFieldTypesHandler from '@/services/LogicEditor/application/handler/query/StateFieldTypesHandler'
import ConditionFieldTypesHandler from '@/services/LogicEditor/application/handler/query/ConditionFieldTypesHandler'
import LogicGroupByGuidHandler from '@/services/LogicEditor/application/handler/query/LogicGroupByGuidHandler'
import ConstraintByGuidHandler from '@/services/LogicEditor/application/handler/query/ConstraintByGuidHandler'
import LogicTreeElementsTreeHandler from '@/services/LogicEditor/application/handler/query/LogicTreeElementsTreeHandler'
import LogicTreeElementByElementGuidHandler from '@/services/LogicEditor/application/handler/query/LogicTreeElementByElementGuidHandler'
import LogicTreeElementByGuidHandler from '@/services/LogicEditor/application/handler/query/LogicTreeElementByGuidHandler'
import LogicTreeElementsHandler from '@/services/LogicEditor/application/handler/query/LogicTreeElementsHandler'
import PluginsHandler from '@/services/LogicEditor/application/handler/query/PluginsHandler'
import CommandEventTypesHandler from '@/services/LogicEditor/application/handler/query/CommandEventTypesHandler'

import ApprovalByGuidQuery from '@/services/LogicEditor/application/query/ApprovalByGuidQuery'
import CommandByGuidQuery from '@/services/LogicEditor/application/query/CommandByGuidQuery'
import LogicByGuidQuery from '@/services/LogicEditor/application/query/LogicByGuidQuery'
import RelatedObjectByGuidQuery from '@/services/LogicEditor/application/query/RelatedObjectByGuidQuery'
import ListenerByGuidQuery from '@/services/LogicEditor/application/query/ListenerByGuidQuery'
import FormulaByGuidQuery from '@/services/LogicEditor/application/query/FormulaByGuidQuery'
import StateByGuidQuery from '@/services/LogicEditor/application/query/StateByGuidQuery'
import LogicGroupByGuidQuery from '@/services/LogicEditor/application/query/LogicGroupByGuidQuery'
import ConstraintByGuidQuery from '@/services/LogicEditor/application/query/ConstraintByGuidQuery'
import LogicTreeElementByElementGuidQuery from '@/services/LogicEditor/application/query/LogicTreeElementByElementGuidQuery'

import { ApprovalDTO } from '@/services/LogicEditor/domain/model/Approval'
import { CommandDTO } from '@/services/LogicEditor/domain/model/Command'
import { ConstraintDTO } from '@/services/LogicEditor/domain/model/Constraint'
import { FormulaDTO } from '@/services/LogicEditor/domain/model/Formula'
import { ListenerDTO } from '@/services/LogicEditor/domain/model/Listener'
import { LogicDTO } from '@/services/LogicEditor/domain/model/Logic'
import { LogicGroupDTO } from '@/services/LogicEditor/domain/model/LogicGroup'
import { RelatedObjectDTO } from '@/services/LogicEditor/domain/model/RelatedObject'
import { RowFormulaDTO } from '@/services/LogicEditor/domain/model/RowFormula'
import { StateDTO } from '@/services/LogicEditor/domain/model/State'

import ApprovalForm from '@/services/LogicEditor/infrastructure/components/forms/ApprovalForm/ApprovalForm.vue'
// import LogicForm from '@/services/LogicEditor/infrastructure/components/forms/LogicForm.vue'
import StateForm from '@/services/LogicEditor/infrastructure/components/forms/StateForm/index.vue'
import LogicGroupForm from '@/services/LogicEditor/infrastructure/components/forms/LogicGroupForm.vue'
import ConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/ConstraintForm.vue'
// import ViewConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/ViewConstraintForm.vue'
// import DisableConstraintForm from '@/services/LogicEditor/infrastructure/components/forms/DisableConstraintForm.vue'
import ListenerForm from '@/services/LogicEditor/infrastructure/components/forms/ListenerForm.vue'
import CommandForm from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/index.vue'
// import RelatedObjectForm from '@/services/LogicEditor/infrastructure/components/forms/RelatedObjectForm.vue'
// import FormulaForm from '@/services/LogicEditor/infrastructure/components/forms/FormulaForm.vue'
// import RowFormulaForm from '@/services/LogicEditor/infrastructure/components/forms/RowFormulaForm.vue'

import Node from 'element-ui/packages/tree/src/model/node'

export default {
  name: 'LogicTreeElements',

  props: {
    value: {
      type: [Number, Array],
      default () {
        return null
      }
    },

    collapseTags: {
      type: Boolean,
      default: true
    },

    hideActions: {
      type: Boolean,
      default: false
    },

    logicId: {
      type: Number,
      default () {
        return null
      }
    },

    objectId: {
      type: Number,
      default () {
        return null
      }
    },

    elementType: {
      type: String,
      validate: function (value) {
        return [
          'state',
          'approval',
          'command',
          'constraint'
        ].indexOf(value) !== -1
      }
    },

    multiple: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: 'Select'
    },

    size: {
      type: String,
      default: 'mini'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    placement: {
      type: String,
      default: 'bottom'
    }
  },

  components: {
    ApprovalForm,
    // LogicForm,
    StateForm,
    LogicGroupForm,
    ConstraintForm,
    // ViewConstraintForm,
    // DisableConstraintForm,
    ListenerForm,
    CommandForm
    // RelatedObjectForm,
    // FormulaForm,
    // RowFormulaForm
  },

  computed: {
    approvalCreated () {
      return this.$store.getters['Approval/getApprovalLocation']
    },

    commandCreated () {
      return this.$store.getters['Command/getCommandLocation']
    },

    constraintCreated () {
      return this.$store.getters['Constraint/getConstraintLocation']
    },

    formulaCreated () {
      return this.$store.getters['Formula/getFormulaLocation']
    },

    listenerCreated () {
      return this.$store.getters['Listener/getListenerLocation']
    },

    logicGroupCreated () {
      return this.$store.getters['LogicGroup/getLogicGroupLocation']
    },

    relatedObjectCreated () {
      return this.$store.getters['RelatedObject/getRelatedObjectLocation']
    },

    rowFormulaCreated () {
      return this.$store.getters['RowFormula/getRowFormulaLocation']
    },

    stateCreated () {
      return this.$store.getters['State/getStateLocation']
    },

    popperClass () {
      return this.disabled ? 'el-tree-select-popper disabled' : 'el-tree-select-popper'
    },

    defaultCheckedKeys () {
      if (this.checkedValue !== null) {
        return this.multiple ? this.checkedValue : [this.checkedValue]
      }
      return []
    },

    _disabled () {
      return this.disabled === true ? this.disabled : this.elements.length === 0
    }
  },

  watch: {
    filterText (value) {
      if (this.$refs.tree) {
        this.$refs.tree.filter(value)
      }
    },

    approvalCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/approvals/', ''))
      }
    },

    commandCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/commands/', ''))
      }
    },

    constraintCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/constraints/', ''))
      }
    },

    formulaCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/formulas/', ''))
      }
    },

    listenerCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/listeners/', ''))
      }
    },

    logicGroupCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/logic_groups/', ''))
      }
    },

    relatedObjectCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/related_objects/', ''))
      }
    },

    rowFormulaCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/row_formulas/', ''))
      }
    },

    stateCreated (location) {
      if (location !== null) {
        this.addElementToTree(location.replace('/states/', ''))
      }
    }
  },

  data () {
    return {
      props: {
        isLeaf: 'is_leaf',
        label: 'name',
        disabled: 'disabled'
      },

      checkedValue: this.multiple ? [] : null,
      localValue: this.value,

      elements: [],
      options: [],

      isParsed: false,

      visible: false,
      width: 250,

      filterText: '',

      icons: {
        logic: 'el-icon-folder',
        group: 'el-icon-folder',
        formula: 'bs-icon-column-formula',
        row_formula: 'bs-icon-row-formula',
        state: 'bs-icon-state',
        listener: 'bs-icon-listener',
        command: 'bs-icon-command',
        constraint: 'bs-icon-constraint',
        view_constraint: 'bs-icon-view-field',
        disable_constraint: 'bs-icon-blocked-field',
        approval: 'bs-icon-approval-stages',
        related_object: 'bs-icon-related-object'
      },

      modal: {
        title: null,
        visible: false,
        component: null,
        parentNode: null,
        model: null,
        logicId: null,
        objectId: null
      },

      defaultModel: {
        approval: ApprovalDTO,
        command: CommandDTO,
        constraint: ConstraintDTO,
        view_constraint: ConstraintDTO,
        disable_constraint: ConstraintDTO,
        formula: FormulaDTO,
        listener: ListenerDTO,
        logic: LogicDTO,
        group: LogicGroupDTO,
        related_object: RelatedObjectDTO,
        row_formula: RowFormulaDTO,
        state: StateDTO
      },

      form: {
        approval: 'ApprovalForm',
        logic: 'LogicForm',
        state: 'StateForm',
        group: 'LogicGroupForm',
        constraint: 'ConstraintForm',
        view_constraint: 'ViewConstraintForm',
        disable_constraint: 'DisableConstraintForm',
        listener: 'ListenerForm',
        command: 'CommandForm',
        related_object: 'RelatedObjectForm',
        formula: 'FormulaForm',
        row_formula: 'RowFormulaForm'
      },

      queryByGuid: {
        approval: ApprovalByGuidQuery,
        logic: LogicByGuidQuery,
        state: StateByGuidQuery,
        group: LogicGroupByGuidQuery,
        constraint: ConstraintByGuidQuery,
        view_constraint: ConstraintByGuidQuery,
        disable_constraint: ConstraintByGuidQuery,
        command: CommandByGuidQuery,
        listener: ListenerByGuidQuery,
        formula: FormulaByGuidQuery,
        related_object: RelatedObjectByGuidQuery
      },

      deleteCommands: {
        logic: LogicDeleteCommand,
        state: StateDeleteCommand,
        group: LogicGroupDeleteCommand,
        approval: ApprovalDeleteCommand,
        command: CommandDeleteCommand,
        listener: ListenerDeleteCommand,
        formula: FormulaDeleteCommand,
        constraint: ConstraintDeleteCommand,
        view_constraint: ConstraintDeleteCommand,
        disable_constraint: ConstraintDeleteCommand,
        related_object: RelatedObjectDeleteCommand
      },

      eventBus: new Vue(),

      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'ApprovalCreateCommand': new ApprovalCreateHandler(
            new ApprovalCommandRepository()
          ),
          'ApprovalUpdateCommand': new ApprovalUpdateHandler(
            new ApprovalCommandRepository()
          ),
          'ApprovalDeleteCommand': new ApprovalDeleteHandler(
            new ApprovalCommandRepository()
          ),
          'RelatedObjectCreateCommand': new RelatedObjectCreateHandler(
            new RelatedObjectCommandRepository()
          ),
          'RelatedObjectUpdateCommand': new RelatedObjectUpdateHandler(
            new RelatedObjectCommandRepository()
          ),
          'RelatedObjectDeleteCommand': new RelatedObjectDeleteHandler(
            new RelatedObjectCommandRepository()
          ),
          'CommandCreateCommand': new CommandCreateHandler(
            new CommandCommandRepository()
          ),
          'CommandUpdateCommand': new CommandUpdateHandler(
            new CommandCommandRepository()
          ),
          'CommandDeleteCommand': new CommandDeleteHandler(
            new CommandCommandRepository()
          ),
          'ConstraintCreateCommand': new ConstraintCreateHandler(
            new ConstraintCommandRepository()
          ),
          'ConstraintUpdateCommand': new ConstraintUpdateHandler(
            new ConstraintCommandRepository()
          ),
          'ConstraintDeleteCommand': new ConstraintDeleteHandler(
            new ConstraintCommandRepository()
          ),
          'ListenerCreateCommand': new ListenerCreateHandler(
            new ListenerCommandRepository()
          ),
          'ListenerUpdateCommand': new ListenerUpdateHandler(
            new ListenerCommandRepository()
          ),
          'ListenerDeleteCommand': new ListenerDeleteHandler(
            new ListenerCommandRepository()
          ),
          'LogicCreateCommand': new LogicCreateHandler(
            new LogicCommandRepository()
          ),
          'LogicUpdateCommand': new LogicUpdateHandler(
            new LogicCommandRepository()
          ),
          'LogicDeleteCommand': new LogicDeleteHandler(
            new LogicCommandRepository()
          ),
          'StateCreateCommand': new StateCreateHandler(
            new StateCommandRepository()
          ),
          'StateUpdateCommand': new StateUpdateHandler(
            new StateCommandRepository()
          ),
          'StateDeleteCommand': new StateDeleteHandler(
            new StateCommandRepository()
          ),
          'LogicGroupCreateCommand': new LogicGroupCreateHandler(
            new LogicGroupCommandRepository()
          ),
          'LogicGroupUpdateCommand': new LogicGroupUpdateHandler(
            new LogicGroupCommandRepository()
          ),
          'LogicGroupDeleteCommand': new LogicGroupDeleteHandler(
            new LogicGroupCommandRepository()
          ),
          'FormulaCreateCommand': new FormulaCreateHandler(
            new FormulaCommandRepository()
          ),
          'FormulaUpdateCommand': new FormulaUpdateHandler(
            new FormulaCommandRepository()
          ),
          'FormulaDeleteCommand': new FormulaDeleteHandler(
            new FormulaCommandRepository()
          )
        })
      ),

      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'ApprovalByGuidQuery': new ApprovalByGuidHandler(
            new ApprovalQueryRepository()
          ),
          'ApprovalStagesByApprovalIdQuery': new ApprovalStagesByApprovalIdHandler(
            new ApprovalQueryRepository()
          ),
          'ApprovalStageTypesQuery': new ApprovalStageTypesHandler(
            new ApprovalQueryRepository()
          ),
          'RelatedObjectByGuidQuery': new RelatedObjectByGuidHandler(
            new RelatedObjectQueryRepository()
          ),
          'StateByGuidQuery': new StateByGuidHandler(
            new StateQueryRepository()
          ),
          'StateOperationTypesQuery': new StateOperationTypesHandler(
            new StateQueryRepository()
          ),
          'StateOperationTypeEntityTypesQuery': new StateOperationTypeEntityTypesHandler(
            new StateQueryRepository()
          ),
          'StateFieldTypesQuery': new StateFieldTypesHandler(
            new StateQueryRepository()
          ),
          'ConditionFieldTypesQuery': new ConditionFieldTypesHandler(
            new StateQueryRepository()
          ),
          'ConstraintByGuidQuery': new ConstraintByGuidHandler(
            new ConstraintQueryRepository()
          ),
          'CommandByGuidQuery': new CommandByGuidHandler(
            new CommandQueryRepository()
          ),
          'CommandEventTypesQuery': new CommandEventTypesHandler(
            new CommandQueryRepository()
          ),
          'CommandEventTypePropertiesQuery': new CommandEventTypePropertiesHandler(
            new CommandQueryRepository()
          ),
          'CommandsQuery': new CommandsHandler(
            new CommandQueryRepository()
          ),
          'ListenerByGuidQuery': new ListenerByGuidHandler(
            new ListenerQueryRepository()
          ),
          'FormulaByGuidQuery': new FormulaByGuidHandler(
            new FormulaQueryRepository()
          ),
          'LogicByGuidQuery': new LogicByGuidHandler(
            new LogicQueryRepository()
          ),
          'LogicQuery': new LogicHandler(
            new LogicQueryRepository()
          ),
          'LogicGroupByGuidQuery': new LogicGroupByGuidHandler(
            new LogicGroupQueryRepository()
          ),
          'LogicTreeElementsTreeQuery': new LogicTreeElementsTreeHandler(
            new LogicTreeElementQueryRepository()
          ),
          'LogicTreeElementByElementGuidQuery': new LogicTreeElementByElementGuidHandler(
            new LogicTreeElementQueryRepository()
          ),
          'LogicTreeElementByGuidQuery': new LogicTreeElementByGuidHandler(
            new LogicTreeElementQueryRepository()
          ),
          'LogicTreeElementsQuery': new LogicTreeElementsHandler(
            new LogicTreeElementQueryRepository()
          ),
          'PluginsQuery': new PluginsHandler(
            new PluginQueryRepository()
          )
        })
      )
    }
  },

  provide () {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },

  async mounted () {
    this._updateH()

    if (this.logicId) {
      await this.loadElements(this.logicId)
    } else if (!this.logicId && this.objectId) {
      this.$http
        .get(`${this.$config.api}/v2/logiceditor/logic_by_entity/${this.objectId}`)
        .then(response => {
          if (response.status === 200) {
            this.loadElements(response.data.id)
          }
        })
    } else {
      await this.loadElements()
    }
  },

  methods: {
    filterNode (value, data) {
      if (!value) {
        return true
      }

      return data.name.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
    },

    getLabel (node, data) {
      const iconClass = this.getTreeIcon(node)
      const icon = iconClass ? `<span class="node-label__icon ${iconClass}"></span>` : ''

      if (data.element_type === 'group') {
        return `${icon}<span class="node-label__name">${node.label}</span>`
      } else if (data.element_type === 'logic') {
        return `${icon}<span class="node-label__name">${node.label}</span><span class="node-label__info">(БЛ: ${data.id}, Реестр: ${data.entity_id})</span>`
      } else {
        return `${icon}<span class="node-label__name">${node.label}</span><span class="node-label__info">(id: ${data.element_id})</span>`
      }
    },

    getTreeIcon (node) {
      if (['group', 'logic'].includes(node.data.element_type)) {
        return !node.expanded
          ? 'el-icon-folder'
          : 'el-icon-folder-opened'
      } else {
        if (this.icons[node.data.element_type]) {
          return [this.icons[node.data.element_type]]
        }
      }

      return null
    },

    addEntity (elementType, node) {
      let logicId = node.data.element_type === 'logic' ? node.data.id : node.data.logic_id

      this.modal = {
        title: this.$t('logic_editor_v2.element_types.' + elementType),
        visible: true,
        component: this.form[elementType],
        parentNode: node,
        model: this.defaultModel[elementType].create(),
        logicId: logicId,
        objectId: node.data.entity_id
      }

      this.modal.model.logic_id = logicId
      this.modal.model.parent_tree_element_id = node.data.id

      return false
    },

    editEntity (node, data) {
      this.queryBus.execute(
        new this.queryByGuid[data.element_type](data.element_guid)
      ).then((response) => {
        this.modal = {
          title: response.name,
          visible: true,
          component: this.form[data.element_type],
          parentNode: node,
          model: response,
          logicId: node.data.logic_id,
          objectId: node.data.entity_id
        }
      })

      return false
    },

    closeEditor () {
      this.modal = {
        title: null,
        visible: false,
        component: null,
        parentNode: null,
        model: null,
        logicId: null,
        objectId: null
      }
    },

    saveEditor () {
      this.$refs.form.submit(() => {
        if (this.modal.model.guid !== 'undefined') {
          this.modal.parentNode.data.name = this.modal.model.name
        }
      })
    },

    addElementToTree (elementGuid) {
      this.queryBus
        .execute(
          new LogicTreeElementByElementGuidQuery(elementGuid)
        )
        .then((response) => {
          this.addNode(response, this.modal.parentNode)
        })
    },

    addNode (data, parentNode) {
      data.disabled = !data.is_leaf

      if (parentNode) {
        this.$refs.tree.append(data, parentNode)
      } else {
        let node = new Node({
          parent: this.$refs.tree.root,
          store: this.$refs.tree.store,
          data: data
        })

        node.level = 1

        this.$refs.tree.root.childNodes.push(node)
      }
    },

    async loadElements (logicId = null) {
      this.elements = await this.queryBus.execute(
        new LogicTreeElementsTreeQuery(this.elementType, logicId)
      )

      if (this.elements.length) {
        this.options = this.buildOptions(this.elements)
      }

      if (this.options.length) {
        this.parseValue()
      } else {
        this.isParsed = true
      }
    },

    _updateH () {
      this.$nextTick(() => {
        this.width = this.$refs.select.$el.getBoundingClientRect().width

        if (this.width < 250) {
          this.width = 250
        }
      })
    },

    _popoverShowFun (val) {
      this._updateH()
    },

    checkNode (node, checked) {
      if (checked) {
        if (this.multiple) {
          this.checkedValue.push(node.guid)
          this.$refs.tree.setCheckedKeys(this.checkedValue)
        } else {
          this.checkedValue = node.guid
          this.$refs.tree.setCheckedKeys([this.checkedValue])
        }
      } else {
        if (this.multiple) {
          let index = this.checkedValue.indexOf(node.guid)
          if (index !== -1) {
            this.checkedValue.splice(index, 1)
          }
        } else {
          if (this.checkedValue === node.guid) {
            this.checkedValue = null
          }
        }
      }

      this.buildValue()
    },

    buildOptions (elements) {
      let options = []
      elements.forEach(element => {
        if (element.element_type === this.elementType) {
          options.push({
            value: element.guid,
            label: element.name,
            element_id: element.element_id
          })
        }

        if (element.children.length) {
          options.push(...this.buildOptions(element.children))
        }
      })
      return options
    },

    buildValue () {
      if (this.multiple) {
        this.localValue = []
        this.checkedValue.forEach(value => {
          let option = this.options.find(item => item.value === value)

          if (option) {
            this.localValue.push(option.element_id)
          }
        })
      } else {
        let option = this.options.find(item => item.value === this.checkedValue)
        this.localValue = option ? option.element_id : null
      }

      this.$emit('change', this.localValue)
      this.$emit('input', this.localValue)
    },

    parseValue () {
      if (this.multiple) {
        this.localValue.forEach(value => {
          let option = this.options.find(item => item.element_id === value)
          if (option) {
            this.checkedValue.push(option.value)
          }
        })
      } else {
        let option = this.options.find(item => item.element_id === this.localValue)
        if (option) {
          this.checkedValue = option.value
        }
      }

      this.isParsed = true
    },

    clear () {
      this.localValue = null
      this.checkedValue = null
      this.$refs.tree.setCheckedKeys([])
      this.$emit('clear')
    },

    removeTag (value) {
      if (this.multiple) {
        let index = this.checkedValue.indexOf(value)
        if (index !== -1) {
          this.checkedValue.splice(index, 1)
        }

        this.$refs.tree.setCheckedKeys(this.localValue)
        this.$emit('remove-tag', value)
      }
    },

    getEventBus () {
      return this.eventBus
    },

    getCommandBus () {
      return this.commandBus
    },

    getQueryBus () {
      return this.queryBus
    }
  }
}
</script>

<style lang="scss" scoped>
  .logic_tree_elements {
    .el-tree {
      //display: flex;
    }
  }

  .el-select-dropdown__wrap .el-tree-node > .el-tree-node__children {
    overflow: visible;
  }
</style>
