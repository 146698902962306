import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class ListenerCreateCommand implements CommandInterface {
  private name: string
  private description: Nullable<string>
  private eventId: number
  private listenerCards: Array<number>
  private listenerCommands: Array<number>
  private logicId: number
  private alias: Nullable<string>
  private parentTreeElementId: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    eventId: number,
    listenerCards: Array<number>,
    listenerCommands: Array<number>,
    logicId: number,
    alias: Nullable<string>,
    parentTreeElementId: Nullable<number>
  ) {
    this.name = name
    this.description = description
    this.eventId = eventId
    this.listenerCards = listenerCards
    this.listenerCommands = listenerCommands
    this.logicId = logicId
    this.alias = alias
    this.parentTreeElementId = parentTreeElementId
  }

  getClassName (): string {
    return 'ListenerCreateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getName (): string {
    return this.name
  }

  getEventId (): number {
    return this.eventId
  }

  getListenerCards (): Array<number> {
    return this.listenerCards
  }

  getListenerCommands (): Array<number> {
    return this.listenerCommands
  }

  getLogicId (): number {
    return this.logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }
}
