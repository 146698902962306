<template>
  <div>
    <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_type.input_type }}</span>
    <!-- <el-tooltip placement="top-start" :open-delay="300" effect="dark">
      <div slot="content" v-html="``"></div>
      <i class="el-icon-question"></i>
    </el-tooltip> -->
    <el-select v-model="localModel.type" :filterable="true" :clearable="true" @change="onChangeType" @clear="localModel.type = null; $emit('change', localModel)">
      <el-option
        v-for="item in typeOptions"
        :key="item.label"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>

    <el-checkbox v-model="localModel.isAdvanced" @change="$emit('change', localModel)">{{ $locale.interface_editor.component_editor.editor_input_type.advanced }}</el-checkbox>
    <div v-if="localModel.isAdvanced">
      <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_type.advanced_type }}</span>
      <el-tooltip placement="top-start" :open-delay="300" effect="dark">
        <div slot="content" v-html="`Для валидации требуется: заполненный Атрибут с сохранением и обновлением вкладки редактора`"></div>
        <i class="el-icon-question"></i>
      </el-tooltip>
      <el-select v-model="localModel.mode" :filterable="true" :clearable="true" @change="onSelectMode" @clear="onClearMode">
        <el-option
          v-for="item in typeAdvancedOptions"
          :key="item.label"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>

      <!-- v-mask -->
      <div v-if="localModel.mode === 'maskSymbolsVmask'">
        <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_type.mask }}</span>
        <el-tooltip placement="top-start" :open-delay="300" effect="dark">
          <div slot="content" v-html="`
            # - Числа (0-9)<br>
            A - Латиница (a-z,A-Z)<br>
            N - Числа и латиница (a-z,A-Z,0-9)<br>
            X - Любой символ<br>
            Я - Кириллица (a-я,A-Я)
          `"></div>
          <i class="el-icon-question"></i>
        </el-tooltip>
        <el-input size="small" v-model="localModel.maskSymbolsVmask" @input="$emit('change', localModel)"></el-input>
      </div>

      <!-- imaskjs -->
      <div v-if="localModel.mode === 'maskSymbolsImask'">
        <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_type.mask }}</span>
        <el-tooltip placement="top-start" :open-delay="500" effect="dark">
          <div slot="content" v-html="`
            0 - Любая цифра<br>
            a - Любая буква<br>
            * - Любой символ<br>
            <br>
            [] - Необязательное<br>
            {} - В значении без маски<br>
            \` - Остановить сдвиг символов (при удалении до \`)<br>
            \\  - Экранировать символ. Например: \\0<br>
            <br>
            Подробнее: https://imask.js.org/guide.html#masked-pattern
          `"></div>
          <i class="el-icon-question"></i>
        </el-tooltip>
        <el-input size="small" v-model="localModel.maskSymbolsImask" @input="$emit('change', localModel)"></el-input>
      </div>

      <!-- <div v-else-if="localModel.mode === 'maskTemplateImask'">
        <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_type.mask_template }}</span>
        <el-select v-model="localModel.maskTemplateImask" :filterable="true" :clearable="true" @change="$emit('change', localModel)" @clear="localModel.maskTemplateImask = null; $emit('change', localModel)">
          <el-option
            v-for="item in maskTemplatesOptions"
            :key="item.label"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div> -->

      <div v-if="['maskSymbolsImask', 'maskTemplateImask'].includes(localModel.mode)">
        <el-checkbox v-model="localModel.isMaskGuide" @change="$emit('change', localModel)">
          {{ $locale.interface_editor.component_editor.editor_input_type.mask_guide }}
          <el-tooltip placement="top-start" :open-delay="500" effect="dark">
            <div slot="content" v-html="`Показывает плейсхолдер места символа для ввода`"></div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </el-checkbox>

        <el-checkbox v-model="localModel.isSaveMasked" @change="$emit('change', localModel)">
          {{ $locale.interface_editor.component_editor.editor_input_type.save_masked }}
          <el-tooltip placement="top-start" :open-delay="500" effect="dark">
            <div slot="content" v-html="`Введённые символы и внутри {} сохраняются.<br>Подробнее в подсказке Маски`"></div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </el-checkbox>

        <el-checkbox v-model="localModel.isValidateMask" @change="$emit('change', localModel)">
          {{ $locale.interface_editor.component_editor.editor_input_type.validate_mask }}
          <el-tooltip placement="top-start" :open-delay="500" effect="dark">
            <div slot="content" v-html="`Символы внутри [] необязательные.<br>Подробнее в подсказке Маски`"></div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </el-checkbox>
      </div>

      <!-- regex -->
      <div v-if="localModel.mode === 'validatorRegex'">
        <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.editor_input_type.regex }}</span>
        <el-tooltip placement="top-start" :open-delay="300" effect="dark">
          <div slot="content" v-html="$locale.interface_editor.component_editor.editor_input_type.tooltip.regex"></div>
          <i class="el-icon-question"></i>
        </el-tooltip>

        <div style="display: flex;">
          <el-input v-model="localModel.validatorRegex" :placeholder="$locale.interface_editor.component_editor.editor_input_type.placeholder.regex" @input="$emit('change', localModel)">
            <el-tooltip slot="append" :content="$locale.interface_editor.component_editor.editor_input_type.tooltip.regex_template" placement="top-start" :open-delay="700" effect="dark">
              <el-select v-model="localModel.validatorTemplateRegex" placeholder="" class="select" @change="changeValidatorRegexByTemplate">
                <el-option
                  v-for="item in regexTemplatesOptions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-tooltip>
          </el-input>

          <el-tooltip :content="$locale.interface_editor.component_editor.editor_input_type.tooltip.regex_flags" placement="top-start" :open-delay="700" effect="dark">
            <div slot="content" v-html="$locale.interface_editor.component_editor.editor_input_type.tooltip.regex_flags"></div>
            <el-input v-model="localModel.validatorRegexFlags" class="regex-flags" :placeholder="$locale.interface_editor.component_editor.editor_input_type.placeholder.regex_flags" @input="$emit('change', localModel)">
            </el-input>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maskTemplatesMixin from '@/mixins/maskTemplatesMixin.js'
import regexTemplatesMixin from '@/mixins/regexTemplatesMixin.js'

export default {
  name: 'editor-input-type',
  mixins: [maskTemplatesMixin, regexTemplatesMixin],
  props: {
    value: {
      type: Object,
      default: () => ({
        isMaskGuide: true,
        isSaveMasked: true
      })
    }
  },
  data () {
    return {
      localModel: this.value,
      typeAdvancedOptions: [
        {
          label: 'Символьная маска (v-mask)',
          value: 'maskSymbolsVmask' // maskmaskSymbolsVmask
        },
        {
          label: 'Символьная маска (imask)',
          value: 'maskSymbolsImask' // maskSymbolsImask
        },
        {
          label: 'Шаблон маски (imask)',
          value: 'maskTemplateImask' // maskTemplateImask
        },
        {
          label: 'Валидатор (Regex)',
          value: 'validatorRegex' // validatorRegex
        },
        {
          label: 'Шаблон валидатора (Regex)',
          value: 'validatorTemplateRegex' // validatorTemplateRegex
        }
      ]
      // maskTemplatesOptions: список выбора в src\mixins\maskTemplatesMixin.js,
      // regexTemplatesOptions: список выбора в src\mixins\regexTemplatesMixin.js,
    }
  },
  computed: {
    typeOptions () {
      return [...this.maskTemplatesOptions, ...this.regexTemplatesOptions]
    }
  },
  watch: {
    value () {
      this.localModel = { ...this.value }
    }
  },
  methods: {
    onChangeType () {
      if (!this.localModel.type) {
        // Изменить "Режим маски/валидации"
        this.localModel.mode = null
        return
      }

      // Определить "Режим маски/валидации" по "Тип поля"
      const maskTemplateImaskOption = this.maskTemplatesOptions.find(maskTemplateOption => maskTemplateOption.value === this.localModel.type)
      const validatorTemplateRegexOption = this.regexTemplatesOptions.find(regexTemplateOption => regexTemplateOption.value === this.localModel.type)
      let mode = null
      if (maskTemplateImaskOption) {
        mode = 'maskTemplateImask'
      }

      if (validatorTemplateRegexOption) {
        mode = 'validatorTemplateRegex'
      }

      // Изменить "Режим маски/валидации"
      this.localModel.mode = mode

      if (mode === 'maskTemplateImask') {
        // Изменить "Шаблон маски (IMask)"
        this.localModel.maskTemplateImask = this.localModel.type
      } else if (mode === 'validatorTemplateRegex') {
        // Изменить "Шаблон валидатора (Regex)"
        this.localModel.validatorTemplateRegex = this.localModel.type

        // Получить регулярное выражение из шаблонов
        const validatorTemplateRegex = this.regexTemplates[this.localModel.validatorTemplateRegex]
        // Изменить "Регулярное выражение"
        this.localModel.validatorRegex = validatorTemplateRegex.value
      }

      this.$emit('change', this.localModel)
    },
    onSelectMode () {
      if (this.localModel.mode === 'maskTemplateImask') {
        this.localModel.type = this.localModel.maskTemplateImask
      }

      if (this.localModel.mode === 'validatorTemplateRegex') {
        this.localModel.type = this.localModel.validatorTemplateRegex
      }

      this.$emit('change', this.localModel)
    },
    onClearMode () {
      this.localModel.mode = null
      this.localModel.type = null

      this.$emit('change', this.localModel)
    },
    changeValidatorRegexByTemplate () {
      // Получить регулярное выражение из шаблонов
      const validatorTemplateRegex = this.regexTemplates[this.localModel.validatorTemplateRegex]

      // Изменить "Регулярное выражение"
      this.localModel.validatorRegex = validatorTemplateRegex.value

      this.$emit('change', this.localModel)
    }
  }
}
</script>

<style scoped>
/* Исправить позицию стрелки select в append в выпадающем списке в el-input */
* /deep/ .el-input-group__append .el-select .el-input {
  width: 40px;
}

.regex-flags {
  margin-left: 5px;
  width: 110px;
}
</style>
