




























































import BasicWidgets from '@/services/InterfaceEditor/domain/model/Components.json'
import { InterfaceType } from '@/services/InterfaceEditor/domain/model/InterfaceType'
import RegistryFields from '@/services/InterfaceEditor/infrastructure/components/Toolbar/RegistryFields.vue'
import TemplatesInterface from '@/services/InterfaceEditor/infrastructure/components/Toolbar/TemplatesInterface.vue'

export default {
  name: 'WidgetList',

  components: { RegistryFields, TemplatesInterface },

  inject: ['getEventBus'],

  props: {
    type: String,
    isShown: Boolean,
    registryId: Number,
    activeBlock: Object
  },

  data () {
    return {
      active: 'basic',
      basicWidgets: BasicWidgets,
      searchText: null,
      widgetTypes: [
        { id: 'basic', name: 'Базовые' }
      ]
    }
  },

  computed: {
    activeTemplate () {
      return this.active === 'template'
    },

    filteredBasicWidgets () {
      let answer = []
      Object.keys(this.basicWidgets.groups).forEach((group) => {
        let object = {
          name: this.basicWidgets.groups[group],
          components: this.basicWidgets.components.filter(cmp => cmp.group === group)
            .filter(cmp => (this.searchText || '').trim().length === 0 ||
              cmp.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
        }
        if (object.components.length > 0) {
          answer.push(object)
        }
      })

      return answer
    }
  },

  mounted () {
    if (this.type === InterfaceType.CARD) {
      this.widgetTypes.push({ id: 'registry_fields', name: 'Поля реестра' })
    }
    this.widgetTypes.push({ id: 'template', name: 'Шаблоны' })
  },

  methods: {
    addWidget (widget, event) {
      this.getEventBus().$emit('addComponent', {
        type: 'basic',
        component: widget.component,
        event: event
      })
      this.getEventBus().$emit('closePopover')
    }
  },

  watch: {
    active () {
      if (this.$refs.search) {
        this.$refs.search.focus()
      }
    },

    isShown (value) {
      if (value && this.$refs.search) {
        this.$refs.search.focus()
      }
    },

    searchText (value) {
      if (this.$refs.registry_fields) {
        this.$refs.registry_fields.$refs.tree.filter(value)
      }
    }
  }
}
