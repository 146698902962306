























// Всплывашка
import store from '@/store/index'
import { getCardId } from '@/helpers'
import mixin from '@/components/System/Notification/mixins/index'
import mixins from 'vue-typed-mixins'

export default mixins(mixin).extend({
  name: 'MessageNotify',
  props: ['val', 'addMainTab'],
  data () {
    return {
      avatarUser: null,
      icon: null,
      interactionType: null,
      loading: false
    }
  },
  methods: {
    // Открыть карточку внешнего реестра
    async openCardOuter (data, dataRegistryByRecord) {
      let { openCard, readOnly, cardId, recordXref } = await this.validationData(data, dataRegistryByRecord)
      if (!openCard) return
      // получить id карточки внешнего реестра
      const card: any = (cardId) ? { id: cardId } : await getCardId(this, { registryId: openCard.external_object_id, recordId: recordXref })
      this.addMainTab({
        name: card?.name || 'Новая карточка',
        componentType: 'RegistryCard',
        payload: {
          cardId: card?.id,
          initialData: {},
          readonly: readOnly,
          recordId: recordXref,
          registryId: openCard.external_object_id
        }
      })
      this.loading = false
    },
    async openCard (data) {
      this.$parent.close()
      store.commit('Notify/readMsg', data.id)
      let dataRegistryByRecord = await this.getDataRegistryByRecord({ registryId: data.notification[0].object_id, recordId: data.record_id })
      if (dataRegistryByRecord.is_deleted) {
        this.$message.error(`Запись удалена из системы`)
        return
      }
      if (!data.notification[0].interaction_type) return false
      // Открыть карточку внешнего реестра
      if (data.notification[0].interaction_type === 'open_external_card') {
        this.openCardOuter(data, dataRegistryByRecord)
        return
      }
      this.loading = true
      let card: any = {}
      try {
        card = await getCardId(this, { registryId: data.notification[0].object_id, recordId: data.record_id })
      } catch (error) {
        console.log({ error })
        this.loading = false
        return
      }
      this.addMainTab({
        name: card?.name || '',
        componentType: 'RegistryCard',
        payload: {
          cardId: card?.id || null,
          initialData: {},
          // readonly: true,
          recordId: data.record_id,
          registryId: data.notification[0].object_id
        }
      })
      this.loading = false
    }
  },
  async created () {
    try {
      let res = await this.$http.get(`${this.$config.api}/notificationeditor/notifications/${this.val.notification_id}`)
      this.icon = res.data.icon
      this.interactionType = res.data.interaction_type
      if (this.val.author[0] && this.val.author[0].avatar_id) {
        let response = await this.$http.get(`${this.$config.api}/registryservice/files/${this.val.author[0].avatar_id}`)
        this.avatarUser = response.data
      }
    } catch (error) {
      console.log(error)
    }
  }
})
