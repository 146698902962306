























import Vue from 'vue'

export default Vue.extend({
  computed: {
    showAuth (): boolean {
      const excludeRoutes: string[] = ['LoginPage', 'OAuthPage', 'EsiaPage', 'FastEsia']

      return !this.$store.getters['Authorization/isAuthenticated'] && !excludeRoutes.includes(this.$route.name)
    }
  },

  data () {
    return {
      hiddenErrors: [
        'record_constraint_forbidden',
        'record_constraint_notify',
        'need_to_sign_record_before_approve_exception'
      ]
    }
  },

  created () {
    if (this.$config.refresh_page_alert_disabled === 0) {
      if (process.env.NODE_ENV !== 'development') {
        let me = this
        window.addEventListener('beforeunload', function (event) {
          event.returnValue = 'Обновление страницы?'
          me.$router.push('/')
        })
      }
    }

    this.$http.interceptors.response.use((response) => {
      if (response.config.method !== 'get' && !response.config.hideNotification) {
        this.$notify({
          title: this.$locale.main.message.success,
          message: this.$locale.main.message.saved,
          type: 'success',
          duration: 2000
        })
      }
      return response
    }, (error) => {
      let me = this
      return new Promise(function (resolve, reject) {
        if (error.config && !error.config.__isRetryRequest) {
          switch (error.response.status) {
            case 401:
              me.$store.dispatch('Authorization/logout')
              break
            case 400:
              if (!error.response.config.hideNotification && !me.hiddenErrors.includes(error.response.data.error)) {
                if (
                  typeof error.response.data.code !== 'undefined' &&
                  error.response.data.code !== 0 &&
                  error.response.data.type === 'client'
                ) {
                  let title = me.$locale.errors[error.response.data.code] || error.response.data.code
                  me.$confirm(error.response.data.message, title, {
                    type: 'error',
                    showCancelButton: false
                  })
                } else {
                  let message = me.$locale.main.message.not_saved
                  if (error.response.data.notify) {
                    message = error.response.data.message || message
                  }
                  me.$notify.error({
                    title: me.$locale.main.message.error,
                    message: message,
                    duration: 2000
                  })
                }
              }
              break
            default:
              break
          }
        }
        throw error
      })
    })
  }
})
