var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{staticClass:"editor_tree",attrs:{"label-width":"120px","label-position":"top","size":"mini"}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.dialogSettingsVisible = true}}},[_vm._v(_vm._s(_vm.$locale.interface_editor.component_editor.editor_tree.button_visibility))]),_c('el-dialog',{attrs:{"title":_vm.$locale.interface_editor.component_editor.editor_tree.button_visibility,"modal":false,"visible":_vm.dialogSettingsVisible},on:{"update:visible":function($event){_vm.dialogSettingsVisible=$event}}},[_c('el-form-item',[_c('editor-conditions',{attrs:{"options":{
          label: _vm.$locale.interface_editor.component_editor.editor_tree.add_btn_hidden,
          sourceRegistry: true,
          fieldsRegistry: _vm.fieldsRegistry
          }},model:{value:(_vm.value.addBtnHidden),callback:function ($$v) {_vm.$set(_vm.value, "addBtnHidden", $$v)},expression:"value.addBtnHidden"}}),_c('editor-conditions',{attrs:{"options":{
          label: _vm.$locale.interface_editor.component_editor.editor_tree.delete_btn_hidden,
          sourceRegistry: true,
          fieldsRegistry: _vm.fieldsRegistry
          }},model:{value:(_vm.value.deleteBtnHidden),callback:function ($$v) {_vm.$set(_vm.value, "deleteBtnHidden", $$v)},expression:"value.deleteBtnHidden"}}),_c('editor-conditions',{attrs:{"options":{
          label: _vm.$locale.interface_editor.component_editor.editor_tree.open_btn_hidden,
          sourceRegistry: true,
          fieldsRegistry: _vm.fieldsRegistry
          }},model:{value:(_vm.value.openBtnHidden),callback:function ($$v) {_vm.$set(_vm.value, "openBtnHidden", $$v)},expression:"value.openBtnHidden"}})],1)],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$locale.interface_editor.component_editor.table.source_type))]),_c('editor-select',{attrs:{"value":_vm.value.type,"options":{
        multiple: false,
        options: [
          { id: 'registry', name: 'Реестр' },
          { id: 'extend_object', name: 'Расширенная таблица' },
          { id: 'requests', name: 'Запросы' }
        ]
      }},on:{"change":function($event){return _vm.$set(_vm.value ,'type', $event)}}})],1),(_vm.value.type === 'registry')?_c('el-form-item',[_c('registry-select',{attrs:{"label":_vm.$locale.interface_editor.component_editor.editor_open_card.select_registry,"value":_vm.value.registryId},on:{"change":function($event){return _vm.$set(_vm.value ,'registryId', $event)}}})],1):_vm._e(),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$locale.interface_editor.component_editor.registry_headers.html_template))]),_c('el-input',{staticClass:"htmlTemplate",attrs:{"type":"textarea","autosize":{ minRows: 3, maxRows: 6}},model:{value:(_vm.value.htmlTemplate),callback:function ($$v) {_vm.$set(_vm.value, "htmlTemplate", $$v)},expression:"value.htmlTemplate"}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$locale.interface_editor.component_editor.editor_tree.limit))]),_c('el-input-number',{attrs:{"controls-position":"right"},model:{value:(_vm.value.limit),callback:function ($$v) {_vm.$set(_vm.value, "limit", $$v)},expression:"value.limit"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }