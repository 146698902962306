

















import EditorFilters from '@/components/InterfaceEditor/components/editor/editor-filters.vue'
export default {

  name: 'editor-add-existing',
  components: {
    EditorFilters
  },
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    localValue: {
      handler () {
        this.$emit('change', this.localValue)
      },
      deep: true
    }
  }
}
