<template>
  <div class="dashboard_admin">
    <div class="wrapper">
      <div class="header">
        <div class="name_header">Настройка системы</div>
        <div class="logo_header"></div>
      </div>
      <!-- <el-scrollbar class="container" wrap-style="overflow-x:hidden;"> -->
      <div class="container">
        <div class="menu">
          <div class="icon icon_data"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: 'Структура данных',
                  componentType: 'ObjectEditor',
                })
              "
            >
              Структура данных
            </div>
            <div class="menu_text">
              Управление структурой реестров и справочников, настройка
              взаимосвязи данных, объединение реестров в тематические группы и
              конструирование пользовательских карточек ввода.
            </div>
          </div>
        </div>
        <!-- columns -->
        <div class="menu">
          <div class="icon icon_interface"></div>
          <div>
            <div class="menu_header non_underline">Интерфейс пользователя</div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Главное меню',
                      componentType: 'MenuEditor',
                    })
                  "
                  >Главное меню</span
                >
                <div class="menu_text">
                  Описание разделов и подразделов бокового меню для различных
                  пользовательских ролей.
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Рабочие экраны (дашборды)',
                      componentType: 'DashboardEditor',
                    })
                  "
                  >Рабочие экраны (дашборды)</span
                >
                <div class="menu_text">
                  Визуальный конструктор экранных форм
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- columns -->
        <div class="menu">
          <div class="icon icon_acess"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: 'Права доступа',
                  componentType: 'AccessEditor_v2',
                })
              "
            >
              Права доступа
            </div>
            <div class="menu_text">
              Управление учетными записями и настройка типовых пользовательских
              ролей.
            </div>
          </div>
        </div>
        <!-- columns -->
        <div class="menu">
          <div class="icon icon_engineering"></div>
          <div>
            <div class="menu_header non_underline">Настройка процессов</div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Бизнес-логика',
                      componentType: 'LogicEditor_v2',
                    })
                  "
                  >Бизнес-логика</span
                >
                <div class="menu_text">
                  Формульные поля, логические состояния, маршруты согласований,
                  пользовательские команды, условия показа и блокировки полей и
                  записей
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Правила создания и закрытия задач',
                      componentType: 'TaskEditor',
                    })
                  "
                  >Правила создания и закрытия задач</span
                >
                <div class="menu_text">
                  Описание условий для назначения и закрытия автоматически
                  создаваемых задач.
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Настройка уведомлений',
                      componentType: 'NotificationEditor',
                    })
                  "
                  >Настройка уведомлений</span
                >
                <div class="menu_text">
                  Правила автоматического уведомления различных групп
                  пользователей о наступлении контрольных событий.
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- columns -->
        <div class="menu">
          <div class="icon icon_source"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: 'Источники данных',
                  componentType: 'BIEditor',
                })
              "
            >
              Источники данных
            </div>
            <div class="menu_text">
              Настройка произвольных источников данных для использования в
              отчетных формах, пользовательских дашбордах и внешних выгрузках.
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_document"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: 'Отчеты и шаблоны документов',
                  componentType: 'ReportEditor',
                })
              "
            >
              Отчеты и шаблоны документов
            </div>
            <div class="menu_text">
              Проектирование отчетных форм, шаблонов автоматически генерируемых
              бланков и xml-выгрузок.
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_map"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({ name: 'Картография', componentType: 'MapEditor' })
              "
            >
              Картография
            </div>
            <div class="menu_text">
              Редактор картографических сервисов, настройка произвольного набора
              слоев, работа с растрами, стилизация карт, подключение внешних
              картографических источников.
            </div>
          </div>
        </div>
        <!-- columns -->
        <div class="menu">
          <div class="icon icon_integration"></div>
          <div>
            <div class="menu_header non_underline">Интеграции</div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Правила импорта-экспорта',
                      componentType: 'EtlEditor',
                    })
                  "
                  >Правила импорта-экспорта</span
                >
                <div class="menu_text">
                  Сопоставление источников для операций выгрузки и загрузки
                  данных.
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Федеральная адресная система',
                      componentType: 'AddressService',
                    })
                  "
                  >Федеральная адресная система</span
                >
                <div class="menu_text">
                  Настройки правил интеграции с федеральными адресными
                  классификаторами.
                </div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Шина данных',
                      componentType: 'Databus',
                    })
                  "
                  >Шина данных</span
                >
                <div class="menu_text"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_sync"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: 'Синхронизация окружений',
                  componentType: 'SyncService',
                })
              "
            >
              Синхронизация окружений
            </div>
            <div class="menu_text">
              Перенос настроек между экземплярами системы
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_logs"></div>
          <div>
            <div
              class="menu_header"
              @click="
                addMainTab({
                  name: 'История пользовательских действий',
                  componentType: 'ActivityService',
                })
              "
            >
              История пользовательских действий
            </div>
            <div class="menu_text">Журнал клиентских запросов</div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_settings"></div>
          <div>
            <div class="menu_header">Системные настройки</div>
            <div class="menu_text">Раздел в разработке</div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_monitoring"></div>
          <div>
            <div class="menu_header non_underline">
              Мониторинг инфраструктуры
            </div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Веб-сервер Nginx',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/A4_qnTv7z/web?orgId=1&refresh=10s`,
                      },
                    })
                  "
                  >Веб-сервер Nginx</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Виртуальные машины',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/e0ESDrZ7z/hardware?orgId=1&refresh=5s`,
                      },
                    })
                  "
                  >Виртуальные машины</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'База данных Postgresql',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/-QLZ8gB7z/postgresql?orgId=1&refresh=5s`,
                      },
                    })
                  "
                  >База данных Postgresql</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Брокер сообщений Kafka',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/HD8pcdM7k/kafka?orgId=1&refresh=1m`,
                      },
                    })
                  "
                  >Брокер сообщений Kafka</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'База данных Redis',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/DiEBNrW7z/redis?orgId=1&refresh=30s`,
                      },
                    })
                  "
                  >База данных Redis</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Планировщик Airflow',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/s4WiSDMnk/airflow-cluster?orgId=1&refresh=5s`,
                      },
                    })
                  "
                  >Планировщик Airflow</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Airflow DAGs',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/XCPzIDM7z/airflow-dag?orgId=1&refresh=5s`,
                      },
                    })
                  "
                  >Airflow DAGs</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column" v-show="$config.project === 'GjiMurmansk'">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Доступность системы',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/grafana/d/Zjv30F04z/hardware-copy?orgId=1`,
                      },
                    })
                  "
                  >Доступность системы</span
                >
                <div class="menu_text"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="icon icon_api"></div>
          <div style="width: 100%">
            <div class="menu_header non_underline">
              Техническая документация
            </div>
            <div class="menu_text_columns">
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'REST API',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/api/index.html`,
                      },
                    })
                  "
                  >Rest API</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="
                    addMainTab({
                      name: 'Плагины',
                      componentType: 'IFrame',
                      payload: {
                        url: `${$config.api}/plugins/index.xhtml`,
                      },
                    })
                  "
                  >Плагины</span
                >
                <div class="menu_text"></div>
              </div>
              <div class="menu_column">
                <span
                  class="column_header"
                  @click="addMainTab({
                    name: 'Сервисы',
                    componentType: 'ChangeLog',
                  })"
                  >Сервисы</span
                >
                <div class="menu_text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </el-scrollbar> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'dashboard_admin',
  inject: ['addMainTab']
}
</script>

<style lang="scss" scoped>
@import "./style/_dashboardAdminVariables";
.dashboard_admin {
  background-color: $main_background;
  // height: 100%;
  box-sizing: border-box;

  & .wrapper {
    background: $background_containter;
    height: 100%;
    width: 65%;
    margin: auto;
  }
  & .header {
    background: url("./resources/images/background.jpg") no-repeat;
    height: 155px;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;
    // position: sticky;
    // top: 0;
    background-size: cover;
  }
  & .name_header {
    color: #fff;
    font-size: 36px;
    line-height: 36px;
    display: inline-block;
  }
  & .logo_header {
    background: url("./resources/icons/logo_accent.svg") no-repeat;
    height: 32px;
    width: 165px;
    float: right;
  }
  & .container {
    // height: calc(100vh - 180px);
    // top: -65px;

    & .menu {
      display: flex;
      align-items: flex-start;
      margin-bottom: 25px;
      margin-left: 50px;
      margin-right: 50px;
      min-height: 100px;
    }
    & .menu:last-child {
      margin-bottom: 0;
    }
    & .menu_text_columns {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      & .menu_column {
        flex-basis: 45%;
        padding-left: 15px;
        margin-bottom: 20px;
      }
      & .column_header {
        color: $menu_column_color;
        font-size: $menu_text_fontSize;
        line-height: $menu_text_lineHeight;
        display: inline-block;
        position: relative;
        margin-bottom: 5px;
      }
      & .column_header:before {
        content: ".";
        font-size: 33px;
        position: absolute;
        left: -14px;
        top: -8px;
      }
      & .column_header:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    & .icon {
      height: 84px;
      margin-right: 24px;
      min-width: 84px;

      &.icon_data {
        background: url("./resources/icons/data.svg") no-repeat;
      }
      &.icon_interface {
        background: url("./resources/icons/interface.svg") no-repeat;
      }
      &.icon_acess {
        background: url("./resources/icons/acess.svg") no-repeat;
        background-position: 0px -8px;
      }
      &.icon_source {
        background: url("./resources/icons/source.svg") no-repeat;
      }
      &.icon_document {
        background: url("./resources/icons/document.svg") no-repeat;
      }
      &.icon_map {
        background: url("./resources/icons/map.svg") no-repeat;
      }
      &.icon_engineering {
        background: url("./resources/icons/engineering.svg") no-repeat;
        background-position: 0px -8px;
      }
      &.icon_integration {
        background: url("./resources/icons/integration.svg") no-repeat;
        background-position: 0px -8px;
      }
      &.icon_sync {
        background: url("./resources/icons/sync.svg") no-repeat;
        background-position: 0px -8px;
      }
      &.icon_logs {
        background: url("./resources/icons/logs.svg") no-repeat;
      }
      &.icon_settings {
        background: url("./resources/icons/settings.svg") no-repeat;
      }
      &.icon_monitoring {
        background: url("./resources/icons/monitoring.svg") no-repeat;
      }
      &.icon_api {
        background: url("./resources/icons/api.svg") no-repeat;
      }
    }
  }
  & .menu_header {
    color: $menu_header_color;
    font-size: $menu_header_fontSize;
    line-height: $menu_header_lineHeight;
    margin-bottom: 8px;
  }
  & .menu_header:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  & .menu_text {
    color: $menu_text_color;
    font-size: $menu_text_fontSize;
    line-height: $menu_text_lineHeight;
  }
  & .non_underline {
    text-decoration: none !important;
    cursor: default !important;
  }
}
</style>
