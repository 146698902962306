




































































































































































































































































import Vue from 'vue'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import SelectRefField from '@/components/Common/SelectRefField.vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'

export default Vue.extend({
  name: 'EdsSignForm',

  components: {
    RegistrySelectTree,
    SelectRefField,
    TreeSelect
  },

  props: ['modelRef', 'objectId', 'properties', 'logicId'],

  watch: {
    model: {
      handler (val) {
        this.$emit('updateParent', val, 'model')
      },
      deep: true
    },
    'propertiesModel.external_object_id': {
      async handler (val) {
        this.fieldsProperties = await this.getFields(val)
      }
    },
    objectIdProperties: {
      async handler (val) {
        this.fieldsProperties = await this.getFields(val)
      }
    },
    'propertiesModel.is_external_object': {
      handler (value) {
        for (let property in this.rules) {
          this.propertiesModel[property] = null
        }
        this.objectIdProperties = value ? this.propertiesModel.external_object_id : this.objectId
      }
    },
    propertiesModel: {
      handler (val) {
        if (val.is_multiple_sign) {
          this.propertiesModel.is_external_object = true
        }
        this.$emit('updateParent', val, 'propertiesModel')
      },
      deep: true
    }
  },

  computed: {
    hiddenBaseField: function () {
      return this.propertiesModel.is_external_object && !this.propertiesModel.external_object_id
    },

    rules () {
      let rules: { [field: string]: any[] } = {
      }

      if (!this.propertiesModel.is_sign_xml) {
        rules = {
          file_field_main_registry: [
            { required: true, message: 'Select internal table', trigger: 'change' }
          ],
          external_object_id: [
            { required: true, message: 'Select external table', trigger: 'change' }
          ],
          external_object_field_id: [
            { required: true, message: 'Select xref field', trigger: 'change' }
          ],
          sign_date_field_id: [
            { required: true, message: 'Select sign date field', trigger: 'change' }
          ],
          user_field_id: [
            { required: true, message: 'Select user field', trigger: 'change' }
          ],
          certificate_number_field_id: [
            { required: true, message: 'Select cert number field', trigger: 'change' }
          ],
          certificate_owner_field_id: [
            { required: true, message: 'Select cert owner field', trigger: 'change' }
          ],
          valid_from_date_field_id: [
            { required: true, message: 'Select field valid from date', trigger: 'change' }
          ],
          valid_to_date_field_id: [
            { required: true, message: 'Select field valid to date', trigger: 'change' }
          ],
          inn_field_id: [
            { required: true, message: 'Select INN field', trigger: 'change' }
          ],
          archive_field_id: [
            { required: true, message: 'Select archive field', trigger: 'change' }
          ],
          multiple_eds_archive_field_id: [{
            required: true, message: 'Select archive field', trigger: 'change'
          }],
          signing_data_field_id: [
            { required: true, message: 'Select signing info field', trigger: 'change' }
          ]
        }
      }

      if (this.propertiesModel.is_sign_xml) {
        rules.source_file_field_id = [{
          required: true, message: 'Select source file field', trigger: 'change'
        }]

        rules.xpath_tag_id = [{
          required: true, message: 'Input xpath for tag identity', trigger: 'blur'
        }]

        rules.xpath_tag_sign = [{
          required: true, message: 'Input xpath for save signature in tag', trigger: 'blur'
        }]

        rules.target_file_field_id = [{
          required: true, message: 'Select target file field', trigger: 'change'
        }]
      }

      return rules
    }
  },

  data () {
    return {
      model: this.modelRef,
      propertiesModel: this.properties,
      objectIdProperties: this.properties.is_external_object
        ? this.properties.external_object_id : this.objectId,

      signTypes: [
        { value: 'client', label: 'client' },
        { value: 'server', label: 'server' },
        { value: 'combined', label: 'combined' }
      ],
      treePropsXref: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return !['xref_field', 'xref_multi_field'].includes(data.entity_type_id)
        }
      },
      treePropsStringAndText: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return !['string_field', 'text_field'].includes(data.entity_type_id)
        }
      },
      treePropsFile: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return !['file_field'].includes(data.entity_type_id)
        }
      },
      treePropsJson: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return !['json_field'].includes(data.entity_type_id)
        }
      },
      treePropsData: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return !['date_field'].includes(data.entity_type_id)
        }
      },
      treePropsDataAndDataTime: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return !['datetime_field', 'date_field'].includes(data.entity_type_id)
        }
      },
      fieldsObject: [],
      fieldsProperties: [],
      certificates: []
    }
  },

  async mounted () {
    this.fieldsObject = await this.getFields(this.objectId)
    this.fieldsProperties = await this.getFields(this.objectIdProperties)
  },

  methods: {
    changeSignXml (value) {
      if (value === false) {
        this.propertiesModel.source_file_field_id = null
        this.propertiesModel.xpath_tag_id = null
        this.propertiesModel.xpath_tag_sign = null
        this.propertiesModel.target_file_field_id = null
      }
    },

    async getFields (objectId) {
      if (!objectId) {
        return []
      } else {
        let res = await this.$http
          .get(`${this.$config.api}/objecteditor/entities?parent_id=${objectId}&show_children=true`)
        return res.data.data
      }
    },

    validate () {
      let isValid = false
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }
        isValid = valid
      })
      return isValid
    },

    renderOptionLabel (item) {
      return `${item.name} (id: ${item.id}, ${this.$t('object_editor.entity_types.' + item.entity_type_id)})`
    },

    renderContent (h, { node, data, store }) {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    }
  }
})
