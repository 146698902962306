import { FluxStandardAction, Nullable } from '@/core/domain/type/types'

export enum ActionTypes {
    GET_EVENTS = 'get_events'
}

export class GetEvents implements FluxStandardAction {
    type = 'Event/' + ActionTypes.GET_EVENTS
    constructor (public payload: object) {}
}
