

















import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import RecordHistoryHandler from '@/services/History/application/handler/query/RecordHistoryHandler'
import HistoryQueryRepository from '@/services/History/infrastructure/domain/repository/HistoryQueryRepository'
import RecordHistoryQuery from '@/services/History/application/query/RecordHistoryQuery'
import Event from '@/services/History/infrastructure/components/Event/index.vue'
import Timing from '@/services/History/infrastructure/components/Timing/index.vue'
import DatesFactory from '@/services/History/infrastructure/service/DatesFactory'

export default {
  name: 'history',
  components: {
    Event,
    Timing
  },
  props: {
    registryId: Number,
    recordId: Number
  },
  data () {
    return {
      events: [],
      dates: {},
      scrollPosition: 0,
      eventsPositions: [],
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'RecordHistoryQuery': new RecordHistoryHandler(
            new HistoryQueryRepository()
          ) }
        )
      )
    }
  },
  async mounted () {
    this.$refs.scroll.$el.children[0].addEventListener('scroll', this.handleScroll)
    await this.load()
    this.initEventsPositions()
  },
  destroyed () {
    if (this.$refs.scroll) {
      this.$refs.scroll.$el.children[0].removeEventListener('scroll', this.handleScroll)
    }
  },
  computed: {
    activeEvent () {
      let active = null
      this.eventsPositions.forEach((item) => {
        if (this.scrollPosition >= item.position) {
          active = item.name
        }
      })

      return active
    }
  },
  methods: {
    onTimeClick (time) {
      this.$refs[time.toString()][0].$el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    initEventsPositions () {
      for (let ref in this.$refs) {
        if (ref === 'scroll') {
          continue
        }
        this.eventsPositions.push({ name: ref, position: this.$refs[ref][0].$el.offsetTop - 15 })
      }
    },
    handleScroll (e) {
      this.scrollPosition = e.target.scrollTop
    },
    async load () {
      this.events = await this.queryBus.execute(
        new RecordHistoryQuery(
          this.registryId, this.recordId
        )
      )
      this.dates = DatesFactory.build(this.events)
    }
  }
}
