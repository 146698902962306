import SmevSettingCommandRepositoryInterface from '@/services/AccessEditor/domain/repository/SmevSettingCommandRepositoryInterface'
import { SmevSettingDTO } from '@/services/AccessEditor/domain/model/Smev/SmevSetting'
import store from '@/store'
import { AddSmevSetting, UpdateSmevSetting, DeleteSmevSetting } from '@/services/AccessEditor/infrastructure/store/modules/SmevSetting/action-types'

export default class SmevSettingCommandRepository implements SmevSettingCommandRepositoryInterface {
  insert (item: SmevSettingDTO): Promise<void> {
    return store.dispatch(new AddSmevSetting(item))
  }

  update (item: SmevSettingDTO): Promise<void> {
    return store.dispatch(new UpdateSmevSetting(item))
  }

  delete (guid: string): Promise<void> {
    return store.dispatch(new DeleteSmevSetting(guid))
  }
}
