<template>
  <el-form>
    <el-select :value="value.fit" @change="$set(local_value ,'fit', $event)">
      <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item"
      :value="item">
      </el-option>
    </el-select>
    <span class="property_label">{{$locale.interface_editor.component_editor.table.source_type}}</span>
      <el-select :value="value.type" @change="$set(local_value ,'type', $event)">
        <el-option
          v-for="(item, index) in source"
          :key="index"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <div v-if="local_value.type === 'registry'">
        <span class="property_label">{{$locale.bi_editor.form.message.object_id.required}}</span>
        <!-- <editor-registry-select :value="value.registryId" @change="$set(local_value ,'registryId', $event)"></editor-registry-select> -->
        <registry-select :value="value.registryId" @change="changeRegistryId"></registry-select>
        <span class="property_label">{{$locale.bi_editor.form.message.object_id.select_field}}</span>
        <selectTree class="extraLine_select" :clearable="false" :standartTree='false' :dataTable="listField" v-model="local_value.fieldId"/>
      </div>
      <div v-if="local_value.type === 'extended_object'">
        <span class="property_label">{{ $t('interface_editor.component_editor.table.extended_object') }}</span>
        <el-select
          :value="local_value.sourceId"
          filterable
          @change="$set(local_value ,'sourceId', $event)"
        >
          <el-option
            v-for="(item, index) in extended_objects"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>

        <span class="property_label">{{ $t('interface_editor.component_editor.table.extended_object_fields') }}</span>
        <el-select
          :value="local_value.fieldId"
          filterable
          @change="$set(local_value ,'fieldId', $event)"
        >
          <el-option
            v-for="(item, index) in extended_object_fields"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>
  </el-form>
</template>

<script>
// import EditorSelect from './editor-select'
// import EditorRegistrySelect from './editor-registry-select.vue'
import SelectTree from '@/core/infrastructure/components/TreeSelect.vue'
import Registry from '@/components/Models/EntityModel'

import RegistrySelect from '@/components/Common/RegistrySelectTreeWithSearch.vue'
export default {
  name: 'editor-fit-image',
  components: {
    // EditorSelect,
    // EditorRegistrySelect,
    SelectTree,
    RegistrySelect
  },
  props: ['value'],
  data () {
    return {
      listField: [],
      local_value: {
        type: null,
        registryId: null,
        sourceId: null,
        fit: 'none',
        fieldId: null
      },
      options: [
        'fill', 'contain', 'cover', 'none', 'scale-down'
      ],
      source: [
        { id: 'registry', name: 'Реестр' },
        { id: 'extended_object', name: 'Расширенная таблица' }
      ],
      extended_objects: [],
      extended_object_fields: []
    }
  },
  watch: {
    'local_value.registryId': {
      async handler (id) {
        if (id) {
          let registryTree = await Registry.params({ parent_id: id, show_children: true }).get()
          this.listField = registryTree.data
        }
      },
      immediate: true
    },
    local_value: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    },
    'local_value.type': {
      async handler (type) {
        if (type === 'extended_object') {
          let data = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
          this.extended_objects = data.data
        }
      },
      immediate: true
    },
    'local_value.sourceId': {
      async handler (id) {
        if (id) {
          let response = await this.$http.post(`${this.$config.api}/datawarehouseservice/extended_object/${id}`, { limit: 1 }, {
            hideNotification: true
          })
          this.extended_object_fields = Object.keys(response.data[0])
        }
      },
      immediate: true
    }
  },
  methods: {
    changeRegistryId (registryId) {
      this.$set(this.local_value, 'registryId', registryId)
      this.local_value.fieldId = null
    }
  },
  mounted () {
    if (typeof this.value === 'string') {
      this.local_value.fit = this.value
    } else {
      this.local_value = this.value
    }
  }
}
</script>

<style scoped>

</style>
