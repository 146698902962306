



















































































































import { VueContext } from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'
import ElCollapsibleTabs from '@/core/infrastructure/components/CollapsibleTabs.vue'
import EditorTree from '@/core/infrastructure/components/EditorTree.vue'
import ElErrorMessageBox from '@/core/infrastructure/components/ErrorMessageBox.vue'
import ProjectTreeElementProjectCreateCommand from '@/services/MapEditor/application/command/ProjectTreeElementProjectCreateCommand'
import ProjectTreeElementGroupCreateCommand from '@/services/MapEditor/application/command/ProjectTreeElementGroupCreateCommand'
import ProjectUpdateCommand from '@/services/MapEditor/application/command/ProjectUpdateCommand'
import ProjectGroupUpdateCommand from '@/services/MapEditor/application/command/ProjectGroupUpdateCommand'
import ProjectTreeElementByGuidQuery from '@/services/MapEditor/application/query/ProjectTreeElementByGuidQuery'
import ProjectTreeElementsQuery from '@/services/MapEditor/application/query/ProjectTreeElementsQuery'
import ProjectTreeElementDeleteCommand from '@/services/MapEditor/application/command/ProjectTreeElementDeleteCommand'
import ProjectTreeAddChildElementCommand from '@/services/MapEditor/application/command/ProjectTreeAddChildElementCommand'
import ProjectTreeElement, { ProjectTreeElementDTO } from '@/services/MapEditor/domain/model/Project/ProjectTreeElement'
import Project, { ProjectDTO } from '@/services/MapEditor/domain/model/Project/Project'
import ProjectGroup, { ProjectGroupDTO } from '@/services/MapEditor/domain/model/Project/ProjectGroup'
import ProjectTreeElementType from '@/services/MapEditor/domain/model/Project/ProjectTreeElementType'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { ProjectAPI } from '@/services/MapEditor/infrastructure/api/ProjectAPI';

export default {
  name: "ProjectPanel",
  components: {
    ElCollapsibleTabs, EditorTree, ElErrorMessageBox, VueContext
  },
  data() {
    return {
      showErrorMessage: false,
      showForms: false,
      projectPanelCollapsed: false,
      projectTreeElementDto: null,
      isCreateProjectWindowVisible: false,
      projectTreeElementErrorVisible: false,
      elementBuilder: ProjectTreeElement,
      selectedProjectDtoFromContextMenu: null,
      projectTreeElementTypes: [{
        value: this.$locale.map_editor.entities.group,
        id: 'group'
      },{
        value: this.$locale.map_editor.entities.project,
        id: 'project'
      }],
      projectTreeNodeProps: {
        isLeaf: 'is_leaf',
        label: 'name',
        children: 'child'
      },
      selectedProjectDtoTreeElement: null,
      userDefaultProjectGuid: null,
      createProjectRules: {
        element_type: {
          required: true,
          message: this.$locale.map_editor.errors.type_required,
          trigger: 'change'
        },
        name: {
          required: true,
          message: this.$locale.map_editor.errors.name_required,
          trigger: 'change'
        }
      }
    }
  },
  inject: ['getEventBus', 'getCommandBus', 'getQueryBus'],
  watch: {    
    selectedProjectDtoTreeElement (element) {
      if (element !== null && element.getElementType() == ProjectTreeElementType.PROJECT) {
        this.getEventBus().$emit('showProjectLayerPanel', element);
      }
    },
    isCreateProjectWindowVisible: function (val) {
      if (val === false) {
        this.projectTreeElement = null;
      }
    },
    projectTreeElementCreated: function (location) {
      let me = this;
      this.getQueryBus().execute(
        new ProjectTreeElementByGuidQuery(
          location.replace('/project_tree_elements/', '')
        )
      ).then(function(elementDto){
        me.isCreateProjectWindowVisible = false;
        me.$refs.projectTree.addEditorElement(elementDto);
      });
    },
    isProjectTreeElementLoading: (state) => state,
    projectTreeElementError: function (error) {
      this.projectTreeElementErrorVisible = true;
      return error;
    }
  },
  computed: {
    isProjectTreeElementLoading() {
      return this.$store.getters['ProjectTreeElement/isElementLoading']
             || this.$store.getters['Project/isLoading']
             || this.$store.getters['ProjectGroup/isLoading'];     
    },
    projectTreeElementCreated() {
      return this.$store.getters['ProjectTreeElement/getElementLocation'];
    },
    projectTreeElementError() {
      return this.$store.getters['ProjectTreeElement/getError']
             || this.$store.getters['Project/getError']
             || this.$store.getters['ProjectGroup/getError']; 
    }
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    collapsePanel() {
      this.projectPanelCollapsed = !this.projectPanelCollapsed;
      this.getEventBus().$emit('updateMapSize');
    },
    createProjectTreeElementWindow(elementType) {
      this.isCreateProjectWindowVisible = true;
      this.projectTreeElementErrorVisible = false;
      this.projectTreeElementDto = new ProjectTreeElementDTO({element_type: elementType});
      let selectedElementType = this.selectedProjectDtoTreeElement !== null ? this.selectedProjectDtoTreeElement.getElementType() : null;
      if (selectedElementType == ProjectTreeElementType.GROUP) {
        this.projectTreeElementDto.parent_id = this.selectedProjectDtoTreeElement.getId();
      } else if (selectedElementType == ProjectTreeElementType.PROJECT) {
        this.projectTreeElementDto.parent_id = this.selectedProjectDtoTreeElement.getParentId();
      }
    },
    saveProjectTreeElement() {
      let me = this;
      let element = ProjectTreeElement.create(this.projectTreeElementDto);
      if (typeof element.getId() === 'undefined') {
        if (this.projectTreeElementDto.element_type == ProjectTreeElementType.PROJECT) {
          let project = element.getProject();
          this.getCommandBus().execute(
            new ProjectTreeElementProjectCreateCommand(
              project.getName(),
              project.getDescription(),
              element.getParentId()
            )
          );
        } else if (this.projectTreeElementDto.element_type == ProjectTreeElementType.GROUP) {
          let group = element.getGroup();
          this.getCommandBus().execute(
            new ProjectTreeElementGroupCreateCommand(
              group.getName(),
              element.getParentId()
            )
          );
        }
      } else {
        if (this.projectTreeElementDto.element_type == ProjectTreeElementType.PROJECT) {
          let project = element.getProject();
          this.getCommandBus().execute(
            new ProjectUpdateCommand(
              project.getGuid(),
              project.getName(),
              project.getDescription()
            )
          ).then(function(response) {
            me.isCreateProjectWindowVisible = false;
          });
        } else if (this.projectTreeElementDto.element_type == ProjectTreeElementType.GROUP) {
          let group = element.getGroup();
          this.getCommandBus().execute(
            new ProjectGroupUpdateCommand(
              group.getGuid(),
              group.getName()
            )
          ).then(function(response) {
            me.isCreateProjectWindowVisible = false;
          });
        }
      }     
    },
    selectProjectTreeNode(elementDto) {
      this.selectedProjectDtoTreeElement = ProjectTreeElement.create(elementDto);
    },
    async setDefaultProject(data) {
        const userId = this.$store.getters['Authorization/userId'];
        if (!userId) {
          return;
        }
        await APIClient.shared.request(new ProjectAPI.DeleteUserDefaultProject(this.userDefaultProjectGuid, userId));
        await APIClient.shared.request(new ProjectAPI.PutUserDefaultProject(data.element_guid, userId));
        this.userDefaultProjectGuid = data.element_guid;
    },
    editProjectTreeElement(data, node) {
      this.projectTreeElementDto = data;
      this.isCreateProjectWindowVisible = true;
    },
    removeProjectTreeElement(elementDto) {
      this.$refs.projectTree.removeEditorNode(elementDto);
      this.selectedProjectDtoTreeElement = null;
    },
    async removeProjectTreeCommand(elementDto) {
      let me = this;
      let element = ProjectTreeElement.create(elementDto);      
      this.getCommandBus().execute(
        new ProjectTreeElementDeleteCommand(
          element.getGuid()
        )
      ).then(() => {
        me.getEventBus().$emit('removeProjectLayerPanel', element);
        me.$refs.projectTree.remove(element.getId());
      });
    },
    async loadProjectTree(node, resolve) {
      this.getQueryBus().execute(
        new ProjectTreeElementsQuery(
          node.level === 0 ? null : node.data.id
        )
      ).then((elements) => {
        resolve(elements);
      });
    },
    async getDefaultUserProject() {
      const userId = this.$store.getters['Authorization/userId'];
      if (!userId) {
          return;
      }
      const res = await APIClient.shared.request(new ProjectAPI.GetUserDefaultProject(userId));
      if (res) {
        const project = await APIClient.shared.request(new ProjectAPI.GetProjectById(res[0]['project_id']));
        if (project) {
          this.userDefaultProjectGuid = project.guid;
        }
      }
    },
    getProjectTreeIcon (data, expanded) {
      if (data.element_type === 'group' && !expanded) {
        return 'el-icon-folder';
      } else if (data.element_type === 'group' && expanded) {
        return 'el-icon-folder-opened';
      }
      return 'el-icon-document';
    },
    openProject(data) {
      let element = ProjectTreeElement.create(data);
      if (this.selectedProjectDtoTreeElement !== null && this.selectedProjectDtoTreeElement.getId() == element.getId()) {
        this.getEventBus().$emit('removeProjectLayerPanel', this.selectedProjectDtoTreeElement);
        this.selectedProjectDtoTreeElement = null;
        this.$refs.projectTree.setCurrentKey(null);        
      } else {
        this.selectedProjectDtoTreeElement = element;
      }
    },
    clipboardCopy() {
      this.$message({
        message: this.$locale.map_editor.errors.copy_error_message,
        type: 'success'
      });
    },
    projectsContextMenu(event, data) {
      let element = ProjectTreeElement.create(data);
      if (element.getElementType() == ProjectTreeElementType.PROJECT) {
        this.selectedProjectDtoFromContextMenu = data;
        this.$refs.projects_context_menu.open(event, data);
      }
    },
    listSources() {
      if (this.selectedProjectDtoTreeElement == null) {
        this.openProject(this.selectedProjectDtoFromContextMenu);
      }
      this.getEventBus().$emit('selectSourcesFromContextMenu');
    },
    listLayers() {
      if (this.selectedProjectDtoTreeElement == null) {
        this.openProject(this.selectedProjectDtoFromContextMenu);
      }
      this.getEventBus().$emit('selectLayersFromContextMenu');
    },
    listCoordinateSystems() {
      if (this.selectedProjectDtoTreeElement == null) {
        this.openProject(this.selectedProjectDtoFromContextMenu);
      }
      this.getEventBus().$emit('selectCoordinateSystemsFromContextMenu');
    },
    addProjectTreeElement(command) {
      if (command == "add_group") {
        this.createProjectTreeElementWindow("group");
      } else if (command == "add_new_project") {
        this.createProjectTreeElementWindow("project");
      } else if (command == "put_existing_project") {
        this.getEventBus().$emit('selectProject', this.projectTreeElementDto != null ? this.projectTreeElementDto.parent_id : null);
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      if (type == "inner") return !dropNode.data.is_leaf;
      return true;
    },
    allowDrag() {
      return true;
    },
    nodeDrop (dragNode, dropNode, type) {
      this.getCommandBus().execute(
        new ProjectTreeAddChildElementCommand(
          dropNode.data.id,
          dragNode.data.id
        )
      ).then((response) => {                      
      });
    }
  },
  mounted() {
    this.getDefaultUserProject();
  }
}
