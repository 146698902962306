<template>
  <div>
    <div class="user-main-edit-panel">
      <div class="wrapper">
        <el-form v-if="userDto != null" :rules="createUserRules" :model="userDto" :disabled="isUserLoading" label-position="left">
          <el-row class="user-main-form-wrapper">
            <el-col class="left-side">
              <div class="main-edit-title">{{$locale.access_editor.titles.main_data}}</div>
              <div v-show="!userDto.is_system">
                <div class="avatar-wrapper">
                  <div class="avatar">
                    <fieldset>
                      <el-upload
                          class="users upload-demo"
                          ref="upload"
                          action=""
                          list-type="picture-card"
                          :on-change="changeAvatar"
                          :on-remove="removeAvatar"
                          :file-list="avatar"
                          :auto-upload="false"
                      >
                        <i class="icon-upload-blue" :title="$locale.access_editor.users_item.download_avatar"></i>
                      </el-upload>
                    </fieldset>
                    <span class="avatar-legend">{{$locale.access_editor.users_item.avatar}}</span>
                  </div>
                </div>
                <div class="user-main-info-wrapper">
                  <div class="fio">
                    <div v-if="!isFIOEdit" class="wrapper">
                      <div v-if="!userDto.id" class="fio-text">
                        <span class="text">{{$locale.access_editor.users_item.surname}}</span>
                        <span class="text">{{$locale.access_editor.users_item.name}}</span>
                        <span class="text">{{$locale.access_editor.users_item.midname}}</span>
                      </div>
                      <div v-if="userDto.id" class="fio-text">
                        <span class="text" v-if="userDto.surname">{{userDto.surname}}</span>
                        <span class="text" v-if="userDto.name">{{userDto.name}}</span>
                        <span class="text" v-if="userDto.midname">{{userDto.midname}}</span>
                      </div>
                    </div>
                    <div v-if="isFIOEdit" class="wrapper">
                      <el-form-item prop="surname">
                        <el-input v-model="userDto.surname" ref="user_surname_input" :placeholder="$locale.access_editor.users_item.surname" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item prop="name">
                        <span class="name-required-star">*</span>
                        <el-input v-model="userDto.name" :placeholder="$locale.access_editor.users_item.name" autocomplete="off"></el-input>
                      </el-form-item>
                      <el-form-item prop="midname">
                        <el-input v-model="userDto.midname" :placeholder="$locale.access_editor.users_item.midname" autocomplete="off"></el-input>
                      </el-form-item>
                    </div>
                    <el-button :icon="iconFIOEdit" size="small" @click="switchFIOEdit"></el-button>
                  </div>
                  <el-form-item prop="role_id" :label="$locale.access_editor.users_item.role">
                    <treeselect
                        v-model="userDto.role_id"
                        :placeholder="$locale.main.fields.select"
                        :normalizer="(node) => {return {id: node.id, label: node.name}}"
                        :options="rolesList"
                        :clear-value-text="$locale.main.message.clear"
                        :loading-text="$locale.main.message.loading"
                        :disable-immediate-search="true"
                        :async="true"
                        :cache-options="false"
                        :append-to-body="false"
                        :load-options="getRolesList"
                        :clearable="false"
                        :delete-removes="false"
                        :backspace-removes="false"
                        @open="getRolesList"
                    ></treeselect>
                  </el-form-item>
                  <el-form-item prop="email" :label="$t('access_editor.users_item.email')">
                    <el-input v-model="userDto.email" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item prop="phone" :label="$t('access_editor.users_item.phone')">
                    <el-input v-model="userDto.phone" @input="validatePhone" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item prop="telegram_username" :label="$t('access_editor.users_item.telegram_username')">
                    <el-tooltip class="item" effect="dark" content="Используется для привязки учётной записи телеграм к профилю в системе" placement="top">
                      <el-input v-model="userDto.telegram_username"></el-input>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item prop="telegram_id" :label="$t('access_editor.users_item.telegram_id')">
                    <el-input v-model="userDto.telegram_id" disabled></el-input>
                  </el-form-item>
                  <el-form-item prop="two_factor_auth_type" :label="$locale.access_editor.users_item.two_factor_auth_type">
                    <el-select
                      :value="userDto.two_factor_auth_type"
                      @input="$set(userDto, 'two_factor_auth_type', $event || null)"
                      clearable>
                      <el-option
                        v-for="(item, index) in twoFactorAuthTypes"
                        :key="index"
                        :label="item"
                        :value="item.toLowerCase()">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="checkbox-group">
                    <el-checkbox v-model="userDto.is_admin" :label="$locale.access_editor.users_item.admin" name="is_admin"></el-checkbox>
                    <el-checkbox v-model="userDto.is_system" :label="$locale.access_editor.users_item.system" name="is_system"></el-checkbox>
                    <el-checkbox v-model="userDto.is_blocked" :label="$locale.access_editor.users_item.block" name="is_blocked"></el-checkbox>
                  </el-form-item>
                </div>
              </div>
              <div v-show="userDto.is_system" class="is-system">
                <div class="system-wrapper">
                  <el-form-item prop="name" :label="$locale.access_editor.users_item.name">
                    <el-input v-model="userDto.name" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item prop="role_id" :label="$locale.access_editor.users_item.role">
                    <treeselect
                        v-model="userDto.role_id"
                        :placeholder="$locale.main.fields.select"
                        :normalizer="(node) => {return {id: node.id, label: node.name}}"
                        :options="rolesList"
                        :clear-value-text="$locale.main.message.clear"
                        :loading-text="$locale.main.message.loading"
                        :disable-immediate-search="true"
                        :async="true"
                        :cache-options="false"
                        :append-to-body="false"
                        :load-options="getRolesList"
                        :clearable="false"
                        :delete-removes="false"
                        :backspace-removes="false"
                        @open="getRolesList"
                    ></treeselect>
                  </el-form-item>
                  <el-form-item class="checkbox-group">
                    <el-checkbox v-model="userDto.is_system" :label="$locale.access_editor.users_item.system" name="is_system"></el-checkbox>
                    <el-checkbox v-model="userDto.is_blocked" :label="$locale.access_editor.users_item.block" name="is_blocked"></el-checkbox>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col class="right-side">
              <div v-if="!userDto.is_system">
                <el-row>
                  <el-col>
                    <el-form-item prop="login" class="login" :label="$locale.access_editor.users_item.login">
                      <el-input v-model="userDto.login" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-form-item prop="password" :label="$locale.access_editor.users_item.password">
                      <el-input v-model="userDto.password" class="password" :readonly="passwordControl['inputLocked']" autocomplete="off" @input="passwordCheck"></el-input>
                      <el-tooltip class="item" effect="dark" :content="$locale.access_editor.users_item.change_password" placement="bottom">
                        <el-button icon="icon-lock" class="password-lock-button" size="small" @click="switchPasswordLocker"></el-button>
                      </el-tooltip>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="passwordControl['isValid'] === false">
                    <ul class="password-control-list">
                      <li v-if="passwordControl['lengthValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordLength}}: {{passwordRules['length']['min']}}-{{passwordRules['length']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                      <li v-if="passwordControl['uppercaseValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordUppercase}}: {{passwordRules['uppercase']['min']}}-{{passwordRules['uppercase']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                      <li v-if="passwordControl['lowercaseValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordLowercase}}: {{passwordRules['lowercase']['min']}}-{{passwordRules['lowercase']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                      <li v-if="passwordControl['numbersValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordNumbers}}: {{passwordRules['numbers']['min']}}-{{passwordRules['numbers']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                      <li v-if="passwordControl['symbolsValid'] === false">{{$locale.access_editor.users_item.rules_form.passwordQuantity}} {{$locale.access_editor.users_item.rules_form.passwordSpecChars}}: {{passwordRules['symbols']['min']}}-{{passwordRules['symbols']['max']}} {{$locale.access_editor.users_item.rules_form.passwordChars}}</li>
                    </ul>
                  </el-col>
                  <el-col v-if="passwordControl['isValid'] === false">
                    <el-button icon="el-icon-refresh" class="password-generate-button" @click="generatePassword">{{$locale.access_editor.users_item.generate_password}}</el-button>
                  </el-col>
                </el-row>
              </div>
              <div v-if="userDto.is_system">
                <div class="is-system">
                  <el-col>
                    <el-form-item v-if="userDto" prop="api_key" :label="$locale.access_editor.users_item.api_key">
                      <el-input v-model="userDto.api_key" readonly type="textarea" autocomplete="off" class="api-key"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col>
                    <el-button icon="el-icon-refresh" class="api-generate-button" @click="generateApiKey">{{$locale.access_editor.users_item.generate_api_key}}</el-button>
                  </el-col>
                  <el-col>
                    <fieldset v-if="userDto.is_system">
                      <legend>{{$locale.access_editor.users_item.system_ips}}</legend>
                      <el-tag
                          class="ip-tag"
                          :key="ip"
                          v-for="ip in userDto.system_ips"
                          closable
                          :disable-transitions="false"
                          @close="handleRemoveIp(ip)">
                        {{ip}}
                      </el-tag>
                      <el-input
                          class="input-new-ip"
                          v-if="inputIpVisible"
                          v-model="inputIpValue"
                          ref="saveIpInput"
                          size="mini"
                          @keyup.enter.native="handleIpInputConfirm"
                          @blur="handleIpInputConfirm"
                      >
                      </el-input>
                      <el-button v-else class="button-new-ip" size="small" @click="showIpInput">+ IP</el-button>
                    </fieldset>
                  </el-col>
                </div>
              </div>
              <span class="dialog-footer">
                <el-button @click="returnToUserList">{{$locale.main.button.cancel}}</el-button>
                <el-button class="save-button" :disabled="saveButtonLocker()" @click="saveUser">{{$locale.main.button.save}}</el-button>
              </span>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div v-if="userCard != null" class="user-card-edit-panel custom_scrollbar">
      <div class="left-side">
        <div class="main-edit-title">{{$locale.access_editor.titles.extended_data}}</div>
        <CardsWrapper
            ref="cardsWrapper"
            :prevent-user-card="true"
            :show-back-breadcrumb="false"
            :show-block-btn="false"
            :show-breadcrumb-empty='false'
          ></CardsWrapper>
        <!-- <registry-card
            :card-id="userCard.id"
            :registry-id="userCard.registryId"
            :initial-data="{}"
            :show-block-btn="false"
            v-bind:record-id.sync="userCard.recordId"
            ref="user_registry_card_data"
        >
        </registry-card> -->
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from '@bingosoftnn/vue-treeselect'
import User, { UserDTO } from '@/services/AccessEditor/domain/model/User/User'
import UserAvatarQuery from '@/services/AccessEditor/application/query/UserAvatarQuery'
import UserCreateCommand from '@/services/AccessEditor/application/command/UserCreateCommand'
import UserUpdateCommand from '@/services/AccessEditor/application/command/UserUpdateCommand'
import UserPasswordQuery from '@/services/AccessEditor/application/query/UserPasswordQuery'
import UserPasswordRulesQuery from '@/services/AccessEditor/application/query/UserPasswordRulesQuery'
import UserPasswordCheckQuery from '@/services/AccessEditor/application/query/UserPasswordCheckQuery'
import UserApiKeyQuery from '@/services/AccessEditor/application/query/UserApiKeyQuery'
import Registry from '@/components/Registry/Models/Registry'
import RegistryCard from '@/components/RegistryCard/index.vue'
import CardsWrapper from '@/components/Registry/CardsWrapper.vue'

export default {
  name: 'UserMainEditPanel',
  components: {
    Treeselect,
    RegistryCard,
    Registry,
    CardsWrapper
  },
  props: {
    rolesList: {
      type: Array,
      required: true
    },
    cardParams: {
      type: Object,
      required: true
    },
    preventReturn: {
      type: Boolean,
      default: false
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      twoFactorAuthTypes: ['SMS', 'TOTP'],
      userDto: null,
      avatar: [],
      createUserRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        role_id: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        },
        login: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      },
      passwordRules: [],
      passwordControl: {
        'isValid': true,
        'locked': true,
        'inputLocked': true,
        'lengthValid': false,
        'uppercaseValid': false,
        'lowercaseValid': false,
        'numbersValid': false,
        'symbolsValid': false
      },
      isSystemUser: null,
      inputIpVisible: false,
      inputIpValue: '',
      isFIOEdit: false,
      userCard: null,
      iconFIOEdit: 'icon-edit-blue',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  created: function () {
    this.getEventBus().$on('editUserEvent', (data) => {
      this.userDto = data
      if (this.userDto != null) {
        if (this.userDto.id == null) {
          this.setFocus()
        } else {
          this.isFIOEdit = false
        }
      }
      this.userDto.password = ''
      this.passwordControl['locked'] = true
      this.passwordControl['inputLocked'] = true
      this.getAvatar()
      this.userCard = this.getUserCard(this.userDto)
      if (Object.keys(this.cardParams).length && this.userDto?.id) {
        this.$nextTick(() => {
          this.$refs.cardsWrapper.openRegistryCard({
            registryId: this.cardParams.registryId,
            cardId: this.cardParams.cardId,
            cardName: '',
            recordId: this.userDto.id
          })
        })
      }
    })
  },
  watch: {
    userDto: {
      handler: async function (curDto) {
        this.isSystemUser = curDto !== null ? curDto.is_system : null
        this.userCard = this.getUserCard(curDto)
      },
      deep: true
    },
    getSaveUserError: function (err) {
      if (err.code === 'duplicate_key_violation_exception') {
        if (err.message.includes('Key login =')) {
          this.$message({
            message: this.$locale.access_editor.users_item.duplicate_login,
            type: 'warning'
          })
        }
      }
    }
  },
  computed: {
    isUserLoading () {
      return this.$store.getters['User/isLoading']
    },
    getSaveUserError () {
      return this.$store.getters['User/getError']
    }
  },
  methods: {
    returnToUserList () {
      if (this.preventReturn) {
        this.$emit('cancelChanges')

        return false
      }

      this.getEventBus().$emit('returnToUserListEvent', {
        'list': true,
        'edit': false
      })
      this.passwordControl['inputLocked'] = true
      this.passwordControl['isValid'] = true
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = []
      }
      this.userCard = null
    },
    getRolesList () {
      return this.rolesList
    },
    switchFIOEdit () {
      this.isFIOEdit = !this.isFIOEdit
      this.isFIOEdit ? this.iconFIOEdit = 'icon-ok-green' : this.iconFIOEdit = 'icon-edit-blue'
    },
    setFocus () {
      this.isFIOEdit = true
      this.$nextTick(() => {
        this.$refs.user_surname_input.focus()
      })
    },
    async getAvatar () {
      if (this.userDto != null) {
        let avatarId = this.userDto.avatar_id
        if (avatarId != null) {
          this.avatar = []
          await this.getQueryBus().execute(
            new UserAvatarQuery(avatarId)
          ).then(data => {
            this.avatar.push({
              name: this.getFileName(data),
              url: `${this.$config.api}/files/${this.getFilePath(data)}`
            })
          })
        }
      }
    },
    changeAvatar (file) {
      if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
        this.removeAvatar()
        this.$message.error(this.$locale.access_editor.users_item.error_avatar)
        return false
      }
      if (this.$refs.upload.uploadFiles.length > 1) {
        this.$refs.upload.uploadFiles.splice(0, 1)
      }
      this.userDto.avatar = this.$refs.upload.uploadFiles[0].raw
      this.userDto.is_avatar_deleted = false
    },
    removeAvatar () {
      if (typeof this.$refs.upload !== 'undefined') {
        this.$refs.upload.uploadFiles = []
      }
      if (this.userDto !== null) {
        this.userDto.avatar = null
        this.userDto.is_avatar_deleted = true
      }
      this.avatar = []
    },
    switchPasswordLocker () {
      this.passwordControl['locked'] = !this.passwordControl['locked']
      this.passwordControl['inputLocked'] = !this.passwordControl['inputLocked']
      if (typeof this.passwordRules['length']['min'] === 'undefined' || this.passwordRules['length']['min'] == null) {
        this.getPasswordRules()
          .then(data => {
            this.passwordControl['isValid'] = !this.passwordControl['isValid']
          })
      } else {
        this.passwordControl['isValid'] = !this.passwordControl['isValid']
      }
    },
    async getPasswordRules () {
      await this.getQueryBus().execute(
        new UserPasswordRulesQuery()
      ).then(data => {
        this.passwordRules = data.rules
      })
    },
    async generatePassword () {
      await this.getQueryBus().execute(
        new UserPasswordQuery()
      ).then(data => {
        this.userDto.password = data.password
        this.passwordCheck()
      })
    },
    async passwordCheck () {
      await this.getQueryBus().execute(
        new UserPasswordCheckQuery(this.userDto.password)
      ).then(data => {
        this.passwordControl['isValid'] = data['valid']
        this.passwordControl['lengthValid'] = data['lengthValid']
        this.passwordControl['uppercaseValid'] = data['uppercaseValid']
        this.passwordControl['lowercaseValid'] = data['lowercaseValid']
        this.passwordControl['numbersValid'] = data['numbersValid']
        this.passwordControl['symbolsValid'] = data['symbolsValid']
      })
    },
    saveButtonLocker () {
      if (this.userDto === null || typeof this.userDto === 'undefined') {
        return true
      }
      if (this.isUserLoading) {
        return true
      }
      if (this.userDto.is_system === true) {
        return false
      }
      if (!this.passwordControl['isValid']) {
        return true
      }
      if (typeof this.userDto.login === 'undefined' || this.userDto.login === null) {
        return true
      }
      if (this.userDto.login.length < 5 || this.userDto.login.length > 32) {
        return true
      }
      if (typeof this.userDto.name === 'undefined' || this.userDto.name === null) {
        return true
      }
      if (this.userDto.name.length < 1 || this.userDto.name.length > 255) {
        return true
      }
      if (typeof this.userDto.role_id === 'undefined' || this.userDto.role_id === null) {
        return true
      }
      return false
    },
    async generateApiKey () {
      if (this.userDto.id === null) {
        this.$message({
          message: this.$locale.access_editor.users_item.user_must_be_saved,
          type: 'warning'
        })
        this.userDto.api_key = ''
        return
      }
      if (this.userDto.role_id === null) {
        this.$message({
          message: this.$locale.access_editor.users_item.user_role_must_be_set,
          type: 'warning'
        })
        this.userDto.api_key = ''
        return
      }
      if (this.userDto.system_ips.length === 0) {
        this.userDto.api_key = ''
        if (this.userDto.id !== 0) {
          this.$message({
            message: this.$locale.access_editor.users_item.system_ips_must_be_set,
            type: 'warning'
          })
          return
        }
      }
      await this.getQueryBus().execute(
        new UserApiKeyQuery(
          this.userDto.id,
          this.userDto.role_id,
          this.userDto.system_ips
        )
      ).then(data => {
        this.userDto.api_key = data.api_key
      })
    },
    handleRemoveIp (ip) {
      this.userDto.system_ips.splice(this.userDto.system_ips.indexOf(ip), 1)
    },
    handleIpInputConfirm () {
      let inputIpValue = this.inputIpValue
      if (inputIpValue) {
        this.userDto.system_ips.push(inputIpValue)
      }
      this.inputIpVisible = false
      this.inputIpValue = ''
    },
    showIpInput () {
      this.inputIpVisible = true
      this.$nextTick(() => {
        this.$refs.saveIpInput.$refs.input.focus()
      })
    },
    saveUser () {
      if (this.isEmailValid()) {
        //this.validatePhone()
        let user = User.create(this.userDto)
        if (user.getId() == null) {
          this.getCommandBus().execute(
            new UserCreateCommand(
              user.getRoleId(),
              user.getName(),
              user.getMidname(),
              user.getSurname(),
              user.getAvatar(),
              user.getLogin(),
              user.getPassword(),
              user.getEmail(),
              user.getIsAdmin(),
              user.getIsBlocked(),
              user.getIsSystem(),
              user.getApiKey(),
              user.getSystemIps(),
              user.getPhone(),
              user.getTwoFactorAuthType(),
              user.getTelegramId(),
              user.getTelegramUsername()
            )
          ).then(() => {
            this.getEventBus().$emit('userIsCreated')
            this.returnToUserList()
          })
        }
        else {
          this.getCommandBus().execute(
            new UserUpdateCommand(
              user.getGuid(),
              user.getRoleId(),
              user.getName(),
              user.getMidname(),
              user.getSurname(),
              user.getAvatar(),
              user.getIsAvatarDeleted(),
              user.getLogin(),
              user.getPassword(),
              user.getEmail(),
              user.getIsAdmin(),
              user.getIsBlocked(),
              user.getIsSystem(),
              user.getApiKey(),
              user.getSystemIps(),
              user.getPhone(),
              user.getTwoFactorAuthType(),
              user.getTelegramId(),
              user.getTelegramUsername()
            )
          ).then(data => {
            if (user.getIsSystem()) {
              this.getEventBus().$emit('userIsUpdated', this.userDto)
              this.returnToUserList()
            } else {
              // this.$refs.user_registry_card_data.saveRecord().then(data => {
              let cards = this.$refs.cardsWrapper.$refs.cards.$refs.registryCard
              cards[cards.length - 1].saveRecord().then(data => {
                this.getEventBus().$emit('userIsUpdated', this.userDto)
                this.returnToUserList()
              })
            }
          })
        }
      } else {
        this.$message({
          message: this.$locale.access_editor.users_item.email_must_be_valid,
          type: 'warning'
        })
      }
    },
    getUserCard (userDto) {
      if (userDto.id != null) {
        return {
          id: this.cardParams.cardId,
          registryId: this.cardParams.registryId,
          recordId: userDto.id
        }
      }
      return null
    },
    isEmailValid () {
      if (this.userDto.email != null) {
        if ((this.userDto.email).trim() == '') {
          this.userDto.email = null
        }
      }
      return (this.userDto.email === null) ? true : !!(this.reg.test(this.userDto.email))
    },
    validatePhone () {
      if (this.userDto.phone != null) {
        this.userDto.phone = ((this.userDto.phone).toString().trim()).replace(/\D/g, "")
        if (this.userDto.phone == "") {
          this.userDto.phone = null
        }
      }
    }
  }
}
</script>
