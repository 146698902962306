<template>
  <div class="role-main-edit-panel">
    <div class="wrapper">
      <el-form v-if="roleDto != null" :rules="createRoleRules" :model="roleDto" :disabled="isRoleLoading" label-position="left">
        <el-row class="role-main-form-wrapper">
          <el-col class="left-side">
            <div class="main-edit-title">{{$locale.access_editor.titles.main_settings}}</div>
            <el-form-item prop="name" :label="$locale.access_editor.name">
              <el-input v-model="roleDto.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="$locale.access_editor.alias">
              <el-input v-model="roleDto.alias" autocomplete="off" :readonly="roleDto.is_associated_with_db_role"></el-input>
            </el-form-item>
            <el-form-item class="checkbox-group">
              <el-checkbox v-model="roleDto.is_associated_with_db_role" :label="$locale.access_editor.roles_table.is_associated_with_db_role" name="is_associated_with_db_role"></el-checkbox>
            </el-form-item>
            <div class="main-edit-title">{{$locale.access_editor.right_object.access_to_components}}</div>
            <el-form-item class="checkbox-group">
              <el-checkbox v-model="roleDto.is_menu_visible_default" :label="$locale.access_editor.roles_table.is_menu_visible_default" name="is_menu_visible_default"></el-checkbox>
            </el-form-item>
            <el-form-item prop="root_menu_id" :label="$locale.access_editor.roles_table.root_menu_id">
              <treeselect
                  v-model="roleDto.root_menu_id"
                  :placeholder="$locale.main.fields.select"
                  :normalizer="(node) => {return {id: node.id, label: node.name}}"
                  :options="menus"
                  :clear-value-text="$locale.main.message.clear"
                  :loading-text="$locale.main.message.loading"
                  :disable-immediate-search="true"
                  :async="true"
                  :cache-options="false"
                  :append-to-body="false"
                  :load-options="getMenuList"
                  :clearable="true"
                  :delete-removes="false"
                  :backspace-removes="false"
                  @open="getMenuList"
              ></treeselect>
            </el-form-item>
            <el-form-item prop="dashboards" :label="$locale.access_editor.roles_table.default_dashboard">
              <treeselect
                  v-model="roleDto.dashboards"
                  :multiple="true"
                  :placeholder="$locale.main.fields.select"
                  :normalizer="(node) => {return {id: node.id, label: node.name}}"
                  :options="dashboards"
                  :clear-value-text="$locale.main.message.clear"
                  :loading-text="$locale.main.message.loading"
                  :disable-immediate-search="true"
                  :async="true"
                  :cache-options="false"
                  :append-to-body="false"
                  :load-options="getDashboardsList"
                  :clearable="true"
                  :delete-removes="false"
                  :backspace-removes="false"
                  @open="getDashboardsList"
              ></treeselect>
            </el-form-item>
            <el-form-item prop="available_roles" :label="$locale.access_editor.roles_table.available_roles">
              <treeselect
                v-model="roleDto.available_roles"
                :multiple="true"
                :placeholder="$locale.main.fields.select"
                :normalizer="(node) => {return {id: node.id, label: node.name}}"
                :options="roles"
                :clear-value-text="$locale.main.message.clear"
                :loading-text="$locale.main.message.loading"
                :disable-immediate-search="true"
                :async="true"
                :cache-options="false"
                :append-to-body="false"
                :load-options="getRolesList"
                :clearable="true"
                :delete-removes="false"
                :backspace-removes="false"
                @open="getRolesList"
              ></treeselect>
            </el-form-item>
            <el-form-item class="checkbox-group">
              <el-checkbox v-model="roleDto.is_profile_available" :label="$locale.access_editor.roles_table.profile.access" name="is_profile_available"></el-checkbox>
            </el-form-item>
            <el-form-item v-if="roleDto.guid != null">
              <div class="profile-rights-list">
                <div v-for="(item, index) in profile" class="profile-right-item">
                  <label class="title">{{$locale.access_editor.users_item[profile[index].user_field_id]}}</label>
                  <el-checkbox v-model="profile[index].is_readable" :label="$locale.access_editor.roles_table.read" name="is_readable" class="checker" :disabled="!roleDto.is_profile_available"></el-checkbox>
                  <el-checkbox v-model="profile[index].is_editable" :label="$locale.access_editor.roles_table.edit" name="is_editable" class="checker" :disabled="!roleDto.is_profile_available"></el-checkbox>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col class="right-side">
            <div class="main-edit-title">{{$locale.access_editor.right_object.default_mask}}</div>
            <el-form-item class="checkbox-group">
              <el-checkbox v-model="roleDto.is_readable_default" :label="$locale.access_editor.roles_table.read" name="is_readable_default"></el-checkbox>
              <el-checkbox v-model="roleDto.is_addable_default" :label="$locale.access_editor.roles_table.add" name="is_addable_default"></el-checkbox>
              <el-checkbox v-model="roleDto.is_editable_default" :label="$locale.access_editor.roles_table.edit" name="is_editable_default"></el-checkbox>
              <el-checkbox v-model="roleDto.is_deletable_default" :label="$locale.access_editor.roles_table.delete" name="is_deletable_default"></el-checkbox>
            </el-form-item>
            <span class="dialog-footer">
              <el-button @click="returnToRoleList">{{$locale.main.button.cancel}}</el-button>
              <el-button class="save-button" :disabled="roleDto == null || isRoleLoading" @click="saveRole">{{$locale.main.button.save}}</el-button>
            </span>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import Treeselect from '@bingosoftnn/vue-treeselect'
import Role, { RoleDTO } from '@/services/AccessEditor/domain/model/Role/Role'
import RoleCreateCommand from '@/services/AccessEditor/application/command/RoleCreateCommand'
import RoleUpdateCommand from '@/services/AccessEditor/application/command/RoleUpdateCommand'
import RoleProfileQuery from '@/services/AccessEditor/application/query/RoleProfileQuery'
import RoleProfileRight, { RoleProfileRightDTO } from '@/services/AccessEditor/domain/model/Right/RoleProfileRight'
import RoleProfileRightUpdateCommand from '@/services/AccessEditor/application/command/RoleProfileRightUpdateCommand'
import RolesQuery from '@/services/AccessEditor/application/query/RolesQuery'

export default {
  name: 'RoleMainEditPanel',
  components: { Treeselect },
  props: {
    menus: {
      type: Array,
      required: true
    },
    dashboards: {
      type: Array,
      required: true
    }
  },
  inject: ['getEventBus', 'getQueryBus', 'getCommandBus'],
  data () {
    return {
      role: null,
      roleDto: null,
      profile: null,
      roles: [],
      createRoleRules: {
        name: {
          required: true,
          message: this.$locale.main.message.required_field,
          trigger: 'change'
        }
      }
    }
  },
  created: function () {
    this.getEventBus().$on('editRoleEvent', (data) => {
      this.roleDto = data
      if (data == null) {
        this.roleDto = new RoleDTO({})
      }
      if (this.profile == null) {
        this.loadProfile()
      }
    })
    this.getQueryBus().execute(
      new RolesQuery({})
    ).then(data => {
      this.roles = data
    })
  },
  watch: {
    getSaveRoleError: function (err) {

    },
    isRoleLoading: (state) => state
  },
  computed: {
    isRoleLoading () {
      return this.$store.getters['Role/isLoading']
    },
    roleCreated () {
      return this.$store.getters['Role/getLocation']
    },
    getSaveRoleError () {
      return this.$store.getters['Role/getError']
    }
  },
  methods: {
    returnToRoleList () {
      this.getEventBus().$emit('returnToRoleListEvent', {
        'list': true,
        'edit': false
      })
      this.profile = null
    },
    getMenuList () {
      return this.menus
    },
    getDashboardsList () {
      return this.dashboards
    },
    getRolesList () {
      return this.roles
    },
    async loadProfile () {
      let roleId = 0
      if (this.roleDto != null) {
        if (typeof this.roleDto.id === 'number') {
          roleId = this.roleDto.id
        }
      }
      await this.getQueryBus().execute(
        new RoleProfileQuery(roleId)
      ).then(data => {
        this.profile = data
      })
    },
    saveRole () {
      if (this.roleDto.is_associated_with_db_role === true && (this.roleDto.alias == null || this.roleDto.alias == "")) {
        this.$message({
          message: this.$locale.access_editor.need_alias,
          type: 'warning'
        });
      }
      else {
        let role = Role.create(this.roleDto)
        if (role.getId() == null) {
          this.getCommandBus().execute(
            new RoleCreateCommand(
              role.getName(),
              role.getAlias(),
              role.getIsReadable(),
              role.getIsAddable(),
              role.getIsEditable(),
              role.getIsDeletable(),
              role.getIsMenuVisible(),
              role.getIsProfileAvailable(),
              role.getIsAssociatedWithDbRole(),
              role.getRootMenuId(),
              role.getDashboards(),
              role.getAvailableRoles()
            )
          ).then(data => {
            this.getEventBus().$emit('rolesListIsChanged', true)
            this.returnToRoleList()
          })
        }
        else {
          this.getCommandBus().execute(
            new RoleUpdateCommand(
              role.getGuid(),
              role.getName(),
              role.getAlias(),
              role.getIsReadable(),
              role.getIsAddable(),
              role.getIsEditable(),
              role.getIsDeletable(),
              role.getIsMenuVisible(),
              role.getIsProfileAvailable(),
              role.getIsAssociatedWithDbRole(),
              role.getRootMenuId(),
              role.getDashboards(),
              role.getAvailableRoles()
            )
          ).then(data => {
            if (this.roleDto.is_profile_available) {
              this.saveProfileRights()
            }
            this.getEventBus().$emit('rolesListIsChanged', true)
            this.getEventBus().$emit('roleIsUpdated', this.roleDto)
            this.returnToRoleList()
          })
        }
      }
    },
    saveProfileRights () {
      if (this.profile != null) {
        for (let i = 0; i < this.profile.length; i++) {
          let profileRightDto = new RoleProfileRightDTO({
            role_guid: this.roleDto.guid,
            user_field_id: this.profile[i].user_field_id,
            is_readable: this.profile[i].is_readable,
            is_editable: this.profile[i].is_editable
          })
          let profileRight = RoleProfileRight.create(profileRightDto)
          this.getCommandBus().execute(
            new RoleProfileRightUpdateCommand(
              this.roleDto.guid,
              profileRight.getUserFieldId(),
              profileRight.getIsReadable(),
              profileRight.getIsEditable()
            )
          )
        }
      }
    }
  }
}
</script>
