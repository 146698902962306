






























import Vue from 'vue'
import Topic from '@/services/LogicEditor/infrastructure/components/elements/Topic.vue'
import FieldSelect from '@/services/LogicEditor/infrastructure/components/elements/FieldSelect.vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'

export default Vue.extend({
  name: 'TaskImportEventForm',

  props: ['value', 'logicId', 'objectId'],

  components: {
    Topic,
    FieldSelect,
    TreeSelect
  },

  data () {
    return {
      model: this.value,
      rules: {
        task_id: [
          { required: true, message: 'Select task', trigger: 'change' }
        ],
        file_field_id: [
          { required: true, message: 'Select file field', trigger: 'change' }
        ]
      },
      treePropsFileFields: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return data.entity_type_id !== 'file_field'
        }
      },
      tasks: [],
      fields: []
    }
  },

  async mounted () {
    if (!this.fields.length && this.objectId) {
      await this.$http
        .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.objectId}&show_children=true`)
        .then((response) => {
          this.fields = response.data.data
        })
        .catch(error => console.error(error))
    }

    if (!this.tasks.length) {
      await this.$http
        .get(`${this.$config.api}/etleditor/tasks?fields=id,name&order=id:desc`)
        .then((response) => {
          this.tasks = response.data
        })
        .catch(error => console.error(error))
    }
  },

  methods: {
    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    },

    renderContent (h, { node, data, store }) {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    },

    renderOptionLabel (item) {
      return `${item.name} (id: ${item.id}, ${this.$t('object_editor.entity_types.' + item.entity_type_id)})`
    }
  }
})
