<template>
  <div class="formula-statement">
    <el-form-item label="Select">
      <el-input
        v-on:input="model.select = $event !== '' ? $event : null"
        v-bind:value="model.select"
        :readonly="readonly"
        type="textarea"
        :rows="3"
      ></el-input>
    </el-form-item>

    <el-form-item label="Join">
      <el-input
        v-on:input="model.join = $event !== '' ? $event : null"
        v-bind:value="model.join"
        :readonly="readonly"
        type="textarea"
        :rows="3"
      ></el-input>
    </el-form-item>

    <el-form-item label="Group By">
      <el-input
        v-on:input="model.group_by = $event !== '' ? $event : null"
        v-bind:value="model.group_by"
        :readonly="readonly"
        type="textarea"
        :rows="3"
      ></el-input>
    </el-form-item>
  </div>
</template>

<script>
const defaultModel = {
  select: null,
  join: null,
  group_by: null
}

export default {
  name: 'FormulaStatement',

  props: {
    value: {
      type: Object,
      default () {
        return Object.assign({}, defaultModel)
      }
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    value: {
      handler: function () {
        this.model = this.value
      },
      deep: true
    },

    model: {
      handler: function () {
        this.$emit('input', this.model)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value ? this.value : Object.assign({}, defaultModel)
    }
  }
}
</script>

<style scoped>

</style>
