<template>
  <div>
    <el-tree
      node-key="guid"
      ref="tree"
      :data="layers"
      :props="properties"
      :expand-on-click-node="false"
      :show-checkbox="true"
      :render-after-expand="false"
      :default-expand-all="expandLayersByDefault"
      :default-checked-keys="defaultCheckedKeys"
      :filter-node-method="filterTreeHandler"
      @node-click="layerClick"
      @check-change="onCheckChange"
    >
      <span slot-scope="{ node, data }" style="flex: 1; min-width: 0; display: contents;" @contextmenu.prevent="showContextMenu($event, data, node)">
        <LayersIcon v-if="data.properties.iconStyleTypeId != null" :type="data.properties.iconStyleTypeId" :properties="data.properties.style[`${data.properties.iconStyleTypeId}`]" style="width: 15px; height: 15px; min-width: 15px;"/>
        <span :style="data.type === 'layer' ? (node.checked ? 'width: calc(100% - 70px)' : 'width: calc(100% - 15px)') : ''" :class="{'label' : true, 'active': data.guid === activeLayerGuid}" style="margin-left: 2px; display: inline-block;">
          {{ node.label }}
        </span>
        <i class="el-icon-loading" v-if="data.type === 'layer' && data.isLoading"></i>
        <span v-if="data.totalFeatures && node.checked" style="margin-right: 5px;">[{{data.totalFeatures}}]</span>
        <el-slider
          v-if="showLayersOpacity && data.type === 'layer' && node.checked && data.isLoading === false"
          v-model="data.properties.opacity"
          :min="0"
          :max="100"
          class="layer-opacity-slider"
          @change="changeTreeItemOpacity(data, data.properties.opacity)"
        ></el-slider>
      </span>
    </el-tree>
    <vue-context
      :use-scroll-height="true"
      :useScrollWidth="true"
      class="layer-context"
      ref="context-menu"
      v-slot="{ data }">
      <div class="el-dropdown-menu__item" @click="calculateFeatures(data)">
        <i class="el-icon-star-on"></i>Посчитать количество контуров
      </div>
      <div class="el-dropdown-menu__item" @click="focusOnLayer(data)">
        <i class="el-icon-star-on"></i>Расширить до всех объектов слоя
      </div>
    </vue-context>
  </div>
</template>

<script>
import LayersIcon from '@/core/infrastructure/components/LayerIcon'
import { VueContext } from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'

export default {
  name: 'LayersTree',
  components: { LayersIcon, VueContext },
  props: {
    layers: {
      type: Array,
      default: () => []
    },
    expandLayersByDefault: {
      type: Boolean,
      default: false
    },
    showLayersOpacity: {
      type: Boolean,
      default: false
    },
    searchText: {
      type: String,
      default: null
    },
    isFullscreen: {
      type: Boolean,
      default: false
    },
    hideLayers: {
      type: Array,
      default: () => {
        return []
      }
    },
    activeLayerGuid: {
      type: String | null,
      default: null
    }
  },
  data () {
    return {
      colors: [
        '#F59300',
        '#06A6E0',
        '#009945'
      ],
      properties: {
        label: 'name',
        isLeaf: 'leaf',
        children: 'children'
      },
      contextMenuItem: null,
      hideAndUnchecked: [],
      defaultCheckedKeys: []
    }
  },
  watch: {
    searchText () {
      this.filterTree()
    },
    hideLayers: {
      handler: function () {
        this.filterTree()
      },
      deep: true
    }
  },
  mounted () {
    //this.enableDefaultLayers()
  },
  methods: {
    filterTree() {
      this.$nextTick(() => this.$refs['tree'].filter(this.searchText))
    },
    getInterfaceEditorVersion() {
      let version = 1
      let parent = this
      while (parent.$parent) {
        if (parent.$options['_componentTag'] === 'InterfaceViewerV2') {
          version = 2
        }
        parent = parent.$parent
      }
      return version
    },
    focusOnLayer (layer) {
      this.$emit('focus-on-layer', layer)
    },
    calculateFeatures (layer) {
      this.$emit('calculate-features', layer)
    },
    showContextMenu (event, layer) {
      let customEvent = {}
      if (this.getInterfaceEditorVersion() === 1) {
        customEvent.clientX = event.layerX
        customEvent.clientY = event.layerY
        customEvent.target = event.target
      } else {
        customEvent = event
      }
      this.$refs['context-menu'].open(this.isFullscreen ? event : customEvent, layer)
    },
    filterTreeHandler (value, data) {
      // если нужно скрывать - скрываем
      if (this.hideLayers.includes(data.guid)) {
        return false
      }
      return value !== null ? data.name.indexOf(value) !== -1 : true
    },
    changeTreeItemOpacity (treeItem, newOpacity) {
      if (treeItem.type === 'layer') {
        this.$emit('change-layer-opacity', treeItem, newOpacity)
      } else {
        treeItem.children.forEach((child) => {
          this.changeTreeItemOpacity(child, newOpacity)
        })
      }
    },
    setCheckOnLayer (layerGuid, isCheck) {
      this.$refs.tree.setChecked(layerGuid, isCheck)
    },
    getDefaultLayers (array = []) {
      let result = []
      array.forEach((item) => {
        if (item.properties.showByDefault) {
          result.push(item.guid)
          if (item.properties.isActiveByDefault) {
            this.activeLayerGuid = item.guid
          }
        }
        if ((item.children || []).length > 0) {
          result.push(...this.getDefaultLayers(item.children))
        }
      })

      return result
    },
    layerClick (layer, node) {
      if (node.checked && layer.properties.isEditable) {
        this.$emit('click-on-layer', layer.guid)
      }
    },
    onCheckChange (data, checked, indeterminate) {
      if (data.type === 'layer') {
        if (!checked && data.guid === this.activeLayerGuid) {
          this.activeLayerGuid = null
        }
        if (!checked) {
          delete(data.totalFeatures)
        }
        this.$emit('layer-select', data, checked)
      }
    },
    setCheckedLayerGuids (checkedGuids) {
      this.$nextTick(() => {
        this.$refs['tree'].setCheckedKeys(checkedGuids)
      })
    },
    getCheckedKeys() {
      return this.$refs['tree'].getCheckedKeys(false)
    }
  }
}
</script>

<style scoped>
/deep/ .el-tree-node__children{
  border-left: 2px solid #4FA9F3;
  margin-left: 5px;
  /* padding-left: 10px; */
  /* box-shadow: 4px 1px 0px -3px rgba(27, 141, 229, 1) inset; */
}
/deep/ .el-tree-node.is-current{
  background-color: #EEF0F1;
  background-color: rgb(245 247 250);
}
/deep/ .el-tree-node.is-focusable.is-expanded{
  background-color: transparent;
}
/deep/ .el-tree-node {
  overflow: hidden;
}
.layer-context {
  padding: 0;
}
.layer-opacity-slider {
  width: 30px;
  position: relative;
  margin-right: 7px;
  display: inline-block;
}
.layer-opacity-slider /deep/ .el-slider__runway {
  height: 3px;
}
.layer-opacity-slider /deep/ .el-slider__bar {
  height: 3px;
}
.layer-opacity-slider /deep/ .el-slider__button {
  width: 8px;
  height: 8px;
}
.layer-opacity-slider /deep/ .el-slider__button-wrapper {
  top: -16px;
}
.label {
  color: #2D3239;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.active {
  border-bottom: 1px dashed black;
}
.dot {
  vertical-align: text-top;
  width: 10px;
  display: inline-block;
  height: 10px;
  border-radius: 10px;
  margin-right: 5px;
}
.loading-icon {

}
</style>
