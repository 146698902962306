<template>
  <div class="editor-layout">
    <el-container>
      <el-header class="editor-layout__header" height="">
        <template v-if="isHeader">
          <slot name="header"></slot>
        </template>
        <template v-else>
          <div class="editor-layout__title">
            <slot name="header.title"></slot>
          </div>

          <div class="editor-layout__toolbar">
            <slot name="header.toolbar"></slot>
          </div>
        </template>
      </el-header>
      <el-container>
        <Split style="height: calc(100vh - 96px);">
          <SplitArea :size="treeSize">
            <slot name="tree.side"></slot>
          </SplitArea>
          <SplitArea :size="editorSize">
            <slot name="editor.side"></slot>
          </SplitArea>
        </Split>
      </el-container>
    </el-container>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EditorLayout',

  props: {
    treeSize: {
      type: Number,
      default: 50
    },

    editorSize: {
      type: Number,
      default: 50
    }
  },

  computed: {
    isHeader () {
      return !!this.$slots.header
    }
  }
}
</script>

<style lang="scss">
  .editor-layout {
    padding-top: 15px;
    height: calc(100vh - 56px);

    &__header, &__title, &__toolbar {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__header {
      justify-content: space-between;
      border-bottom: 1px solid #efefef;
      font-size: 24px;
      font-weight: 700;
      padding: 0 20px 10px 20px;
    }

    &__title {
      justify-content: flex-start;
    }

    &__toolbar {
      justify-content: flex-end;
    }
  }
</style>
