import EDS from '@/core/infrastructure/components/EDS/index.vue'

export default class CommandExecutor {
  static async execute (
    context: any,
    commandId: number,
    showNotificationMessages = true,
    callback?: Function
  ): Promise<any> {
    let card = context.getCard()

    return new Promise(async (resolve, reject) => {
      try {
        const command = await context.$http.get(`${context.$config.api}/v2/logiceditor/commands/${commandId}`)
        if (command.data.event_id === 'eds_sign_event') {
          await card.saveRecord()

          if (!callback) {
            callback = async () => {
              card.$emit('cancelChanges')
              await context.$nextTick(() => {
                context.openRegistryCard({
                  'registryId': card.registryId,
                  'cardId': card.cardId,
                  'cardName': card.name,
                  'recordId': parseInt(card.activeRecordId)
                })
              })
            }
          }

          await this.edsSignEvent(command, context, callback)
        } else {
          await context.getCard().saveRecord({ commandId: commandId, showNotificationMessages })
        }
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }

  private static async edsSignEvent (command, context, callback?: Function) {
    const signType = command.data.properties.find(property => property.type === 'sign_type')
    if (!signType || (signType && !signType.value)) {
      throw Error('О боже! Не настроили тип подписания ЭП')
    }

    const signTypes = {
      client: 'клиентская ЭП',
      server: 'серверная ЭП',
      combined: 'комбинированная ЭП'
    }

    const h = context.$createElement
    command.data.properties.forEach(function (property) {
      if (property.type != 'field') {
        command.data[property.id] = property.value
      }
    })
    context.$msgbox({
      title: `Подписание данных с ЭП (${signTypes[signType.value]})`,
      message: h('p', {}, [
        h(EDS, { props: { command: command.data, context: context, callback: callback }, key: context.generateGuid() })
      ]),
      customClass: 'dashboard_window_width_40',
      showClose: false,
      closeOnPressEscape: false,
      showCancelButton: false,
      showConfirmButton: false,
      closeOnClickModal: false
    })
  }
}
