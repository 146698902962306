





















































































































































































































































































import mixins from 'vue-typed-mixins'
import Command, { CommandDTO } from '@/services/LogicEditor/domain/model/Command'
import CommandCreateCommand from '@/services/LogicEditor/application/command/CommandCreateCommand'
import CommandUpdateCommand from '@/services/LogicEditor/application/command/CommandUpdateCommand'
import CommandByGuidQuery from '@/services/LogicEditor/application/query/CommandByGuidQuery'
import CommandEventTypePropertiesQuery from '@/services/LogicEditor/application/query/CommandEventTypePropertiesQuery'
import PluginsQuery from '@/services/LogicEditor/application/query/PluginsQuery'
import CommandEventTypesQuery from '@/services/LogicEditor/application/query/CommandEventTypesQuery'
import LogicTreeElementsQuery from '@/services/LogicEditor/application/query/LogicTreeElementsQuery'

import SelectRefField from '@/components/Common/SelectRefField.vue'
import EdsSignEventForm from './EdsSignEventForm.vue'
import ReportEventForm from './ReportEventForm.vue'
import SendToDatabusEventForm from './SendToDatabusEventForm.vue'
import LogicGroupedEntities from '@/core/infrastructure/components/LogicGroupedEntities.vue'
import SendEmailEventForm
  from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/SendEmailEventForm.vue'
import ConvertToPdfEventForm
  from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/ConvertToPdfEventForm.vue'
import TaskImportEventForm
  from '@/services/LogicEditor/infrastructure/components/forms/CommandForm/TaskImportEventForm.vue'

import draggable from 'vuedraggable'
import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'

export default mixins(FormMixin).extend({
  name: 'CommandForm',

  components: {
    TaskImportEventForm,
    SendEmailEventForm,
    SelectRefField,
    EdsSignEventForm,
    ReportEventForm,
    LogicGroupedEntities,
    ConvertToPdfEventForm,
    SendToDatabusEventForm,
    draggable
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    commandElements () {
      // Исключаем уже прикреплённые команды из списка, а также исключаем родительскую команду
      const excludeCommands = this.model.child_commands
        .filter(child => typeof child.id !== 'undefined' && child.id !== null)
        .map(child => child.id)

      return this.allCommandElements
        .filter(el => !excludeCommands.includes(el.element_id))
        .filter(el => this.model.id !== el.element_id)
        .map((el) => ({
          id: el.element_id,
          guid: el.element_guid,
          name: `${el.name} (id: ${el.element_id})`
        }))
    },

    isDefaultForm () {
      return !this.model.is_plugin && !this.model.is_complex
    },

    isPluginForm () {
      return this.model.is_plugin && !this.model.is_complex
    },

    isComplexForm () {
      return !this.model.is_plugin && this.model.is_complex
    },

    isNew () {
      if (typeof this.model.guid === 'undefined') {
        return true
      }

      return this.model.guid === null
    },

    commandCreated () {
      return this.$store.getters['Command/getCommandLocation']
    },

    filteredEvents () {
      if (this.model.is_timer) {
        return this.events.filter(event => ['add_event'].includes(event.id))
      }

      return this.events
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ],
        event_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.event'), trigger: 'change' }
        ],
        plugin_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.plugin'), trigger: 'change' }
        ],
        command_constraints: [
          { required: true, message: this.$t('logic_editor_v2.rules.constraints'), trigger: 'change' }
        ],
        period: [
          { required: true, message: this.$t('logic_editor_v2.rules.period'), trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.model)) {
          this.model = value
        }
      },
      deep: true
    },

    model: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', value)
        }
      },
      deep: true
    },

    commandCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new CommandByGuidQuery(
            location.replace('/commands/', '')
          )
        ).then(response => {
          this.model = response
          this.$emit('created', response)
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      formType: 'default',
      accordionActive: null,

      events: [],
      plugins: [],
      allCommandElements: [],
      commandEventTypeProperties: [],

      propertiesModel: {},
      eventFormLoaded: false,

      commandSelected: null,

      periodTypes: ['5_minutes', '30_minutes', '1_hour', '12_hours', 'day'],

      isLoaded: false
    }
  },

  async mounted () {
    if (!this.events.length) {
      this.events = await this.getQueryBus().execute(
        new CommandEventTypesQuery()
      )
    }

    if (!this.plugins.length) {
      this.plugins = await this.getQueryBus().execute(
        new PluginsQuery()
      )
    }

    this.allCommandElements = await this.getQueryBus().execute(
      new LogicTreeElementsQuery({
        fields: 'element_guid,name,element_id',
        order: 'name:asc',
        logic_id: this.logicId,
        element_type: 'command'
      })
    )

    await this.eventFormLoading(this.model.event_id)

    if (this.isPluginForm) this.formType = 'plugin'
    else if (this.isComplexForm) this.formType = 'complex'
    else this.formType = 'default'

    this.$nextTick(() => {
      this.isLoaded = true
    })
  },

  methods: {
    setIsTimer () {
      if (!this.model.timer_id) {
        this.model.period = null
        this.model.event_id = null
        this.model.plugin_id = null
        this.model.target_state_id = null
        this.model.child_commands = []
        this.model.eds_sign_command_fields = []
        this.model.command_external_states = []
        this.model.properties = null

        this.eventFormLoaded = false

        this.$refs.form.clearValidate(['event_id', 'plugin_id'])
      }
    },

    updateByChild (value, type) {
      this[type] = value
    },

    submit (callback) {
      if (this.model.properties) {
        this.commandEventTypeProperties.forEach(property => {
          let propIndex = this.model.properties.findIndex(prop => prop.id === property.id)

          this.model.properties[propIndex].value = this.propertiesModel[property.id]
        })
      }

      if (this.validate()) {
        this.getCommandBus()
          .execute(
            this.getCommand()
          ).then(() => {
            if (typeof callback !== 'undefined') {
              callback()
            }
          })
      }
    },

    async eventFormLoading (eventId, isChangeEvent = false) {
      const allowEvents = [
        'eds_sign_event', 'create_report_event', 'send_to_databus_event',
        'run_external_command_event', 'send_email', 'task_import_event',
        'convert_to_pdf'
      ]

      if (eventId !== null && allowEvents.includes(eventId)) {
        if (!this.model.properties || isChangeEvent) {
          this.model.properties = []
          this.propertiesModel = {}
        }

        await this.getQueryBus().execute(
          new CommandEventTypePropertiesQuery(this.model.event_id)
        ).then((response) => {
          response.forEach(property => {
            const propIndex = this.model.properties.findIndex(prop => prop.id === property.id)
            const defValue = this.getDefValue(property)

            if (propIndex === -1) {
              this.model.properties.push({
                id: property.id,
                type: property.type,
                value: defValue
              })
            }

            this.$set(
              this.propertiesModel,
              property.id,
              propIndex !== -1
                ? this.model.properties[propIndex].value
                : defValue
            )

            this.commandEventTypeProperties.push(property)
          })

          this.eventFormLoaded = true
        })
      }
    },

    // Получение команды для POST или PUT запроса
    getCommand () {
      const command = Command.create(this.value)

      if (this.isNew) {
        return new CommandCreateCommand(
          command.getName(),
          command.getDescription(),
          command.getIsPlugin(),
          command.getIsComplex(),
          command.getProperties(),
          command.getBeforeCommandId(),
          command.getAfterCommandId(),
          command.getCancelCommandId(),
          command.getCommandStates(),
          command.getCommandExternalStates(),
          command.getEdsSignCommandFields(),
          command.getCommandConstraints(),
          command.getChildCommands(),
          command.getLogicId(),
          command.getTargetStateId(),
          command.getPluginId(),
          command.getEventId(),
          command.getAlias(),
          command.getIsTimer(),
          command.getPeriod(),
          command.getParentTreeElementId()
        )
      }

      return new CommandUpdateCommand(
        command.getGuid(),
        command.getName(),
        command.getDescription(),
        command.getIsPlugin(),
        command.getIsComplex(),
        command.getProperties(),
        command.getBeforeCommandId(),
        command.getAfterCommandId(),
        command.getCancelCommandId(),
        command.getCommandStates(),
        command.getCommandExternalStates(),
        command.getEdsSignCommandFields(),
        command.getCommandConstraints(),
        command.getChildCommands(),
        command.getTargetStateId(),
        command.getPluginId(),
        command.getEventId(),
        command.getAlias(),
        command.getIsTimer(),
        command.getPeriod()
      )
    },

    // Валидация всех дочерних форм
    childFormValid () {
      if (this.$refs.childCommandForm) {
        let cntx = 0

        this.$refs.childCommandForm.forEach((form) => {
          if (form.validate()) {
            cntx++
          }
        })

        return cntx === this.$refs.childCommandForm.length
      }

      return true
    },

    // Валидация формы настройки ЭЦП
    edsValidate () {
      if (this.model.event_id === 'eds_sign_event') {
        return typeof this.$refs.edsForm !== 'undefined' && this.$refs.edsForm.validate()
      }

      return true
    },

    // Валидация формы настройки импорта через ETL
    taskImportValidate () {
      if (this.model.event_id === 'task_import_event') {
        return typeof this.$refs.taskImportForm !== 'undefined' && this.$refs.taskImportForm.validate()
      }

      return true
    },

    // Валидация формы настройки генерации отчёта
    reportValidate () {
      if (this.model.event_id === 'create_report_event') {
        return typeof this.$refs.reportForm !== 'undefined' && this.$refs.reportForm.validate()
      }

      return true
    },

    // Валидация формы настройки генерации отчёта
    sendToDatabusValidate () {
      if (this.model.event_id === 'send_to_databus_event') {
        return typeof this.$refs.sendToDatabusForm !== 'undefined' && this.$refs.sendToDatabusForm.validate()
      }

      return true
    },

    // Валидация формы отправки почты
    sendEmailValidate () {
      if (this.model.event_id === 'send_email') {
        return typeof this.$refs.sendEmailForm !== 'undefined' && this.$refs.sendEmailForm.validate()
      }

      return true
    },

    // Валидация формы конвертации в PDF
    convertToPdfValidate () {
      if (this.model.event_id === 'convert_to_pdf') {
        return typeof this.$refs.convertToPdfForm !== 'undefined' && this.$refs.convertToPdfForm.validate()
      }

      return true
    },

    // Валидация родительской формы
    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        isValid = !(!valid || !this.childFormValid() || !this.edsValidate() || !this.taskImportValidate() || !this.reportValidate() || !this.sendToDatabusValidate() || !this.sendEmailValidate() || !this.convertToPdfValidate())
      })

      return isValid
    },

    // При выборе события команды, выполняем запрос для получения списка свойств
    async changeEvent (value) {
      this.eventFormLoaded = false
      this.model.eds_sign_command_fields = []
      this.model.command_external_states = []
      this.model.properties = null

      await this.eventFormLoading(value, true)
    },

    // Получение значения по умолчанию для свойства команды
    getDefValue (prop) {
      if (['boolean', 'integer', 'array', 'object'].indexOf(prop.primitive_type) !== -1 && prop.default_value !== null) {
        return JSON.parse(prop.default_value)
      } else {
        return prop.default_value
      }
    },

    // Прикрепление существующей команды в качестве дочерней (is_complex === true)
    async attachCommand () {
      if (this.commandSelected) {
        const command = await this.getQueryBus().execute(
          new CommandByGuidQuery(this.commandSelected)
        )

        this.model.child_commands.push(command)

        this.commandSelected = null
      }
    },

    // Добавление новой дочерней комманды (is_complex === true)
    addChildCommand () {
      this.model.child_commands.push(
        CommandDTO.createChildCommand(this.$t('logic_editor_v2.default_values.new_command'), this.logicId)
      )
    },

    // Удаление дочерней команды (is_complex === true)
    removeChildCommand (index) {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.model.child_commands.splice(index, 1)
      })
    },

    // Вычисление типа формы
    formTypeChange (value) {
      if (this.formType === 'plugin') {
        this.model.is_plugin = true
        this.model.is_complex = false
      } else if (this.formType === 'complex') {
        this.model.is_plugin = false
        this.model.is_complex = true
      } else {
        this.model.is_plugin = false
        this.model.is_complex = false
      }

      this.model.event_id = null
      this.model.plugin_id = null
      this.model.target_state_id = null
      this.model.child_commands = []
      this.model.eds_sign_command_fields = []
      this.model.command_external_states = []
      this.model.properties = null

      this.eventFormLoaded = false

      this.$refs.form.clearValidate(['event_id', 'plugin_id'])
    }
  }
})
