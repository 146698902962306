import { isNumeric } from '@/helpers/index'
enum Operation {
   eq = 'eq',
   neq = 'neq',
   lte = 'lte',
   gte = 'gte',
   lt = 'lt',
   gt = 'gt',
   isNull = 'is_null',
   isNotNull = 'is_not_null',
   in = 'in',
   notIn = 'not_in',
}
type filterTypes ='field' | 'constant'
 interface IDataFilters {
  alias: string;
  attribute: string;
  equalsType: Operation;
  isKey: boolean;
  isXref: boolean;
  type: filterTypes
}
interface IRowClassRulesBuilder {
  id: number;
  operator: 'and'| 'or'
  className: string
  nameCondition: string
  dataFilter: IDataFilters[]
}
interface IRowClassRules {
  [cssClassName: string]: (params: any) => boolean | string;
}
interface IModelCard {
  id?: number,
  guid?: string,
  [attr: string]: any;
}
const notMathOperation = [Operation.isNull, Operation.isNotNull, Operation.in, Operation.notIn]

const mathOperations = {
  [Operation.eq]: function (x: number, y: number): boolean { return x === y },
  [Operation.neq]: function (x: number, y: number): boolean { return x !== y },
  [Operation.lt]: function (x: number, y: number): boolean { return x < y },
  [Operation.lte]: function (x: number, y: number): boolean { return x <= y },
  [Operation.gt]: function (x: number, y: number): boolean { return x > y },
  [Operation.gte]: function (x: number, y: number): boolean { return x >= y }
}
const notMathOperations = {
  [Operation.isNull]: function (x, y?): boolean {
    if (isNumeric(x)) return false
    if (x?.length) return false
    return true
  },
  [Operation.isNotNull]: function (x, y?): boolean {
    if (isNumeric(x)) return true
    if (x?.length) return true
    return false
  },
  [Operation.in]: function (x, y: string[] | number): boolean {
    let attr = x + ''
    let valueColumn = y + ''
    if (attr?.length && valueColumn?.length) {
      let arrAttr = attr.split(',')
      let arrvalueColumn = valueColumn.split(',')
      // сравнить элементы двух массивов
      return arrAttr.some(el => arrvalueColumn.includes(el))
    }
    return false
  },
  [Operation.notIn]: function (x, y: string[] | number): boolean {
    let attr = x + ''
    let valueColumn = y + ''
    if (attr?.length && valueColumn?.length) {
      let arrAttr = attr.split(',')
      let arrvalueColumn = valueColumn.split(',')
      // сравнить элементы двух массивов
      return !arrAttr.some(el => arrvalueColumn.includes(el))
    }
    return false
  }
}
export default class RowClassRulesBuilder {
  static build (rules: IRowClassRulesBuilder[], modelCard: IModelCard = {}): IRowClassRules {
    let result = Object.create(null)

    result = rules.reduce((acc, item) => {
      let userClass = item.className
      acc[userClass] = function (params) {
        return this.сalculateRule(params, item, modelCard)
      }.bind(this)
      return acc
    }, {})

    return result
  }
  static сalculateRule (params, rules: IRowClassRulesBuilder, modelCard): boolean | string {
    if (params.data) {
      let result: boolean[] = []
      result = rules.dataFilter.map(item => {
        let mathOperation = !notMathOperation.includes(item.equalsType)
        if (item.type === 'constant' && mathOperation) {
          return this.сalculateConstant(item, params)
        }
        if (item.type === 'field' && mathOperation) {
          return this.сalculateField(item, params, modelCard)
        }
        if (!mathOperation) {
          return this.сalculateNotMathOperation(item, params, modelCard)
        }
        return false
      })
      if (rules.operator === 'and') {
        return result.every(item => item)
      }
      if (rules.operator === 'or') {
        return result.some(item => item)
      }
    }

    return ''
  }
  static сalculateConstant (dataFilters: IDataFilters, dataTable): boolean {
    // Псевдоним атрибута
    let attr: string = dataFilters.alias
    if (dataFilters.isXref) {
      attr = `${attr}id`
    }
    let value: number | undefined = isNumeric(dataFilters.attribute) ? +dataFilters.attribute : undefined
    let attrColumn: number | undefined = isNumeric(dataTable.data[attr]) ? +dataTable.data[attr] : undefined
    if (typeof value === 'undefined' || typeof attrColumn === 'undefined') {
      return false
    }
    let equalsType: Operation = dataFilters.equalsType

    return mathOperations[equalsType](attrColumn, value)
  }
  static сalculateField (dataFilters: IDataFilters, dataTable, modelCard: IModelCard): boolean {
    // Псевдоним атрибута
    let attr: string = dataFilters.alias
    if (dataFilters.isXref) {
      attr = `${attr}id`
    }
    // поле в карточке
    let value: number | undefined = isNumeric(modelCard[dataFilters.attribute]) ? +modelCard[dataFilters.attribute] : undefined
    let attrColumn: number | undefined = isNumeric(dataTable.data[attr]) ? +dataTable.data[attr] : undefined
    if (typeof value === 'undefined' || typeof attrColumn === 'undefined') {
      return false
    }
    let equalsType: Operation = dataFilters.equalsType

    return mathOperations[equalsType](attrColumn, value)
  }
  static сalculateNotMathOperation (dataFilters: IDataFilters, dataTable, modelCard: IModelCard): boolean {
    let attr: string = dataFilters.alias
    if (dataFilters.isXref) {
      attr = `${attr}id`
    }

    let attrColumn: any = dataTable.data[attr]
    let equalsType: Operation = dataFilters.equalsType
    // мн. ссылка
    if (dataFilters.type === 'field') {
      let value: string[] = modelCard[dataFilters.attribute]
      if (!attrColumn || !value) {
        return false
      }
      return notMathOperations[equalsType](attrColumn, value)
    }
    return notMathOperations[equalsType](attrColumn)
  }
}
