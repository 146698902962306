






















import Vue from 'vue'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'
import LogicByGuidQuery from '@/services/LogicEditor/application/query/LogicByGuidQuery'
import Logic from '@/services/LogicEditor/domain/model/Logic'
import LogicCreateCommand from '@/services/LogicEditor/application/command/LogicCreateCommand'
import LogicUpdateCommand from '@/services/LogicEditor/application/command/LogicUpdateCommand'

export default Vue.extend({
  name: 'LogicForm',

  props: {
    value: Object
  },

  components: {
    RegistrySelectTree
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['Logic/isLogicLoading']
    },

    logicCreated () {
      return this.$store.getters['Logic/getLogicLocation']
    },

    rules () {
      return {
        entity_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    logicCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new LogicByGuidQuery(
            location.replace('/logic/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      model: this.value
    }
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let logic = Logic.create(this.value)

      if (this.isNew) {
        return new LogicCreateCommand(
          logic.getEntityId(),
          logic.getDescription(),
          logic.getAlias()
        )
      }

      return new LogicUpdateCommand(
        logic.getGuid(),
        logic.getDescription(),
        logic.getAlias()
      )
    }
  }
})
