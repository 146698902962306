<template>
  <div class="ag-paging-panel ag-unselectable">
    <span class="ag-paging-row-summary-panel">
      <div style="display: inline-block">Количество записей:&nbsp;
        <el-button
          style="padding:0"
          v-show="showBtn"
          type="text"
          size="small"
          @click="onClick"
          :loading="loading"
          plain
        >?</el-button>
        <span class="ag-paging-row-summary-panel-number">{{ count }}</span>
      </div>
    </span>
    <!-- <span class="ag-paging-page-summary-panel" role="presentation">
        <div class="ag-paging-button">
          <span
            class="ag-icon ag-icon-first"
            @click="$emit('first-page')"
          ></span>
        </div>
        <div
          class="ag-paging-button">
          <span
            class="ag-icon ag-icon-previous"
            @click="$emit('previous-page')"
            ></span>
        </div>
        <span class="ag-paging-description" aria-hidden="true">
            <span class="margin_class">Страница</span>
            <span ref="lbCurrent" class="ag-paging-number">{{paginationData.currentPage}}</span>
            <span class="margin_class">из</span>
            <span ref="lbTotal" class="ag-paging-number">{{paginationData.totalPage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}</span>
        </span>
        <div class="ag-paging-button" >
          <span
            class="ag-icon ag-icon-next"
            @click="$emit('next-page')"
          ></span>
        </div>
        <div class="ag-paging-button">
          <span
            class="ag-icon ag-icon-last"
            @click="$emit('last-page')"
          ></span>
        </div>
    </span> -->
  </div>
</template>

<script>
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { DataAPI } from '@/services/RegistryTable/infrastructure/api/DataAPI'
export default {
  props: {
    paginationData: {
      type: Object
    },
    registryId: {
      type: Number
    }
  },
  data () {
    return {
      showBtn: true,
      loading: false,
      count: null,
      entityId: null
    }
  },
  methods: {
    async getNumberRecords () {
      try {
        let response = await APIClient.shared.request(new DataAPI.GetData(this.registryId, { 'request_type': 'count' }))
        return response.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } catch (error) {
        return 0
      }
    },
    async onClick () {
      this.loading = true
      this.count = await this.getNumberRecords()
      this.loading = false
      this.showBtn = false
    }
  }
}
</script>

<style>
  .margin_class {
    margin: 5px;
  }

  .ag-theme-alpine .ag-paging-panel {
    border: 1px solid #ccc;
  }
</style>
