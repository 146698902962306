export default class StiHelper {
  constructor (url, timeout) {
    this.url = url
    this.timeout = timeout
  }

  process (args, callback) {
    if (args) {
      if (callback) { args.preventDefault = true }

      if (args.event === 'BeginProcessData') {
        if (args.database === 'XML' || args.database === 'JSON' || args.database === 'Excel') { return callback(null) }
        if (args.database === 'Data from DataSet, DataTables') { return callback(args) }
      }

      var command = {}
      for (var p in args) {
        if (p === 'report') {
          if (args.report && (args.event === 'CreateReport' || args.event === 'SaveReport' || args.event === 'SaveAsReport')) { command.report = JSON.parse(args.report.saveToJsonString()) }
        } else if (p === 'settings' && args.settings) command.settings = args.settings
        else if (p === 'data') command.data = window.Stimulsoft.System.Convert.toBase64String(args.data)
        else if (p === 'variables') command[p] = this.getVariables(args[p])
        else command[p] = args[p]
      }

      var sendText = window.Stimulsoft.Report.Dictionary.StiSqlAdapterService.getStringCommand(command)
      if (!callback) {
        callback = function (args) {
          if (!args.success || !window.Stimulsoft.System.StiString.isNullOrEmpty(args.notice)) {
            var message = window.Stimulsoft.System.StiString.isNullOrEmpty(args.notice) ? 'There was some error' : args.notice
            window.Stimulsoft.System.StiError.showError(message, true, args.success)
          }
        }
      }
      window.Stimulsoft.Helper.send(sendText, callback)
    }
  }

  send (json, callback) {
    try {
      var request = new XMLHttpRequest()
      request.open('post', this.url, true)
      request.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate')
      request.setRequestHeader('Cache-Control', 'max-age=0')
      request.setRequestHeader('Pragma', 'no-cache')
      request.timeout = this.timeout * 1000
      request.onload = function () {
        if (request.status === 200) {
          var responseText = request.responseText
          request.abort()

          try {
            var args = JSON.parse(responseText)
            if (args.report) {
              var json = args.report
              args.report = new window.Stimulsoft.Report.StiReport()
              args.report.load(json)
            }

            callback(args)
          } catch (e) {
            window.Stimulsoft.System.StiError.showError(e.message)
          }
        } else {
          window.Stimulsoft.System.StiError.showError('Server response error: [' + request.status + '] ' + request.statusText)
        }
      }
      request.onerror = function (e) {
        var errorMessage = 'Connect to remote error: [' + request.status + '] ' + request.statusText
        window.Stimulsoft.System.StiError.showError(errorMessage)
      }
      request.send(json)
    } catch (e) {
      var errorMessage = 'Connect to remote error: ' + e.message
      window.Stimulsoft.System.StiError.showError(errorMessage)
      request.abort()
    }
  }

  getUrlVars (json, callback) {
    var vars = {}
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value
    })
    return vars
  }

  isNullOrEmpty (value) {
    return value == null || value === '' || value === undefined
  }

  getVariables (variables) {
    if (variables) {
      for (let variable of variables) {
        if (variable.type === 'DateTime' && variable.value != null) { variable.value = variable.value.toString('YYYY-MM-DD HH:mm:SS') }
      }
    }

    return variables
  }
}
