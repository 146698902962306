import { ActionTree } from 'vuex'
import { EventState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, EventLoadedEvent, EventLoadingEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { EventAPI } from '@/services/ActivityService/infrastructure/api/EventAPI'

export const actions: ActionTree<EventState, any> = {
  [ActionTypes.GET_EVENTS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new EventLoadingEvent())
      let res = await APIClient.shared.request(new EventAPI.GetEvents(data.payload))
      commit(new EventLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
