<template>
    <el-form style="height: calc(100% - 12px)" v-loading="loading">
        <InterfaceEditor
          v-if="version === 1"
          ref="interface_editor"
          @save="saveStructure"
          :entityTitle="name">
        </InterfaceEditor>
        <InterfaceEditorV2
          v-else-if="version === 2"
          type="dashboard"
          ref="interface_editor_v2"
          @save="saveStructure">
        </InterfaceEditorV2>
    </el-form>
</template>

<script>
import Dashboard from './Models/Dashboard'
import InterfaceEditor from '@/components/InterfaceEditor'
import InterfaceEditorV2 from '@/services/InterfaceEditor'
export default {
  components: {
    InterfaceEditor,
    InterfaceEditorV2
  },
  props: ['dashboard-id'],
  name: 'viewer',
  data () {
    return {
      editorModel: null,
      version: null,
      loading: true
    }
  },
  computed: {
    name () {
      if (this.editorModel === null) {
        return null
      }
      return this.editorModel.name
    }
  },
  async mounted () {
    if (!this.dashboardId) {
      return false
    }
    this.editorModel = await new Dashboard().find(this.dashboardId)
    const structure = JSON.parse(this.editorModel.structure)
    if (typeof structure.version !== 'undefined') {
      this.version = structure.version
    } else {
      this.version = 1
    }
    this.$nextTick(() => {
      this.loadInterface(structure)
    })
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  methods: {
    loadInterface (data) {
      if (this.version === 1) {
        this.$refs.interface_editor.loadState(data)
      } else if (this.version === 2) {
        this.$refs.interface_editor_v2.loadInterface(data)
      }
    },
    async saveStructure (structure) {
      if (!this.editorModel) {
        return false
      }
      let dashboard = new Dashboard({ id: this.editorModel.id })
      await dashboard.sync(Object.assign(this.editorModel, {
        structure: Object.assign(structure, { version: this.version })
      }))
    }
  }
}
</script>

<style scoped>

</style>
