export default class XrefEditor {
  private value: any
  private input: HTMLSelectElement
  private axios: any
  private api_url: any
  private attributeId: number
  private options: [] = []
  private isMulti: boolean

  init (params) {
    this.attributeId = params.colDef.field.replace(/^\D+/g, '').replace('_', '')
    this.value = params.value || []
    try {
      this.value = JSON.parse(params.value)
    } catch (e) {}

    this.axios = params.axios
    this.api_url = params.api_url
    this.isMulti = params.multi

    this.input = document.createElement('select')
    this.input.id = 'input'
    this.input.multiple = !!this.isMulti

    this.buildOptions([{ name: 'Загрузка...' }])

    this.input.addEventListener('change', (event: any) => {
      this.value = this.options.filter((item:any) => parseInt(item.id) === parseInt(event.target.value))
    })
    this.loadingOptions()
  }

  loadingOptions () {
    this.axios.get(`${this.api_url}/registryservice/xref/${this.attributeId}/data?limit=100`)
      .then((response) => {
        this.options = response.data
        this.buildOptions([{ name: 'Пусто' }, ...this.value.map((_) => Object.assign(_, { selected: true })),
          ...response.data.filter((item) => {
            return !this.value.map(_ => parseInt(_.id)).includes(parseInt(item.id))
          })])
      })
      .catch((error) => {
        console.log(error)
        this.buildOptions([{ name: 'Ошибка загрузки' }])
      })
  }

  buildOptions (array) {
    this.input.innerHTML = ''
    array.forEach((item) => {
      let option = document.createElement('option')
      option.value = item.id
      option.text = item.name
      option.selected = !!item.selected
      this.input.appendChild(option)
    })
  }

  /* Component Editor Lifecycle methods */

  // gets called once when grid ready to insert the element
  getGui () {
    return this.input
  }

  // the final value to send to the grid, on completion of editing
  getValue () {
    // this simple editor doubles any value entered into the input
    return this.value
  }

  // after this component has been created and inserted into the grid
  afterGuiAttached () {
    this.input.focus()
  }
}
