import AbstractQueryRepository from '@/core/domain/repository/AbstractQueryRepository'
import EDSQueryRepositoryInterface from '@/core/domain/repository/modules/EDSQueryRepositoryInterface'
import {APIClient} from '@/core/infrastructure/api/APIClient'
import {EDSAPI} from '@/core/infrastructure/api/modules/EDSAPI'

export default class EDSQueryRepository extends AbstractQueryRepository implements EDSQueryRepositoryInterface {
  getHashes(registryId: number, recordId: number, certificate: string): Promise<any> {
    return APIClient.shared.request(new EDSAPI.GetHashes(registryId, recordId, certificate))
  }

  getSigningFields(registryId: number, recordId: number, params: any): Promise<any> {
    return APIClient.shared.request(new EDSAPI.GetFields(registryId, recordId, params))
  }

  getFullCommandInfo(recordId: number, commandId: number, params: any): Promise<any> {
    return APIClient.shared.request(new EDSAPI.GetFullCommandInfo(recordId, commandId, params))
  }

  createFileWithAllInformation(recordId: number, commandId: number, params: any): Promise<any> {
    return APIClient.shared.request(new EDSAPI.createFileWithAllInformation(recordId, commandId, params))
  }

  generateArchiveAndSave(registryId: number, recordId: number, params: any): Promise<any> {
    return APIClient.shared.request(new EDSAPI.generateArchiveAndSave(registryId, recordId, params))
  }
}
