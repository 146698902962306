<template>
  <div class="card_and_dashboard">
    <el-header v-show="hideBreadcrumb" style="height: 24px; overflow-y: auto;">
      <el-row>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-show="showBackBreadcrumb">
            <a @click="backBreadcrumb({ backRegistry: true, index: null })">
              Назад
            </a>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-for="(item, index) in computedOpenedCards" :key="index" v-show="(!showBreadcrumbEmpty ? (computedOpenedCards.length - 1) === 1 : true)">
            <a @click="backBreadcrumb({ backRegistry: false, index })">
              {{ (item.name || '').length > 100 ? (item.name || '').substring(0, 100) + '...' : (item.name || '')}}
            </a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </el-row>
    </el-header>
    <div v-if="showCard" style="height: calc(100% - 24px)">
      <!--<registry-card @close="this.closeCard" @recordAdded="$refs.registry_table.addRecord"
                    @recordEdited="$refs.registry_table.updateRecord"
                    :card-id="activeCardId" :registry-id="id" v-bind:record-id.sync="activeRecordId"></registry-card>-->
      <template v-for="(card, index) in computedOpenedCards">
        <adapter-user-card
          v-if="card.isUserCard"
          :key="index"
          :user-id="card.recordId"
          @cancelChanges="$emit('cancelChanges', $event)"
          @recordAdded="(event) => {if (card.registry) {card.registry.addRecord(event)}}"
          @recordEdited="(event) => {if (card.registry) {card.registry.updateRecord(event)}}"
        >
        </adapter-user-card>
        <registry-card
          v-else-if="!card.isDashboard"
          ref="registryCard"
          v-show="index === (computedOpenedCards.length - 1)"
          :card-id="card.id" :registry-id="card.registryId" v-bind:record-id.sync="card.recordId"
          :initial-data="card.initialData"
          :key="index"
          :show-block-btn="showBlockBtn"
          :show-close-card-btn="showCloseCardBtn"
          @recordAdded="(event) => {if (card.registry) {card.registry.addRecord(event)}}"
          @recordEdited="(event) => {if (card.registry) {card.registry.updateRecord(event)}}"
          :readonly="card.readonly"
          @cancelChanges="(event) => {if (card.registry && typeof card.registry.cancelChanges === 'function') {card.registry.cancelChanges(event); $emit('cancelChanges', event)} else { $emit('cancelChanges', event) }}"
          @change-name="$set(card, 'name', $event)"
        ></registry-card>
        <dashboard-simple
          v-else
          v-show="index === (computedOpenedCards.length - 1)"
          :key="index"
          :model="card.initialData"
          :id="card.id"
        ></dashboard-simple>
        <!--@close="this.closeCard"
        @recordAdded="$refs.registry_table.addRecord"
        @recordEdited="$refs.registry_table.updateRecord"-->
      </template>
    </div>
  </div>
</template>

<script>
import RegistryCard from '@/components/RegistryCard/index.vue'
import DashboardSimple from '@/components/Dashboard/simple.vue'
import AdapterUserCard
  from '@/services/AccessEditor/infrastructure/components/UserPanel/UserMainEditPanel/AdapterUserCard'
export default {
  props: {
    openedCards: Array,
    showCard: {
      type: Boolean,
      default: true
    },
    showBreadcrumb: {
      type: Boolean,
      default: true
    },
    breadcrumbByButton: {
      type: Boolean,
      default: true
    },
    showBlockBtn: {
      type: Boolean,
      default: true
    },
    showBackBreadcrumb: {
      type: Boolean,
      default: true
    },
    showCloseCardBtn: {
      type: Boolean,
      default: true
    },
    showBreadcrumbEmpty: {
      type: Boolean,
      default: true
    },
    preventUserCard: {
      type: Boolean,
      default: false
    }
  },
  provide () {
    return {
      openedCards: this.openedCards
    }
  },
  computed: {
    hideBreadcrumb () {
      // во фрейме не показывать
      if (!this.showBreadcrumb) {
        return false
        // в кнопке не показывать
      } else if (!this.breadcrumbByButton) {
        return false
      } else {
        return true
      }
    },
    computedOpenedCards () {
      return this.openedCards.map((item) => {
        if (item.isDashboard || this.preventUserCard) {
          return item
        }
        if (parseInt(item.registryId) === parseInt(this.usersTableId)) {
          return Object.assign(item, {
            isUserCard: true
          })
        }

        return item
      })
    }
  },
  data () {
    return {
      usersTableId: null
    }
  },
  mounted () {
    if (!this.$config.is_portal) {
      this.$http
        .get(`${this.$config.api}/objecteditor/entities?properties[is_users_table]=true`)
        .then((response) => {
          this.usersTableId = response.data.data[0].id
        })
    }
  },
  methods: {
    confirmClosingCard (confirmCloseCardText) {
      let response = this.$confirm(confirmCloseCardText, 'Закрытие карточки', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(() => {
        return true
      }).catch(() => {
        return false
      })
      return response
    },
    // переход назад по "хлебным крошкам" с подтверждением юзера
    async backBreadcrumb ({ backRegistry, index }) {
      let dataCloseCard = {}
      if (index === null) {
        dataCloseCard = this.$refs.registryCard?.[this.$refs.registryCard.length - 1]?.buttonClose || {}
      } else {
        dataCloseCard = this.$refs.registryCard?.[index + 1]?.buttonClose || {}
      }
      if (Object.keys(dataCloseCard).length && dataCloseCard.confirmCloseCard) {
        let responseUser = await this.confirmClosingCard(dataCloseCard.confirmCloseCardText)
        if (responseUser) {
          backRegistry ? this.$emit('back-registry') : this.$emit('back', index)
        }

        return
      }

      backRegistry ? this.$emit('back-registry') : this.$emit('back', index)
    }
  },
  components: {
    AdapterUserCard,
    DashboardSimple,
    RegistryCard
  },
  name: 'Cards'
}
</script>

<style scoped>

</style>
