<template>
    <div style="margin: 12px; margin-left: 0px; height: calc(100% - 24px)" class="interfaceEditor">
        <input hidden accept=".json" type="file" id="file" ref="json" v-on:change="onInputChange"/>
        <el-row>
            <span :class="{'interface_editor_title': true, 'interface_editor_title_interactive': showCardSettings}"
                  @click="showCardSettings = false">
                {{$locale.interface_editor.main.title}}
            </span>
            <i v-show="showCardSettings" style="color: #C0C4CC" class="el-icon-arrow-right"></i>
            <span v-show="showCardSettings" style="margin-left: 4px"
                  class="interface_editor_title">{{ entityTitle }}</span>
        </el-row>
        <el-row v-show="!showCardSettings" style="margin-top: 8px;">
            <el-col :span="20" style="display: flex;align-items: center;">
                <span class="entity_title">{{ entityTitle }}</span>
                <i @click="showCardSettings = true" class="el-icon-s-tools"
                   style="cursor: pointer; margin-left: 20px; color: #C4C4C4; font-size: 19px"></i>
              <i @click="showCardSourceWindow = true" class="el-icon-coin"
                 style="cursor: pointer; margin-left: 20px; color: #C4C4C4; font-size: 19px"></i>
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                        <i class="el-icon-more el-icon--right" style="cursor: pointer; margin-left: 10px; color: #C4C4C4; font-size: 19px"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item icon="el-icon-upload2">
                            <span @click="loadFile">{{ $locale.interface_editor.toolbar.upload }}</span>
                        </el-dropdown-item>
                        <el-dropdown-item icon="el-icon-download">
                            <span @click="exportData">{{ $locale.interface_editor.toolbar.download }}</span>
                        </el-dropdown-item>
                        <el-dropdown-item icon="el-icon-download">
                            <span @click="exportTemplate">{{ $locale.interface_editor.toolbar.export_template }}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div v-show="isUseTabs" style="display:inline-block;margin-left: auto;">
                  <el-tooltip content="Добавить вкладку" placement="top" effect="light" :open-delay="500">
                    <el-input size="mini" v-model="tabName" @keyup.enter.native="addTab(tabName);tabName = null">
                      <el-button slot="append" icon="el-icon-plus" @click="addTab(tabName);tabName = null"></el-button>
                    </el-input>
                  </el-tooltip>
                </div>
            </el-col>
            <export-template :visibleTemplate.sync="visibleTemplate" :structure="structure"/>
            <el-col :span="4" style="text-align: right">
                <el-button size="small" @click="$emit('save', getData())">{{ $locale.main.button.save }}</el-button>
            </el-col>
        </el-row>
        <el-row v-show="showCardSettings" style="margin-top: 8px;">
            <el-col :span="18">
                <span class="entity_title">{{ $locale.main.button.settings }}</span>
            </el-col>
            <el-col :span="6" style="text-align: right">
                <el-button type="text" icon="el-icon-back" @click="showCardSettings = false">{{ $locale.main.button.back }}</el-button>
            </el-col>
        </el-row>
        <el-row v-show="!showCardSettings" style="height: calc(100% - 50px)">
            <el-col :span="20" style="height: 100%">
                <component :is="'style'" v-if="CSS">
                  {{ CSS }}
                </component>
                <el-tabs :tab-position="tabPosition" :style="stylesActiveTabs" v-model="activeTabGuid"
                        v-if="isUseTabs" class="customTab_card wrapper_flex" :class="userClass">
                    <el-tab-pane v-for="tab in tabs" :key="tab.guid" :name="tab.guid" :disabled="tab.isBlocked">
                      <span class="custom_text" :class="[{text_danger: tab.isHide}, tab.isCss ? tab.cssStyleTab.icon : '']" :style="tab.isCss ? tab.cssStyleTab.css : ''" slot="label">
                        <i class="el-icon-plus" v-if="tabs.find(item => item.parentGuid === tab.guid)"></i>
                        {{tab.label}}
                      </span>
                      <div>
                          <layout
                            :style="scaleStyle"
                            :ref="'layout-on-' + tab.guid"
                            :layout-data.sync="layout[tab.guid]"
                            :containers="getContainersFromTab(tab.guid)"
                            :available-components="availableComponents"
                            :active-container-guid="activeContainerGuid"
                            :active-component-guid="activeComponentGuid"
                            :components="components"
                            :model="model"
                            :openModalWindowByConfigurator="openModalWindowByConfigurator"
                            @change-property="updateComponentProperty($event.name, $event.value, $event.guid)"
                            @change-prop-property="updateComponentPropProperty($event.prop, $event.name, $event.value, $event.guid)"
                          ></layout>
                      </div>
                    </el-tab-pane>
                </el-tabs>
                <div :style='stylesTabs' class="custom_scrollbar" v-else>
                    <layout
                      :style="scaleStyle"
                      ref="layout"
                      :layout-data.sync="layout.null"
                      :containers="getContainersFromTab()"
                      :available-components="availableComponents"
                      :active-container-guid="activeContainerGuid"
                      :active-component-guid="activeComponentGuid"
                      :components="components"
                      :model="model"
                      :openModalWindowByConfigurator="openModalWindowByConfigurator"
                      @change-property="updateComponentProperty($event.name, $event.value, $event.guid)"
                      @change-prop-property="updateComponentPropProperty($event.prop, $event.name, $event.value, $event.guid)"
                    ></layout>
                </div>
            </el-col>
            <el-col :span="4" style="height: 100%;" >
                <settings-panel
                  ref="settingsPanel"
                  :container-guid="activeContainerGuid"
                  :active-component-guid="activeComponentGuid"
                  :available-components="availableComponents"
                  :components="activeContainerGuid ? getComponentsFromContainer(activeContainerGuid) : []"
                  @add-component="addComponent"
                  @copy-container="copyContainer"
                  @add-container="addContainer"
                  @delete-container="deleteContainer"
                  @open-modal-window-by-configurator="openModalWindowByConfigurator = $event"
                ></settings-panel>
            </el-col>
        </el-row>
        <!-- шестеренка в карточке -->
        <el-row v-show="showCardSettings" style="height: calc(100% - 50px)">
            <el-tabs style="height: 100%; overflow: hidden" class="customTab_card">
              <!-- табы: карточка -->
                <el-tab-pane :label="$locale.interface_editor.component_editor.table.card">
                    <el-row>
                      <el-col :span="12" style="padding: 10px" class="setting_card">
                        <el-form-item>
                          {{ $locale.interface_editor.settings.card.background }}
                          <editor-bg-card v-model="background"></editor-bg-card>
                        </el-form-item>
                        <el-form-item>
                          <span class="registry_fields_label" style="font-size: 14px; color: #000">
                            {{ $locale.interface_editor.settings.card.background_color }}
                          </span>
                          <div style="display: inline-flex; align-items: center; height: 40px;">
                            <el-color-picker show-alpha v-model="background.bgColor"></el-color-picker>
                            <el-input type="text" size="small" v-model="background.bgColor" placeholder="rgba(111, 54, 210, 0.5"></el-input>
                          </div>
                        </el-form-item>
                        <el-form-item>
                          <span class="registry_fields_label" style="font-size: 14px; color: #000">
                            {{ $locale.interface_editor.settings.card.CSS }}
                          </span>
                          <el-input
                            type="textarea"
                            :rows="4"
                            :placeholder="$locale.interface_editor.settings.card.CSS"
                            v-model="CSS">
                          </el-input>
                        </el-form-item>
                        <el-form-item>
                          <span class="registry_fields_label" style="font-size: 14px; color: #000">
                            {{ $locale.interface_editor.settings.card.user_class }}
                          </span>
                          <el-input type="text" size="small" v-model="userClass" :placeholder="$locale.interface_editor.settings.card.user_class"></el-input>
                        </el-form-item>
                        <el-form-item>
                          {{ $locale.interface_editor.toolbar.is_use_tabs }}
                          <el-checkbox v-model="isUseTabs" style="margin-left: 5px;"></el-checkbox>
                        </el-form-item>
                        <el-form-item>
                        <span class="registry_fields_label" style="font-size: 14px; color: #000">
                          {{ $locale.interface_editor.settings.card.tabs_position }}
                        </span>
                          <el-select v-model="tabPosition" size="mini" style="width: 50%">
                            <el-option
                              v-for="item in listTabPositions"
                              :key="item.id"
                              :label="item.name"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" style="padding: 10px">
                          <slot name="settings"></slot>
                      </el-col>
                    </el-row>
                </el-tab-pane>
                <!-- табы: вкладки -->
                <el-tab-pane :label="$locale.interface_editor.toolbar.is_tabs">
                    <el-row>
                        <el-col :span="12">
                            <template v-for="(tab, index) in tabs">
                                <el-row :key="index" :class="{'activeTabSettings' : index===activeTabSettingsIndex}">
                                    <el-col :span="24">
                                        <el-button-group style="width: 39px">
                                            <el-button @click.stop="reorderTab(index, 'down')" style="border: none;"
                                                       icon="el-icon-top"
                                                       size="mini"></el-button>
                                            <el-button @click.stop="reorderTab(index, 'up')" style="border: none;"
                                                       icon="el-icon-bottom"
                                                       size="mini"></el-button>
                                        </el-button-group>
                                        <el-input @focus="activeTabSettingsIndex = index"
                                                  style="width: calc(100% - 39px - 44px)" size="small"
                                                  v-model="tab.label"></el-input>
                                        <el-button @click=removeTab(tab.guid) icon="el-icon-delete" size="small" plain
                                                   type="danger"></el-button>
                                    </el-col>
                                </el-row>
                            </template>
                            <el-row>
                                <el-col :span="24">
                                    <el-button-group style="width: 39px">
                                        <el-button :disabled="true"
                                                   @click.stop="activeTabSettingsIndex = null;reorderTab(index, 'down');"
                                                   style="border: none;" icon="el-icon-top"
                                                   size="mini"></el-button>
                                        <el-button :disabled="true"
                                                   @click.stop="activeTabSettingsIndex = null;reorderTab(index, 'up')"
                                                   style="border: none;" icon="el-icon-bottom"
                                                   size="mini"></el-button>
                                    </el-button-group>
                                    <el-button @click="activeTabSettingsIndex = null;addTab()" plain
                                               style="width: calc(100% - 49px - 44px)">{{$locale.main.button.add}}
                                    </el-button>
                                    <el-button @click="activeTabSettingsIndex = null;removeTab(tab.guid)"
                                               icon="el-icon-delete" :disabled="true" size="small" plain
                                               type="danger"></el-button>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="12">
                            <el-form v-if="activeTabSettingsIndex !== null" style="margin-left: 20px; margin-top: 20px">
                              <el-form-item :label="$locale.interface_editor.settings.card.parent">
                                <el-select clearable size="mini" v-model="tabs[activeTabSettingsIndex].parentGuid">
                                  <el-option
                                    v-for="item in tabs"
                                    :key="item.guid"
                                    :label="item.label"
                                    :value="item.guid">
                                  </el-option>
                                </el-select>
                              </el-form-item>
                                <el-form-item :label="$locale.interface_editor.settings.card.background">
                                    <editor-bg-card v-model="tabs[activeTabSettingsIndex]"></editor-bg-card>
                                </el-form-item>
                                <el-form-item :label="$locale.interface_editor.settings.card.background_color">
                                  <div style="display: inline-flex; align-items: center; height: 40px;">
                                    <el-color-picker show-alpha v-model="tabs[activeTabSettingsIndex].bgColor"></el-color-picker>
                                    <el-input type="text" size="small" v-model="tabs[activeTabSettingsIndex].bgColor" placeholder="rgba(111, 54, 210, 0.5"></el-input>
                                  </div>
                                </el-form-item>
                                <el-form-item >
                                  <editor-conditions
                                    v-model="tabs[activeTabSettingsIndex].hideTabs"
                                    :options="{label: $locale.interface_editor.settings.card.hide_tabs}"
                                    :key="activeTabSettingsIndex"
                                    @change="tabVisibility"/>
                                </el-form-item>
                                <el-form-item>
                                  <editor-conditions
                                    v-model="tabs[activeTabSettingsIndex].blockedTabs"
                                    :options="{label: $locale.interface_editor.settings.card.block_tabs}"
                                    :key="activeTabSettingsIndex"
                                    @change="blockTabs"/>
                                </el-form-item>
                                <el-form-item>
                                  <editor-conditions
                                    v-model="tabs[activeTabSettingsIndex].cssTabs"
                                    :options="{label: $locale.interface_editor.settings.card.css_tabs}"
                                    :key="activeTabSettingsIndex"
                                    @change="changeCssTabs"/>
                                </el-form-item>
                                <el-input type="text" size="small" v-model="tabs[activeTabSettingsIndex].cssStyleTab.css" placeholder="style CSS"></el-input>
                                <el-select clearable size="mini" v-model="tabs[activeTabSettingsIndex].cssStyleTab.icon">
                                  <el-option
                                    v-for="item in icons"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    <span style="float: left">{{ item.label }}</span>
                                    <span style="float: right;">
                                      <i style="font-size: 30px;width: 22px;height: 22px;" :class="item.value"></i>
                                    </span>
                                  </el-option>
                                </el-select>
                            </el-form>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </el-row>
      <modal-window
        v-if="registryId"
        :active.sync="showCardSourceWindow"
        title="Источник карточки"
      >
        <registry-card-source
          style="height: calc(100% - 38px)"
          :registry-id="registryId"
          :card-fields="cardFields"
          :fields.sync="cardSource.fields"
          :is-active.sync="cardSource.isActive"
        ></registry-card-source>
        <div class="button" style="display: inline-block;float: right" @click="showCardSourceWindow = false">Сохранить и закрыть</div>
      </modal-window>
    </div>
</template>

<script>
import components from './components'
import propertyTypes from './property_types'
import EditorBgCard from './components/editor/editor-background-card'
import EditorConditions from './components/editor/editor-conditions'
import Icons from '@/components/MenuEditor/components/el-icons'
import ExportTemplate from '@/components/InterfaceEditor/exportTemplate.vue'
import Layout from './layout'

import conditionsMixin from './components/conditions_mixin'
import SettingsPanel from './settings-panel'
import RegistryCardSource from '@/services/InterfaceEditor/infrastructure/components/RegistryCardSource'
import ModalWindow from '@/core/infrastructure/components/ModalWindow'
import { InterfaceType } from '@/services/InterfaceEditor/domain/model/InterfaceType'

export default {
  name: 'interface-editor',
  inject: ['addMainTab'],
  components: {
    Layout,
    EditorBgCard,
    EditorConditions,
    SettingsPanel,
    ExportTemplate,
    RegistryCardSource,
    ModalWindow
  },
  mixins: [conditionsMixin],
  props: {
    entityTitle: {
      type: String,
      default: 'Редактор интерфейсов'
    },
    registryFields: {
      type: Array,
      default () {
        return []
      }
    },
    registryId: {
      default: null
    },
    getAdditionalSettings: {
      type: Function
    }
  },
  data () {
    return {
      showCardSourceWindow: false,
      tabName: null,
      CSS: null,
      userClass: null,
      showCardSettings: false,
      /* использовать вкладки */
      isUseTabs: false,
      tabPosition: 'top',
      listTabPositions: [
        { value: 'top', name: 'Сверху' },
        { value: 'right', name: 'Справа' },
        { value: 'bottom', name: 'Снизу' },
        { value: 'left', name: 'Слева' }
      ],
      tabs: [],
      activeTabGuid: null,
      /* модель для каскадера для выбора компонента */
      chooseComponent: [],
      /* список доступных компонентов */
      availableBaseComponents: JSON.parse(JSON.stringify(components)),
      /* дефолтные значения размеров добавляемых контейнеров */
      defaultContainerSizes: {
        width: 6,
        height: 6
      },
      /* контейнеры */
      containers: [],
      /* компоненты */
      components: [],
      /* видимость панели настроек */
      settingsVisible: false,
      loading: null,
      /* насраиваемый контейнер */
      activeContainerGuid: null,
      activeComponentGuid: null,
      model: {},
      background: {
        urlImage: null,
        fitImage: null,
        bgColor: null
      },
      icons: Icons.map(el => ({ value: el, label: el })),
      scale: 1,
      fileList: [],
      activeTabSettingsIndex: null,
      visibleTemplate: false,
      structure: null,
      cardSource: {
        isActive: false,
        fields: []
      },
      // ag-grid
      openModalWindowByConfigurator: false
    }
  },
  provide () {
    return {
      getRegistryRecordId: function () {
        return null
      },
      isEditor: function () {
        return true
      },
      getRegistryId: this.getRegistryId,
      openRegistryCard: function () {
        return null
      },
      openDashboardCard: function () {
        return null
      },
      getModel: this.getModel,
      getComponents: this.getComponents,
      setActiveComponent: this.setActiveComponent,
      setActiveContainer: this.setActiveContainer,
      getComponentPropertiesFields: this.getComponentPropertiesFields,
      updateComponentProperty: this.updateComponentProperty,
      addComponent: this.addComponent,
      deleteComponent: this.deleteComponent,
      reorderComponent: this.reorder,
      getContainer: this.getContainer,
      updateContainerStyles: this.updateContainerStyles,
      getAvailableComponents: this.getAvailableComponents,
      forceUpdateSettingsPanel: () => this.$refs.settingsPanel.$forceUpdate() // @TODO поменять на события из компонента
    }
  },
  mounted () {
    if (this.registryId) {
      const settings = this.getAdditionalSettings()
      if ('alternativeSource' in settings) {
        this.cardSource.isActive = settings.alternativeSource.isActive || false
        this.cardSource.fields = settings.alternativeSource.fields || []
      }
    }
  },
  computed: {
    cardFields () {
      let answer = []
      this.components.forEach((item) => {
        const name = item.properties?.name
        if (name && /attr_[0-9]+_/i.test(name)) {
          const attributeId = name.match(/\d+/)[0]
          if (!answer.includes(attributeId)) {
            answer.push(attributeId)
          }
        }
        if (item.initialType === 'basic/a-html') {
          const html = item.properties?.html || ''
          const attributes = html.match(/\{{(.*?)\}}/g) || []
          attributes.forEach((attribute) => {
            attribute = attribute.replace('{{', '').replace('}}', '')
            if (attribute && /attr_[0-9]+_/i.test(attribute)) {
              const attributeId = attribute.match(/\d+/)[0]
              if (!answer.includes(attributeId)) {
                answer.push(attributeId)
              }
            }
          })
        }
      })

      return answer
    },
    scaleStyle () {
      let style = `transform: scale(${this.scale});/*width: 134.478%;*/`
      if (this.scale < 1) {
        style += `outline: 1px solid black;`
      }
      return style
    },
    layout () {
      let layout = { null: [] }
      this.tabs.forEach((tab) => {
        if (!layout[tab.guid]) {
          layout[tab.guid] = []
        }
      })
      this.containers.forEach((container) => {
        if (!layout[container.tabGuid]) {
          layout[container.tabGuid] = []
        }
        layout[container.tabGuid].push(container)
      })

      return layout
    },
    activeTab () {
      let me = this
      return this.tabs.find((tab) => {
        return tab.guid === me.activeTabGuid
      }) || {}
    },
    availableComponents () {
      let components = JSON.parse(JSON.stringify(this.availableBaseComponents)) || []
      if (this.registryFields) {
        components.push({
          label: 'Поля реестра',
          value: 'registry',
          children: this.registryFields
        })
      }
      return components
    },
    stylesTabs () {
      let style = {
        height: '100%',
        overflow: 'auto'
      }
      if (this.background.urlImage) {
        if (this.background.urlImage.startsWith('http')) {
          style['background-image'] = `url(${this.background.urlImage})`
        } else {
          style['background-image'] = `url(${this.$config.api}${this.background.urlImage})`
        }
        style['background-size'] = this.background.fitImage
      }
      if (this.background.bgColor) {
        style['background-color'] = this.background.bgColor
      }

      return style
    },
    stylesActiveTabs () {
      let style = {
        height: '100%',
        overflow: 'auto'
      }
      if (this.activeTab.urlImage) {
        if (this.activeTab.urlImage.startsWith('http')) {
          style['background-image'] = `url(${this.activeTab.urlImage})`
        } else {
          style['background-image'] = `url(${this.$config.api}${this.activeTab.urlImage})`
        }
        style['background-size'] = this.activeTab.fitImage
      }
      if (this.activeTab.bgColor) {
        style['background-color'] = this.activeTab.bgColor
      }
      return style
    }
  },
  watch: {
    activeTabGuid () {
      this.setActiveComponent(null)
      this.setActiveContainer(null)
    },
    cardSource: {
      handler (value) {
        let settings = this.getAdditionalSettings()
        if (!('alternativeSource' in settings)) {
          this.$set(settings, 'alternativeSource', {
            isActive: false,
            fields: []
          })
        }

        settings.alternativeSource.isActive = value.isActive || false
        settings.alternativeSource.fields = value.fields || []

        this.$emit('set-additional-settings', settings)
      },
      deep: true
    }
  },
  methods: {
    getRegistryId () {
      return this.registryId
    },
    tabVisibility (data) {
      this.tabs[this.activeTabSettingsIndex].isHide = this.checkConditions(data)
    },
    blockTabs (data) {
      this.tabs[this.activeTabSettingsIndex].isBlocked = this.checkConditions(data)
    },
    changeCssTabs (data) {
      this.tabs[this.activeTabSettingsIndex].isCss = this.checkConditions(data)
    },
    getAvailableComponents () {
      return this.availableComponents
    },
    setActiveComponent (guid) {
      this.activeComponentGuid = guid
    },
    setActiveContainer (guid) {
      this.activeContainerGuid = guid
    },
    getModel () {
      return this.model
    },
    getComponents () {
      return this.components
    },
    reorderTab (index, type) {
      let to = (type === 'down') ? index - 1 : index + 1
      this.tabs.splice(to, 0, this.tabs.splice(index, 1)[0])
    },
    reorder (guid, containerGuid, type) {
      let component = this.getComponentsFromContainer(containerGuid).find(cmp => cmp.guid === guid)
      if (!component) {
        return false
      }
      if (type === 'up') {
        let siblingComponent = this.getComponentsFromContainer(containerGuid).find(cmp => cmp.orderId >= component.orderId && cmp.guid !== component.guid)
        if (!siblingComponent) {
          return false
        }
        if (siblingComponent.orderId === component.orderId) {
          this.$set(component, 'orderId', component.orderId + 1)
        } else {
          let itemOrderId = component.orderId
          this.$set(component, 'orderId', siblingComponent.orderId)
          this.$set(siblingComponent, 'orderId', itemOrderId)
        }
      } else if (type === 'down') {
        let siblingComponent = this.getComponentsFromContainer(containerGuid).sort((a, b) => {
          return b.orderId - a.orderId
        }).find(cmp => cmp.orderId <= component.orderId && cmp.guid !== component.guid)
        if (!siblingComponent) {
          return false
        }
        if (siblingComponent.orderId === component.orderId) {
          this.$set(component, 'orderId', component.orderId - 1)
        } else {
          let itemOrderId = component.orderId
          this.$set(component, 'orderId', siblingComponent.orderId)
          this.$set(siblingComponent, 'orderId', itemOrderId)
        }
      }
    },
    deleteComponent (guid) {
      this.components = this.components.filter(component => component.guid !== guid)
    },
    removeTab (guid) {
      let me = this
      this.activeTabSettingsIndex = null
      this.containers
        .filter((container) => {
          return container.tabGuid === guid
        })
        .forEach((container) => {
          me.deleteContainer(container.guid)
        })
        // this.activeTabGuid = this.tabs.find((tab) => tab.guid !== guid) || null
      this.tabs = this.tabs.filter((tab) => {
        return tab.guid !== guid
      })
    },
    addTab (name) {
      let guid = this.generateGuid()
      if (!name) {
        name = this.$locale.interface_editor.default_tab_name
      }
      this.tabs.push({
        label: name,
        guid: guid,
        urlImage: '',
        fitImage: '',
        bgColor: '',
        isBlocked: false,
        isCss: false,
        cssStyleTab: { css: '', icon: '' },
        blockedTabs: { type: 'never', condition_type: 'and', conditions: [] },
        hideTabs: { type: 'never', condition_type: 'and', conditions: [] },
        cssTabs: { type: 'never', condition_type: 'and', conditions: [] },
        isHide: false
      })
      this.activeTabGuid = guid
    },
    toCamelCase (s) {
      return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '')
      })
    },
    /* __________________________________________________________ */
    loadFile () {
      this.$refs.json.click()
    },
    onInputChange (e) {
      let me = this
      const files = e.target.files
      if (files[0] !== undefined) {
        const fr = new FileReader()
        fr.readAsText(files[0])
        fr.addEventListener('load', () => {
          let json = JSON.parse(fr.result)
          me.loadState(json)
        })
      }
    },
    loadState (json) {
      this.components = []
      if (!json) {
        json = { components: [], containers: [], tabs: [] }
      }

      if (json.tabs && json.tabs.length > 0) {
        this.tabs = json.tabs
        this.tabPosition = json.tabPosition || 'top'
        this.tabs.forEach(el => {
          if (el.isHide === undefined) this.$set(el, 'isHide', false)
          if (el.isBlocked === undefined) this.$set(el, 'isBlocked', false)
          if (el.isCss === undefined) this.$set(el, 'isCss', false)
          // для сохранения существующих вкладок
          if (el.isHide && el.hideTabs === undefined) this.$set(el, 'hideTabs', { type: 'always', condition_type: 'and', conditions: [] })
          if (!el.isHide && el.hideTabs === undefined) this.$set(el, 'hideTabs', { type: 'never', condition_type: 'and', conditions: [] })
          if (el.bgColor === undefined) this.$set(el, 'bgColor', '')
          if (el.blockedTabs === undefined) this.$set(el, 'blockedTabs', { type: 'never', condition_type: 'and', conditions: [] })
          if (el.cssStyleTab === undefined) this.$set(el, 'cssStyleTab', { css: '', icon: '' })
          if (el.cssTabs === undefined) this.$set(el, 'cssTabs', { type: 'never', condition_type: 'and', conditions: [] })
        })
        this.isUseTabs = true
        this.activeTabGuid = this.tabs[0].guid
      }
      this.containers = json.containers
      this.background.urlImage = json.urlImage
      this.background.fitImage = json.fitImage
      this.background.bgColor = json.bgColor
      this.CSS = json.CSS
      this.userClass = json.userClass
      json.components.forEach(item => {
        if (Array.isArray(item.properties)) {
          item.properties = {}
        }
        this.loadComponent(item.initialType)
          .then(() => {
            let component = () => this.loadComponent(item.initialType)
            this.components.push({
              type: component,
              name: item.name,
              editorAlias: item.properties.editorAlias,
              guid: item.guid,
              orderId: item.orderId || (this.getComponentsFromContainer(item.containerGuid).length + 1),
              initialType: item.initialType,
              containerGuid: item.containerGuid,
              properties: item.properties
            })
          })
          .catch((error) => {
            console.log(`error load: ${item.name}, error: ${error}`)
          })
      })
    },
    /* __________________________________________________________ */
    /* копирует контейнер со всеми подконтейнерами и компонентами */
    copyContainer (sourceGuid, destinationGuid = '') {
      let destination = this.containers
      if (destinationGuid) {
        destination = this.getContainerByGuid(destinationGuid, this.containers)
          .items
      }
      let container = JSON.parse(
        JSON.stringify(this.getContainerByGuid(sourceGuid, this.containers))
      )

      let me = this

      let containerGuid = this.generateGuid()
      let children = []
      container.guid = containerGuid
      container.i = destination.length + 1
      if (container.items.length) {
        children = container.items.slice()
        container.items.length = 0
      }
      destination.push(container)
      this.copyComponents(sourceGuid, containerGuid)
      children.forEach(item => {
        me.copyContainer(item.guid, containerGuid)
      })

      return containerGuid
    },
    getComponentsFromContainer (guid) {
      return this.components.filter((item) => {
        return item.containerGuid === guid
      }).sort((a, b) => {
        return a.orderId - b.orderId
      })
    },
    getContainersFromTab (guid = null) {
      return this.containers.filter((item) => {
        return item.tabGuid === guid
      })
    },
    /* копирует компоненты между контейнерами */
    copyComponents (sourceContainerGuid, destinationContainerGuid) {
      let components = JSON.parse(
        JSON.stringify(
          this.getComponentsFromContainer(sourceContainerGuid)
        )
      )
      let me = this
      components.forEach(item => {
        me.loadComponent(item.initialType)
          .then(() => {
            let component = () => me.loadComponent(item.initialType)
            me.components.push({
              type: component,
              name: item.name,
              initialType: item.initialType,
              guid: me.generateGuid(),
              orderId: (me.getComponentsFromContainer(destinationContainerGuid).length + 1),
              containerGuid: destinationContainerGuid,
              properties: item.properties
            })
          })
          .catch((error) => {
            console.log(`error load: ${item.name}, error: ${error}`)
          })
      })
    },
    /* добавляет компонент в контейнер, предварительно загружая его */
    addComponent (type, containerGuid) {
      if (type[0] === 'basic') {
        type = type.join('/')
        this.loadComponent(type)
          .then(() => {
            let component = () => this.loadComponent(type)
            this.components.push({
              type: component,
              initialType: type,
              orderId: (this.getComponentsFromContainer(containerGuid).length + 1),
              name: type,
              guid: this.generateGuid(),
              containerGuid: containerGuid,
              properties: {}
            })
          })
          .catch((error) => {
            console.log(error)
          })
      } else if (type[0] === 'registry') {
        let field = type[1]
        let cmp = `registry/${field.entity_type_id}`
        this.loadComponent(cmp)
          .then(() => {
            let component = () => this.loadComponent(cmp)
            this.components.push({
              type: component,
              initialType: cmp,
              orderId: (this.getComponentsFromContainer(containerGuid).length + 1),
              name: `${field.name} (attr_${field.id}_)`,
              guid: this.generateGuid(),
              containerGuid: containerGuid,
              properties: {
                label: field.name,
                name: `attr_${field.id}_`,
                registry_properties: field.properties
              }
            })
          })
          .catch((error) => {
            console.log(`error load: ${cmp}, error: ${error}`)
          })
      }
    },
    loadComponent (type) {
      if (!type) {
        return null
      }
      // eslint-disable-next-line no-undef
      return import(`./components/${type}.vue`)
    },
    /* получает свойства компонента */
    getComponentProperties (guid) {
      return this.components.find((item) => {
        return item.guid === guid
      }).properties
    },
    /* добавляет контейнер */
    addContainer (event, parentGuid = '') {
      let containersArray = this.containers
      if (parentGuid) {
        containersArray = this.containers.find(item => {
          return item.guid === parentGuid
        }).items
      }
      containersArray.push({
        x: 0,
        y: 0,
        w: this.defaultContainerSizes.width,
        h: this.defaultContainerSizes.height,
        i: Math.max.apply(Math, (containersArray.length > 0 ? containersArray.map(o => o.i) : [0])) + 1,
        guid: this.generateGuid(),
        tabGuid: (this.isUseTabs && this.activeTabGuid) ? this.activeTabGuid : null,
        style: null,
        class: null,
        items: []
      })
    },
    updateContainerStyles (guid, styles) {
      let container = this.getContainerByGuid(guid, this.containers)
      if (!container) {
        return false
      }
      this.$set(container, 'style', styles.style)
      this.$set(container, 'extended', styles.extended)
      this.$set(container, 'extendedToBottom', styles.extendedToBottom)
      this.$set(container, 'urlImage', styles.urlImage)
      this.$set(container, 'fitImage', styles.fitImage)
      this.$set(container, 'bgColor', styles.bgColor)
      this.$set(container, 'isHtml', styles.isHtml)
      this.$set(container, 'class', styles.class)
    },
    /* удаляет контейнер */
    deleteContainer (guid) {
      let container = this.getContainerByGuid(guid, this.containers)
      let me = this
      if (container && container.items.length) {
        container.items.forEach(item => {
          me.deleteComponentsFromContainer(item.guid)
        })
      }
      this.containers = this.containers.filter(item => {
        if (item.items.length) {
          item.items = item.items.filter(child => {
            return child.guid !== guid
          })
        }
        return item.guid !== guid
      })
      me.deleteComponentsFromContainer(guid)
    },
    deleteComponentsFromContainer (guid) {
      this.components = this.components.filter((item) => {
        return item.containerGuid !== guid
      })
    },
    /* ищем контейнер по guid */
    getContainerByGuid (guid, array) {
      let me = this
      let container
      array.forEach(item => {
        if (!container) {
          if (item.guid === guid) {
            container = item
          } else if (item.items.length) {
            container = me.getContainerByGuid(guid, item.items)
          }
        }
      })
      return container
    },
    getContainer (guid) {
      return this.getContainerByGuid(guid, this.containers)
    },
    /* генерируем поля для редактирования свойств компонента */
    getComponentPropertiesFields (guid) {
      let answer = []
      let layout = this.$refs.layout
      if (this.isUseTabs) {
        layout = this.$refs['layout-on-' + this.activeTabGuid][0]
      }
      let component = layout.getComponent(guid)
      if (!component) {
        return answer
      }

      let properties = component.getProperties()
      if (!properties) {
        return answer
      }

      for (let property in properties) {
        let type = this.toCamelCase(
          propertyTypes[properties[property].editor ? properties[property].editor : properties[property].type.name].name
        )
        answer.push({
          label: properties[property].description, // @TODO получать label из справочника по ключу компонента и свойства внутри, если в справочнике нет, то properties[property].description
          name: property,
          type: type,
          bind: {
            value: component.$props[property],
            otherProperties: component.$props,
            options: properties[property].options,
            hidden: properties[property].hidden // @TODO функция с контекстом компонента?
          }
        })
      }

      // Компонент находящийся в выпадающем списке компонентов в settings-panel
      let componentInList = this.components.find(component => component.guid === guid) || {}
      // Обновить псевдоним компонента в settings-panel
      componentInList.editorAlias = component.$props['editorAlias']

      return answer
    },
    /* Обновить свойство компонента в store */
    updateComponentProperty (property, value, guid) {
      // Поиск компонента по guid
      let component = this.components.find(item => item.guid === guid)
      if (component) {
        // Изменить значение свойства в компоненте
        this.$set(component.properties, property, value)
      }
    },
    /**
     * Обновить свойство свойства компонента
     *
     * @param {string} prop - Имя свойства prop в компоненте
     * @param {string} property - Имя свойства в свойстве prop в компоненте (Например: hidden)
     * @param {any} value - Значение property
     * @param {string} guid - guid компонента
     */
    updateComponentPropProperty (prop, property, value, guid) {
      let layout = this.$refs.layout
      if (this.isUseTabs) {
        layout = this.$refs['layout-on-' + this.activeTabGuid][0]
      }
      let component = layout.getComponent(guid)
      if (!component) {
        return
      }

      // Получить свойства (props) со свойствами (type/hidden в prop) компонента
      let properties = component.getProperties()
      if (!properties) {
        return
      }
      // Изменить значение свойства у свойства в компоненте
      this.$set(properties[prop], property, value)
      // Обновить отображение свойств компонента (getComponentPropertiesFields не реактивная)
      this.$refs.settingsPanel.$forceUpdate()
    },
    /* получаем данные из store */
    getData () {
      return {
        tabs: this.isUseTabs ? JSON.parse(JSON.stringify(this.tabs)) : [],
        containers: JSON.parse(JSON.stringify(this.isUseTabs ? this.containers.filter((item) => {
          return !!item.tabGuid
        }) : this.containers)),
        components: JSON.parse(JSON.stringify(this.components)),
        urlImage: this.background.urlImage,
        fitImage: this.background.fitImage,
        bgColor: this.background.bgColor,
        tabPosition: this.tabPosition || 'top',
        CSS: this.CSS,
        userClass: this.userClass
      }
    },
    exportData () {
      let data = JSON.stringify(this.getData())
      let a = document.createElement('a')
      let file = new Blob([data], { type: 'text/plain' })
      a.href = URL.createObjectURL(file)
      a.download = 'interface.json'
      a.click()
      a.remove()
    },
    exportTemplate () {
      let a = JSON.stringify(this.getData())
      // заменить \" на \\"
      this.structure = a.replace(/\\"/g, '\\\\"').replace(/\\n/g, '\\\\n')
      this.visibleTemplate = true
    }
  }
}
</script>

<style lang="scss">
  .interface_editor_title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    margin-top: 16px;
    margin-left: 12px;
    color: #7D7D7D;
  }

  .entity_title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-left: 12px;
    color: #2C2D35;
  }

  .interface_editor_title_interactive:hover {
    border-bottom: 1px dashed #7D7D7D;
    cursor: pointer;
  }

  .activeTabSettings {
    background: #EDF5FC;
    border-radius: 4px;
  }

  .interfaceEditor .el-tabs__header .is-top .el-tabs__nav-scroll{
    padding-left: 10px;
  }

  .interfaceEditor .custom_text:before {
    font-family: 'element-icons'!important;
  }

  .interfaceEditor .custom_text {
    font-family: 'Roboto', sans-serif !important;
  }

  .setting_card .el-form-item {
    margin-bottom: 10px;
  }
</style>
<style scoped src="../../services/InterfaceEditor/main.scss" lang="scss"></style>
