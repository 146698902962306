import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'
import { CommandDTO } from '@/services/LogicEditor/domain/model/Command'

export default class CommandCreateCommand implements CommandInterface {
  private name: string
  private description: Nullable<string>
  private targetStateId: Nullable<number>
  private pluginId: Nullable<string>
  private eventId: Nullable<string>
  private isPlugin: boolean
  private isComplex: boolean
  private properties: Array<object>
  private beforeCommandId: Nullable<number>
  private afterCommandId: Nullable<number>
  private cancelCommandId: Nullable<number>
  private commandStates: Array<number>
  private commandExternalStates: Array<number>
  private edsSignCommandFields: Array<object>
  private commandConstraints: Array<number>
  private childCommands: Array<CommandDTO>
  private logicId: number
  private alias: Nullable<string>
  private isTimer: boolean
  private period: Nullable<string>
  private parentTreeElementId: Nullable<number>

  constructor (
    name: string,
    description: Nullable<string>,
    isPlugin: boolean,
    isComplex: boolean,
    properties: Array<object>,
    beforeCommandId: Nullable<number>,
    afterCommandId: Nullable<number>,
    cancelCommandId: Nullable<number>,
    commandStates: Array<number>,
    commandExternalStates: Array<number>,
    edsSignCommandFields: Array<object>,
    commandConstraints: Array<number>,
    childCommands: Array<CommandDTO>,
    logicId: number,
    targetStateId: Nullable<number>,
    pluginId: Nullable<string>,
    eventId: Nullable<string>,
    alias: Nullable<string>,
    isTimer: boolean,
    period: Nullable<string>,
    parentTreeElementId: Nullable<number>
  ) {
    this.name = name
    this.description = description
    this.targetStateId = targetStateId
    this.pluginId = pluginId
    this.eventId = eventId
    this.isPlugin = isPlugin
    this.isComplex = isComplex
    this.properties = properties
    this.beforeCommandId = beforeCommandId
    this.afterCommandId = afterCommandId
    this.cancelCommandId = cancelCommandId
    this.commandStates = commandStates
    this.commandExternalStates = commandExternalStates
    this.edsSignCommandFields = edsSignCommandFields
    this.commandConstraints = commandConstraints
    this.childCommands = childCommands
    this.logicId = logicId
    this.alias = alias
    this.isTimer = isTimer
    this.period = period
    this.parentTreeElementId = parentTreeElementId
  }

  getClassName (): string {
    return 'CommandCreateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getName (): string {
    return this.name
  }

  getTargetStateId (): Nullable<number> {
    return this.targetStateId
  }

  getPluginId (): Nullable<string> {
    return this.pluginId
  }

  getEventId (): Nullable<string> {
    return this.eventId
  }

  getIsPlugin (): boolean {
    return this.isPlugin
  }

  getIsComplex (): boolean {
    return this.isComplex
  }

  getProperties (): Array<object> {
    return this.properties
  }

  getBeforeCommandId (): Nullable<number> {
    return this.beforeCommandId
  }

  getAfterCommandId (): Nullable<number> {
    return this.afterCommandId
  }

  getCancelCommandId (): Nullable<number> {
    return this.cancelCommandId
  }

  getCommandStates (): Array<number> {
    return this.commandStates
  }

  getCommandExternalStates (): Array<number> {
    return this.commandExternalStates
  }

  getEdsSignCommandFields (): Array<object> {
    return this.edsSignCommandFields
  }

  getCommandConstraints (): Array<number> {
    return this.commandConstraints
  }

  getChildCommands (): Array<CommandDTO> {
    return this.childCommands
  }

  getLogicId (): number {
    return this.logicId
  }

  getAlias (): Nullable<string> {
    return this.alias
  }

  getIsTimer (): boolean {
    return this.isTimer
  }

  getPeriod (): Nullable<string> {
    return this.period
  }

  getParentTreeElementId (): Nullable<number> {
    return this.parentTreeElementId
  }
}
