<template>
  <Split style="height: 500px">
    <SplitArea :size="50">
      <div>
        <el-popover trigger="click" placement="top">
          <el-button type="text" icon="el-icon-arrow-down" slot="reference">{{ $locale.interface_editor.component_editor.configurator.source }}</el-button>
          <div>
            <el-input
              size="mini"
              :placeholder="$locale.main.fields.search"
              v-model="filterText">
            </el-input>
            <el-scrollbar :style="{height:'300px'}">
              <el-tree
                ref="availableColumnsTree"
                :data="availableColumns"
                :props="properties"
                :filter-node-method="filterNode"
                :expand-on-click-node="false"
                @node-click="addColumn"
              >
                  <span slot-scope="{ node, data }">
                    <span :class="{'isset-node': hasColumn(value, data.field)}">{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.field }})</b></span>
                  </span>
              </el-tree>
            </el-scrollbar>
          </div>
        </el-popover>
        <el-button type="text" icon="el-icon-plus" style="margin-left: 10px" @click="addCustomColumn">{{ $locale.main.button.add }}</el-button>
      </div>
      <el-scrollbar :style="{height:'calc(100% - 29px)'}">
        <el-tree
          node-key="guid"
          :data="value"
          :props="properties"
          :expand-on-click-node="false"
          @node-click="activeNode = $event"
        >
          <span :class="{'active-node' : activeNode.guid === data.guid }" slot-scope="{ node, data }">
            <span>{{ node.label.length > 25 ? (node.label.slice(0, 25) + '...') : node.label }} <b>({{ data.field }})</b></span>
            <el-button
              v-show="activeNode.guid === data.guid"
              type="text"
              class="delete-button"
              @click.stop="deleteColumn(data)">
              {{ $locale.main.button.delete }}
            </el-button>
          </span>
        </el-tree>
      </el-scrollbar>
    </SplitArea>
    <SplitArea :size="50">
      <el-form size="mini" style="padding: 10px" v-if="activeNode.guid" :key="activeNode.guid" class="form">
        <el-col :span="12">
          <el-form-item label="Название">
            <el-input v-model="activeNode.headerName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Атрибут">
            <el-input v-model="activeNode.field"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Тип поля">
            <el-select
              :value="activeNode.columnType"
              @change="changeColumnType"
            >
              <el-option
                v-for="(item, index) in columnTypes"
                :key="index"
                :label="$locale.object_editor.entity_types[toSnakeCase(item)] || item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
            <!-- agSparklineCellRenderer -->
          <el-form-item v-if="activeNode.columnType === 'agSparklineCellRenderer'" label="Тип графика">
            <editor-select
              :value="activeNode.sparklineType"
              @change="$set(activeNode, 'sparklineType', $event);$set(activeNode, 'sparklineOptions', {})"
              :options="{
                multiple: false,
                options: [
                  { id: 'line', name: 'Линия' },
                  { id: 'area', name: 'Линия с тенью' },
                  { id: 'bar', name: 'Прогресс' },
                  { id: 'column', name: 'Колонки' },
                  { id: 'percentage', name: 'Доли в процентах' }
                ]
              }"
            ></editor-select>
          </el-form-item>
          <div
            v-if="activeNode.columnType === 'agSparklineCellRenderer' && activeNode.sparklineType !== 'percentage'"
            @click="showModalWindow = true"
            class="active-link">
            Дополнительные настройки графика
          </div>
          <modal-window
            :active.sync="showModalWindow"
            title="Настройка графика"
          >
            <sparkline-options :type="activeNode.sparklineType" :activeNode="activeNode.sparklineOptions"></sparkline-options>
            <div class="button" style="display: inline-block;float: right; margin: 0 10px 10px 0" @click="showModalWindow = false">Сохранить и закрыть</div>
          </modal-window>
          <div
            v-if="activeNode.columnType === 'agSparklineCellRenderer' && activeNode.sparklineType === 'percentage'"
            @click="showModalWindow = true"
            class="active-link">
            Настройка цветовых сегментов
          </div>
          <el-col :span="12">
            <el-form-item>
              <el-checkbox v-if="activeNode.columnType === 'agSparklineCellRenderer' && activeNode.sparklineType === 'percentage'"
                           :value="activeNode.showLegend"
                           @change="$set(activeNode, 'showLegend', $event)">
                {{ $locale.interface_editor.component_editor.configurator.legend }}
              </el-checkbox>
            </el-form-item>
          </el-col>
          <modal-window
            :active.sync="showModalWindow"
            title="Настройка цветовых сегментов"
          >
            <sparkline-options :type="activeNode.sparklineType" :activeNode="activeNode.sparklineOptions"></sparkline-options>
            <div class="button" style="display: inline-block;float: right; margin: 0 10px 10px 0" @click="showModalWindow = false">Сохранить и закрыть</div>
          </modal-window>

        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-checkbox :value="!!activeNode.filter" @change="setFilterable($event, activeNode)">{{ $locale.interface_editor.component_editor.configurator.filter }}</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-checkbox :value="activeNode.isExtended" @change="$set(activeNode, 'isExtended', $event)">{{ $locale.interface_editor.component_editor.configurator.extended }}</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-checkbox :value="activeNode.editable" @change="$set(activeNode, 'editable', $event)">Редактируемый</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-checkbox :value="activeNode.isFlex" @change="$set(activeNode, 'isFlex', $event)">{{ $locale.interface_editor.component_editor.configurator.flex }}</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="activeNode.columnType === 'stringField'">
          <el-form-item>
            <el-checkbox :value="activeNode.isStringSetFilter" @change="$set(activeNode, 'isStringSetFilter', $event)">{{ $locale.interface_editor.component_editor.configurator.filter_list }}</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.hide_if">
            <el-tooltip class="item" effect="dark" :open-delay="300" placement="top-start">
              <div slot="content">Атрибут в одинарных скобках "{ }" <br>
                Основное операторы: <br>
                == - РАВНО<br>
                || - ИЛИ<br>
                && - И<br>
                != - НЕ РАВНО<br>
                Пример: {attr_N_}!=42 || {user_role}==1
              </div>
              <el-input v-model="activeNode.hiddenCondition" placeholder="{Атрибут} == 1"></el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="CSS">
            <el-input class="htmlTemplate" type="textarea" :rows="3" v-model="activeNode.cssStyle">
          </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="activeNode.columnType === 'htmlField'">
          <el-form-item label="HTML">
            <el-input class="htmlTemplate" type="textarea" :rows="4" v-model="activeNode.htmlTemplate">
          </el-input>
          </el-form-item>
        </el-col>
        <el-form-item>
          <editor-button-action
            :value='activeNode.action'
            :source-id="sourceId"
            :source-type="sourceType"
            :is-headers="true"
            :component-type="1"
            @change="$set(activeNode, 'action', $event)">
          </editor-button-action>
        </el-form-item>
        <el-collapse-item
          v-if="activeNode.card || activeNode.dashboard"
          :title="$t('interface_editor.component_editor.button_action.action_old_title')"
          class="editor-wrapper">
          <el-form-item :label="$locale.interface_editor.component_editor.registry_headers.click_column">
            <editor-select
              :value="activeNode.clickType"
              @change="$set(activeNode, 'clickType', $event);$set(activeNode, 'dashboard', {})"
              :options="{
                multiple: false,
                options: [
                  { id: 'none', name: $locale.interface_editor.component_editor.registry_headers.none },
                  { id: 'open_dashboard', name: $locale.interface_editor.component_editor.button_action.open_dashbord },
                  { id: 'open_card', name: $locale.interface_editor.component_editor.button_action.open_card },
                ]
              }"
            ></editor-select>
          </el-form-item>
          <el-form-item>
            <template v-if="activeNode.clickType === 'open_dashboard'">
              <el-form-item>
                <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.dashbord }}</span>
                <editor-dashboards @change="$set(activeNode.dashboard ,'id', $event)" :value="activeNode.dashboard.id" ></editor-dashboards>
              </el-form-item>

              <el-form-item>
                <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.frame }}</span>
                <el-select v-model="activeNode.dashboard.frameGuid" clearable>
                  <el-option
                    v-for="(item, index) in components.filter((item) => item.name === 'basic/a-frame')"
                    :key="index"
                    :label="item.name"
                    :value="item.guid"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item class="checkbox">
                <el-checkbox v-model="activeNode.dashboard.breadcrumbByButton"></el-checkbox>
                <span class="property_label" style="display: inline-block;">{{ $locale.interface_editor.component_editor.button_action.breadcrumb }}</span>
              </el-form-item>

              <el-form-item>
                <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_title }}</span>
                <el-input v-model="activeNode.dashboard.window_title"/>
              </el-form-item>

              <el-form-item class="checkbox">
                <el-checkbox v-model="activeNode.dashboard.isFullscreen"></el-checkbox>
                <span class="property_label" style="display: inline-block;">{{ $locale.etl_editor.tooltip.full_screen }}</span>
              </el-form-item>

              <el-form-item>
                <span class="property_label">{{ $locale.interface_editor.component_editor.button_action.window_width }}</span>
                <editor-select
                  :value="activeNode.dashboard.window_width"
                  @change="$set(activeNode.dashboard ,'window_width', $event)"
                  :options="{
                              multiple: false,
                              options: [
                                { id: '25', name:'25%' },
                                { id: '50', name:'50%' },
                                { id: '75', name:'75%' },
                                { id: '100', name:'100%' },
                              ]
                            }"
                ></editor-select>
              </el-form-item>
              <el-form-item>
                <editor-options
                  :value="activeNode.dashboard.defaults"
                  @change="$set(activeNode.dashboard ,'defaults', $event)"
                  :title="$locale.main.fields.default"/>
              </el-form-item>
            </template>
            <editor-open-card
              v-if="activeNode.clickType === 'open_card'"
              :isButton="false"
              :isTreeSelect="true"
              :listExtendObject="listExtendObject"
              :xrefObjectId="xrefObjectId"
              :value="activeNode"
              :query="query"
              :show-setting-open-in-window="true"
            ></editor-open-card>
          </el-form-item>
        </el-collapse-item>
      </el-form>
    </SplitArea>
  </Split>
</template>

<script>
import { FilterBuilder } from '@/core/infrastructure/components/Grid/infrastructure/service/FilterBuilder'
import ColumnTypes from '@/core/infrastructure/components/Grid/ColumnTypesList.json'
import EditorOpenCard from '@/components/InterfaceEditor/components/editor/editor-open-card.vue'
import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'
import EditorDashboards from '@/components/InterfaceEditor/components/editor/editor-dashboards'
import EditorOptions from '@/components/InterfaceEditor/components/editor/editor-options.vue'
import EditorButtonAction from '@/components/InterfaceEditor/components/editor/editor-button-action'
import ModalWindow from '@/core/infrastructure/components/ModalWindow'
import SparklineOptions from '@/components/InterfaceEditor/components/editor/Columns/configurator-sparkline.vue'
const customField = {
  headerName: 'Столбец',
  field: 'Атрибут',
  children: undefined,
  type: ['stringField'],
  enableRowGroup: true,
  columnType: 'stringField'
}
export default {
  name: 'configurator',
  components: {
    EditorOpenCard,
    EditorSelect,
    EditorDashboards,
    EditorOptions,
    EditorButtonAction,
    ModalWindow,
    SparklineOptions
  },
  props: {
    sourceType: {
      type: String
    },
    sourceId: {
      type: Number
    },
    availableColumns: {
      type: Array
    },
    value: {
      type: Array
    }
  },
  inject: {
    getComponents: {
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      components: this.getComponents(),
      properties: {
        label: 'headerName'
      },
      filterText: null,
      activeNode: {},
      columnTypes: ColumnTypes,
      listExtendObject: null,
      xrefObjectId: null,
      showModalWindow: false
    }
  },
  methods: {
    hasColumn (array, field) {
      let result = false
      array.forEach((item) => {
        if (result) {
          return
        }
        if (item.field === field) {
          result = true
        } else {
          if (Array.isArray(item.children) && item.children.length > 0) {
            result = this.hasColumn(item.children, field)
          }
        }
      })
      return result
    },
    changeColumnType (type) {
      let types = this.activeNode.type
      types[0] = type
      const isFilterable = !!this.activeNode.filter

      isFilterable && this.setFilterable(false, this.activeNode)

      this.$set(this.activeNode, 'type', types)
      this.$set(this.activeNode, 'columnType', type)

      isFilterable && this.setFilterable(true, this.activeNode)
    },
    addCustomColumn () {
      let result = JSON.parse(JSON.stringify(Object.assign(customField, { guid: this.generateGuid() })))
      this.$emit('update:value', [...this.value, result])
    },
    filterNode (value, data) {
      if (!value) return true
      return data.headerName.indexOf(value) !== -1 || data.field.indexOf(value) !== -1
    },
    addColumn (data) {
      const result = Object.assign(JSON.parse(JSON.stringify(data)), { guid: this.generateGuid() })
      this.$emit('update:value', [...this.value, result])
    },
    deleteColumn (data) {
      if (this.activeNode.guid === data.guid) {
        this.activeNode = {}
      }
      this.$emit('update:value', this.recursiveRemoveItem(this.value, data.guid))
    },
    recursiveRemoveItem (value, guid) {
      let currentEl = null
      value.forEach((item, index) => {
        if (currentEl) {
          return false
        }
        if (item.guid === guid) {
          currentEl = item
          value.splice(index, 1)
        } else {
          if (item.children instanceof Array) {
            this.recursiveRemoveItem(item.children, guid)
          }
        }
      })

      return value
    },
    setFilterable (toSet, data) {
      let filterObject = FilterBuilder.build(data.columnType)
      console.log(data.columnType, filterObject)
      for (let key in filterObject) {
        if (toSet) {
          this.$set(data, key, filterObject[key])
        } else {
          this.$delete(data, key)
        }
      }
    },
    async getExtendedObjectFields () {
      let response = await this.$http.get(`${this.$config.api}/bieditor/extended_objects/${this.sourceId}`)
      return response.data.extended_object_fields
    }
  },
  computed: {
    query () {
      return this.sourceType === 'query'
    }
  },
  watch: {
    filterText (val) {
      this.$refs.availableColumnsTree.filter(val)
    }
  },
  async mounted () {
    console.log(this.getComponents())
    if (this.sourceId && this.sourceType === 'extended_object') {
      this.listExtendObject = await this.getExtendedObjectFields()
    }
    if (this.sourceId && this.sourceType === 'registry') {
      this.xrefObjectId = this.sourceId
    }
  }
}
</script>

<style scoped>
.form /deep/ .el-form-item__label {
  padding-bottom: 0px;
}
.form /deep/ .el-form-item {
  padding: 0 5px;
}
.active-node {
  font-weight: bold;
}
.delete-button {
  background: white;
  position: absolute;
  right: 5px;
  color: #F56C6C;
  display: none;
}
/deep/ .el-tree-node:hover>.el-tree-node__content>span>.delete-button {
  display: inline-block;
}
.isset-node {
  color: #67c23a;
}
.active-link {
  font-weight: bold;
  color: black;
  cursor: pointer;
}
.active-link:hover {
  color: skyblue;
}
.button {
  padding: 5px;
  background: skyblue;
  cursor: pointer;
  margin-right: 15px;
  color: rgb(247, 240, 240);
}
.button:hover {
  background: rgb(100, 163, 189);
}

.scrollable {
  overflow-y: scroll;
}
</style>
