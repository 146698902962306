import ApprovalStage from '@/services/BPMNEditor/domain/model/ApprovalStage'
import BPMNService from '@/services/BPMNEditor/infrastructure/service/BPMNService'

export default class ApprovalStageBPMNBuilder {
  private approvalStage: ApprovalStage
  public constructor (approvalStage: ApprovalStage) {
    this.approvalStage = approvalStage
  }

  public build (BPMNService: BPMNService, position: {X, Y}): {elements, connections, lastPosition} {
    const modeling = BPMNService.getModeler().get('modeling')
    let lastX = position.X
    let lastY = position.Y
    const elementFactory = BPMNService.getModeler().get('elementFactory')
    // создание самого этапа
    const [approvalStage, approveGateway, cancelGateway] = BPMNService.createElement({
      type: 'ApprovalStage',
      properties: {
        accentGuid: this.approvalStage.guid,
        accentType: 'approval_stage'
      },
      name: this.approvalStage.name,
      position
    })
    lastX = approveGateway.x
    lastY = approveGateway.y

    let additionalApproveElements = []
    let additionalCancelElements = []
    let connections = []
    // создание команд отмены со шлюзом
    if (this.approvalStage.cancelCommandElements.length > 0) {
      let lastElement = cancelGateway
      approveGateway.x = approveGateway.x + 80
      cancelGateway.x = cancelGateway.x + 80
      approvalStage.x = approvalStage.x + 80
      if (this.approvalStage.isParallelCancelGateway()) {
        lastElement = elementFactory.createShape({
          type: 'bpmn:ParallelGateway'
        })
        lastElement.x = cancelGateway.x
        lastElement.y = cancelGateway.y + 100
        connections.push({
          from: cancelGateway,
          to: lastElement
        })
        additionalCancelElements.push(lastElement)
      }

      this.approvalStage.cancelCommandElements.forEach((command: {x, y}, index) => {
        command.y = lastElement.y + (100 * (index + 1))
        command.x = lastElement.x - 100
        connections.push({
          from: lastElement,
          to: command
        })
        additionalCancelElements.push(command)
      })
      if (this.approvalStage.isParallelCancelGateway() && this.approvalStage.returnStageGuid) {
        let previousStageGateway = elementFactory.createShape({
          type: 'bpmn:ParallelGateway'
        })
        previousStageGateway.x = cancelGateway.x - 100
        previousStageGateway.y = cancelGateway.y + 100
        additionalCancelElements.push(previousStageGateway)
        this.approvalStage.cancelCommandElements.forEach((command) => {
          connections.push({
            from: command,
            to: previousStageGateway
          })
        })
      }
    }
    // создание команд согласия со шлюзом
    if (this.approvalStage.approveCommandElements.length > 0) {
      let lastElement = approveGateway
      if (this.approvalStage.isParallelApproveGateway()) {
        lastElement = elementFactory.createShape({
          type: 'bpmn:ParallelGateway'
        })
        lastX = lastElement.x = approveGateway.x
        lastY = lastElement.y = approveGateway.y + 100
        connections.push({
          from: approveGateway,
          to: lastElement
        })
        additionalApproveElements.push(lastElement)
      }
      this.approvalStage.approveCommandElements.forEach((command: {x, y}, index) => {
        lastY = command.y = lastElement.y + (100 * (index + 1))
        lastX = command.x = lastElement.x + 50
        connections.push({
          from: lastElement,
          to: command
        })
        additionalApproveElements.push(command)
      })
    }

    if (this.approvalStage.isParallelApproveGateway()) {
      // создаем шлюз для перехода в след. этап, если не понадобится, то он удалится
      let nextStageGateway = elementFactory.createShape({
        type: 'bpmn:ParallelGateway'
      })
      lastX = nextStageGateway.x = lastX + 200
      nextStageGateway.y = Math.ceil(position.Y + ((lastY - position.Y) / 2))
      additionalApproveElements.push(nextStageGateway)
      this.approvalStage.approveCommandElements.forEach((command) => {
        connections.push({
          from: command,
          to: nextStageGateway
        })
      })
    }

    return {
      elements: [approvalStage, ...additionalCancelElements.reverse(), cancelGateway, approveGateway, ...additionalApproveElements],
      connections: connections,
      lastPosition: {
        X: lastX,
        Y: lastY
      }
    }
  }
}
