import { render, staticRenderFns } from "./card.vue?vue&type=template&id=6925255e&scoped=true&"
import script from "./card.vue?vue&type=script&lang=js&"
export * from "./card.vue?vue&type=script&lang=js&"
import style0 from "./card.vue?vue&type=style&index=0&id=6925255e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6925255e",
  null
  
)

export default component.exports