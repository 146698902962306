import { ActionTree } from 'vuex'
import { UserState, initialState } from './state'
import { ActionTypes } from './action-types'
import { MutationTypes, UserLoadingEvent, UserLoadedEvent, UserCreatedEvent, UserUpdatedEvent, UserDeletedEvent } from './mutation-types'
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'

export const actions: ActionTree<UserState, any> = {
  [ActionTypes.ADD_USER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new UserLoadingEvent())
      let location = await APIClient.shared.request(new UserAPI.AddUser(data.payload))
      commit(new UserCreatedEvent(location))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPDATE_USER]: async ({ commit, dispatch }, data) => {
    try {
      commit(new UserLoadingEvent())
      let location = await APIClient.shared.request(new UserAPI.UpdateUser(data.payload))
      commit(new UserUpdatedEvent())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.DELETE_USER]: async ({ commit, dispatch }, data) => {
    try {
      await APIClient.shared.request(new UserAPI.DeleteUser(data.guid))
      commit(new UserDeletedEvent(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_USERS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new UserLoadingEvent())
      let res = await APIClient.shared.request(new UserAPI.GetUsers(data.payload))
      commit(new UserLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.COUNT_USERS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new UserLoadingEvent())
      let res = await APIClient.shared.request(new UserAPI.CountUsers(data.payload))
      commit(new UserLoadedEvent())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_USER_BY_GUID]: async ({ commit, dispatch }, data) => {
    try {
      return await APIClient.shared.request(new UserAPI.GetUserByGuid(data.guid))
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_PASSWORD]: async ({ commit, dispatch }, data) => {
    try {
      let res = await APIClient.shared.request(new UserAPI.GetPassword())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_PASSWORD_RULES]: async ({ commit, dispatch }, data) => {
    try {
      let res = await APIClient.shared.request(new UserAPI.GetPasswordRules())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.PASSWORD_CHECK]: async ({ commit, dispatch }, data) => {
    try {
      let res = await APIClient.shared.request(new UserAPI.PasswordCheck(
        data.password
      ))
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_API_KEY]: async ({ commit, dispatch }, data) => {
    try {
      let res = await APIClient.shared.request(new UserAPI.GetApiKey(
        data.userId,
        data.roleId,
        data.systemIps
      ))
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_AVATAR]: async ({ commit, dispatch }, data) => {
    try {
      let res = await APIClient.shared.request(new UserAPI.GetAvatar(
        data.avatarId
      ))
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.UPLOAD_USERS]: async ({ commit, dispatch }, data) => {
    try {
      commit(new UserLoadingEvent())
      let location = await APIClient.shared.request(new UserAPI.UploadUsers(data.payload))
      commit(new UserUpdatedEvent())
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  },
  [ActionTypes.GET_USER_CARD]: async ({ commit, dispatch }, data) => {
    try {
      let res = await APIClient.shared.request(new UserAPI.GetUserCard())
      return res
    } catch (error) {
      commit(MutationTypes.ERROR, error)
    }
  }
}
