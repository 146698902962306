import CommandInterface from '@/core/application/command/CommandInterface'
import { Nullable } from '@/core/domain/type/types'

export default class ListenerUpdateCommand implements CommandInterface {
  private guid: string
  private name: string
  private description: Nullable<string>
  private eventId: number
  private listenerCards: Array<number>
  private listenerCommands: Array<number>
  private alias: Nullable<string>

  constructor (
    guid: string,
    name: string,
    description: Nullable<string>,
    eventId: number,
    listenerStates: Array<number>,
    listenerCommands: Array<number>,
    alias: Nullable<string>
  ) {
    this.guid = guid
    this.name = name
    this.description = description
    this.eventId = eventId
    this.listenerCards = listenerStates
    this.listenerCommands = listenerCommands
    this.alias = alias
  }

  getClassName (): string {
    return 'ListenerUpdateCommand'
  }

  getDescription (): Nullable<string> {
    return this.description
  }

  getGuid (): string {
    return this.guid
  }

  getName (): string {
    return this.name
  }

  getEventId (): number {
    return this.eventId
  }

  getListenerCards (): Array<number> {
    return this.listenerCards
  }

  getListenerCommands (): Array<number> {
    return this.listenerCommands
  }

  getAlias (): Nullable<string> {
    return this.alias
  }
}
