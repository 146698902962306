




























import Vue from 'vue'
import Report from '@/components/ReportEditor/Models/Reports'

export default Vue.extend({
  name: 'editor-report',

  props: {
    value: Object
  },

  watch: {
    value: {
      handler: function () {
        this.model = this.value.reports || null
      },
      deep: true
    },

    model: {
      handler: function () {
        this.$set(this.value, 'reports', this.model)
      },
      deep: true
    },

    tabName: {
      handler: function (value) {
        this.$set(this.value.reports, 'tabName', value)
      }
    }
  },

  data () {
    return {
      model: this.value.reports || null,
      tabName: this.value?.reports?.tabName || this.value?.reports?.name,
      reports: []
    }
  },
  async mounted () {
    this.reports = await new Report().params({ fields: 'id,guid,name', report_type: 'report' }).$get()
  }
})
