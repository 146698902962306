var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tree-select',{staticClass:"custom_scrollbar",attrs:{"value":_vm.value,"options":_vm.options,"load-options":_vm.loadOptions,"multiple":_vm.multiple,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"normalizer":_vm.normalizer,"disable-branch-nodes":true,"clear-on-select":false,"append-to-body":_vm.appendToBody,"clearable":_vm.clearable,"clearValueText":_vm.clearValueText,"noChildrenText":_vm.noChildrenText,"noResultsText":_vm.noResultsText,"limitText":_vm.limitText,"limit":1,"matchKeys":_vm.matchKeys,"value-consists-of":_vm.valueConsistsOf},on:{"input":function($event){return _vm.$emit('input', $event)},"select":_vm.selectAlias},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('div',{},[_vm._v(_vm._s(node.label)+" "),_c('b',[_vm._v(" (id: "+_vm._s(node.raw.id)+")")])])}},{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return (_vm.standardTree)?_c('label',{class:labelClassName},[_vm._v(" "+_vm._s(node.label)+"(id: "+_vm._s(node.id)+", "),_c('b',[_vm._v(_vm._s(_vm.$locale.object_editor.entity_types[node.raw.entity_type_id] || node.raw.entity_type_id))]),_vm._v(") ")]):_c('label',{class:labelClassName},[_c('b',[_vm._v(_vm._s(node.label))]),_c('br'),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("attr_"+_vm._s(node.id)+"_")]),_c('span',{staticStyle:{"float":"right","font-size":"10px"}},[_vm._v(_vm._s(_vm.$locale.object_editor.entity_types[node.raw.entity_type_id] || node.raw.entity_type_id))])])}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }