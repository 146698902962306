






























































import mixins from 'vue-typed-mixins'
import RowFormulaByGuidQuery from '@/services/LogicEditor/application/query/RowFormulaByGuidQuery'
import RowFormula from '@/services/LogicEditor/domain/model/RowFormula'
import RowFormulaCreateCommand from '@/services/LogicEditor/application/command/RowFormulaCreateCommand'
import RowFormulaUpdateCommand from '@/services/LogicEditor/application/command/RowFormulaUpdateCommand'
import FormulaCalculator from '@/core/infrastructure/components/FormulaCalculator/index.vue'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import ObjectTree from '@/components/Common/ObjectTree.vue'
import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'

export default mixins(FormMixin).extend({
  name: 'RowFormulaForm',

  components: {
    FormulaCalculator,
    RegistrySelectTree,
    ObjectTree
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['RowFormula/isRowFormulaLoading']
    },

    rowFormulaCreated () {
      return this.$store.getters['RowFormula/getRowFormulaLocation']
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ],
        alias_field_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.alias_field'), trigger: 'change' }
        ],
        target_alias_value: [
          { required: true, message: this.$t('logic_editor_v2.rules.value'), trigger: 'change' }
        ],
        target_row_formula_columns: [
          { required: true, message: this.$t('logic_editor_v2.rules.columns'), trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    rowFormulaCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new RowFormulaByGuidQuery(
            location.replace('/row_formulas/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    },

    'model.alias_field_id': {
      handler: function (value) {
        if (value !== null) {
          this.$http
            .get(`${this.$config.api}/objecteditor/entities/${value}`)
            .then((response) => {
              this.aliasField = response.data.data
            })
        }
      }
    },

    'aliasField': {
      handler: function (value) {
        if (value !== null) {
          this.$http
            .get(`${this.$config.api}/registryservice/xref/`, {
              params: {
                registry_id: this.objectId,
                attribute_id: value.id,
                distinct: true
              }
            })
            .then(response => {
              this.targetAliasValues = []
              if (response.status === 200) {
                this.targetAliasValues = this.parseXrefData(value.entity_type_id, response.data)
              }
            })
        }
      }
    }
  },

  data () {
    return {
      model: this.value,

      toolProps: {
        functions: true,
        operators: false,
        currentArguments: true,
        externalArguments: true
      },

      aliasField: [],
      targetAliasValues: []
    }
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    parseXrefData (entityType, data) {
      let result = []
      if (entityType === 'xref_multi_field' || entityType === 'xref_field') {
        data.forEach(item => {
          item.name = JSON.parse(item.name)

          item.name.forEach(row => {
            if (typeof result.find(el => el.id === row.id) === 'undefined') {
              result.push(row)
            }
          })
        })
      } else {
        result = data.filter(el => el.name !== null).map((el, index) => ({ name: el.name.toString(), id: index }))
      }
      return result
    },

    getCommand () {
      let rowFormula = RowFormula.create(this.value)

      if (this.isNew) {
        return new RowFormulaCreateCommand(
          rowFormula.getName(),
          rowFormula.getDescription(),
          rowFormula.getObjectId(),
          rowFormula.getAliasFieldId(),
          rowFormula.getTargetAliasValue(),
          rowFormula.getValue(),
          rowFormula.getAst(),
          rowFormula.getPreparedStatement(),
          rowFormula.getHasTargetRows(),
          rowFormula.getLogicId(),
          rowFormula.getAlias(),
          rowFormula.getParentTreeElementId()
        )
      }

      return new RowFormulaUpdateCommand(
        rowFormula.getGuid(),
        rowFormula.getName(),
        rowFormula.getDescription(),
        rowFormula.getObjectId(),
        rowFormula.getAliasFieldId(),
        rowFormula.getTargetAliasValue(),
        rowFormula.getValue(),
        rowFormula.getAst(),
        rowFormula.getPreparedStatement(),
        rowFormula.getHasTargetRows(),
        rowFormula.getAlias()
      )
    }
  }
})
