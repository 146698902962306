<template>
  <div class="route-panel">
    <span class="wrapper">
      <el-tabs
          v-model="activeEntity"
          :tab-position="tabPosition"
          type="border-card"
          class="main-tabs"
      >
        <el-tab-pane
            :label="$locale.databus.entities.packages_incoming"
            name="package"
        >
          <RoutePackagePanel/>
        </el-tab-pane>
        <el-tab-pane
            :label="$locale.databus.entities.routes_smev"
            name="smev"
        >
          <RouteSmevPanel/>
        </el-tab-pane>
        <el-tab-pane
            :label="$locale.databus.entities.routes_smev_incoming"
            name="smev_incoming"
        >
          <RouteSmevIncomingPanel/>
        </el-tab-pane>
        <el-tab-pane
            :label="$locale.databus.entities.routes_gisgkh"
            name="gis_gkh"
        >
          <RouteGisgkhPanel/>
        </el-tab-pane>
        <el-tab-pane
            :label="$locale.databus.entities.routes_sn"
            name="sn"
        >
          <RouteSnPanel/>
        </el-tab-pane>
      </el-tabs>
    </span>
  </div>
</template>


<script>
  import RoutePackagePanel from '@/services/Databus/infrastructure/components/RoutePanel/RoutePackagePanel/index.vue'
  import RouteSmevPanel from '@/services/Databus/infrastructure/components/RoutePanel/RouteSmevPanel/index.vue'
  import RouteSmevIncomingPanel from '@/services/Databus/infrastructure/components/RoutePanel/RouteSmevIncomingPanel/index.vue'
  import RouteGisgkhPanel from '@/services/Databus/infrastructure/components/RoutePanel/RouteGisgkhPanel/index.vue'
  import RouteSnPanel from '@/services/Databus/infrastructure/components/RoutePanel/RouteSnPanel/index.vue'

  export default {
    name: 'RoutePanel',
    components: {
      RoutePackagePanel,
      RouteSmevPanel,
      RouteSmevIncomingPanel,
      RouteGisgkhPanel,
      RouteSnPanel
    },
    data() {
      return {
        activeEntity: 'package',
        tabPosition: "left"
      }
    },
    inject: ['getEventBus', 'getQueryBus', 'getCommandBus']
  }
</script>
