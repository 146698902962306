import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElRuLocale from 'element-ui/lib/locale/lang/ru-RU'
import locales from '@/locale/ru.json'

Vue.use(VueI18n)

const messages = {
  ru: {
    ...locales,

    el: ElRuLocale.el
  }
}

const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages
})

export default i18n
