export default class CertificateModel {
  Algorithm: string;
  HasPrivateKey: boolean;
  IsValid: boolean;
  Issuer: object;
  IssuerName: string;
  ProviderName: string;
  ProviderType: number;
  Subject: object;
  SubjectName: string;
  Thumbprint: string;
  ValidFromDate: string;
  ValidToDate: string;
  SerialNumber: string;
  AlgorithmOid: string;

  constructor(data: any) {
    this.SerialNumber = data.SerialNumber;
    this.Algorithm = data.Algorithm;
    this.HasPrivateKey = data.HasPrivateKey;
    this.IsValid = data.IsValid;
    this.Issuer = data.Issuer;
    this.IssuerName = data.IssuerName;
    this.ProviderName = data.ProviderName;
    this.ProviderType = data.ProviderType;
    this.Subject = data.Subject;
    this.SubjectName = data.SubjectName;
    this.Thumbprint = data.Thumbprint;
    this.AlgorithmOid = data.AlgorithmOid;
    this.ValidFromDate = new Date(
      data.ValidFromDate
    ).toLocaleDateString();
    this.ValidToDate = new Date(
      data.ValidToDate
    ).toLocaleDateString();
  }
}
