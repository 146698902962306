import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import System from '@/components/System'
import LoginPage from '@/components/LoginPage'
import Registry from '@/components/Registry'
import OAuthPage from '@/components/LoginPage/oauth'
import EsiaPage from '@/components/LoginPage/esia'
import FastEsia from '@/components/LoginPage/FastEsia'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['Authorization/isAuthenticated']) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters['Authorization/isAuthenticated']) {
    next()
    return
  }
  next('/login')
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Index',
      component: System,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/login_esia',
      name: 'FastEsia',
      component: FastEsia,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/registry/:id',
      name: 'Registry',
      component: Registry,
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/oauth/:providerId',
      name: 'OAuthPage',
      component: OAuthPage,
      props: true
    },
    {
      path: '/esia',
      name: 'EsiaPage',
      component: EsiaPage,
      props: true
    }
  ]
})
