













































































































































import Vue from 'vue'
import Report from '@/services/LogicEditor/infrastructure/components/elements/Report.vue'
import Topic from '@/services/LogicEditor/infrastructure/components/elements/Topic.vue'
import FieldSelect from '@/services/LogicEditor/infrastructure/components/elements/FieldSelect.vue'
import TreeSelect from '@/components/Common/TreeSelect.vue'

export default Vue.extend({
  name: 'ReportEventForm',

  props: ['value', 'logicId', 'objectId'],

  components: {
    Report,
    Topic,
    FieldSelect,
    TreeSelect
  },

  data () {
    return {
      model: this.value,
      adapterKeys: [
        { id: 'smev_reply_to_client_id', name: 'id клиента ответа СМЭВ' },
        { id: 'sn_receiver_code', name: 'Код получателя цифровой вертикали строительства' }
      ],
      rules: {
        report_id: [
          { required: true, message: 'Select report', trigger: 'change' }
        ],
        format: [
          { required: true, message: 'Select format', trigger: 'change' }
        ],
        operation_type: [
          { required: true, message: 'Select user field', trigger: 'change' }
        ],
        file_field_id: [
          { required: true, message: 'Select file field', trigger: 'change' }
        ]
      },
      templateSourceTypes: [
        { id: 'report_editor', name: 'Редактор отчётов' },
        { id: 'file_field', name: 'Файловое поле' }
      ],
      formatTypes: [
        { value: 'docx', label: 'Document MS Word' },
        { value: 'pdf', label: 'PDF' },
        { value: 'xml', label: 'XML file' }
      ],
      operationTypes: [
        { value: 'send_to_databus', label: 'send_to_databus' },
        { value: 'associate_registry_field', label: 'associate_registry_field' }
      ],
      treePropsFileFields: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return data.entity_type_id !== 'file_field'
        }
      },
      treePropsAllFields: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return data.entity_type_id === 'field_group'
        }
      },
      fields: []
    }
  },

  async mounted () {
    if (!this.fields.length && this.objectId) {
      await this.$http
        .get(`${this.$config.api}/objecteditor/entities?parent_id=${this.objectId}&show_children=true`)
        .then((response) => {
          this.fields = response.data.data
        })
        .catch(error => console.error(error))
    }
  },

  methods: {
    addAdapterField () {
      if (!Array.isArray(this.model.additional_adapter_fields)) {
        this.model.additional_adapter_fields = []
      }

      this.model.additional_adapter_fields.push({ key: null, value: null })
    },

    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = valid
      })

      return isValid
    },

    renderContent (h, { node, data, store }) {
      return h('span', {
        class: 'custom-tree-node'
      }, [
        h(
          'span', {
            class: 'node-label'
          }, [
            h(
              'span',
              {
                class: 'node-label__name'
              },
              node.label
            ),
            h(
              'span',
              {
                class: 'node-label__info'
              },
              `${this.$t('object_editor.entity_types.' + data.entity_type_id)} id ${data.id}`
            )
          ])
      ])
    },

    renderOptionLabel (item) {
      return `${item.name} (id: ${item.id}, ${this.$t('object_editor.entity_types.' + item.entity_type_id)})`
    }
  }
})
