<template>
  <el-row :gutter="10">
    <el-col :span="8">
      <registry-select-tree
        style="width: 100%;"
        type="registry"
        value-as="number"
        v-model="model.registry_id"
        @change="changeRegistryId"
        :placeholder="placeholder"
      ></registry-select-tree>
    </el-col>

    <el-col :span="8">
      <el-select v-model="model.field_id" placeholder="Field" :disabled="!fields.length" @change="changeField" filterable>
        <el-option
          v-for="item in fields"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        >
          <span style="margin-right: 10px;">{{ item.name }}</span>
          <span style="color: #8492a6; font-size: 13px">
            <span style="font-weight: bold;margin-right: 5px;">{{ item.id === 'id' ? item.id : `attr_${item.id}_` }}</span>
            <span>{{ item.id === 'id' ? item.type : $locale.object_editor.entity_types[item.type] }}</span>
          </span>
        </el-option>
      </el-select>
    </el-col>

    <el-col :span="8">
      <el-tooltip class="item" effect="dark" placement="left" :open-delay="500">
        <div slot="content">
          <!--<p>Определение связи текущего реестра со внешним</p>
          <p>Необходимо когда Вы хотите проверить условие: <b>"равно ли поле текущего реестра, полю внешнего реестра"</b></p>-->

          <p>В разработке</p>
        </div>

        <tree-select
          v-model="model.xref_field_id"
          :data="currentFields"
          :props="treeProps"
          :disabled="isXrefField"
          :render-content="renderContent"
          :render-option-label="renderOptionLabel"
          :placeholder="$t('logic_editor_v2.labels.xrefExternal')"
          clearable
          @change="changeXrefField"
        ></tree-select>
      </el-tooltip>
    </el-col>
  </el-row>
</template>

<script>
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree'
import TreeSelect from '@/components/Common/TreeSelect.vue'

export default {
  name: 'ExternalTableField',

  props: {
    value: Object,

    placeholder: {
      type: String,
      default: 'External Object Field'
    },

    currentFields: {
      type: Array,
      default () {
        return []
      }
    }
  },

  components: {
    RegistrySelectTree,
    TreeSelect
  },

  computed: {
    isXrefField () {
      return !(this.model.registry_id && this.model.field_id)
    }
  },

  watch: {
    value () {
      this.model = this.value
    }
  },

  data () {
    return {
      model: this.value,

      fields: [],

      treeProps: {
        children: 'children',
        isLeaf: 'isLeaf',
        label: 'name',
        disabled: (data, node) => {
          return !([
            'xref_field', 'xref_multi_field'
          ].includes(data.entity_type_id))
        }
      }
    }
  },

  mounted () {
    if (typeof this.model.registry_id === 'undefined') {
      this.model.registry_id = null
    }

    console.log('this.model.registry_id', this.model.registry_id)

    if (this.model.registry_id) {
      this.changeRegistryId(this.model.registry_id)
    }
  },

  methods: {
    changeXrefField (value) {
    },

    changeRegistryId (value) {
      this.fields = []

      if (value) {
        this.fields.push({
          id: 'id',
          name: 'Идентификатор',
          type: 'Идентификатор'
        })

        this.$http
          .get(`${this.$config.api}/objecteditor/entities?parent_id=${value}&show_children=true`)
          .then((response) => {
            this.fields.push(...this.buildFields(response.data.data))
          })
      }
    },

    buildFields (fields = []) {
      const result = []

      fields.forEach((field) => {
        if (field.entity_type_id === 'field_group') {
          if (field.children.length) {
            result.push(...this.buildFields(field.children))
          }
        } else {
          result.push({
            id: field.id,
            name: field.name,
            type: field.entity_type_id
          })
        }
      })

      return result
    },

    changeField (value) {
      this.$emit('input', this.model)
    },

    renderOptionLabel (item) {
      return `${item.name} (id: ${item.id}, ${this.$locale.object_editor.entity_types[item.entity_type_id]})`
    },

    renderContent (h, { node, data, store }) {
      const attr = `[attr_${data.id}_] ${this.$t('object_editor.entity_types.' + data.entity_type_id)}`

      return h('span', {
        class: {
          'custom-tree-node': true
        }
      }, [
        h('span', node.label),
        h(
          'span',
          {
            class: 'el-tree-node__attribute'
          },
          attr
        )
      ])
    },
  }
}
</script>

<style lang="scss" scoped>
  .el-select-dropdown__item {
    display: flex;
    justify-content: space-between;
  }
</style>
