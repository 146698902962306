<template>
  <div class="xml_editor">
    <div class="tool_box">
      <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.button.save" placement="top">
        <el-button
          size="small"
          @click="save"
          type="success"
          icon="el-icon-success"
          class="tool_item">
          {{ $locale.main.button.save }}
        </el-button>
      </el-tooltip>

      <el-select
        v-model="options.tabSize"
        placeholder="Tab Size"
        size="small"
        class="tool_item tool_item-select">
        <el-option
          v-for="item in [{ value: 2, label: 'Tab Size 2' }, { value: 4, label: 'Tab Size 4' }]"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>

    <codemirror class="code_box" v-model="code" :options="options"></codemirror>

    <el-button
      class="cd-button"
      type="primary"
      :style="{ right: !collapsed ? '0px' : '300px' }"
      :icon="!collapsed ? 'el-icon-caret-left' : 'el-icon-caret-right'"
      @click="collapsePanel">
    </el-button>
    <div class="cd-panel from-right" v-bind:class="{ 'is-visible': collapsed }">
      <div class="cd-panel-container">
        <div class="cd-panel-content">
          <el-tree
            :data="fields"
            :props="{ children: 'children', label: 'name' }">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>
                <el-tooltip :open-delay="300" class="item" effect="dark" :content="$locale.main.button.copy_clipboard" placement="top">
                  <el-button
                    type="text"
                    size="small"
                    v-clipboard:copy="data.name">
                    {{ node.label }}
                  </el-button>
                </el-tooltip>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Report from '@/components/ReportEditor/Models/Reports'

export default {
  name: 'XmlEditor',

  props: {
    reportId: Number
  },

  data () {
    return {
      report: null,
      code: null,
      freezeCode: null,
      options: {
        tabSize: 4,
        mode: 'application/xml',
        theme: 'base16-light',
        styleActiveLine: true,
        styleSelectedText: true,
        lineNumbers: true,
        line: false,
        autoCloseTags: true,
        extraKeys: {
          'Ctrl-S': this.save
        }
      },
      fields: [],
      collapsed: false
    }
  },

  async mounted () {
    await this.$http
      .get(`${this.$config.api}/reporteditor/report_source_columns/${this.reportId}`)
      .then(response => {
        this.fields.push(...response.data)
      })

    this.report = await Report.find(this.reportId)
    this.code = this.report.content
    this.freezeCode = this.report.content
  },

  methods: {
    async save () {
      if (this.report) {
        this.report.content = this.code
        this.freezeCode = this.code
        await this.report.save()
      }
    },

    isEditXml () {
      return this.freezeCode !== this.code
    },

    collapsePanel () {
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<style type="text/css">
.xml_editor {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.code_box {
  height: calc(100vh - 95px);
}
.code_box .CodeMirror {
  width: 100%;
  height: 100%;
}
.code_box .cm-s-base16-light.CodeMirror {
  background: #ffffff;
  color: #202020;
}
.code_box .CodeMirror-gutters {
  z-index: 2;
}
.code_box .cm-s-base16-light .CodeMirror-gutters {
  border-right: 1px solid #e4e7ed;
}

.cd-button {
  position: absolute;
  top: calc(50% - 35px);
  border-radius: 5px 0 0 5px;
  height: 70px;
  width: 35px;
  -webkit-transition: top 1s ease-out 0.5s;
  transition: top 1s ease-out 0.5s;
  padding: 10px;
  text-align: center;
  z-index: 1001;
}
.cd-panel {
  visibility: hidden;
  transition: visibility 0s 0.6s;
}
.cd-panel.is-visible {
  visibility: visible;
  transition: visibility 0s 0s;
}

.cd-panel-container {
  position: absolute;
  width: 300px;
  height: 100%;
  top: 0;
  right: 0;

  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0.3s;

  transform: translate3d(100%, 0, 0);

  background-color: #fff;
  border-left: 1px solid #e4e7ed;

  z-index: 1001;
}

.is-visible .cd-panel-container {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}
</style>
