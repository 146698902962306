






















































import mixins from 'vue-typed-mixins'
import ListenerByGuidQuery from '@/services/LogicEditor/application/query/ListenerByGuidQuery'
import Listener from '@/services/LogicEditor/domain/model/Listener'
import ListenerCreateCommand from '@/services/LogicEditor/application/command/ListenerCreateCommand'
import ListenerUpdateCommand from '@/services/LogicEditor/application/command/ListenerUpdateCommand'
import CommandEventTypesQuery from '@/services/LogicEditor/application/query/CommandEventTypesQuery'
import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'

export default mixins(FormMixin).extend({
  name: 'ListenerForm',

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['Listener/isListenerLoading']
    },

    listenerCreated () {
      return this.$store.getters['Listener/getListenerLocation']
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ],
        event_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.event'), trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    listenerCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new ListenerByGuidQuery(
            location.replace('/listeners/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      events: [],
      cards: []
    }
  },

  async mounted () {
    if (!this.events.length) {
      this.events = await this.getQueryBus().execute(
        new CommandEventTypesQuery()
      )
    }

    if (!this.cards.length) {
      this.$http
        .get(`${this.$config.api}/interfaceeditor/cards?entity_id=${this.objectId}`)
        .then((response) => {
          this.cards.push(...response.data)
        })
        .catch((error) => console.error(error))
    }
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getCommandBus().execute(
            this.getCommand()
          ).then(() => {
            callback()
          })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let listener = Listener.create(this.value)

      if (this.isNew) {
        return new ListenerCreateCommand(
          listener.getName(),
          listener.getDescription(),
          listener.getEventId(),
          listener.getListenerCards(),
          listener.getListenerCommands(),
          listener.getLogicId(),
          listener.getAlias(),
          listener.getParentTreeElementId()
        )
      }

      return new ListenerUpdateCommand(
        listener.getGuid(),
        listener.getName(),
        listener.getDescription(),
        listener.getEventId(),
        listener.getListenerCards(),
        listener.getListenerCommands(),
        listener.getAlias()
      )
    }
  }
})
