<template>
  <div v-loading="loading">
    <select-tree
      class="property"
      :value="value"
      @input="$emit('input', $event)"
      type-field="report_type"
      :clearable="false"
      :standartTree='true'
      :dataTable="reports"
    />
  </div>
</template>

<script>
import SelectTree from '@/core/infrastructure/components/TreeSelect'
import Report from '@/components/Models/reporteditor/Report'

export default {
  name: 'Report',
  components: {
    SelectTree
  },
  props: ['value'],
  data () {
    return {
      reports: [],
      loading: false
    }
  },
  methods: {
    createReportsTree (data) {
      data = data.map((item) => {
        item.report_type = this.$locale.report_editor.types[item.report_type]
        item.leaf = !item.has_children
        return item
      })
      let hashTable = Object.create(null)
      data.forEach((item) => {
        hashTable[item.id] = { ...item, children: [] }
      })

      let dataTree = []
      data.forEach(record => {
        if (record.parent_id) {
          hashTable[record.parent_id].children.push(hashTable[record.id])
        } else {
          dataTree.push(hashTable[record.id])
        }
      })
      return dataTree
    }
  },
  async mounted () {
    this.loading = true

    let reports = await Report.params({ 'order': 'report_type:asc,row_order:asc,name:desc' }).get()
    this.reports = this.createReportsTree(reports)

    this.loading = false
  }
}
</script>

<style scoped>

</style>
