


























































































import Vue from 'vue'
import Node from 'element-ui/packages/tree/src/model/node'
import SourcesQuery from '@/services/NotificationEditor/application/query/SourcesQuery'
import SourceDTO from "@/services/NotificationEditor/domain/model/SourceDTO";

export default Vue.extend({
  name: 'TreeElementLayout',

  props: {
    createElement: {
      type: Function,
      default: function (elementType: string, parentNode: Node) {
      }
    },

    updateElement: {
      type: Function,
      default: function (node: Node, data: SourceDTO) {
      }
    },

    deleteElement: {
      type: Function,
      default: function (node: Node, data: SourceDTO) {
      }
    },

    close: {
      type: Function,
      default: function () {
      }
    },

    externalNotificationGuid: {
      type: String,
      default: null
    }
  },

  inject: ['getQueryBus'],

  computed: {
    treeProp () {
      return {
        ref: 'tree', // Vue ID компонента
        nodeKey: 'guid', // Основной уникальный ключ узла
        key: this.isSearch ? 'searchTree' : 'defaultTree', // Меняем ключ, что бы перерендерить компонент с новыми свойствами
        lazy: true,
        draggable: !this.isSearch, // Блокируем drag & drop если сейчас активен поиск
        props: this.treeProps,
        expandOnClickNode: false,
        load: this.loadNode,
        allowDrop: this.allowDrop,
        allowDrag: this.allowDrag
      }
    },

    isSearchText () {
      return !!this.searchText
    },

    isSearchLoading () {
      if (this.isSearch) {
        return this.$store.getters['NotificationTreeElement/isSourceLoading'] || false
      }

      return false
    }
  },

  watch: {
    searchText () {
      if (!this.isSearchText) {
        this.isSearch = false
      }
    }
  },

  data () {
    return {
      searchText: null,
      isSearch: false,

      selectedNode: null,

      isEdit: false,

      treeProps: {
        label: 'name',
        isLeaf: 'is_leaf',
        children: 'children'
      },

      icons: {
        group: 'el-icon-folder',
        notification: 'el-icon-message'
      },

      elementTypes: [
        'group', 'notification'
      ]
    }
  },

  methods: {
    allowDrop (draggingNode, dropNode, type) {
      if (dropNode.level === 0) {
        return false
      }

      if (type === 'inner') {
        // inner drop
        if (draggingNode.data.guid === dropNode.data.guid || dropNode.data.type !== 'group') {
          return false
        }

        if (this.preventParentNodeFromInheritingChildNode(dropNode).indexOf(draggingNode.data.guid) !== -1) {
          return false
        }

        if (dropNode.data.type === 'group') {
          return draggingNode.data.parent_guid !== dropNode.data.guid
        }
      } else {
        // before, after drop
        if (typeof draggingNode.data.type === 'undefined') {
          return false
        }
      }

      return true
    },

    allowDrag (draggingNode) {
      return typeof draggingNode.data.type !== 'undefined'
    },

    // Супер мега функция... Запрещает родительскому узлу перемещаться в дочерний узел, на любой уровень вложенности
    preventParentNodeFromInheritingChildNode (node) {
      let nodeKeys = []
      if (node !== null && typeof node.data !== 'undefined') {
        nodeKeys.push(node.data.parent_guid)
        nodeKeys.push(...this.preventParentNodeFromInheritingChildNode(node.parent))
      }
      return nodeKeys
    },

    async nodeDrop (draggingNode, dropNode, dropType, ev) {
      let model = {}

      if (dropType !== 'inner') {
        model = {
          target_id: draggingNode.data.parent_guid === dropNode.data.parent_guid ? draggingNode.data.parent_guid : dropNode.data.parent_guid,
          row_order: this.mediumRowOrder(draggingNode, dropNode)
        }
      } else {
        model = {
          target_id: dropNode ? dropNode.data.id : draggingNode.data.parent_guid,
          row_order: draggingNode.data.row_order
        }
      }

      await this.$http.post(`${this.$config.api}/notificationeditor/sources/${draggingNode.data.id}/draggable`, model)
    },

    mediumRowOrder (draggingNode, node) {
      // children into parent
      const a = node.parent.childNodes
      let rowOrder
      for (let i = 0; i < a.length; i++) {
        if (draggingNode.data.id === a[i].data.id) {
          // console.log('совпадение', a[i].data.name, a[i].data.id)
          if (!a[i - 1]) {
            // console.log('сосед сверху не найден')
            rowOrder = Math.round((a[i + 1].data.row_order - 330))
          }
          if (!a[i + 1]) {
            // console.log('сосед снизу не найден')
            rowOrder = Math.round((100 + a[i - 1].data.row_order))
          }
          if (a[i + 1] && a[i - 1]) {
            rowOrder = Math.round((a[i + 1].data.row_order + a[i - 1].data.row_order) / 2)
          }
        }
      }

      return rowOrder
    },

    getLabel (node: Node, data: SourceDTO) {
      const icon = `<span class="node-label__icon ${this.getTreeIcon(node)}"></span>`
      const label = `<span class="node-label__name">${node.label}</span>`
      const identifier = `<span class="node-label__info">(id: ${data.id})</span>`

      if (data.type === 'group') {
        return icon + label
      } else {
        return icon + label + identifier
      }
    },

    getTreeIcon (node: Node) {
      if (node.data.type) {
        if (node.data.type === 'group') {
          return !node.expanded
            ? 'el-icon-folder'
            : 'el-icon-folder-opened'
        } else {
          if (this.icons[node.data.type]) {
            return [this.icons[node.data.type]]
          }
        }
      } else {
        return !node.expanded
          ? 'el-icon-folder'
          : 'el-icon-folder-opened'
      }

      return null
    },

    getTree () {
      return this.$refs.tree
    },

    closeEditor () {
      this.isEdit = false
      this.selectedNode = null
      this.close()
    },

    selected (node: Node) {
      if (this.selectedNode === node) {
        this.closeEditor()
        return
      }

      this.closeEditor()
      this.edit(node, node.data)
    },

    addNode (data: SourceDTO, parentNode: Node = null) {
      if (parentNode) {
        this.$refs.tree.append(data, parentNode)
      } else {
        let node = new Node({
          parent: this.$refs.tree.root,
          store: this.$refs.tree.store,
          data: data
        })

        node.level = 1

        this.$refs.tree.root.childNodes.push(node)
      }
    },

    async getNodeByElementGuid (elementGuid: string): Node {
      // let treeElement = await this.getQueryBus().execute(
      //   new SourceByGuidQuery(elementGuid)
      // )

      return this.$refs.tree.getNode(elementGuid)
    },

    updateNode (node: Node): void {
      this.selectedNode = node
    },

    add (elementType: string = null, parentNode: Node = null) {
      this.closeEditor()

      this.$nextTick(() => {
        this.selectedNode = parentNode
        this.createElement(elementType, parentNode)
      })
    },

    edit (node: Node, data: SourceDTO) {
      if (this.isEdit) {
        return
      }

      this.selectedNode = node
      this.isEdit = true

      this.updateElement(node, data)
    },

    remove (node: Node, data: SourceDTO) {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteElement(node, data)
        this.$refs.tree.remove(node)
        this.closeEditor()
      })
    },

    inputSearch (value: string) {
      if (value.length >= 1) {
        this.isSearch = true
      }
    },

    async loadNode (node: Node, resolve: Function): Promise<void> {
      if (node.level === 0) {
        if (this.externalNotificationGuid) {
          let element = await this.getQueryBus().execute(new SourcesQuery({ guid: this.externalNotificationGuid }))
          resolve(element)
          let externalNode = await this.$refs.tree.getNode(this.externalNotificationGuid)
          this.selected(externalNode)
        } else {
          resolve(await this.loadElements())
        }
      } else {
        resolve(await this.loadElements(node.data.guid))
      }
    },

    async loadElements (parentGuid: string = null): Promise<SourceDTO[]> {
      let elements = []

      if (!parentGuid) {
        if (!this.isSearch) {
          elements = await this.getQueryBus().execute(new SourcesQuery({is_null: 'parent_guid', order: 'name:asc' }))
        } else {
          const expressions = []
          const elementId = parseInt(this.searchText)

          if (!isNaN(elementId)) {
            // Либо ищем по ID сущности
            expressions.push({
              eq: {
                id: elementId
              }
            })
          } else {
            // Ищем по имени реестра, либо по имени сущности
            expressions.push({
              like: {
                name: `%${this.searchText}%`
              }
            })
          }

          elements = await this.getQueryBus().execute(new SourcesQuery({
            order: 'name:asc',
            where: {
              or: expressions
            }
          }))
        }
      } else {
        elements = await this.getQueryBus().execute(new SourcesQuery({ parent_guid: parentGuid }))
      }

      return elements
    }
  }
})
