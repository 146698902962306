








import Vue from 'vue'
import RasterPanel from '@/services/RasterLibrary/infrastructure/components/RasterPanel/index.vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import RastersHandler from '@/services/RasterLibrary/application/handler/query/RastersHandler'
import RastersCountHandler from '@/services/RasterLibrary/application/handler/query/RastersCountHandler'
import RasterByGuidHandler from '@/services/RasterLibrary/application/handler/query/RasterByGuidHandler'
import RasterQueryRepository from '@/services/RasterLibrary/infrastructure/domain/repository/RasterQueryRepository'
import RasterCreateHandler from '@/services/RasterLibrary/application/handler/command/RasterCreateHandler'
import RasterUpdateHandler from '@/services/RasterLibrary/application/handler/command/RasterUpdateHandler'
import RasterDeleteHandler from '@/services/RasterLibrary/application/handler/command/RasterDeleteHandler'
import RasterCommandRepository from '@/services/RasterLibrary/infrastructure/domain/repository/RasterCommandRepository'

export default {
  name: 'RasterLibrary',
  components: {
    RasterPanel
  },
  data() {
    return {
      eventBus: new Vue(),
      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'RasterCreateCommand': new RasterCreateHandler(
            new RasterCommandRepository()
          ),
          'RasterUpdateCommand': new RasterUpdateHandler(
            new RasterCommandRepository()
          ),
          'RasterDeleteCommand': new RasterDeleteHandler(
            new RasterCommandRepository()
          )
        })
      ),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'RastersQuery': new RastersHandler(
            new RasterQueryRepository()
          ),
          'RastersCountQuery': new RastersCountHandler(
            new RasterQueryRepository()
          ),
          'RasterByGuidQuery': new RasterByGuidHandler(
            new RasterQueryRepository()
          )
        })
      )
    }
  },
  provide() {
    return {
      getEventBus: this.getEventBus,
      getCommandBus: this.getCommandBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus() {
      return this.eventBus;
    },
    getCommandBus() {
      return this.commandBus;
    },
    getQueryBus() {
      return this.queryBus;
    }
  },
  mounted() {
    
  }
}
