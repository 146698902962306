var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-collapse',{staticClass:"editor-wrapper",attrs:{"value":"editor-input-label"}},[_c('el-collapse-item',{attrs:{"title":_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.calendar_source'),"name":"editor-tasks-source"}},[_c('el-form',[_c('el-collapse-item',{attrs:{"title":_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.source'),"name":"editor-source"}},[_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.table.source_type')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.entityType,"options":{
            multiple: false,
             options: _vm.dataSourceTypes
          }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource, 'entityType', $event)}}})],1),(_vm.value.editorProperties.taskSource.entityType === 'registry')?_c('el-form-item',[_c('registry-select',{attrs:{"append-to-body":true,"label":_vm.$t('interface_editor.component_editor.table.registry'),"value":_vm.value.editorProperties.taskSource.entityId},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource, 'entityId', $event)}}})],1):_vm._e(),(_vm.value.editorProperties.taskSource.entityType === 'query')?_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.table.request')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.entityId,"options":{
              multiple: false,
              options: _vm.entityTypeData
            }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'entityId', $event)}}})],1):_vm._e()],1),_c('el-collapse-item',{attrs:{"title":_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.comparison'),"name":"comparison_task"}},[_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.key_field')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.keyField,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'keyField', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.parent_field')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.parentIdField,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'parentIdField', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.name')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.nameField,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'nameField', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.percent_done')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.percentDoneField,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'percentDoneField', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.duration')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.duration,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'duration', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.effort')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.effort,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'effort', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.constraint_type')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.constraintType,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'constraintType', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.constraint_date')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.constraintDate,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'constraintDate', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.manually_scheduled')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.isManuallyScheduledField,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'isManuallyScheduledField', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.inactive')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.inactive,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'inactive', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.index_number')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.indexNumber,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'indexNumber', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.hierarchy_number')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.hierarchyNumber,"options":{
          multiple: false,
          options: _vm.attrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'hierarchyNumber', $event)}}})],1)],1),_c('el-collapse-item',{attrs:{"title":_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.dates'),"name":"dates"}},[_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.plan_start_date')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.planStartDate,"options":{
              multiple: false,
              options: _vm.attrs
            }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource, 'planStartDate', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.plan_end_date')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.planEndDate,"options":{
              multiple: false,
              options: _vm.attrs
            }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource, 'planEndDate', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.fact_start_date')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.factStartDate,"options":{
              multiple: false,
              options: _vm.attrs
            }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource, 'factStartDate', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.fact_end_date')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.taskSource.factEndDate,"options":{
              multiple: false,
              options: _vm.attrs
            }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource, 'factEndDate', $event)}}})],1)],1),_c('el-collapse-item',{attrs:{"title":_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.filters'),"name":"editor_filters"}},[_c('editor-filters',{attrs:{"value":_vm.value.editorProperties.taskSource.filters},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.taskSource ,'filters', $event)}}})],1)],1)],1),_c('el-collapse-item',{attrs:{"title":_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.table_source'),"name":"editor-table-source"}},[_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.table.column')))]),_c('editor-registry-headers',{attrs:{"is-gantt":true,"value":_vm.value.editorProperties.tableSource.columns,"otherProperties":{list: {}},"options":{ objectId: _vm.value.editorProperties.taskSource.entityId }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.tableSource, 'columns', $event)}}})],1)],1),_c('el-collapse-item',{attrs:{"title":_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.tasks_relation'),"name":"editor_relations"}},[_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.table.source_type')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.tasksRelation.entityType,"options":{
            multiple: false,
             options: _vm.dataSourceTypes
          }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.tasksRelation, 'entityType', $event)}}})],1),(_vm.value.editorProperties.tasksRelation.entityType === 'registry')?_c('el-form-item',[_c('registry-select',{attrs:{"append-to-body":true,"label":_vm.$t('interface_editor.component_editor.table.registry'),"value":_vm.value.editorProperties.tasksRelation.entityId},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.tasksRelation, 'entityId', $event)}}})],1):_vm._e(),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.key_field')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.tasksRelation.keyField,"options":{
          multiple: false,
          options: _vm.relationAttrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.tasksRelation ,'keyField', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.parent_field')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.tasksRelation.fromAttr,"options":{
          multiple: false,
          options: _vm.relationAttrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.tasksRelation ,'fromAttr', $event)}}})],1),_c('el-form-item',[_c('span',{staticClass:"property_label"},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.child')))]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.tasksRelation.toAttr,"options":{
          multiple: false,
          options: _vm.relationAttrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.tasksRelation ,'toAttr', $event)}}})],1),_c('el-form-item',[_c('span',[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.relation_type')))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.tooltips.relationType}},[_c('i',{staticClass:"el-icon-question"})]),_c('editor-select',{attrs:{"value":_vm.value.editorProperties.tasksRelation.type,"options":{
          multiple: false,
          options: _vm.relationAttrs
        }},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties.tasksRelation ,'type', $event)}}})],1)],1),_c('el-collapse-item',{attrs:{"title":_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.base_settings'),"name":"editor_gantt_settings"}},[_c('el-form-item',[_c('el-checkbox',{model:{value:(_vm.value.editorProperties.isDiagramEditing),callback:function ($$v) {_vm.$set(_vm.value.editorProperties, "isDiagramEditing", $$v)},expression:"value.editorProperties.isDiagramEditing"}}),_c('span',{staticClass:"property_label",staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.$t('interface_editor.component_editor.editor_gantt_bryntum.is_diagram_editing')))])],1),_c('el-form-item',[_c('editor-filters',{attrs:{"value":_vm.value.editorProperties.defaultValuesForTask,"title":_vm.$t('main.fields.default'),"show-xref-option":false},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties ,'defaultValuesForTask', $event)}}})],1),_c('el-form-item',[_c('editor-button-action',{attrs:{"value":_vm.value.editorProperties.chartItemAction,"label":_vm.$t('interface_editor.component_editor.editor_gantt_new.chart_item_action'),"source-id":_vm.value.editorProperties.chartItemAction.card.registryId,"source-type":"registry","is-headers":true},on:{"change":function($event){return _vm.$set(_vm.value.editorProperties, 'chartItemAction', $event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }