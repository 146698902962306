

































import Vue from 'vue'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'

export default Vue.extend({
  name: 'FilterFieldForm',

  props: {
    value: Object,
    relatedObjectId: Number,
    currentObjectId: Number
  },

  components: {
    RegistrySelectTree
  },

  computed: {
    rules () {
      return {
        related_object_field_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.related_object_field'), trigger: 'change' }
        ],
        current_object_field_id: [
          { required: true, message: this.$t('logic_editor_v2.rules.current_object_field'), trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value,

      isLoaded: false
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isLoaded = true
    })
  },

  methods: {
    validate () {
      let isValid = false

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        }

        isValid = true
      })

      return isValid
    }
  }
})
