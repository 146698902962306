



































import Vue from 'vue'
import { LicenseManager, VirtualList, SetFilter, GetContextMenuItemsParams, MenuItemDef, ProcessRowGroupForExportParams, ProcessCellForExportParams } from 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AgGridVue } from 'ag-grid-vue'
import ColumnTypes from './ColumnTypes'
import RuLocale from './locale/ru'
import ResultColumnsBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/ResultColumnsBuilder'
import CountNumberRecords from '@/core/infrastructure/components/Grid/infrastructure/components/StatusBar/CountNumberRecords'
import RowClassRulesBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/RowClassRules'
import PercentageFieldHeader from '@/core/infrastructure/components/Grid/infrastructure/components/Headers/PercentageFieldHeader'

import XrefFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/XrefFilter'
import DisabledFitler from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/disabledFitler'
import DateFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/dateFilter'
import DateTimeFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/dateTimeFilter'
import TimeFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/timeFilter'
import BooleanFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/booleanFilter'
import BooleanFloatingFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/booleanFloatingFilter'
import StringSetFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/StringSetFilter'
import AddressFilter from '@/core/infrastructure/components/Grid/infrastructure/components/Filters/AddressFilter'

import stringField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/stringField'
import booleanField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/booleanField'
import xrefField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/xrefField'
import htmlField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/htmlField'
import dateField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/dateField.js'
import xrefMultiField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/xrefMultiField.js'
import timeField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/timeField.js'
import textField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/textField.js'
import integerField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/integerField.js'
import floatField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/floatField.js'
import datetimeField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/datetimeField.js'
import addressField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/addressField.js'
import fileField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/fileField.js'
import ExportValueFormatter from '@/core/infrastructure/components/Grid/infrastructure/service/ExportValueFormatter'
import PercentageSparklineField from '@/core/infrastructure/components/Grid/infrastructure/components/CellRenderer/percentageSparklineField.js'

export default Vue.extend({
  name: 'Ag-grid',

  components: {
    AgGridVue,
    PercentageFieldHeader,
    addressFilter: AddressFilter,
    xrefFilter: XrefFilter,
    booleanFilter: BooleanFilter,
    booleanFloatingFilter: BooleanFloatingFilter,
    dateTimeFilter: DateTimeFilter,
    timeFilter: TimeFilter,
    agDateInput: DateFilter,
    stringSetFilter: StringSetFilter,
    stringField: stringField,
    booleanField: booleanField,
    xrefField: xrefField,
    htmlField: htmlField,
    dateField: dateField,
    xrefMultiField: xrefMultiField,
    timeField: timeField,
    textField: textField,
    integerField: integerField,
    floatField: floatField,
    datetimeField: datetimeField,
    addressField: addressField,
    fileField: fileField,
    disabledFitler: DisabledFitler,
    statusBarComponent: CountNumberRecords,
    percentageSparklineField: PercentageSparklineField
  },

  inject: {
    getEventBus: {
      default: () => false
    },
    getQueryBus: {
      default: () => {}
    },
    getModel: {
      default: () => {}
    }
  },

  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    dataSourceService: {
      type: Object,
      default: () => { return {} }
    },
    pagination: {
      type: Boolean
    },
    sideBar: {
      type: Boolean,
      default: false
    },
    closeToolPanel: {
      type: Boolean
    },
    floatingFilter: {
      type: Boolean
    },
    rowDoubleClicked: {
      type: Boolean
    },
    groupUseEntireRow: {
      type: Boolean
    },
    pageSize: {
      type: Number
    },
    cacheBlockSize: {
      type: Number
    },
    multiSelection: {
      type: Boolean,
      default: false
    },
    checkBoxSelection: {
      type: Boolean,
      default: false
    },
    isPivotMode: {
      type: Boolean
    },
    showCount: {
      type: Boolean,
      default: false
    },
    disabledColumnHeader: {
      type: Boolean
    },
    hideHeader: {
      type: Boolean
    },
    wrapHeader: {
      type: Boolean
    },
    filterModel: {
      // type: Object,
      // default: () => { return {} }
    },
    readonly: {
      type: Boolean
    },
    theme: {
      type: String
    },
    CSS: {
      type: String
    },
    CSSClasses: {
      type: String
    },
    rowClassRulesProps: {
      type: Array
    },
    contextMenu: {
      type: Array,
      default () {
        return [
          'copy',
          'copyWithHeaders',
          'paste',
          'separator',
          'chartRange',
          'export'
        ]
      }
    }
  },

  created () {
    // после обновления до версии 28 предупреждение в консоли
    // Если таблица содерижт одинаковые id (такое бывает О-о) - то записи начинают прыгать
    // https://www.ag-grid.com/vue-data-grid/server-side-model-selection/#providing-row-ids
    // if (['extended_object', 'query'].includes(this.dataSourceService.type)) {
    //   return null
    // }

    // this.getRowId = (params) => {
    //   // if leaf level, we have ID
    //   if (params.data.id != null) {
    //     return params.data.id
    //   }
    //   // this array will contain items that will compose the unique key
    //   var parts = []
    //   // if parent groups, add the value for the parent group
    //   if (params.parentKeys) {
    //     parts.push(...params.parentKeys)
    //   }
    //   // it we are a group, add the value for this level's group
    //   var rowGroupCols = params.columnApi.getRowGroupColumns()
    //   var thisGroupCol = rowGroupCols[params.level]
    //   if (thisGroupCol) {
    //     parts.push(params.data[thisGroupCol.getColDef().field])
    //   }
    //   return parts.join('-')
    // }
  },

  beforeMount () {
    this.context = { componentParent: this }
    LicenseManager.setLicenseKey(this.$config.ag_grid_license)
  },

  data () {
    return {
      selectedRows: null,
      getRowId: null,
      defaultExcelExportParams: {
        processCellCallback: ExportValueFormatter.getProcessCellCallback()
      },
      defaultCsvExportParams: {
        processCellCallback: ExportValueFormatter.getProcessCellCallback()
      },
      gridOptions: {
        rowModelType: Object.keys(this.dataSourceService).length ? 'serverSide' : 'clientSide',
        pagination: this.pagination,
        paginationPageSize: this.pageSize,
        rowSelection: this.multiSelection ? 'multiple' : 'single',
        columnTypes: ColumnTypes,
        enableRangeSelection: true,
        enableCharts: true,
        // pivotMode: true,
        suppressPropertyNamesCheck: true,
        sideBar: {
          toolPanels: ['columns', 'filters'],
          hiddenByDefault: this.sideBar,
          defaultToolPanel: this.closeToolPanel ? '' : 'columns'
        },
        localeText: RuLocale,
        context: null,
        defaultColDef: {
          sortable: !this.disabledColumnHeader,
          enableValue: true,
          // flex: 1,
          resizable: !this.disabledColumnHeader,
          // wrapText: true,
          // cellClass: 'cell-wrap-text',
          autoHeight: false,
          floatingFilter: this.floatingFilter,
          filterParams: {
            buttons: ['apply', 'reset'],
            filters: [
              {
                floatingFilterComponent: 'filter'
              }
            ]
          },
          menuTabs: this.disabledColumnHeader ? [] : ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
          columnsMenuParams: {
            suppressColumnFilter: this.disabledColumnHeader
            // suppressColumnSelectAll: true,
            // suppressColumnExpandAll: true,
            // suppressSyncLayoutWithGrid: true
          }
        },
        rowClass: 'ag_grid_row',
        getContextMenuItems: (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
          return this.contextMenu as (string | MenuItemDef)[]
        }
      },
      excelStyles: [
        {
          id: 'fontFamily',
          font: { color: '#000000', size: 12, fontName: 'Times New Roman' }
        },
        {
          id: 'header',
          font: { color: '#000000', size: 12, fontName: 'Times New Roman' }
        }
      ],
      gridApi: undefined,
      columnApi: undefined,
      rowClassRules: null,
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'statusBarComponent',
            key: 'statusBarCompKey'
          },
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              // only show count and sum ('min', 'max', 'avg' won't be shown)
              aggFuncs: ['count', 'sum']
            }
          }
        ]
      }
    }
  },

  computed: {
    resultColumns () {
      // console.log('%c%s', 'color: red;', 'grid resultColumns', this.columns)
      const cols = ResultColumnsBuilder.build(this.columns, this)
      if (cols[0]) {
        // Чекбокс в первый столбец таблицы
        // console.log('%c%s', 'color: red;', this.gridOptions.rowModelType)
        if (this.gridOptions.rowModelType === 'clientSide') {
          // headerCheckboxSelection is not supported for Server Side Row Model
          cols[0].headerCheckboxSelection = true
        }
        cols[0].headerCheckboxSelectionFilteredOnly = true
        cols[0].checkboxSelection = this.checkBoxSelection
      }

      // console.log('%c%s', 'color: red;', 'cols', cols)

      return cols
    },
    themeAgGrid () {
      if (this.theme) {
        return this.theme
      }
      return 'ag-theme-alpine'
    }
  },

  methods: {
    headerHeightGetter () {
      let columnHeaderTexts = [
        ...document.querySelectorAll('.wrap-header .ag-header-cell-text')
      ]
      let clientHeights = columnHeaderTexts.map(
        headerText => headerText.clientHeight
      )
      return Math.max(...clientHeights) // tallestHeaderTextHeight
    },

    headerHeightSetter () {
      if (!this.wrapHeader) return
      let padding = 20
      let height = this.headerHeightGetter() + padding
      this.gridApi.setHeaderHeight(height)
      this.gridApi.resetRowHeights()
    },

    editRecord (row) {
      if (this.rowDoubleClicked && !this.readonly) {
        this.$emit('edit-record', row.data)
      }
    },

    getResultColumns () {
      return this.resultColumns
    },

    load () {
      // console.log('%c%s', 'color: red;', 'grid load')
      if (this.dataSourceService && this.isPivotMode) {
        this.gridApi.setServerSideDatasource(this.dataSourceService)
      }
      this.gridApi?.refreshServerSide({ purge: true })
    },

    getFilterModel () {
      return this.gridApi.getFilterModel()
    },

    delete (row) {
      this.gridApi.deselectAll()
    },

    onSelectionChanged () {
      if (this.getEventBus) {
        this.getEventBus().$emit('selectedRows', this.gridApi.getSelectedRows())
      }
    },

    getColumnApi () {
      return this.columnApi
    },

    onGridReady (params) {
      // console.log('%c%s', 'color: red;', 'grid onGridReady')
      this.gridApi = params.api
      this.columnApi = params.columnApi
      if (!this.pagination) {
        let statusBarComponent = this.gridApi.getStatusPanel('statusBarCompKey')
        statusBarComponent.setVisible(!statusBarComponent.isVisible())
      }
      this.$emit('grid-ready')
      this.afterRender()
      this.$nextTick(() => {
        this.setVisibleColumns()
      })
      if (this.rowClassRulesProps?.length) {
        this.setColorRows()
      }
    },
    setColorRows () {
      let modelCard = this.getModel()
      this.rowClassRules = RowClassRulesBuilder.build(this.rowClassRulesProps, modelCard)
    },
    setVisibleColumns () {
      this.resultColumns.forEach(item => {
        if (item.hide) {
          // console.warn('%c%s', 'color: red;', item)
          this.columnApi.setColumnVisible(item.field, false)
        }
      })
    },

    afterRender () {
      // console.log('%c%s', 'color: red;', 'grid afterRender PIVOT', this.isPivotMod)
      if (this.dataSourceService && !this.isPivotMode) {
        this.gridApi.setServerSideDatasource(this.dataSourceService)
      }
      // this.columnApi.setPivotMode(this.isPivotMode)
    }
  }
})
