<template>
  <div>
    <el-form-item label="Реестр">
      <registry-select-tree
        v-model="value.entityId"
        type="registry"
        value-as="number">
      </registry-select-tree>
    </el-form-item>
    <el-form-item label="Геометрия">
      <select-tree
        :clearable="true"
        ref="entity_tree"
        :dataTable="fields"
        class="custom_scrollbar"
        :appendToBody='false'
        v-model="value.geometryField"/>
    </el-form-item>
    <el-form-item label="Метки значений">
      <select-tree
        :clearable="true"
        ref="entity_tree"
        :dataTable="fields"
        class="custom_scrollbar"
        :appendToBody='false'
        v-model="value.marksField"/>
    </el-form-item>
    <el-form-item label="Система координат">
      <coordinate-system
        :value="value.nativeCoordinateSystemId"
        @change-cs="value.nativeCoordinateSystemId = $event"
      ></coordinate-system>
    </el-form-item>
    <el-form-item label="Способ обработки">
      <el-select
        v-model="value.srsHandlingType">
        <el-option
          v-for="item in $locale.interface_editor.component_editor.map_layers.srs_handling_types"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import Registry from '@/components/Models/EntityModel'
import SelectTree from '@/core/infrastructure/components/TreeSelect'
import RegistrySelectTree from '@/components/Common/RegistrySelectTree'
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'
export default {
  components: {
    SelectTree,
    RegistrySelectTree,
    CoordinateSystem
  },
  name: 'Registry',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          entityId: null,
          geometryField: null,
          marksField: null
        }
      }
    }
  },
  data () {
    return {
      fields: []
    }
  },
  mounted () {
    this.loadFields(this.value.entityId)
  },
  watch: {
    'value.entityId': {
      handler (value) {
        this.loadFields(value)
        this.value.geometryField = null
        this.value.marksField = null
      }
    }
  },
  methods: {
    async loadFields (registryId) {
      if (!registryId) {
        return false
      }
      this.fields = await Registry.params({
        parent_id: registryId,
        show_children: true,
        properties: { is_visible_in_grid: true } }).$get()
    }
  }
}
</script>

<style scoped>

</style>
