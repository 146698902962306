var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard_admin"},[_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_data"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){return _vm.addMainTab({
                name: 'Структура данных',
                componentType: 'ObjectEditor',
              })}}},[_vm._v(" Структура данных ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Управление структурой реестров и справочников, настройка взаимосвязи данных, объединение реестров в тематические группы и конструирование пользовательских карточек ввода. ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_interface"}),_c('div',[_c('div',{staticClass:"menu_header non_underline"},[_vm._v("Интерфейс пользователя")]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Главное меню',
                    componentType: 'MenuEditor',
                  })}}},[_vm._v("Главное меню")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Описание разделов и подразделов бокового меню для различных пользовательских ролей. ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){_vm.addMainTab({
                    name: 'Рабочие экраны (дашборды)',
                    componentType: 'DashboardEditor',
                  })}}},[_vm._v("Рабочие экраны (дашборды)")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Визуальный конструктор экранных форм ")])])])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_acess"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){return _vm.addMainTab({
                name: 'Права доступа',
                componentType: 'AccessEditor_v2',
              })}}},[_vm._v(" Права доступа ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Управление учетными записями и настройка типовых пользовательских ролей. ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_engineering"}),_c('div',[_c('div',{staticClass:"menu_header non_underline"},[_vm._v("Настройка процессов")]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Бизнес-логика',
                    componentType: 'LogicEditor_v2',
                  })}}},[_vm._v("Бизнес-логика")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Формульные поля, логические состояния, маршруты согласований, пользовательские команды, условия показа и блокировки полей и записей ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Правила создания и закрытия задач',
                    componentType: 'TaskEditor',
                  })}}},[_vm._v("Правила создания и закрытия задач")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Описание условий для назначения и закрытия автоматически создаваемых задач. ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Настройка уведомлений',
                    componentType: 'NotificationEditor',
                  })}}},[_vm._v("Настройка уведомлений")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Правила автоматического уведомления различных групп пользователей о наступлении контрольных событий. ")])])])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_source"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){return _vm.addMainTab({
                name: 'Источники данных',
                componentType: 'BIEditor',
              })}}},[_vm._v(" Источники данных ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Настройка произвольных источников данных для использования в отчетных формах, пользовательских дашбордах и внешних выгрузках. ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_document"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){return _vm.addMainTab({
                name: 'Отчеты и шаблоны документов',
                componentType: 'ReportEditor',
              })}}},[_vm._v(" Отчеты и шаблоны документов ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Проектирование отчетных форм, шаблонов автоматически генерируемых бланков и xml-выгрузок. ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_map"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){return _vm.addMainTab({ name: 'Картография', componentType: 'MapEditor' })}}},[_vm._v(" Картография ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Редактор картографических сервисов, настройка произвольного набора слоев, работа с растрами, стилизация карт, подключение внешних картографических источников. ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_integration"}),_c('div',[_c('div',{staticClass:"menu_header non_underline"},[_vm._v("Интеграции")]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Правила импорта-экспорта',
                    componentType: 'EtlEditor',
                  })}}},[_vm._v("Правила импорта-экспорта")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Сопоставление источников для операций выгрузки и загрузки данных. ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Федеральная адресная система',
                    componentType: 'AddressService',
                  })}}},[_vm._v("Федеральная адресная система")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Настройки правил интеграции с федеральными адресными классификаторами. ")])]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Шина данных',
                    componentType: 'Databus',
                  })}}},[_vm._v("Шина данных")]),_c('div',{staticClass:"menu_text"})])])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_sync"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){return _vm.addMainTab({
                name: 'Синхронизация окружений',
                componentType: 'SyncService',
              })}}},[_vm._v(" Синхронизация окружений ")]),_c('div',{staticClass:"menu_text"},[_vm._v(" Перенос настроек между экземплярами системы ")])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_logs"}),_c('div',[_c('div',{staticClass:"menu_header",on:{"click":function($event){return _vm.addMainTab({
                name: 'История пользовательских действий',
                componentType: 'ActivityService',
              })}}},[_vm._v(" История пользовательских действий ")]),_c('div',{staticClass:"menu_text"},[_vm._v("Журнал клиентских запросов")])])]),_vm._m(1),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_monitoring"}),_c('div',[_c('div',{staticClass:"menu_header non_underline"},[_vm._v(" Мониторинг инфраструктуры ")]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Веб-сервер Nginx',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/grafana/d/A4_qnTv7z/web?orgId=1&refresh=10s"),
                    },
                  })}}},[_vm._v("Веб-сервер Nginx")]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Виртуальные машины',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/grafana/d/e0ESDrZ7z/hardware?orgId=1&refresh=5s"),
                    },
                  })}}},[_vm._v("Виртуальные машины")]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'База данных Postgresql',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/grafana/d/-QLZ8gB7z/postgresql?orgId=1&refresh=5s"),
                    },
                  })}}},[_vm._v("База данных Postgresql")]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Брокер сообщений Kafka',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/grafana/d/HD8pcdM7k/kafka?orgId=1&refresh=1m"),
                    },
                  })}}},[_vm._v("Брокер сообщений Kafka")]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'База данных Redis',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/grafana/d/DiEBNrW7z/redis?orgId=1&refresh=30s"),
                    },
                  })}}},[_vm._v("База данных Redis")]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Планировщик Airflow',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/grafana/d/s4WiSDMnk/airflow-cluster?orgId=1&refresh=5s"),
                    },
                  })}}},[_vm._v("Планировщик Airflow")]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Airflow DAGs',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/grafana/d/XCPzIDM7z/airflow-dag?orgId=1&refresh=5s"),
                    },
                  })}}},[_vm._v("Airflow DAGs")]),_c('div',{staticClass:"menu_text"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$config.project === 'GjiMurmansk'),expression:"$config.project === 'GjiMurmansk'"}],staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Доступность системы',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/grafana/d/Zjv30F04z/hardware-copy?orgId=1"),
                    },
                  })}}},[_vm._v("Доступность системы")]),_c('div',{staticClass:"menu_text"})])])])]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_api"}),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"menu_header non_underline"},[_vm._v(" Техническая документация ")]),_c('div',{staticClass:"menu_text_columns"},[_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'REST API',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/api/index.html"),
                    },
                  })}}},[_vm._v("Rest API")]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                    name: 'Плагины',
                    componentType: 'IFrame',
                    payload: {
                      url: ((_vm.$config.api) + "/plugins/index.xhtml"),
                    },
                  })}}},[_vm._v("Плагины")]),_c('div',{staticClass:"menu_text"})]),_c('div',{staticClass:"menu_column"},[_c('span',{staticClass:"column_header",on:{"click":function($event){return _vm.addMainTab({
                  name: 'Сервисы',
                  componentType: 'ChangeLog',
                })}}},[_vm._v("Сервисы")]),_c('div',{staticClass:"menu_text"})])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"name_header"},[_vm._v("Настройка системы")]),_c('div',{staticClass:"logo_header"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('div',{staticClass:"icon icon_settings"}),_c('div',[_c('div',{staticClass:"menu_header"},[_vm._v("Системные настройки")]),_c('div',{staticClass:"menu_text"},[_vm._v("Раздел в разработке")])])])}]

export { render, staticRenderFns }