<template>
  <div style="height: 100%">
    <el-form v-show="!showCard" ref="validateForm" v-loading="loading" style="height: 100%">
      <InterfaceViewer
        v-if="version === 1"
        ref="interface_viewer"
        :model="interfaceModel"
        :dashboard-id="id"
      ></InterfaceViewer>
      <InterfaceViewerV2
        v-else-if="version === 2"
        :model="interfaceModel"
        type="dashboard"
        ref="interface_viewer_v2"
        :dashboard-id="id"
        ></InterfaceViewerV2>
    </el-form>
  </div>
</template>

<script>
import InterfaceViewerV2 from '@/services/InterfaceViewer/index.vue'
import InterfaceViewer from '@/components/InterfaceViewer'
import Dashboard from '@/components/DashboardEditor/Models/Dashboard'

export default {
  components: {
    InterfaceViewer,
    InterfaceViewerV2
  },
  props: ['id', 'model', 'main'],
  name: 'Dashboard',
  inject: {
    getParentDashboard: {
      default: () => {}
    }
  },
  data () {
    return {
      version: null,
      loading: true,
      openedCards: [],
      showCard: false,
      interfaceModel: this.model
    }
  },
  provide () {
    return {
      getParentDashboard: () => this
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      if (!this.id) {
        return false
      }
      this.loading = true
      let dashboard = await new Dashboard().find(this.id)
      const structure = JSON.parse(dashboard.structure)
      if (typeof structure.version !== 'undefined') {
        this.version = structure.version
      } else {
        this.version = 1
      }
      this.$nextTick(() => {
        this.loadInterface(structure)
      })
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    loadInterface (data) {
      if (this.version === 1) {
        this.$refs.interface_viewer.loadState(data)
      } else if (this.version === 2) {
        this.$refs.interface_viewer_v2.loadInterface(data)
      }
    }
  }
}
</script>

<style scoped>

</style>
