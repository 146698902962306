
import { Nullable } from '@/core/domain/type/types'

/**
 @param context - контекст компонента (this),
 @param registryId - id реестра,
 @param recordId - id записи
*/
export const getCardId = async (context: any, { registryId, recordId }:
  { registryId: number, recordId: Nullable<number> }): Promise<object> => {
  let url = `${context.$config.api}/registryservice/registry/${registryId}/card`
  if (recordId) {
    url = `${context.$config.api}/registryservice/registry/${registryId}/records/${recordId}/card`
  }

  return (await context.$http.get(url)).data[0]
}

/**
 проверка на число
 @param n - Любая переменная
*/
export const isNumeric = (n: any): boolean => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}
/**
 преобразовать в число, если возможно
 "123-foo" вернет 123
 @param n - Любая переменная
*/
export const looseToNumber = (val: any): any => {
  const n = parseFloat(val)
  return isNaN(n) ? val : n
}
/**
 сравнение массивов учитывая порядок
 isEqualArrays([1, 2, 3], [1, '2', 3]); // false
 @param a - Любая переменная,
 @param b - Любая переменная,
*/
export const isEqualArrays = (a: any, b: any): boolean => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false
  return a.length === b.length && a.every((v, i) => v === b[i])
}
/**
 https://stackoverflow.com/questions/27030/comparing-arrays-of-objects-in-javascript
 сравнение двух объектов
 const obj1 = { name: 'John', age: 33, info: { married: true, hobbies: ['sport', 'art'] } };
  const obj2 = { age: 33, name: 'John', info: { hobbies: ['sport', 'art'], married: true } };
  const obj3 = { name: 'John', age: 33 };
  console.log(objectsEqual(obj1, obj2)); // true
 @param o1 - Объект,
 @param o2 - Объект,
*/
export const objectsEqual = (o1: object, o2: object): boolean => {
  if (typeof o1 === 'object' && Object.keys(o1).length > 0) {
    return Object.keys(o1).length === Object.keys(o2).length &&
            Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
  } else {
    return o1 === o2
  }
}
/**
 сравнение двух массивов с объектами
 const arr1 = [obj1, obj1];
 const arr2 = [obj1, obj3];
 console.log(arraysEqualByObjects(arr1, arr2)); // false
 @param a1 - массив объектов,
 @param a2 - массив объектов,
*/
export const arraysEqualByObjects = (a1: object[], a2: object[]): boolean => {
  return a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
}
