


































































import mixins from 'vue-typed-mixins'
import StateFieldForm from './StateFieldForm.vue'
import QueryBuilder from '@/components/Common/QueryBuilder/index.vue'
import State, { StateFieldDTO } from '@/services/LogicEditor/domain/model/State'
import StateCreateCommand from '@/services/LogicEditor/application/command/StateCreateCommand'
import StateUpdateCommand from '@/services/LogicEditor/application/command/StateUpdateCommand'
import StateOperationTypesQuery from '@/services/LogicEditor/application/query/StateOperationTypesQuery'
import StateOperationTypeEntityTypesQuery from '@/services/LogicEditor/application/query/StateOperationTypeEntityTypesQuery'
import StateFieldTypesQuery from '@/services/LogicEditor/application/query/StateFieldTypesQuery'
import ConditionFieldTypesQuery from '@/services/LogicEditor/application/query/ConditionFieldTypesQuery'
import StateByGuidQuery from '@/services/LogicEditor/application/query/StateByGuidQuery'
import FormMixin from '@/services/LogicEditor/infrastructure/mixins/FormMixin'

export default mixins(FormMixin).extend({
  name: 'StateForm',

  components: {
    QueryBuilder,
    StateFieldForm
  },

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isNew () {
      return this.model.guid === null
    },

    isLoading () {
      return this.$store.getters['State/isStateLoading']
    },

    stateCreated () {
      return this.$store.getters['State/getStateLocation']
    },

    rules () {
      return {
        name: [
          { required: true, message: this.$t('logic_editor_v2.rules.name'), trigger: 'blur' }
        ]
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        this.model = value
      },
      deep: true
    },

    model: {
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },

    stateCreated (location) {
      if (location !== null) {
        this.getQueryBus().execute(
          new StateByGuidQuery(
            location.replace('/states/', '')
          )
        ).then(response => {
          this.model = response
        })
      }
    }
  },

  data () {
    return {
      model: this.value,

      mappedProp: {
        field: 'field_id',
        type: 'state_field_type_id'
      },

      stateFieldTypes: [],
      conditionFieldTypes: [],

      qbFields: [],
      qbOperationTypes: [],
      qbOperationEntityMapping: {},

      objectFields: []
    }
  },

  async mounted () {
    if (!this.stateFieldTypes.length) {
      this.stateFieldTypes = await this.getQueryBus().execute(new StateFieldTypesQuery())
    }

    if (!this.conditionFieldTypes.length) {
      this.conditionFieldTypes = await this.getQueryBus().execute(new ConditionFieldTypesQuery())
    }

    if (!this.qbOperationTypes.length) {
      this.qbOperationTypes = await this.getQueryBus().execute(new StateOperationTypesQuery())
    }

    if (!Object.keys(this.qbOperationEntityMapping).length) {
      this.qbOperationEntityMapping = await this.getQueryBus().execute(new StateOperationTypeEntityTypesQuery())
    }

    if (!this.objectFields.length) {
      await this.$http.get(`${this.$config.api}/objecteditor/entities?parent_id=${this.objectId}&show_children=true`)
        .then(response => {
          this.objectFields = response.data.data

          this.qbFields = this.buildFieldList(this.objectFields)
        })
        .catch(error => console.error(error))
    }
  },

  methods: {
    submit (callback) {
      this.$refs.form.validate((valid) => {
        if (valid && this.fieldValid()) {
          this.getCommandBus()
            .execute(
              this.getCommand()
            ).then(() => {
              callback()
            })
        } else {
          return false
        }
      })
    },

    getCommand () {
      let state = State.create(this.value)

      if (this.isNew) {
        return new StateCreateCommand(
          state.getName(),
          state.getDescription(),
          state.getIsComplex(),
          state.getStateFields(),
          state.getLogicId(),
          state.getProperties(),
          state.getAlias(),
          state.getParentTreeElementId()
        )
      }

      return new StateUpdateCommand(
        state.getGuid(),
        state.getName(),
        state.getDescription(),
        state.getIsComplex(),
        state.getStateFields(),
        state.getProperties(),
        state.getAlias()
      )
    },

    // Добавить условие
    addStateField () {
      this.model.state_fields.push(StateFieldDTO.create())
    },

    // Удалить условие
    removeStateField (index) {
      this.$confirm(this.$t('main.message.delete'), this.$t('main.message_title.warning'), {
        confirmButtonText: this.$t('main.button.delete'),
        cancelButtonText: this.$t('main.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.model.state_fields.splice(index, 1)
      })
    },

    buildCondition (index) {
      const condition = this.model.state_fields[index]

      let result = `Условие: <b>object_${this.objectId}_.attr_${condition.field_id ? condition.field_id : 'N'}_</b>`

      const prop = condition.properties
      if (condition.state_field_type_id === 'external_table_field') {
        if (prop) {
          const attr = prop.field_id ? (prop.field_id === 'id' ? 'id' : `attr_${prop.field_id}_`) : 'attr_N_'

          result += ` = <b>object_${prop.registry_id ? prop.registry_id : 'N'}_.${attr}</b>`
        }
      } else if (condition.state_field_type_id === 'constant') {
        result += ` = <b>${prop.value ? prop.value : '?'}</b>`
      } else if (condition.state_field_type_id === 'parameter') {
        result += ` = <b>${prop.name ? `Параметр(${prop.name})` : '?'}</b>`
      } else if (condition.state_field_type_id === 'condition') {
        const conditions = {
          is_null: 'Пусто',
          is_not_null: 'Не пусто'
        }

        result += ` = <b>${condition.condition_field_type_id ? conditions[condition.condition_field_type_id] : '?'}</b>`
      } else if (condition.state_field_type_id === 'users_table') {
        result += ` = <b>${condition.users_field_id ? `Сотрудники.attr_${condition.users_field_id}_` : '?'}</b>`
      } else if (condition.state_field_type_id === 'current_table_field') {
        result += ` = <b>${condition.current_table_field_id ? `object_${this.objectId}_.attr_${condition.current_table_field_id}_` : '?'}</b>`
      } else {
        const value = {
          current_user: 'Текущий пользователь',
          current_datetime: 'Текущая дата'
        }

        result += ` = <b>${value[condition.state_field_type_id] ? value[condition.state_field_type_id] : 'Что это?'}</b>`
      }

      return result
    },

    changeIsComplex (value) {
      if (value === true) {
        if (this.qbFields.length < 1) {
          this.qbFields = this.buildFieldList(this.objectFields)
        }

        this.model.state_fields = []
        this.model.properties = {
          filters: { children: [], logical_operator: 'and' }
        }
      }
    },

    buildFieldList (fields) {
      let fieldList = []
      fields.forEach((field) => {
        if ([
          'string_field', 'text_field', 'integer_field',
          'float_field', 'date_field', 'time_field',
          'datetime_field', 'boolean_field', 'xref_field',
          'xref_multi_field', 'field_group'
        ].indexOf(field.entity_type_id) !== -1) {
          let qbField = {
            id: field.id,
            name: field.name,
            alias: `attr_${field.id}_`,
            objectId: field.object_id,
            fieldTypeId: field.entity_type_id,
            children: []
          }

          if (field.entity_type_id === 'field_group' && field.children.length) {
            qbField.children.push(...this.buildFieldList(field.children))
          }

          fieldList.push(qbField)
        }
      })
      return fieldList
    },

    fieldValid () {
      if (this.$refs.stateFieldForm) {
        let cntx = 0

        this.$refs.stateFieldForm.forEach((form) => {
          if (form.validate()) {
            cntx++
          }
        })

        return cntx === this.$refs.stateFieldForm.length
      }

      return true
    }
  }
})
