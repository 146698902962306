<template>
  <div class="editor-row-class-rules">
    <el-button type="text" size="medium" @click="dialogSettingsVisible = true">{{ $t('row_selection_condition') }}</el-button>
    <el-dialog
      :title="$t('row_selection_condition')"
      :modal="false"
      :visible.sync="dialogSettingsVisible">
       <el-alert
        :title="$t('need_to_register_necessary_classes')"
        description="Пример: .className {background-color: sandybrown !important;}.
        Если два и более условия выполняются одновременно - приоритетным считается класс расположенный ниже."
        type="info"
        effect='dark'
        show-icon>
      </el-alert>
      <el-button size="small" class="add_condition" @click="addCondition">{{$locale.interface_editor.component_editor.editor_control_values.add_condition}}</el-button>
      <el-collapse v-model="activeNames" v-for="(condition, index) in conditions" :key="index">
        <el-row type="flex" align="middle">
          <el-col :span="21">
            <el-collapse-item :title="condition.nameCondition" :name="condition.id" class="condition">

                <el-row>
                <el-col :span="12">
                  <div>{{$t('name_conditions')}}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-input v-model="condition.nameCondition" size="mini" />
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <div>{{$t('class')}}</div>
                </el-col>
                <el-col :span="6" :offset="1">
                  <div>{{$t('link_conditions')}}</div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-input v-model="condition.className" size="mini" />
                </el-col>
                <el-col :span="6" :offset="1">
                  <el-radio-group v-model="condition.operator">
                    <el-radio-button label="and">{{$t('and')}}</el-radio-button>
                    <el-radio-button label="or">{{$t('or')}}</el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>

            <EditorFiltersWithClass
              :value="condition.dataFilter"
              :isOnlyMathOperation="true"
              :isFieldAndConstant="true"
              :title="$t('condition')">
              @change="getDataFilters"
            </EditorFiltersWithClass>

            </el-collapse-item>
          </el-col>
          <el-col :span="2" :offset="1"><el-button size="small" @click="deleteCondition(condition.id)">{{$t('main.button.delete')}}</el-button></el-col>
        </el-row>
      </el-collapse>

      <!-- <EditorFiltersWithClass @change="getDataFilters" :value="value" :title="$t('condition')">
        <template v-slot:header="{ condition }">
          <el-input v-model="condition.className" size="mini" />
        </template>
      </EditorFiltersWithClass> -->
    </el-dialog>
  </div>
</template>

<script>
import EditorFiltersWithClass from '@/components/InterfaceEditor/components/editor/editor-filters.vue'
export default {
  name: 'editor-row-class-rules',
  components: {
    EditorFiltersWithClass
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      dialogSettingsVisible: false,
      activeNames: null,
      conditions: []
    }
  },
  watch: {
    conditions: {
      handler (value) {
        console.log({ value })
        this.$emit('change', value)
      },
      deep: true
    }
  },
  methods: {
    addCondition () {
      this.conditions.push(
        {
          id: Date.now(),
          operator: 'and',
          className: 'className',
          nameCondition: 'Название условия',
          dataFilter: []
        }
      )
    },
    deleteCondition (id) {
      this.conditions = this.conditions.filter(item => item.id !== id)
    },
    getDataFilters (value) {
      console.log('getDataFilters', value)
    }
  },

  mounted () {
    if (this.value) {
      this.conditions = this.value
    }
  }
}
</script>

<style scoped>
.editor-row-class-rules .add_condition {
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}
.editor-row-class-rules .condition {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #f5f5f5;
  border-color: #ddd;
  border-left: 2px solid #8bc34a;
  padding: 15px;
}
</style>
