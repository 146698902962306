var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-popover',{staticClass:"popover",attrs:{"placement":"bottom-start","trigger":"click","popper-class":"interface_editor_popper","disabled":_vm.disabled},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('span',{class:{
    'active': _vm.isActive,
    'reference': true,
    'icon_button': true,
    'disabled': _vm.disabled,
    'mini': _vm.mini
    },attrs:{"slot":"reference"},slot:"reference"},[(_vm.icon)?_c('font-awesome-icon',{attrs:{"icon":_vm.icon}}):_vm._e(),((_vm.title || '').length)?_c('span',{staticClass:"label",staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('i',{class:{
    'el-icon-caret-top': _vm.isActive,
    'el-icon-caret-bottom': !_vm.isActive,
    'el-icon--right': true
  }})],1),_vm._t("default",null,{"isActive":_vm.isActive})],2)}
var staticRenderFns = []

export { render, staticRenderFns }