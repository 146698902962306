<template>
  <el-form size="mini" style="padding: 10px">
    <editor-open-card
      :isButton="false"
      :sourceId="registryId"
      :sourceType="'registry'"
      :isHeaders="true"
      :value="value.card"
    ></editor-open-card>
  </el-form>
</template>

<script>
import EditorOpenCard from '@/components/InterfaceEditor/components/editor/editor-open-card'

export default {
  name: 'Interactive',
  components: { EditorOpenCard },
  props: {
    entityId: Number,
    value: Object
  },
  data () {
    return {
      registryId: this.entityId,
    }
  },
  created () {
    if (Object.keys(this.value.card.card).length === 0) {
      this.$set(this.value.card, 'card', JSON.parse(JSON.stringify(this.value.card)))
    }
    this.$nextTick(() => {this.$set(this.value.card, 'type', 'open_card')}, this)
  }
}
</script>

<style scoped>

</style>
